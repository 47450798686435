import { Dimensions } from 'react-native';

export function getRealWidth(deviceContext, type: 'window' | 'screen' = 'window') {

    switch (deviceContext.deviceType as any) {
        case 'KaiOS':
            return  Dimensions.get(type).width;
        case 'FireTV':
            return Dimensions.get(type).width;
        case 'AndroidTV':
            return Dimensions.get(type).width;
        case 'AppleTV':
            return Dimensions.get(type).width;
        case 'SmartTV_Tizen':
            return Dimensions.get(type).width;
        case 'SmartTV_LG':
            return Dimensions.get(type).width;
        case 'PWA':
            return Dimensions.get(type).width;
        case 'WebTV':
            return Dimensions.get('window').width;
        case 'iOS':
            return Dimensions.get(type).width;
        case 'iPad':
            return Dimensions.get(type).width;
        case 'Android_Mobile':
            return Dimensions.get(type).width;
        case 'Android_Tablet':
            return Dimensions.get(type).width;
        default:
            break;
    }
}
export function getRealHeight(deviceContext, type: 'window' | 'screen' = 'window') {
    switch (deviceContext.deviceType as any) {
        case 'KaiOS':
            return Dimensions.get(type).height;
        case 'FireTV':
            return Dimensions.get(type).height;
        case 'AndroidTV':
            return Dimensions.get(type).height;
        case 'AppleTV':
            return Dimensions.get(type).height;
        case 'SmartTV_Tizen':
            return Dimensions.get(type).height;
        case 'SmartTV_LG':
            return Dimensions.get(type).height;
        case 'PWA':
            return Dimensions.get(type).height;
        case 'WebTV':
            return Dimensions.get('window').height;
        case 'iOS':
            return Dimensions.get(type).height;
        case 'iPad':
            return Dimensions.get(type).height;
        case 'Android_Mobile':
            return Dimensions.get(type).height;
        case 'Android_Tablet':
            return Dimensions.get(type).height;
        case 'PWA':
            return Dimensions.get(type).height;
        default:
            break;
    }
}
