import * as React from 'react';
import { DeviceContext } from '../../../context/deviceContext';

import Slider_Portrait from './slider.portrait';
import Slider_Landscape from './slider.landscape';

export const Hero_Slider = ({ styling, navigation, focusContext }) => {
    const deviceContext = React.useContext(DeviceContext);
    return (
        <>
            {(deviceContext.isPhone || deviceContext.isPwaVertical) && (
                <Slider_Portrait styling={styling} navigation={navigation} focusContext={focusContext}></Slider_Portrait>
            )}
            {!deviceContext.isPhone && !deviceContext.isKaiOs && !deviceContext.isPwaVertical && (
                <Slider_Landscape styling={styling} navigation={navigation} focusContext={focusContext}></Slider_Landscape>
            )}
        </>
    );
};

export default Hero_Slider;
