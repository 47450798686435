import { View, Image } from '@scriptx-com/xtv-toolkit';
import { CommonActions, useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import * as React from 'react';
import Pressable from '../../../application/builder/components/pressable/pressable';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import utils from '../../../application/general/utils';
import { useSwipe } from '../../../application/hooks/useSwipe';
import { useTVRemoteHandler } from '../../../application/hooks/useTVRemoteHandler';
import lang from '../../../application/languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealHeight } from '../../../application/styling/realDeviceSizes';
import { sendPageReport } from '../../../reporting/apis/page';
import { pageType } from '../../../reporting/models/apps';
import ScreenWrapper from '../../wrapper';
import Text from '../../../application/builder/components/text';
import { getPressableStyling, getFocusStyling, getPressableTextStyling } from '../../../application/builder/components/helpers/helper';

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const [onboardings] = React.useState(appContext.application.authentication.onboarding.slides);
    const [index, setIndex] = React.useState(0);

    useTVRemoteHandler(({ eventType, eventKeyAction }: any) => {
        if (eventType == 'back' && eventKeyAction == 'down') {
            userContext.setSelectedLanguage('');
            userContext.setSelectedLanguageCode('');
            userContext.setAutoLogin(false);
            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'Language' }],
                })
            );
        }
    });
    React.useEffect(() => {
        const smartTVRemoteHandler = (event) => {
            const { key, keyCode } = event;
            if (keyCode == 413 || keyCode == 10009 || keyCode == 461) {
                userContext.setSelectedLanguage('');
                userContext.setSelectedLanguageCode('');
                userContext.setAutoLogin(false);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Language' }],
                    })
                );
            }
        };

        if (deviceContext.isTizen || deviceContext.isWebos) {
            window.addEventListener('keydown', smartTVRemoteHandler);
        }
        return () => {
            if (deviceContext.isTizen || deviceContext.isWebos) {
                window.removeEventListener('keydown', smartTVRemoteHandler);
            }
        };
    }, []);

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.ONBOARDING, duration);
            };
        }, [])
    );

    const openLogin = () => {
        userContext.setIsOnboardingFinished(true);
        utils.storeJson('OnboardingFinished', true);
        if (appContext.application?.settings.enable_anonymous == undefined || appContext.application?.settings.enable_anonymous == false) {
            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'Login' }],
                })
            );
        } else {
            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'Anonymous' }],
                })
            );
        }
    };
    const getStarted = () => {
        if (appContext.application.settings.enable_signup) {
            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'GetStarted' }],
                })
            );
        }
    };

    const registerAccount = () => {
        if (appContext.application.settings.enable_register) {
            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'Register' }],
                })
            );
        }
    };

    const nextSlide = () => {
        var newIndex = index + 1;
        if (onboardings.length > newIndex) {
            setIndex(newIndex);
        }
    };
    const previousSlide = () => {
        var newIndex = index - 1;
        if (newIndex >= 0) {
            setIndex(index - 1);
        }
    };

    const { onTouchStart, onTouchEnd } = useSwipe(nextSlide, previousSlide, 6);

    const getOnboardingTitle = (type) => {
        switch (type) {
            case 'watchanydevice.png':
                return 'watch_on_any_device';
                break;
            case 'nopeskycontracts.png':
                return 'no_pesky_contracts';
                break;
            case 'tvthewayyoulike.png':
                return 'tv_the_way_you_like';
                break;
            case 'allthecontent.png':
                return 'all_content_fingertips';
                break;
            case 'securedprofiles.png':
                return 'secured_profiles';
                break;
            case 'howdoiwatchmember.png':
                return 'how_watch_members';
                break;
            case 'howdoiwatch2.png':
                return 'how_watch_signup';
                break;
            case 'howdoiwatch.png':
                return 'how_watch_signup';
                break;
            default:
                break;
        }
    };
    const getOnboardingInfo = (type) => {
        switch (type) {
            case 'watchanydevice.png':
                return 'watch_on_any_device_info';
                break;
            case 'nopeskycontracts.png':
                return 'no_pesky_contracts_info';
                break;
            case 'tvthewayyoulike.png':
                return 'tv_the_way_you_like_info';
                break;
            case 'allthecontent.png':
                return 'all_content_fingertips_info';
                break;
            case 'securedprofiles.png':
                return 'secured_profiles_info';
                break;
            case 'howdoiwatchmember.png':
                return 'how_watch_members_info';
                break;
            case 'howdoiwatch2.png':
                return 'how_watch_signup_info';
                break;
            case 'howdoiwatch.png':
                return 'how_watch_signup_info';
                break;
            default:
                break;
        }
    };

    const getPaging = () => {
        var paging = [] as any;
        for (let index_ = 0; index_ < onboardings.length; index_++) {
            paging.push(
                <View
                    key={index_}
                    style={{
                        borderRadius: 100,
                        backgroundColor: index == index_ ? '#666' : '#fff',
                        height: getCorrectWidth(deviceContext, 5),
                        width: getCorrectWidth(deviceContext, 10),
                        margin: getCorrectWidth(deviceContext, 2),
                    }}
                ></View>
            );
        }
        return paging;
    };

    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ focusKey: 'screen' }}>
            <View onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} style={{ flex: 1, backgroundColor: '#111', alignItems: 'center' }}>
                <View
                    style={{
                        flex: deviceContext.isKaiOs || deviceContext.isIos ? 1 : 2,
                        alignItems: 'center',
                        paddingTop: getCorrectHeight(
                            deviceContext,
                            (deviceContext.isPhone && !deviceContext.isIos) || deviceContext.isPwaVertical ? 75 : deviceContext.isKaiOs ? 10 : 50
                        ),
                    }}
                >
                    <Text
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                            fontWeight: appContext.application.authentication?.onboarding?.header.sub_bold ? 'bold' : 'normal',
                            color: '#fff',
                            textAlign: 'center',
                        }}
                    >
                        {lang.getTranslation(userContext, getOnboardingTitle(onboardings[index].image))}
                    </Text>
                </View>
                <View style={{ flex: 3 }}>
                    {(deviceContext.isPwa || deviceContext.isKaiOs || deviceContext.isPhone || deviceContext.isTablet) && (
                        <Image
                            resizeMode={'contain'}
                            style={{ height: getRealHeight(deviceContext) * 0.3, width: getRealHeight(deviceContext) * 0.3 }}
                            source={{
                                uri: 'https://cloudtv.akamaized.net/donotremove/tvms/onboardings/app/' + onboardings[index].image,
                            }}
                        />
                    )}

                    {!deviceContext.isPwa &&
                        !deviceContext.isPhone &&
                        !deviceContext.isKaiOs &&
                        !deviceContext.isPwaVertical &&
                        !deviceContext.isTablet && (
                            <Image
                                resizeMode={'contain'}
                                style={{ height: getRealHeight(deviceContext) * 0.3, width: getRealHeight(deviceContext) * 0.3 }}
                                source={{
                                    uri: 'https://cloudtv.akamaized.net/donotremove/tvms/onboardings/app/' + onboardings[index].image,
                                }}
                            />
                        )}
                </View>
                <View
                    style={{
                        flex: 2,
                        alignItems: 'center',
                        paddingTop: getCorrectHeight(
                            deviceContext,
                            deviceContext.isPhone || deviceContext.isPwaVertical ? 50 : deviceContext.isKaiOs ? 10 : 25
                        ),
                    }}
                >
                    <View>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Large'),
                                fontWeight: appContext.application.authentication?.onboarding?.header.sub_bold ? 'bold' : 'normal',
                                color: '#fff',
                                textAlign: 'center',
                                marginHorizontal: getCorrectWidth(deviceContext, 20),
                            }}
                        >
                            {lang.getTranslation(userContext, getOnboardingInfo(onboardings[index].image))}
                        </Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', paddingTop: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 5 : 25) }}>
                        {getPaging()}
                    </View>
                </View>

                <View style={{ flex: 1, flexDirection: 'row', paddingBottom: getCorrectHeight(deviceContext, deviceContext.isPhone ? 50 : 25) }}>
                    {index > 0 && index != onboardings.length - 1 && (
                        <Pressable
                            style={{
                                margin: getCorrectWidth(deviceContext, 5),
                                width: getCorrectWidth(deviceContext, 100),

                                height: getCorrectHeight(deviceContext, 30),
                                ...getPressableStyling(appContext.application.authentication.onboarding.components?.button?._id, appContext),
                            }}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => previousSlide()}
                        >
                            <View
                                style={{
                                    height: getCorrectHeight(deviceContext, 30),
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        ...getPressableTextStyling(
                                            appContext.application.authentication.onboarding.components?.button?._id,
                                            appContext
                                        ),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'previous')}
                                </Text>
                            </View>
                        </Pressable>
                    )}
                    {index == onboardings.length - 1 && (
                        <Pressable
                            style={{
                                margin: getCorrectWidth(deviceContext, 5),
                                width: getCorrectWidth(deviceContext, 100),

                                height: getCorrectHeight(deviceContext, 30),
                                ...getPressableStyling(appContext.application.authentication.onboarding.components?.button?._id, appContext),
                            }}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => openLogin()}
                        >
                            <View
                                style={{
                                    height: getCorrectHeight(deviceContext, 30),
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        ...getPressableTextStyling(
                                            appContext.application.authentication.onboarding.components?.button?._id,
                                            appContext
                                        ),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'login')}
                                </Text>
                            </View>
                        </Pressable>
                    )}
                    {index == onboardings.length - 1 &&
                        appContext.application.settings.enable_signup &&
                        appContext.application.settings.offer_type == 'cart' && (
                            <Pressable
                                style={{
                                    margin: getCorrectWidth(deviceContext, 5),
                                    width: getCorrectWidth(deviceContext, 100),

                                    height: getCorrectHeight(deviceContext, 30),
                                    ...getPressableStyling(appContext.application.authentication.onboarding.components?.button?._id, appContext),
                                }}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                onPress={() => getStarted()}
                            >
                                <View
                                    style={{
                                        height: getCorrectHeight(deviceContext, 30),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            ...getPressableTextStyling(
                                                appContext.application.authentication.onboarding.components?.button?._id,
                                                appContext
                                            ),
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'get_started')}
                                    </Text>
                                </View>
                            </Pressable>
                        )}
                    {index == onboardings.length - 1 &&
                        appContext.application.settings.enable_register &&
                        appContext.application.settings.offer_type == 'freemium' && (
                            <Pressable
                                style={{
                                    margin: getCorrectWidth(deviceContext, 5),
                                    width: getCorrectWidth(deviceContext, 100),

                                    height: getCorrectHeight(deviceContext, 30),
                                    ...getPressableStyling(appContext.application.authentication.onboarding.components?.button?._id, appContext),
                                }}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                onPress={() => registerAccount()}
                            >
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: getCorrectHeight(deviceContext, 30),
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            ...getPressableTextStyling(
                                                appContext.application.authentication.onboarding.components?.button?._id,
                                                appContext
                                            ),
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'register_free_account')}
                                    </Text>
                                </View>
                            </Pressable>
                        )}

                    {index < onboardings.length - 1 && (
                        <Pressable
                            style={{
                                margin: getCorrectWidth(deviceContext, 5),
                                width: getCorrectWidth(deviceContext, 100),

                                height: getCorrectHeight(deviceContext, 30),
                                ...getPressableStyling(appContext.application.authentication.onboarding.components?.button?._id, appContext),
                            }}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => nextSlide()}
                        >
                            <View
                                style={{
                                    height: getCorrectHeight(deviceContext, 30),
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        ...getPressableTextStyling(
                                            appContext.application.authentication.onboarding.components?.button?._id,
                                            appContext
                                        ),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'next')}
                                </Text>
                            </View>
                        </Pressable>
                    )}
                </View>
            </View>
        </ScreenWrapper>
    );
};
export default Screen_;
