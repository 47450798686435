import moment from 'moment';

export const getApp = async (packageId) => {
    try {
        var path = 'https://cloudtv.akamaized.net/donotremove/tvms/domains/' + packageId + '/app.json?' + moment().unix();
        return await fetch(path)
            .then((response) => response.json())
            .then((responseJson) => {
                global.Application = responseJson;
                return { success: true };
            });
    } catch (err) {
        return { success: false };
    }
};

export const getCart = async (client, deployment, service, webshop_id, appContext) => {
    try {
        var pathCart = 'https://cloudtv.akamaized.net/' + client + '/' + deployment + '/' + service + '/carts/' + webshop_id + '_cart.json';
        return await fetch(pathCart)
            .then((responseCart) => responseCart.json())
            .then((responseCartJson) => {
                global.Cart = responseCartJson;
                appContext.setCart(responseCartJson);
                return { success: true };
            });
    } catch (err) {
        return { success: true };
    }
};

export const getLanguages = async (languages, client, deployment) => {
    try {
        var urls = [] as any;
        languages.forEach((language) => {
            urls.push({
                url:
                    'https://cloudtv.akamaized.net/' +
                    client +
                    '/' +
                    deployment +
                    '/appbuilder/translations/' +
                    language.name +
                    '.json?t=' +
                    moment().unix(),
            });
        });
        var translations = [] as any;
        var promises = urls.map((url) => fetch(url.url).then((y) => y.json()));
        return Promise.all(promises).then((results) => {
            results.forEach((result, index) => {
                translations.push({
                    language: languages[index].name,
                    translations: result.language,
                });
            });
            global.Translations = translations;
        });
    } catch (err) {
        return { success: false };
    }
};
