import { FocusContext, View, Pressable } from '@scriptx-com/xtv-toolkit';
import * as React from 'react';
import { AppContext } from '../../../context/appContext';
import { DeviceContext } from '../../../context/deviceContext';
import { useEffectOnce } from '../../../hooks/useEffectOnce';
import { getCorrectWidth } from '../../../styling/correctSizes';
import { getRealWidth } from '../../../styling/realDeviceSizes';
import { getAdsStyling, getFocusStyling } from '../../components/helpers/helper';
import { RemoteImage } from '../../components/remoteimage/remoteimage';
import { getCorrectLeftMargin } from '../../../styling/appLeftMargin';
import { getCurrentScreenName } from '../../../hooks/getScreenName';

export interface Data {
    image: string;
}

export interface Props {
    styling: any;
    focusContext?: FocusContext;
    fromLoader?: boolean;
}

export const Promotions_Ads = ({ styling, focusContext, fromLoader }: Props) => {
    const [show, setShow] = React.useState(false);
    const [data, setData] = React.useState<Data>([] as any);
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const screenName = getCurrentScreenName();

    const adWidth =
        getRealWidth(deviceContext) / (fromLoader != undefined && !deviceContext.isPhone ? 1.5 : 1) -
        getCorrectWidth(deviceContext, 10) -
        getCorrectWidth(deviceContext, global.menuWidth) -
        getCorrectLeftMargin(deviceContext, appContext, screenName) -
        getCorrectLeftMargin(deviceContext, appContext, screenName);

    const getAd = async () => {
        setData({
            image: styling.image_url,
        });
        setShow(true);
    };

    useEffectOnce(() => {
        getAd();
    });

    return (
        <View focusContext={focusContext}>
            {show && (
                <View
                    style={{
                        ...getAdsStyling(appContext),

                        marginLeft: getCorrectLeftMargin(deviceContext, appContext, screenName) +3,
                        marginRight: getCorrectLeftMargin(deviceContext, appContext, screenName) + 3,
                        marginTop: appContext.application.theme.rails_top_margin,
                        marginBottom: appContext.application.theme.rails_bottom_margin,
                    }}
                >
                    <Pressable  animatorOptions={getFocusStyling('Buttons', appContext)} hasTVPreferredFocus={false} style={{
                        borderRadius:getAdsStyling(appContext).borderRadius
                    }}>
                        <View
                            style={{
                                ...getAdsStyling(appContext),
                                display: 'flex',
                                flex: 1,
                                padding: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 5 : 3),
                                flexDirection: 'row',
                                justifyContent: 'center',
                                backgroundColor: 'transparent',
                            }}
                        >
                            <RemoteImage
                                style={{
                                   // borderRadius: getAdsStyling(appContext).borderRadius,
                                }}
                                uri={data.image}
                                desiredWidth={adWidth}
                            />
                        </View>
                    </Pressable>
                </View>
            )}
        </View>
    );
};

export default Promotions_Ads;
