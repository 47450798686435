import { Pressable, ScrollView, View } from '@scriptx-com/xtv-toolkit';
import { useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import * as React from 'react';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import lang from '../../../application/languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import { useSafeAreaInsets } from '../../../libs/react-native-safe-area-context';
import { sendPageReport } from '../../../reporting/apis/page';
import { pageType } from '../../../reporting/models/apps';
import ScreenWrapper from '../../wrapper';
import { getFocusOptions } from '../../wrapper/helpers/getFocusOptions';
import Text from '../../../application/builder/components/text';

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const insets = useSafeAreaInsets();
    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        setShow(true);
    }, [navigation, route]);

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.SUPPORT, duration);
            };
        }, [])
    );

    const focusOptions = getFocusOptions(deviceContext, appContext, 'settings-screen');

    return (
        <ScreenWrapper
            style={{ flex: 1 }}
            focusOptions={{
                ...focusOptions,
                group: 'settings-screen',
            }}
        >
            {show && (
                <View style={{ flex: 1, marginLeft: getCorrectWidth(deviceContext, global.menuWidth) }}>
                    <View
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: appContext.application.theme.settings.background_color1,
                            borderRadius: appContext.application.theme.settings.border_radius,
                        }}
                    >
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                fontWeight: appContext.application.authentication?.services?.header.title_bold ? 'bold' : 'normal',
                                color: '#ffffff',
                            }}
                        >
                            {lang.getTranslation(userContext, 'support')}
                        </Text>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, appContext.application.authentication?.services?.header.sub_size),
                                fontWeight: appContext.application.authentication?.services?.header.sub_bold ? 'bold' : 'normal',
                                color: '#ffffff',
                                textAlign: 'center',
                            }}
                        >
                            {lang.getTranslation(userContext, 'support_info').toUpperCase()}
                        </Text>
                    </View>
                    <View
                        style={{ flex: 4, alignItems: 'center', paddingBottom: getCorrectHeight(deviceContext, deviceContext.isTelevision ? 0 : 30) }}
                    >
                        <ScrollView style={{ flex: 4 }}>
                            {appContext.application.settings.phone != '' && (
                                <Pressable
                                    focusOptions={{
                                        animator: {
                                            type: 'scale',
                                            focus: {
                                                scale: 1.01,
                                            },
                                        },
                                    }}
                                    style={{
                                        flexDirection: 'row',
                                        width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth + 20),
                                        backgroundColor: appContext.application.theme.settings.background_color2,
                                        borderRadius: appContext.application.theme.settings.border_radius,

                                        marginHorizontal: getCorrectWidth(deviceContext, 10),
                                        marginVertical: getCorrectWidth(deviceContext, 2),
                                    }}
                                >
                                    <View
                                        style={{
                                            flex: 1,
                                            flexDirection: 'column',
                                            padding: getCorrectWidth(deviceContext, 10),
                                        }}
                                    >
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text
                                                style={{
                                                    color: appContext.application.theme.settings.font_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'phone_contact')}:{' '}
                                            </Text>
                                            <Text
                                                style={{
                                                    color: appContext.application.theme.settings.font_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                }}
                                            >
                                                {appContext.application.settings.phone}
                                            </Text>
                                        </View>
                                    </View>
                                </Pressable>
                            )}
                            {appContext.application.settings.skype != '' && (
                                <Pressable
                                    focusOptions={{
                                        animator: {
                                            type: 'scale',
                                            focus: {
                                                scale: 1.01,
                                            },
                                        },
                                    }}
                                    style={{
                                        flexDirection: 'row',
                                        width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth + 20),
                                        backgroundColor: appContext.application.theme.settings.background_color2,
                                        borderRadius: appContext.application.theme.settings.border_radius,

                                        marginHorizontal: getCorrectWidth(deviceContext, 10),
                                        marginVertical: getCorrectWidth(deviceContext, 2),
                                    }}
                                >
                                    <View
                                        style={{
                                            flex: 1,
                                            flexDirection: 'column',
                                            padding: getCorrectWidth(deviceContext, 10),
                                        }}
                                    >
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text
                                                style={{
                                                    color: appContext.application.theme.settings.font_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'skype_contact')}:{' '}
                                            </Text>
                                            <Text
                                                style={{
                                                    color: appContext.application.theme.settings.font_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                }}
                                            >
                                                {appContext.application.settings.skype}
                                            </Text>
                                        </View>
                                    </View>
                                </Pressable>
                            )}
                            {appContext.application.settings.whatsapp != '' && (
                                <Pressable
                                    focusOptions={{
                                        animator: {
                                            type: 'scale',
                                            focus: {
                                                scale: 1.01,
                                            },
                                        },
                                    }}
                                    style={{
                                        flexDirection: 'row',
                                        width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth + 20),
                                        backgroundColor: appContext.application.theme.settings.background_color2,
                                        borderRadius: appContext.application.theme.settings.border_radius,

                                        marginHorizontal: getCorrectWidth(deviceContext, 10),
                                        marginVertical: getCorrectWidth(deviceContext, 2),
                                    }}
                                >
                                    <View
                                        style={{
                                            flex: 1,
                                            flexDirection: 'column',
                                            padding: getCorrectWidth(deviceContext, 10),
                                        }}
                                    >
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text
                                                style={{
                                                    color: appContext.application.theme.settings.font_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'whatsapp_contact')}:{' '}
                                            </Text>
                                            <Text
                                                style={{
                                                    color: appContext.application.theme.settings.font_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                }}
                                            >
                                                {appContext.application.settings.whatsapp}
                                            </Text>
                                        </View>
                                    </View>
                                </Pressable>
                            )}
                            {appContext.application.settings.email != '' && (
                                <Pressable
                                    focusOptions={{
                                        animator: {
                                            type: 'scale',
                                            focus: {
                                                scale: 1.01,
                                            },
                                        },
                                    }}
                                    style={{
                                        flexDirection: 'row',
                                        width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth + 20),
                                        backgroundColor: appContext.application.theme.settings.background_color2,
                                        borderRadius: appContext.application.theme.settings.border_radius,

                                        marginHorizontal: getCorrectWidth(deviceContext, 10),
                                        marginVertical: getCorrectWidth(deviceContext, 2),
                                    }}
                                >
                                    <View
                                        style={{
                                            flex: 1,
                                            flexDirection: 'column',
                                            padding: getCorrectWidth(deviceContext, 10),
                                        }}
                                    >
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text
                                                style={{
                                                    color: appContext.application.theme.settings.font_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'email_contact')}:{' '}
                                            </Text>
                                            <Text
                                                style={{
                                                    color: appContext.application.theme.settings.font_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                }}
                                            >
                                                {appContext.application.settings.email}
                                            </Text>
                                        </View>
                                    </View>
                                </Pressable>
                            )}
                            {appContext.application.settings.website != '' && (
                                <Pressable
                                    focusOptions={{
                                        animator: {
                                            type: 'scale',
                                            focus: {
                                                scale: 1.01,
                                            },
                                        },
                                    }}
                                    style={{
                                        flexDirection: 'row',
                                        width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth + 20),
                                        backgroundColor: appContext.application.theme.settings.background_color2,
                                        borderRadius: appContext.application.theme.settings.border_radius,

                                        marginHorizontal: getCorrectWidth(deviceContext, 10),
                                        marginVertical: getCorrectWidth(deviceContext, 2),
                                    }}
                                >
                                    <View
                                        style={{
                                            flex: 1,
                                            flexDirection: 'column',
                                            padding: getCorrectWidth(deviceContext, 10),
                                        }}
                                    >
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text
                                                style={{
                                                    color: appContext.application.theme.settings.font_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'website_contact')}:{' '}
                                            </Text>
                                            <Text
                                                style={{
                                                    color: appContext.application.theme.settings.font_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                }}
                                            >
                                                {appContext.application.settings.website}
                                            </Text>
                                        </View>
                                    </View>
                                </Pressable>
                            )}
                        </ScrollView>
                    </View>
                </View>
            )}
        </ScreenWrapper>
    );
};
export default Screen_;
