import * as React from 'react';
import { DeviceContext } from '../../../context/deviceContext';
import Landscape from './Scrubber_Landscape';
import Portrait from './Scrubber_Portrait';
import { getCurrentScreenName } from '../../../hooks/getScreenName';

export const Details_Top = (props) => {
    const deviceContext = React.useContext(DeviceContext);
    const screenName = getCurrentScreenName();

    return (
        <>
            {(deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && screenName != 'Album_Player' && screenName != 'Radio_Player' && screenName != 'Podcast_Player' && !deviceContext.isApple && <Portrait {...props}></Portrait>}
            {!deviceContext.isPhone && !deviceContext.isKaiOs && !deviceContext.isPwaVertical && <Landscape {...props}></Landscape>}
            {deviceContext.isPhone && deviceContext.isApple && screenName != 'Album_Player' && screenName != 'Radio_Player' && screenName != 'Podcast_Player' && <Landscape {...props}></Landscape>}
            {(screenName == 'Album_Player' || screenName == 'Radio_Player' || screenName == 'Podcast_Player') && deviceContext.isPhone && <Landscape {...props}></Landscape>}
        </>
    );
};

export default Details_Top;
