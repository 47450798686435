const events: {
    [key: string]: { [key: string]: (...args: any) => void };
} = {};

class Event {
  static subscribe(
      eventName: string,
      cb: (...args: any) => void
  ) {
      if (!eventName) throw 'Event name cannot be empty or null';
      if (!cb) throw 'A callback must be registered for subscription';

      const uuid = Event._generateGuid();

      if (!events[eventName]) {
          events[eventName] = {};
      }

      // @ts-ignore
      events[eventName][uuid] = cb;

      return () => {
        Event._unsubscribe(eventName, uuid);
      }
  }

  static _generateGuid() {
      const s4 = () =>
          Math.floor((1 + Math.random()) * 0x10000)
              .toString(16)
              .substring(1);

      return (
          s4() +
          s4() +
          '-' +
          s4() +
          '-' +
          s4() +
          '-' +
          s4() +
          '-' +
          s4() +
          s4() +
          s4()
      );
  }

  static emit(
      eventName: string,
      data = {}
  ) {
      if (!eventName) throw 'An event name is required for emission.';
      // The callbacks for eventName are registered listener Id keys under events[eventName]
      const registeredListeners = events[eventName];

      try {
          // @ts-ignore
          for (const uuid of Object.keys(registeredListeners)) {
              // @ts-ignore
              registeredListeners[uuid](data);
          }
      } catch (x) {
          // errors when no listeners are initialized, safe to ignore
      }

      return true;
  }

  static _unsubscribe(
      eventName: string,
      uuid: string
  ) {
      delete events[eventName]?.[uuid];
  }

  static destroy(callerType: string, callerId: string) {
      // @ts-ignore
      delete events[callerType][callerId];
  }
}

export default Event;
