import { ScrollView as CreateScrollView, setFocus, View, ViewGroup } from '@scriptx-com/xtv-toolkit';
//@ts-expect-error
import { PressableFocusOptions } from '@scriptx-com/xtv-toolkit/src/focusManager/type';
import { faCheck, faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { isPlatformAndroid } from '@rnv/renative';
import moment from 'moment';
import * as React from 'react';
import { ScrollView as RNGHScrollView } from 'react-native-gesture-handler';
import { useTVRemoteHandler } from '../../../../application/hooks/useTVRemoteHandler';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { AppContext } from '../../../context/appContext';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import lang from '../../../languages/languages';
import { Dropdown as Styling } from '../../../models/components/dropdown/dropdown';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import { getCorrectName } from '../../elements/players/helpers/functions';
import Pressable from '../pressable/pressable';
import Text from '../text';
import { getPressableStyling, getFocusStyling, getPressableTextStyling } from '../helpers/helper';
import { ContentContext } from '../../../context/contentContext';
import { colorShade } from '../../../styling/colorShade';

export interface InputProps {
    props?: any;
    styling: Styling;
    animation: any;
    value: any;
    dataIn: any;
    type: any;
    setSelection: any;
    width: any;
    height?: number | string;
    focusContext?: any;
    focusOptions?: any;
    onToggleDropdown?: (bool: boolean) => void;
    firstOptionFocusOptions?: PressableFocusOptions;
    dropdownRef?: React.MutableRefObject<any>;
}
const Dropdown_ = ({ value, styling, animation, dataIn, type, setSelection, width, height, focusContext, focusOptions, onToggleDropdown, firstOptionFocusOptions, dropdownRef, ...props }: InputProps) => {
    const [data, setData] = React.useState([] as any);
    const [show, setShow] = React.useState(false);
    const isDropdownOpenRef = React.useRef(false);
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    const contentContext = React.useContext(ContentContext);
    const screenName = getCurrentScreenName();
    const userContext = React.useContext(UserContext);
    const generatedFocusKey = React.useRef(focusOptions?.focusKey ?? (Math.random() + 1).toString(36).substring(7)).current;

    useTVRemoteHandler(({ eventType }: any) => {
        if (['back', 'menu'].includes(eventType) && isDropdownOpenRef.current) {
            toggleDropdown(false);
        }
    });

    React.useImperativeHandle(
        dropdownRef,
        () => {
            return {
                toggleDropdown: (value: boolean) => {
                    toggleDropdown(value);
                },
                isOpen: () => show,
            };
        },
        [show]
    );

    React.useEffect(() => {
        const smartTVRemoteHandler = (event) => {
            const { key, keyCode } = event;
            if (keyCode == 413 || keyCode == 10009 || keyCode == 461) {
                toggleDropdown(false);
            }
        };
        if (deviceContext.isTizen || deviceContext.isWebos) {
            window.addEventListener('keydown', smartTVRemoteHandler);
        }
        return () => {
            if (deviceContext.isTizen || deviceContext.isWebos) {
                window.removeEventListener('keydown', smartTVRemoteHandler);
            }
        };
    }, []);

    const toggleDropdown = (bool: boolean) => {
        setShow(bool);
        isDropdownOpenRef.current = bool;
        onToggleDropdown?.(bool);
        if (!bool) setFocus(generatedFocusKey);
    };

    React.useEffect(() => {
        let options;
        if (['Categories', 'Languages', 'Times', 'Subs', 'Audio', 'Screens'].includes(type)) {
            options = dataIn.map((data) => ({
                name: data.name,
                value: data.name,
            }));
        }

        if (['Seasons'].includes(type)) {
            options = dataIn.map((data, index) => ({
                name: lang.getTranslation(userContext, 'season') + ' ' + (index + 1),
                value: index,
            }));
        }

        if (type == 'Dates') {
            options = dataIn.map((data) => ({
                name: moment(data.date).format('ll'),
                value: data.date,
            }));
        }

        if (['Menu'].includes(type)) {
            options = dataIn;
        }
        setData(options);
    }, [dataIn]);

    const setSelectionLocal = (item, index) => {
        // Tv guide selected handled in tv guide screen
        if (isPlatformAndroid && screenName !== 'TV Guide') {
            contentContext.setDisableScroll(false);
        }
        toggleDropdown(false);
        if (type != 'Menu') {
            setSelection(item, index);
        } else {
            setSelection(index, item);
        }
    };

    const getCorrectMenuName = (item) => {
        var length = '';
        var extra = '';
        // if (screenName == 'Seasons') {
        //     extra = item.number != undefined ? item.number + '. ' : '';
        // }
        if (screenName == 'Channels') {
            length = ' (' + item.channels.length + ')' ?? '';
        }
        if (screenName == 'Radio') {
            length = ' (' + item.radios.length + ')' ?? '';
        }
        if (screenName == 'Cams') {
            length = ' (' + item.cams.length + ')' ?? '';
        }
        if (screenName == 'Movies') {
            length = ' (' + item.movies.length + ')' ?? '';
        }
        if (screenName == 'Series') {
            length = ' (' + item.series.length + ')' ?? '';
        }
        if (screenName == 'Podcasts') {
            length = ' (' + item.podcasts.length + ')' ?? '';
        }
        if (screenName == 'Music') {
            length = ' (' + item.albums.length + ')' ?? '';
        }
        if (screenName == 'Courses') {
            length = ' (' + item.courses.length + ')' ?? '';
        }
        var name = '';
        if (screenName != 'Seasons') {
            name = getCorrectName(item, userContext);
        } else {
            name = item.name;
        }

        return name + length + extra;
    };

    //for android content behind (like a grid) seems to steal touch events which makes dropdown not scrollable. RNGHScrollView fixes this
    const ScrollView = isPlatformAndroid ? RNGHScrollView : CreateScrollView;
    return (
        <ViewGroup style={{ flex: 1, overflow: 'visible', justifyContent: 'center' }} focusContext={focusContext} focusOptions={{ group: show ? 'restricted-dropdown-container' : '' }}>
            <Pressable
                style={{
                    ...getPressableStyling(styling.components?.button?._id, appContext),
                    width: width,
                    borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                }}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                onPress={() => {
                    if (isPlatformAndroid) {
                        if (!show) {
                            contentContext.setDisableScroll(true);
                        } else {
                            contentContext.setDisableScroll(false);
                        }
                    }
                    toggleDropdown(!show);
                }}
                focusOptions={{ focusKey: generatedFocusKey, ...focusOptions }}
            >
                <View style={{ flexDirection: 'row', height: getCorrectHeight(deviceContext, 35) }}>
                    <View
                        style={{
                            justifyContent: 'center',
                            alignContent: 'center',
                            paddingLeft: getCorrectWidth(deviceContext, 5),
                            paddingRight: getCorrectWidth(deviceContext, 5),
                        }}
                    >
                        <FontAwesomeIcon size={getFontSize(deviceContext, 'Large')} style={{ ...getPressableTextStyling(styling.components?.button?._id, appContext), marginHorizontal: 5 }} icon={faChevronDown} />
                    </View>
                    <View style={{ justifyContent: 'center', alignContent: 'center' }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                width: width - getCorrectWidth(deviceContext, 30 + getFontSize(deviceContext, 'Normal')),
                                overflow: 'hidden',
                                color: getPressableTextStyling(styling.components?.button?._id, appContext).color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),
                            }}
                        >
                            {type == 'Languages' && lang.getLanguage(value)}
                            {type == 'Menu' && getCorrectMenuName(value)}
                            {type != 'Languages' && type != 'Menu' && value}
                        </Text>
                    </View>
                </View>
            </Pressable>
            {show && (
                <View
                    style={{
                        backgroundColor: getPressableStyling(styling.components?.button?._id, appContext).backgroundColor,
                        position: 'absolute',
                        top: getCorrectHeight(deviceContext, 35),
                        width: width - getCorrectWidth(deviceContext, 10),
                        paddingLeft: getCorrectWidth(deviceContext, 5),
                        marginLeft: getCorrectWidth(deviceContext, 5),
                        justifyContent: 'center',

                        paddingBottom: getCorrectHeight(deviceContext, 5),
                        maxHeight: height,
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 0,
                    }}
                >
                    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                        {data.map((element, index) => {
                            return (
                                <Pressable
                                    style={{
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        flexDirection: 'column',
                                        width: width - getCorrectWidth(deviceContext, 20),

                                        paddingHorizontal: getCorrectWidth(deviceContext, 10),

                                        marginTop: getCorrectHeight(deviceContext, 2),
                                        backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95),
                                    }}
                                    key={index}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => setSelectionLocal(type != 'Menu' ? element.value : element, index)}
                                >
                                    <View
                                        key={index}
                                        style={{
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                            height: getCorrectHeight(deviceContext, 25),
                                            borderBottomColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95),
                                            borderBottomWidth: 1,
                                        }}
                                    >
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                width: width - getCorrectWidth(deviceContext, 30 + getFontSize(deviceContext, 'Normal')),
                                                overflow: 'hidden',
                                                marginLeft: 5,
                                                color: getPressableTextStyling(styling.components?.button?._id, appContext).color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: element.name == value ? 'bold' : 'normal',
                                            }}
                                        >
                                            {type == 'Languages' && lang.getLanguage(element.name)}
                                            {type == 'Menu' && getCorrectMenuName(element)}
                                            {type != 'Languages' && type != 'Menu' && element.name}
                                        </Text>
                                        {element.name == value && (
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignItems: 'flex-end',
                                                    paddingRight: getCorrectWidth(deviceContext, 10),
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faCheck} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                            </View>
                                        )}
                                    </View>
                                </Pressable>
                            );
                        })}
                    </ScrollView>
                </View>
            )}
        </ViewGroup>
    );
};

export default Dropdown_;
