import { getCorrectSizeUpDown } from './correctSizeUpDown';

export function getCorrectHeight(deviceContext, height: number) {
    height = height * 1.5;
    height = height * getCorrectSizeUpDown(deviceContext);

    switch (deviceContext.deviceType as any) {
        case 'KaiOS':
            return height * 1.6;
        case 'FireTV':
            return height * 1;
        case 'AndroidTV':
            return height * 1;
        case 'AppleTV':
            return height;
        case 'SmartTV_Tizen':
            return height;
        case 'SmartTV_LG':
            return height;
        case 'PWA':
            return height * 0.8;
        case 'WebTV':
            return height;
        case 'iOS':
            return height * 0.9;
        case 'iPad':
            return height * 1.2;
        case 'Android_Mobile':
            return height * 0.9;
        case 'Android_Tablet':
            return height * 1;
        case 'SetTopBox':
            return height;
        default:
            break;
    }
}

export function getCorrectWidth(deviceContext, width: number) {
    width = width * 1.5;
    width = width * getCorrectSizeUpDown(deviceContext);
    switch (deviceContext.deviceType as any) {
        case 'KaiOS':
            return width * 1.4;
        case 'FireTV':
            return width * 1;
        case 'AndroidTV':
            return width * 1;
        case 'AppleTV':
            return width;
        case 'SmartTV_Tizen':
            return width;
        case 'SmartTV_LG':
            return width;
        case 'PWA':
            return width * 0.6;
        case 'WebTV':
            return width;
        case 'iOS':
            return width * 0.7;
        case 'iPad':
            return width * 1.2;
        case 'Android_Mobile':
            return width * 0.7;
        case 'Android_Tablet':
            return width * 1;
        case 'SetTopBox':
            return width;
        default:
            break;
    }
}
