import AsyncStorage from '@react-native-community/async-storage';
const storeJson = async (key, value) => {
    try {
        return await AsyncStorage.setItem(key, JSON.stringify(value));
    } catch (error) {}
};
const retrieveJson = async (key) => {
    try {
        const value = await AsyncStorage.getItem(key);
        if (value !== null) {
            return JSON.parse(value);
        }
    } catch (error) {}
};

export { storeJson, retrieveJson };
