import * as React from 'react';
import { View } from '@scriptx-com/xtv-toolkit';
import { DetailsBar as styling } from '../../../models/elements/misc/detailsbar/detailsbar';

export interface Data {}

export interface Props {
    styling: styling;
}

export const DetailsBar = ({ styling }: Props) => {
    return <View screenOrder={0} focusOptions={{ focusKey: 'screen', nextFocusLeft: 'side-menu' }}></View>;
};
export default DetailsBar;
