export default [{
                              fontFamily: 'Abel-Regular',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/appConfigs/base/fonts/Abel-Regular.ttf'),
                          },{
                              fontFamily: 'Helvetica-Bold',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/appConfigs/base/fonts/Helvetica-Bold.ttf'),
                          },{
                              fontFamily: 'Helvetica-Regular',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/appConfigs/base/fonts/Helvetica-Regular.ttf'),
                          },{
                              fontFamily: 'Inter-Light',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/appConfigs/base/fonts/Inter-Light.ttf'),
                          },{
                              fontFamily: 'Lato-Bold',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/appConfigs/base/fonts/Lato-Bold.ttf'),
                          },{
                              fontFamily: 'Lato-Regular',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/appConfigs/base/fonts/Lato-Regular.ttf'),
                          },{
                              fontFamily: 'Montserrat-Bold',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/appConfigs/base/fonts/Montserrat-Bold.ttf'),
                          },{
                              fontFamily: 'Montserrat-Regular',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/appConfigs/base/fonts/Montserrat-Regular.ttf'),
                          },{
                              fontFamily: 'OpenSans-Bold',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/appConfigs/base/fonts/OpenSans-Bold.ttf'),
                          },{
                              fontFamily: 'OpenSans-Regular',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/appConfigs/base/fonts/OpenSans-Regular.ttf'),
                          },{
                              fontFamily: 'Oswald-Bold',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/appConfigs/base/fonts/Oswald-Bold.ttf'),
                          },{
                              fontFamily: 'Oswald-Regular',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/appConfigs/base/fonts/Oswald-Regular.ttf'),
                          },{
                              fontFamily: 'Roboto-Bold',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/appConfigs/base/fonts/Roboto-Bold.ttf'),
                          },{
                              fontFamily: 'Roboto-Regular',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/appConfigs/base/fonts/Roboto-Regular.ttf'),
                          },{
                              fontFamily: 'AntDesign',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/node_modules/react-native-vector-icons/Fonts/AntDesign.ttf'),
                          },{
                              fontFamily: 'Entypo',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/node_modules/react-native-vector-icons/Fonts/Entypo.ttf'),
                          },{
                              fontFamily: 'EvilIcons',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/node_modules/react-native-vector-icons/Fonts/EvilIcons.ttf'),
                          },{
                              fontFamily: 'Feather',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/node_modules/react-native-vector-icons/Fonts/Feather.ttf'),
                          },{
                              fontFamily: 'FontAwesome',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/node_modules/react-native-vector-icons/Fonts/FontAwesome.ttf'),
                          },{
                              fontFamily: 'FontAwesome5_Brands',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/node_modules/react-native-vector-icons/Fonts/FontAwesome5_Brands.ttf'),
                          },{
                              fontFamily: 'FontAwesome5_Regular',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/node_modules/react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf'),
                          },{
                              fontFamily: 'FontAwesome5_Solid',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/node_modules/react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf'),
                          },{
                              fontFamily: 'Fontisto',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/node_modules/react-native-vector-icons/Fonts/Fontisto.ttf'),
                          },{
                              fontFamily: 'Foundation',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/node_modules/react-native-vector-icons/Fonts/Foundation.ttf'),
                          },{
                              fontFamily: 'Ionicons',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/node_modules/react-native-vector-icons/Fonts/Ionicons.ttf'),
                          },{
                              fontFamily: 'MaterialCommunityIcons',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/node_modules/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'),
                          },{
                              fontFamily: 'MaterialIcons',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/node_modules/react-native-vector-icons/Fonts/MaterialIcons.ttf'),
                          },{
                              fontFamily: 'Octicons',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/node_modules/react-native-vector-icons/Fonts/Octicons.ttf'),
                          },{
                              fontFamily: 'SimpleLineIcons',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/node_modules/react-native-vector-icons/Fonts/SimpleLineIcons.ttf'),
                          },{
                              fontFamily: 'Zocial',
                              file: require('/Users/sanderkerstens/Downloads/Development/Playground/mware.rn.tvapp.builder/node_modules/react-native-vector-icons/Fonts/Zocial.ttf'),
                          },];