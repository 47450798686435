import * as React from 'react';
import {View,  CreateListRenderItemInfo, FlashList, FocusContext } from '@scriptx-com/xtv-toolkit';
import { Square_Rails as styling } from '../../../models/elements/rails/square/square';
import { useEffectOnce } from '../../../hooks/useEffectOnce';
import Genre from '../shared/genre';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getRealWidth } from '../../../styling/realDeviceSizes';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { AppContext } from '../../../context/appContext';
import { FlashList as FlashList_ } from '@scriptx-com/flash-list';
import { getFocusStyling } from '../../components/helpers/helper';
import { isFactorMobile, isFactorTv, isPlatformAndroid, isPlatformIos } from '@rnv/renative';
import { getCorrectLeftMargin } from '../../../styling/appLeftMargin';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { GetScrollElement, GetTopPart } from './helpers/views';


export interface Data {
    items: [
        {
            square_image: string;
            name: string;
            _id: string;
        }
    ];
}

export interface Props {
    styling: styling;
    focusContext?: FocusContext;
    navigation: any;
}

export const Genre_Rails = React.memo(({ styling, focusContext, navigation }: Props) => {

    const [data, setData] = React.useState([] as any);
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const rails = React.useRef<FlashList_<number> | null>(null);
    const [viewIndex, setViewIndex] = React.useState(0);
    const [viewableItemsCount, setViewableItemsCount] = React.useState(0);
    const screenName = getCurrentScreenName();

    let height = isPlatformAndroid || isPlatformIos ? React.useRef(getCorrectHeight(deviceContext, styling.placement.height)).current : getCorrectHeight(deviceContext, styling.placement.height);
    var width = (height / 9) * 16;

    const getData = async () => {
        var categories = [] as any;
        if (styling.content.list?.type == 'Channels') {
            categories = contentContext.channels.categories;
            if (categories != undefined) {
                setData(categories);
            }
        }
        if (styling.content.list?.type == 'Radio') {
            categories = contentContext.radios.categories;
            if (categories != undefined) {
                setData(categories);
            }
        }
        if (styling.content.list?.type == 'Cams') {
            categories = contentContext.cams.categories;
            if (categories != undefined) {
                setData(categories);
            }
        }
        if (styling.content.list?.type == 'Movies') {
            categories = contentContext.movies.categories;
            if (categories != undefined) {
                setData(categories);
            }
        }
        if (styling.content.list?.type == 'Shorts') {
            categories = contentContext.shorts.categories;
            if (categories != undefined) {
                setData(categories);
            }
        }
        if (styling.content.list?.type == 'Series') {
            categories = contentContext.series.categories;
            if (categories != undefined) {
                setData(categories);
            }
        }
        if (styling.content.list?.type == 'Seasons') {
            categories = contentContext.seriesData.seasons;
            if (categories != undefined) {
                setData(categories);
            }
        }
        if (styling.content.list?.type == 'Podcasts') {
            categories = contentContext.podcasts.categories;
            if (categories != undefined) {
                setData(categories);
            }
        }
        if (styling.content.list?.type == 'Music') {
            categories = contentContext.albums.categories;
            if (categories != undefined) {
                setData(categories);

            }
        }
        if (styling.content.list?.type == 'Courses') {
            categories = contentContext.courses.categories;
            if (categories != undefined) {
                setData(categories);
    
            }
        }
    };

    useEffectOnce(() => {
        getData();
    })

    const detailsItem = (item: any, index: any) => {
        if (styling.content.list?.type == 'Channels') {
            contentContext.setChannelData({
                ...contentContext.channelData,
                channelCategoryIndex: index,
                channelListCategoryIndex: index,
                localCategoryIndex: index,
            });
            contentContext.setActiveMenu(styling.content.list?.type);
            navigation && navigation.navigate(styling.content.list?.type);
        }
        if (styling.content.list?.type == 'Radio') {
            contentContext.setRadioData({
                ...contentContext.radioData,
                radioCategoryIndex: index,
                localCategoryIndex: index,
            });
            contentContext.setActiveMenu(styling.content.list?.type);
            navigation && navigation.navigate(styling.content.list?.type);
        }
        if (styling.content.list?.type == 'Cams') {
            contentContext.setCamData({
                ...contentContext.camData,
                camCategoryIndex: index,
                localCategoryIndex: index,
            });
            contentContext.setActiveMenu(styling.content.list?.type);
            navigation && navigation.navigate(styling.content.list?.type);
        }
        if (styling.content.list?.type == 'Movies') {
            contentContext.setMovieData({
                ...contentContext.movieData,
                movieCategoryIndex: index,
                localCategoryIndex: index,
            });
            contentContext.setActiveMenu(styling.content.list?.type);
            navigation && navigation.navigate(styling.content.list?.type);
        }
        if (styling.content.list?.type == 'Shorts') {
            contentContext.setShortData({
                ...contentContext.shortData,
                shortCategoryIndex: index,
                localCategoryIndex: index,
            });
            contentContext.setActiveMenu(styling.content.list?.type);
            navigation && navigation.navigate(styling.content.list?.type);
        }
        if (styling.content.list?.type == 'Series') {
            contentContext.setSeriesData({
                ...contentContext.seriesData,
                seriesCategoryIndex: index,
                localCategoryIndex: index,
            });
            contentContext.setActiveMenu(styling.content.list?.type);
            navigation && navigation.navigate(styling.content.list?.type);
        }
        if (styling.content.list?.type == 'Seasons') {
            contentContext.setSeriesData({
                ...contentContext.seriesData,
                seasonCategoryIndex: index,
                season: contentContext.seriesData.seasons[index],
                localCategoryIndex: index,
            });
            contentContext.setActiveMenu(styling.content.list?.type);
            navigation && navigation.navigate(styling.content.list?.type);
        }
        if (styling.content.list?.type == 'Podcasts') {
            contentContext.setPodcastData({
                ...contentContext.podcastData,
                podcastCategoryIndex: index,
                localCategoryIndex: index,
            });
            contentContext.setActiveMenu(styling.content.list?.type);
            navigation && navigation.navigate(styling.content.list?.type);
        }
        if (styling.content.list?.type == 'Music') {
            contentContext.setAlbumData({
                ...contentContext.albumData,
                albumCategoryIndex: index,
                localCategoryIndex: index,
            });
            contentContext.setActiveMenu(styling.content.list?.type);
            navigation && navigation.navigate(styling.content.list?.type);
        }
        if (styling.content.list?.type == 'Courses') {
            contentContext.setCourseData({
                ...contentContext.courseData,
                courseCategoryIndex: index,
                localCategoryIndex: index,
            });
            contentContext.setActiveMenu(styling.content.list?.type);
            navigation && navigation.navigate(styling.content.list?.type);
        }
    };

    const renderItem = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        return <Genre rails={true} type={styling.content.list?.type} styling={styling} data={item} height={height} width={width} focusRepeatContext={focusRepeatContext} onPress={() => detailsItem(item, index)}></Genre>;
    };
    const getRailsWidth = () => {
        if (deviceContext.isPhone || deviceContext.isPwaVertical) {
            return getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 5);
        } else {
            return getRealWidth(deviceContext) - getCorrectWidth(deviceContext, +styling.placement.padding + styling.placement.margin_left + styling.placement.margin_right + global.menuWidth);
        }
    };
    const getRailsHeight = () => {
        if (styling.placement.height < height * 2) {
            return null;
        } else {
            return getCorrectHeight(deviceContext, styling.placement.height + styling.placement.padding * 2);
        }
    };

    const getTitle = (titles) => {
        if (titles == undefined || titles.length == 0) {
            return '';
        }
        var test = styling.content.list?.title.find((t) => t.language == userContext.selectedLanguage);
        if (test != undefined) {
            return test.title;
        } else {
            if (styling.content.list?.title != undefined) {
                return styling.content.list?.title[0].title;
            } else {
                return '';
            }
        }
    };

    const scrollRight = () => {
        const next = viewIndex + 1;
        if (next === data.length) {
            rails.current?.scrollToEnd({ animated: true });
        } else {
            rails.current?.scrollToIndex({ animated: true, index: viewIndex + 1 });
        }
    };

    const scrollLeft = () => {
        const next = Math.max(0, viewIndex - viewableItemsCount);
        rails.current?.scrollToIndex({ animated: true, index: next });
    };

    const onViewableItemsChanged = ({ viewableItems, changed }) => {
        if (isFactorTv || isFactorMobile) return;
        if (viewableItems.length !== viewableItemsCount) {
            setViewableItemsCount(viewableItems.length);
        }
        if (Array.isArray(changed)) {
            let visibleItemIndex;
            changed.forEach((item) => {
                if (item.isViewable) {
                    visibleItemIndex = item.index;
                }
            });
            if (visibleItemIndex !== undefined) {
                setViewIndex(visibleItemIndex);
            }
        }
    };

    const extraTopBottomMargin = () => {
        if(styling.placement.background_color != '#00000000' && styling.placement.background_color != 'transparent'){
            return  getCorrectHeight(deviceContext, styling.placement.padding)
        }else{
            return 0
        }
    }

    return (
        <View focusContext={focusContext}  >
                {data != null && data.length > 0 && (
                     
                <View
                    style={{
                        flex: 1,
                        marginLeft: getCorrectLeftMargin(deviceContext, appContext, screenName),
                        marginRight: 0,
                        marginTop: appContext.application.theme.rails_top_margin + extraTopBottomMargin(),
                        marginBottom:appContext.application.theme.rails_bottom_margin + extraTopBottomMargin(),
                    }}
                >
                    <View style={{ flex: 1 }}>
                        <View
                            style={{
                                backgroundColor: styling.placement.background_color,
                                height: data.length > 0 ? getRailsHeight() : data.length == 0 && styling.content.type != 'Search' ? getRailsHeight() : 0,
                                borderRadius: getCorrectWidth(deviceContext, styling.placement.border_radius),
                                paddingTop: getCorrectHeight(deviceContext, styling.placement.padding),
                                paddingBottom: getCorrectHeight(deviceContext, styling.placement.padding),
                                paddingLeft:
                                styling.placement.background_color != '#00000000' && styling.placement.background_color != 'transparent'
                                    ? deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs
                                        ? getCorrectWidth(deviceContext, 5)
                                        : getCorrectWidth(deviceContext, styling.placement.padding)
                                    : 0,
                                paddingRight: deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs
                                ? getCorrectWidth(deviceContext, 0)
                                :deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0 : getCorrectWidth(deviceContext, styling.placement.padding),
                            }}
                        >
                     <GetTopPart deviceContext={deviceContext} appContext={appContext} userContext={userContext} styling={styling} data={data} focusContext={focusContext} openOverviewPage={undefined} ></GetTopPart>

                            {data.length > 0 && (
                                <View style={{ flex: 1, justifyContent: 'center', width: getRailsWidth() }} focusContext={focusContext}>
                                    <FlashList
                                        type="row"
                                        ref={rails}
                                        data={data}
                                        onViewableItemsChanged={onViewableItemsChanged}
                                        estimatedItemSize={height}
                                        drawDistance={height * 2}
                                        numColumns={1}
                                        horizontal={true}
                                        showsHorizontalScrollIndicator={false}
                                        renderItem={renderItem}
                                        initialScrollIndex={0}
                                        style={{ flex: 1 }}
                                        focusOptions={{
                                            autoLayoutScaleAnimation: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? true : false,
                                            autoLayoutSize: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? 10 : 0,
                                        }}
                                    />
                                                {deviceContext.isWebTV && !deviceContext.isPwa && data.length > 0 && data.length > viewableItemsCount && (
                                        <GetScrollElement height={height} scrollLeft={scrollLeft} scrollRight={scrollRight} deviceContext={deviceContext} appContext={appContext}></GetScrollElement>
                                    )}
                                </View>
                            )}
                        </View>
                    </View>
                </View>
            )}
        </View>
    );
});
export default Genre_Rails;
