import { ScrollView, View } from '@scriptx-com/xtv-toolkit';
import { useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import * as React from 'react';
import Pressable from '../../../application/builder/components/pressable/pressable';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import lang from '../../../application/languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import { sendPageReport } from '../../../reporting/apis/page';
import { pageType } from '../../../reporting/models/apps';
import ScreenWrapper from '../../wrapper';
import Text from '../../../application/builder/components/text';

const Screen_ = ({ navigation, route, focusContext, showButton }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.PWA, duration);
            };
        }, [])
    );

    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ focusKey: 'screen' }}>
            <View style={{ flex: 1, marginLeft: getCorrectWidth(deviceContext, global.menuWidth) }}>
                <View
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: appContext.application.theme.settings.background_color1,
                        borderRadius: appContext.application.theme.settings.border_radius,
                    }}
                >
                    <Text
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                            fontWeight: appContext.application.authentication?.disclaimer?.header.title_bold ? 'bold' : 'normal',
                            color: appContext.application.authentication?.disclaimer?.header.title_color,
                        }}
                    >
                        {lang.getTranslation(userContext, 'pwa_info')}
                    </Text>
                </View>
                <View style={{ flex: 4 }}>
                    <ScrollView
                        contentContainerStyle={{ flex: 1, paddingBottom: getCorrectHeight(deviceContext, deviceContext.isTelevision ? 0 : 30) }}
                    >
                        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: 'column',
                                    padding: getCorrectWidth(deviceContext, 20),
                                }}
                            >
                                <View style={{ flexDirection: 'row' }}>
                                    <Text
                                        style={{
                                            color: appContext.application.theme.settings.font_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'pwa_android')}
                                    </Text>
                                </View>
                            </View>
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: 'column',
                                    padding: getCorrectWidth(deviceContext, 20),
                                }}
                            >
                                <View style={{ flexDirection: 'row' }}>
                                    <Text
                                        style={{
                                            color: appContext.application.theme.settings.font_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'pwa_safari')}
                                    </Text>
                                </View>
                            </View>
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: 'column',
                                    padding: getCorrectWidth(deviceContext, 20),
                                }}
                            >
                                <View style={{ flexDirection: 'row' }}>
                                    <Text
                                        style={{
                                            color: appContext.application.theme.settings.font_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'pwa_samsung')}
                                    </Text>
                                </View>
                            </View>
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: 'column',
                                    padding: getCorrectWidth(deviceContext, 20),
                                }}
                            >
                                <View style={{ flexDirection: 'row' }}>
                                    <Text
                                        style={{
                                            color: appContext.application.theme.settings.font_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'pwa_windows')}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </ScrollView>
                </View>
            </View>
        </ScreenWrapper>
    );
};
export default Screen_;
