import { View, CreateListRenderItemInfo, Image, FlashList, Screen, ViewGroup, FocusContext } from '@scriptx-com/xtv-toolkit';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import { ScreenFadeIn } from '../../animations/screenFadeIn';
import lang from '../../../languages/languages';
import { useEffectOnce } from '../../../hooks/useEffectOnce';
import React from 'react';
import Pressable from '../pressable/pressable';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { useTVRemoteHandler } from '../../../hooks/useTVRemoteHandler';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { AppContext } from '../../../context/appContext';
import { Modal as RNModal } from 'react-native';
import Text from '../text';
import { getFocusStyling, getPressableStyling, getPressableTextStyling } from '../helpers/helper';
import { isPlatformAndroid, isPlatformAndroidtv, isPlatformFiretv, isPlatformTizen, isPlatformTvos, isPlatformWebos } from '@rnv/renative';

export interface ParentalProps {
    setShowProfileImageSelection: any;
    profile: any;
    setProfileImage: any;
    styling: any;
    stylingprofiles: any;
    focusContext?: FocusContext;
}
const ProfileImage = ({ setShowProfileImageSelection, profile, styling, setProfileImage, focusContext, stylingprofiles }: ParentalProps) => {
    const [data, setData] = React.useState([] as any);
    const [selectedImageIndex, setSelectedImageIndex] = React.useState(profile.avatar);
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    const contextUser = React.useContext(UserContext);

    useEffectOnce(() => {
        setData(appContext.application.authentication.profiles.avatars);
    });

    useTVRemoteHandler(({ eventType, eventKeyAction }: any) => {
        if (!deviceContext.isWebTV && eventKeyAction === 'down') {
            if (['back', 'menu'].includes(eventType)) {
                setShowProfileImageSelection(false);
            }
        }
    });

    React.useEffect(() => {
        const smartTVRemoteHandler = (event) => {
            const { key, keyCode } = event;
            if (keyCode == 413 || keyCode == 10009 || keyCode == 461) {
                setShowProfileImageSelection(false);
            }
        };
        if (deviceContext.isTizen || deviceContext.isWebos) {
            window.addEventListener('keydown', smartTVRemoteHandler);
        }
        return () => {
            if (deviceContext.isTizen || deviceContext.isWebos) {
                window.removeEventListener('keydown', smartTVRemoteHandler);
            }
        };
    }, []);

    const renderItem = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        return (
            <Pressable
                style={{
                    margin: getCorrectWidth(deviceContext, 5),

                    borderRadius: 100,
                }}
                focusRepeatContext={focusRepeatContext}
                animatorOptions={{
                    type: 'scale',
                    focus: {
                        scale: 1.1,
                        duration: 150,
                    },
                }}
                onPress={() => setProfileImage(item, index)}
                focusOptions={{ hasPreferredFocus: index === 0 }}
            >
                <View
                    style={{
                        borderRadius: 100,
                        borderWidth: deviceContext.isKaiOs ? getCorrectWidth(deviceContext, 4) : getCorrectWidth(deviceContext, 2),
                        borderColor: getPressableStyling(styling.components?.button?._id, appContext).backgroundColor,
                    }}
                >
                    <Image
                        resizeMethod={'scale'}
                        resizeMode={'contain'}
                        source={{ uri: item.image }}
                        style={{
                            width:
                                deviceContext.isKaiOs || deviceContext.isPhone
                                    ? getRealWidth(deviceContext) * 0.2
                                    : getRealWidth(deviceContext) * 0.1,
                            height:
                                deviceContext.isKaiOs || deviceContext.isPhone
                                    ? getRealWidth(deviceContext) * 0.2
                                    : getRealWidth(deviceContext) * 0.1,
                            borderRadius: 1000,
                        }}
                    ></Image>
                </View>
            </Pressable>
        );
    };

    let androidStyle = {};
    if (isPlatformAndroid) {
        androidStyle = {
            position: 'absolute',
            zIndex: 9999,
            flex: 1,
            width: getRealWidth(deviceContext),
            height: getRealHeight(deviceContext),
            alignItems: 'center',
        };
    }

    const Content = (
        <View style={{ flex: 1, flexDirection: 'row' }}>
            <View
                style={{
                    width: deviceContext.isKaiOs || deviceContext.isPhone ? getRealWidth(deviceContext) * 0.9 : getRealWidth(deviceContext) * 0.7,
                    height: deviceContext.isKaiOs || deviceContext.isPhone ? getRealHeight(deviceContext) / 2 : getRealHeight(deviceContext) * 0.7,
                    borderColor: getPressableStyling(styling.components?.button?._id, appContext).borderColor,
                    borderWidth: getPressableStyling(styling.components?.button?._id, appContext).borderWidth,
                    borderRadius: getCorrectWidth(deviceContext, 3),
                    flexDirection: 'row',
                    alignSelf: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.90)',
                }}
            >
                <View
                    style={{ position: 'absolute', zIndex: 999, right: getCorrectWidth(deviceContext, 10), top: getCorrectWidth(deviceContext, 14) }}
                >
                    <Pressable
                        style={{
                            ...getPressableStyling(styling.components?.button?._id, appContext),
                            width: getCorrectWidth(deviceContext, 35),
                            marginTop: 10,
                        }}
                        hasTVPreferredFocus={true}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        onPress={() => setShowProfileImageSelection(false)}
                    >
                        <View
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: getCorrectHeight(deviceContext, 35),
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faXmark}
                                color={getPressableTextStyling(styling.components?.button?._id, appContext).color}
                                size={getFontSize(deviceContext, 'ExtraLarge')}
                            ></FontAwesomeIcon>
                        </View>
                    </Pressable>
                </View>
                <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20), marginTop: getCorrectWidth(deviceContext, 30) }}>
                    <Text
                        style={{
                            color: '#fff',
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                        }}
                    >
                        {lang.getTranslation(contextUser, 'choose_avatar')}
                    </Text>
                    <View
                        style={{
                            flex: 1,
                            flexDirection: 'row',
                            marginTop: getCorrectHeight(deviceContext, 10),
                        }}
                    >
                        <View
                            style={{
                                width: '100%',
                                height:
                                    deviceContext.isKaiOs || deviceContext.isPhone
                                        ? getRealHeight(deviceContext) * 0.36
                                        : getRealHeight(deviceContext) * 0.5,
                            }}
                        >
                            <FlashList
                                data={data}
                                estimatedItemSize={getCorrectWidth(deviceContext, 50)}
                                numColumns={deviceContext.isPhone ? 3 : 5}
                                horizontal={false}
                                renderItem={renderItem}
                                showsHorizontalScrollIndicator={false}
                                initialScrollIndex={selectedImageIndex}
                                type={'grid'}
                                style={{ flex: 1 }}
                            />
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );

    const wrapperStyles: any = {
        position: 'absolute',
        zIndex: 9999,
        flex: 1,
        width: getRealWidth(deviceContext),
        height: getRealHeight(deviceContext),
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    };

    if (isPlatformAndroidtv || isPlatformFiretv || isPlatformTvos || isPlatformTizen || isPlatformWebos) {
        return (
            <ViewGroup
                focusContext={focusContext}
                focusOptions={{
                    group: 'profile-image',
                    focusKey: 'profile-image',
                }}
                style={wrapperStyles}
            >
                <ScreenFadeIn visible transparent>
                    {Content}
                </ScreenFadeIn>
            </ViewGroup>
        );
    }

    const Wrapper = deviceContext.isKaiOs ? RNModal : ScreenFadeIn;

    return (
        <Wrapper visible transparent style={{ ...androidStyle }}>
            <View style={wrapperStyles}>
                <ScreenFadeIn>{Content}</ScreenFadeIn>
            </View>
        </Wrapper>
    );
};

export default ProfileImage;
