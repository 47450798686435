import * as React from 'react';
import Page from '../../../application/builder/pages/page';
import ScreenWrapper from '../../wrapper';
import { getFocusOptions } from '../../wrapper/helpers/getFocusOptions';
import { DeviceContext } from '../../../application/context/deviceContext';
import { AppContext } from '../../../application/context/appContext';

const Screen_ = ({ navigation, route }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const data = [];
    const focusOptions = getFocusOptions(deviceContext, appContext, 'content-screen');
    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ ...focusOptions, group: 'content-screen' }}>
            <Page key={route.name} navigation={navigation} route={route} screen={undefined} data={data} type={'Overview'}></Page>
        </ScreenWrapper>
    );
};
export default Screen_;
