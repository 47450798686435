import * as React from 'react';
import { DeviceContext } from '../../../context/deviceContext';
import Player_Portrait from './player.portrait';
import Player_Tablet from './player.tablet';
import Player_Television from './player.television';

export const Hero_Player = ({ styling, navigation, focusContext }) => {
    const deviceContext = React.useContext(DeviceContext);

    return (
        <>
            {(deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && (
                <Player_Portrait styling={styling} navigation={navigation} focusContext={focusContext}></Player_Portrait>
            )}
            {(deviceContext.isTablet || deviceContext.isWebTV) && (
                <Player_Tablet styling={styling} navigation={navigation} focusContext={focusContext}></Player_Tablet>
            )}
            {(deviceContext.isTizen || deviceContext.isAndroidTV || deviceContext.isFireTV || deviceContext.isWebos) && (
                <Player_Television styling={styling} navigation={navigation} focusContext={focusContext}></Player_Television>
            )}
        </>
    );
};

export default Hero_Player;
