import { ActivityIndicator, View } from '@scriptx-com/xtv-toolkit';
import { faShield, faUser } from '@fortawesome/pro-light-svg-icons';
import { CommonActions, useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import * as React from 'react';
import Input from '../../../application/builder/components/input/input';
import Keyboard from '../../../application/builder/components/keyboard/keyboard';
import Pressable from '../../../application/builder/components/pressable/pressable';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { KeyboardContext } from '../../../application/context/keyboardContext';
import { PlayerContext } from '../../../application/context/playerContext';
import { UserContext } from '../../../application/context/userContext';
import { getUserIp } from '../../../application/data/account';
import { useEffectOnce } from '../../../application/hooks/useEffectOnce';
import lang from '../../../application/languages/languages';
import { getCorrectHeight } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import HTMLRenderer from '../../../libs/react-native-render-html';
import { sendActionReport } from '../../../reporting/apis/action';
import { sendPageReport } from '../../../reporting/apis/page';
import { actionType, pageType } from '../../../reporting/models/apps';
import ScreenWrapper from '../../wrapper';
import Text from '../../../application/builder/components/text';
import {
    getKeyboardStyling,
    getFocusStyling,
    getInputStyling,
    getPressableStyling,
    getPressableTextStyling,
} from '../../../application/builder/components/helpers/helper';

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const playerContext = React.useContext(PlayerContext);
    const { keyboardVisible, openKeyboard, value, setKeyboardVisible } = React.useContext(KeyboardContext);
    const [show, setShow] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [username, setUsername] = React.useState(userContext.userId);
    const [password, setPassword] = React.useState(userContext.pass);
    const [error, setError] = React.useState('');
    const [type, setType] = React.useState('');

    useEffectOnce(() => {
        if (userContext.autoLogin) {
            loginUser();
        } else {
            setShow(true);
        }
    });

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.LOGIN, duration);
            };
        }, [])
    );

    const loginUser = async () => {
        if (loading == false) {
            setLoading(true);
            sendActionReport(appContext, deviceContext, userContext, actionType.LOGIN);
            var result = await getUserIp(username, password, appContext, userContext, deviceContext, userContext, playerContext, false);
            if (result != 'success') {
                setError(result);
                setLoading(false);
                setShow(true);
            } else {
                setError('');
                setLoading(false);
                navigation && navigation.navigate('Code');
            }
        }
    };

    const forgotLogin = () => {
        navigation && navigation.navigate('Forgot');
    };

    const goBack = () => {
        if (appContext.application.settings.languages.length > 1) {
            userContext.setSelectedLanguage('');
            userContext.setAutoLogin(false);
            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'Language' }],
                })
            );
        }
    };

    const setUsernameLocal = (value: any) => {
        setUsername(value);
        setError('');
    };
    const setPasswordLocal = (value: any) => {
        setPassword(value);
        setError('');
    };
    const loginUserViaQr = () => {
        navigation && navigation.navigate('Connect');
    };
    const getStarted = () => {
        navigation.dispatch(
            CommonActions.reset({
                index: 1,
                routes: [{ name: 'Onboarding' }],
            })
        );
    };

    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ focusKey: 'screen' }}>
            {keyboardVisible && type == 'userid' && (
                <Keyboard
                    onCloseKeyboard={() => setKeyboardVisible(false)}
                    focusContext={focusContext}
                    width={getRealWidth(deviceContext) * 0.8}
                    isSecure={false}
                    placeholder={''}
                    styling={getKeyboardStyling(appContext.application.authentication.connect.components?.keyboard._id, appContext)}
                    onChangText={setUsernameLocal}
                    value={username}
                ></Keyboard>
            )}
            {keyboardVisible && type == 'pass' && (
                <Keyboard
                    onCloseKeyboard={() => setKeyboardVisible(false)}
                    focusContext={focusContext}
                    width={getRealWidth(deviceContext) * 0.8}
                    isSecure={false}
                    placeholder={''}
                    styling={getKeyboardStyling(appContext.application.authentication.connect.components?.keyboard._id, appContext)}
                    onChangText={setPasswordLocal}
                    value={password}
                ></Keyboard>
            )}
            {!show && (
                <View style={{ flex: 1 }}>
                    <View style={{ flex: 4, flexDirection: 'row' }}>
                        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                            <ActivityIndicator size={'large'} color={'white'}></ActivityIndicator>
                        </View>
                    </View>
                </View>
            )}
            {show && (
                <View style={{ flex: 1 }}>
                    {error != '' && (
                        <View
                            style={{
                                zIndex: 9999,
                                position: 'absolute',
                                top: deviceContext.isAndroid && deviceContext.isPhone ? 20 : 0,

                                width: '100%',
                                height: getCorrectHeight(deviceContext, 30),
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    color: '#fff',
                                    fontWeight: 'bold',
                                }}
                            >
                                {error}
                            </Text>
                        </View>
                    )}
                    <View
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0.25)',
                        }}
                    >
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                fontWeight: appContext.application.authentication?.login?.header.title_bold ? 'bold' : 'normal',
                                color: appContext.application.authentication?.login?.header.title_color,
                            }}
                        >
                            {lang.getTranslation(userContext, 'signin')}
                        </Text>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, appContext.application.authentication?.login?.header.sub_size),
                                fontWeight: appContext.application.authentication?.login?.header.sub_bold ? 'bold' : 'normal',
                                color: appContext.application.authentication?.login?.header.sub_color,
                            }}
                        >
                            {lang.getTranslation(userContext, 'sigininaccount').toUpperCase()}
                        </Text>
                    </View>
                    <View style={{ flex: 4, flexDirection: 'row' }}>
                        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                            <Input
                                value={username}
                                onChangText={(value) => setUsernameLocal(value)}
                                icon={faUser}
                                width={
                                    getRealWidth(deviceContext) *
                                    (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3)
                                }
                                isSecure={false}
                                placeholder={lang.getTranslation(userContext, 'userid')}
                                animation={getFocusStyling('Inputs', appContext)}
                                styling={getInputStyling(appContext.application.authentication.connect.components?.input?._id, appContext)}
                                focusOptions={{ focusKey: 'userid' }}
                                onFocus={() => {
                                    if (keyboardVisible) {
                                        setKeyboardVisible(false);
                                    }
                                }}
                                showKeyboard={() => {
                                    setType('userid');
                                    openKeyboard({
                                        focusOptions: { nextFocusUp: 'userid', nextFocusDown: 'userid' },
                                    });
                                }}
                            ></Input>
                            <Input
                                value={password}
                                style={{ marginTop: 10 }}
                                onChangText={(value) => setPasswordLocal(value)}
                                icon={faShield}
                                width={
                                    getRealWidth(deviceContext) *
                                    (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3)
                                }
                                isSecure={true}
                                placeholder={lang.getTranslation(userContext, 'password')}
                                animation={getFocusStyling('Inputs', appContext)}
                                styling={getInputStyling(appContext.application.authentication.connect.components?.input?._id, appContext)}
                                focusOptions={{ focusKey: 'pass' }}
                                onFocus={() => {
                                    if (keyboardVisible) {
                                        setKeyboardVisible(false);
                                    }
                                }}
                                showKeyboard={() => {
                                    setType('password');
                                    openKeyboard({
                                        focusOptions: { nextFocusUp: 'pass', nextFocusDown: 'pass' },
                                    });
                                }}
                            ></Input>
                            <Pressable style={{ marginTop: 20 }} animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => loginUser()}>
                                <View
                                    style={{
                                        width:
                                            getRealWidth(deviceContext) *
                                            (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {!loading && (
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(
                                                    appContext.application.authentication.connect.components?.button?._id,
                                                    appContext
                                                ),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'submit')}
                                        </Text>
                                    )}
                                    {loading && <ActivityIndicator color={getFocusStyling('Buttons', appContext)} size={'small'}></ActivityIndicator>}
                                </View>
                            </Pressable>
                            {appContext.application.settings?.enable_forgot && (
                                <Pressable animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => forgotLogin()}>
                                    <View
                                        style={{
                                            marginTop: 10,
                                            width:
                                                getRealWidth(deviceContext) *
                                                (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                            height: getCorrectHeight(deviceContext, 30),
                                            ...getPressableStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(
                                                    appContext.application.authentication.connect.components?.button?._id,
                                                    appContext
                                                ),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'forgot')}
                                        </Text>
                                    </View>
                                </Pressable>
                            )}
                            {!deviceContext.isPhone && !deviceContext.isTablet && !deviceContext.isWebTV && (
                                <Text
                                    style={{
                                        padding: 10,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'or')}
                                </Text>
                            )}
                            {!deviceContext.isPhone && !deviceContext.isTablet && !deviceContext.isWebTV && (
                                <Pressable animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => loginUserViaQr()}>
                                    <View
                                        style={{
                                            width:
                                                getRealWidth(deviceContext) *
                                                (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                            height: getCorrectHeight(deviceContext, 30),
                                            ...getPressableStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(
                                                    appContext.application.authentication.connect.components?.button?._id,
                                                    appContext
                                                ),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'signin_via_qr')}
                                        </Text>
                                    </View>
                                </Pressable>
                            )}
                      

                        </View>
                        {appContext.application.authentication?.login?.text.length > 0 && (
                            <View
                                style={{
                                    borderColor: appContext.application.authentication?.login?.header.title_color,
                                    borderWidth: 1,
                                    marginVertical: 50,
                                }}
                            ></View>
                        )}
                        {appContext.application.authentication?.login?.text.length > 0 && !deviceContext.isKaiOs && (
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                <View
                                    style={{
                                        borderRadius: getPressableStyling(
                                            appContext.application.authentication.connect.components?.button?._id,
                                            appContext
                                        ).borderRadius,
                                        backgroundColor: 'rgba(0, 0, 0, 0.25)',
                                        flex: 1,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width:
                                            getRealWidth(deviceContext) *
                                            (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                        marginVertical: 50,
                                    }}
                                >
                                    <HTMLRenderer
                                        html={appContext.application.authentication?.login?.text}
                                        contentWidth={
                                            getRealWidth(deviceContext) *
                                            (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3)
                                        }
                                    />
                                </View>
                            </View>
                        )}
                    </View>
                </View>
            )}
        </ScreenWrapper>
    );
};
export default Screen_;
