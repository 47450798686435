import * as React from 'react';
import { View } from '@scriptx-com/xtv-toolkit';
import Page from '../../../application/builder/pages/page';
import { ContentContext } from '../../../application/context/contentContext';
import ScreenWrapper from '../../wrapper';
import { getFocusOptions } from '../../wrapper/helpers/getFocusOptions';
import { DeviceContext } from '../../../application/context/deviceContext';
import { AppContext } from '../../../application/context/appContext';

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const screen = appContext.application.screens.find((s) => s.type == 'Seasons');
    const data = [];
    const focusOptions = getFocusOptions(deviceContext, appContext, 'content-screen');

    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ ...focusOptions, group: 'content-screen', autoFocusEnabled: false }}>
            <Page navigation={navigation} route={route} screen={screen} data={data} type={'Seasons'}></Page>
        </ScreenWrapper>
    );
};
export default Screen_;
