import { View } from '@scriptx-com/xtv-toolkit';
import NavigationBase from './navigation.base';

export default () => {
    return (
        <View style={{ flex: 1 }}>
            <NavigationBase></NavigationBase>
        </View>
    );
};
