import { App } from '../shared/app';
import { Customer } from '../shared/customer';
import { Device } from '../shared/device';
import { Location } from '../shared/location';
import { Network } from '../shared/network';
import { Product } from '../shared/product';


export enum actionType {
    SELECT_LANGUAGE = 'Select Language',
    SELECT_SERVICE = 'Select Service',
    SELECT_PROFILE = 'Select Profile',
    ADD_PROFILE = 'Add Profile',
    EDIT_PROFILE = 'Edit Profile',
    DELETE_PROFILE = 'Delete Profile',
    LOGIN = 'Login',
    SINGUP = 'Signup',
    FORGOT_LOGIN = 'Forgot Login',
    ADD_FAVORITE = 'Add Favorite',
    DELETE_FAVORITE = 'Delete Favorite',
    DELETE_DEVICE = 'Delete Device',
    PLAYER_PAUSED = 'Player Paused',
    PLAYER_REW = 'Player Rewind',
    PLAYER_FWD = 'Player Forward',
    PLAYER_CHROMECAST = 'Chromecast',
    PLAYER_AIRPLAY = 'Airplay',
    PLAYER_PIP = 'PiP',
    PLAYER_SCRUBBER = 'Use Scrubber',
    PLAYER_SELECT_SUBTITLE = 'Change Subtitle',
    PLAYER_SELECT_SCREENSZIE = 'Change Screensize',
    PLAYER_SELECT_AUDIO = 'Change Audio Language',
    PLAYER_SELECT_PROBLEM_REPORT = 'Sent Problem Report',
    PLAYER_RESTART = 'Player Restart',
}

export class Action {
    actionType: string;
    timestamp: number;
    app: App;
    location: Location;
    device: Device;
    network: Network;
    customer: Customer;
    product: Product;
}
