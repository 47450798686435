import * as React from 'react';
import { Easing } from 'react-native';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import { getCorrectHeight, getCorrectWidth } from '../../styling/correctSizes';
import { DeviceContext } from '../../context/deviceContext';
import { Animated, View, FocusContext } from '@scriptx-com/xtv-toolkit';
import { AppContext } from '../../context/appContext';

const AnimatedView = Animated.createAnimatedComponent(View);

export interface Props {
    children: React.ReactChild;
    onNext: any;
    focusContext?: FocusContext;
}

export const NextContentTransform = ({ children, onNext, focusContext }: Props) => {
    const nextWidth = React.useRef(new Animated.Value(0)).current;
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    function startAnimation() {
        Animated.timing(nextWidth, {
            toValue: 1,
            duration: 30000,
            easing: Easing.linear,
            useNativeDriver: false,
        }).start(() => {
            //next
            onNext();
        });
    }

    const maxWidth = nextWidth.interpolate({
        inputRange: [0, 1],
        outputRange: [0, getCorrectWidth(deviceContext, 177)],
    });

    useEffectOnce(() => {
        startAnimation();
    });

    return (
        <AnimatedView
            focusContext={focusContext}
            style={{
                backgroundColor: appContext.application.theme.progresses.progress,
                width: maxWidth,
                height: getCorrectHeight(deviceContext, 100),
            }}
        >
            {children}
        </AnimatedView>
    );
};
