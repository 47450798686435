import { ActivityIndicator, Pressable, ScrollView, View } from '@scriptx-com/xtv-toolkit';
import { CommonActions, useFocusEffect } from '@react-navigation/native';
import { isPlatformAndroid, isPlatformIos } from '@rnv/renative';
import moment from 'moment';
import * as React from 'react';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import lang from '../../../application/languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import { useSafeAreaInsets } from '../../../libs/react-native-safe-area-context';
import { sendPageReport } from '../../../reporting/apis/page';
import { pageType } from '../../../reporting/models/apps';
import ScreenWrapper from '../../wrapper';
import { getFocusOptions } from '../../wrapper/helpers/getFocusOptions';
import Text from '../../../application/builder/components/text';
import utils from '../../../application/general/utils';
import { getFocusStyling, getModalStyling, getPressableStyling, getPressableTextStyling } from '../../../application/builder/components/helpers/helper';
import { getGlobals } from '../../../globals';
import Parental from '../../../application/builder/components/parental/parental_lock';
import { SettingsContext } from '../../../application/context/settingsContext';

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const settingsContext = React.useContext(SettingsContext);
    const insets = useSafeAreaInsets();
    const [loading, setLoading] = React.useState(false);
    const [showParental, setShowParental] = React.useState(false);

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.SUBINFO, duration);
            };
        }, [])
    );
    const deleteAccountModal = () => {
        setShowParental(true);
    };
    const deleteAccount = async () => {
        setLoading(true);
        fetch(
            getGlobals().AppApiUrl +
                '/register/delete?client=' +
                encodeURIComponent(appContext.application.client) +
                '&deployment=' +
                encodeURIComponent(appContext.application.deployment) +
                '&service=' +
                encodeURIComponent(appContext.application.branding.service),
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    password: userContext.pass,
                    user: userContext.userId,
                }),
            }
        )
            .then((response) => response.json())
            .then((response) => {
                if (response.success == true) {
                    userContext.setUserId('');
                    utils.storeJson('UserID', '');
                    userContext.setPass('');
                    utils.storeJson('Pass', '');
                    userContext.setAutoLogin(false);
                    utils.storeJson('AutoLogin', false);
                    settingsContext.setShowMainMenu(false);
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 1,
                            routes: [{ name: 'Login' }],
                        })
                    );
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const focusOptions = getFocusOptions(deviceContext, appContext, 'settings-screen');

    return (
        <ScreenWrapper
            style={{ flex: 1 }}
            focusOptions={{
                ...focusOptions,
                group: 'settings-screen',
            }}
        >
            {showParental && <Parental focusContext={focusContext} styling={getModalStyling(appContext.application.theme.modals.parental, appContext)} setParentalApproved={deleteAccount} setShowParental={setShowParental} type={'delete_account'} />}
            <View style={{ flex: 1, marginLeft: getCorrectWidth(deviceContext, global.menuWidth) }}>
                <View
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: appContext.application.theme.settings.background_color1,
                        borderRadius: appContext.application.theme.settings.border_radius,
                    }}
                >
                    <Text
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                            fontWeight: appContext.application.authentication?.services?.header.title_bold ? 'bold' : 'normal',
                            color: '#ffffff',
                        }}
                    >
                        {lang.getTranslation(userContext, 'your_registration')}
                    </Text>
                    <Text
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, appContext.application.authentication?.services?.header.sub_size),
                            fontWeight: appContext.application.authentication?.services?.header.sub_bold ? 'bold' : 'normal',
                            color: '#ffffff',
                            textAlign: 'center',
                        }}
                    >
                        {lang.getTranslation(userContext, 'registration_info').toUpperCase()}
                    </Text>
                </View>
                <View style={{ flex: 4, alignItems: 'center', paddingBottom: getCorrectHeight(deviceContext, deviceContext.isTelevision ? 0 : 30) }}>
                    <ScrollView
                        contentContainerStyle={{
                            paddingBottom: insets.bottom + (isPlatformAndroid ? 40 : isPlatformIos ? 120 : 40),
                        }}
                    >
                        <Pressable
                            focusContext={focusContext}
                            focusOptions={{
                                animator: {
                                    type: 'scale',
                                    focus: {
                                        scale: 1.01,
                                    },
                                },
                            }}
                            style={{
                                flexDirection: 'row',
                                width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth + 20),
                                backgroundColor: appContext.application.theme.settings.background_color2,
                                borderRadius: appContext.application.theme.settings.border_radius,

                                margin: getCorrectWidth(deviceContext, 10),
                            }}
                        >
                            {loading && <ActivityIndicator color={getPressableTextStyling(appContext.application.authentication.registration.components?.button?._id, appContext).color} size={'small'}></ActivityIndicator>}
                            {!loading && (
                                <View>
                                    <View
                                        style={{
                                            flexDirection: 'row',
                                            padding: getCorrectHeight(deviceContext, 20),
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'delete_info')}
                                        </Text>
                                    </View>
                                </View>
                            )}
                        </Pressable>
                        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                            <Pressable
                                focusContext={focusContext}
                                style={{
                                    marginTop: 10,
                                    width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                    height: getCorrectHeight(deviceContext, 30),
                                    ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                    backgroundColor: 'crimson',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                onPress={() => deleteAccountModal()}
                            >
                                <View>
                                    <Text
                                        style={{
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                            color: '#ffffff',
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'delete_your_account')}
                                    </Text>
                                </View>
                            </Pressable>
                        </View>
                    </ScrollView>
                </View>
            </View>
        </ScreenWrapper>
    );
};
export default Screen_;
