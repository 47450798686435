import { View } from '@scriptx-com/xtv-toolkit';
import * as React from 'react';
import { AppContext } from '../../../context/appContext';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { Images } from '../../../models/content/shared/images';
import { Hero_Slider as styling } from '../../../models/elements/heros/slider';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import Image from '../../components/image/image';
import { getChannelsFromSubscription, getCoursesFromSubscription, getGamesFromSubscription, getMoviesFromSubscription, getSeriesFromSubscription } from '../rails/helpers/helper';
import moment from 'moment';
import { BackgroundImage, ButtonRow, ButtonRowTop, DescriptionRow, LogoRow, Pager, StatusTimeRow } from './helpers/helpers_portrait';
import { onPressContinue, onPressInformation, onPressPlay, onPressPlayCatchupTV, onPressPlaySeason, onPressPurchase, onPressReplay } from '../details/helpers/functions';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { PlayerContext } from '../../../context/playerContext';
import { sendActionReport } from '../../../../reporting/apis/action';
import { actionType } from '../../../../reporting/models/apps';
import Swiper from '../../components/swiper/swiper';
import { SettingsContext } from '../../../context/settingsContext';
import { checkIfChannelHasPrograms } from './helpers/helper';

export interface Data {
    images: Images;
    name: string;
    tags: string;
    translations: any;
    _id: string;
}

export interface Props {
    styling: styling;
    navigation: any;
    focusContext: any;
}

export const Hero_Single = ({ styling, navigation, focusContext }: Props) => {
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const playerContext = React.useContext(PlayerContext);
    const settingsContext = React.useContext(SettingsContext);

    const [data, setData] = React.useState([] as any);

    const getData = async () => {
        var path = '';
        if (styling.content.type == 'Content List') {
            if (styling.content.list?.type == 'Movies') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/movies/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Series') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/series/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Courses') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/courses/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Channels') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/channels/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Games') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/games/lists/' + styling.content.list.list + '_list.json';
            }
            try {
                let response = await fetch(path);
                let data = (await response.json()) as any;
                if (data != undefined && data.length > 0) {
                    if (styling.content.list?.type == 'Channels') {
                        var dataOut = getChannelsFromSubscription(contentContext, data[0].items);
                        var dataOutNew = [] as any;
                        if (dataOut.length > 0) {
                            for (var channel of dataOut) {
                                var test = checkIfChannelHasPrograms(channel, contentContext);
                                if (test) {
                                    dataOutNew.push(channel);
                                }
                            }
                            setData(dataOutNew);
                        }
                    } else if (styling.content.list?.type == 'Movies') {
                        var dataOut = getMoviesFromSubscription(contentContext, data[0].items);
                        if (dataOut.length > 0) {
                            let newData = dataOut;
                            if (dataOut.length > 5) {
                                newData = dataOut.slice(0, 5);
                            }
                            setData(newData);
                        }
                    } else if (styling.content.list?.type == 'Courses') {
                        var dataOut = getCoursesFromSubscription(contentContext, data[0].items);
                        if (dataOut.length > 0) {
                            let newData = dataOut;
                            if (dataOut.length > 5) {
                                newData = dataOut.slice(0, 5);
                            }
                            setData(newData);
                        }
                    } else if (styling.content.list?.type == 'Series') {
                        var dataOut = getSeriesFromSubscription(contentContext, data[0].items);
                        if (dataOut.length > 0) {
                            let newData = dataOut;
                            if (dataOut.length > 5) {
                                newData = dataOut.slice(0, 5);
                            }
                            setData(newData);
                        }
                    } else if (styling.content.list?.type == 'Games') {
                        var dataOut = getGamesFromSubscription(contentContext, data[0].items);
                        if (dataOut.length > 0) {
                            let newData = dataOut;
                            if (dataOut.length > 5) {
                                newData = dataOut.slice(0, 5);
                            }
                            setData(newData);
                        }
                    }
                }
            } catch (error) {
                setData(null);
            }
        }
    };

    React.useEffect(() => {
        navigation.addListener('focus', () => {
            getData();
        });
    }, [navigation]);

    const onPressChromecast = () => {
        sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_CHROMECAST);
        if (playerContext.casting?.type != 'Chromecast') {
            playerContext.setCasting({
                type: 'Chromecast',
            });
        } else {
            playerContext.setCasting({
                type: '',
            });
        }
    };
    const onPressAirplay = () => {
        sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_AIRPLAY);
        if (playerContext.casting?.type != 'Airplay') {
            playerContext.setCasting({
                type: 'Airplay',
            });
        } else {
            playerContext.setCasting({
                type: '',
            });
        }
    };


    const checkIfHeaderHasLogoWide = () => {
        var test = appContext.application?.screens[0]?.header[0];
        if (test.logowide) {
            return true;
        } else {
            return false;
        }
    };


    const HeroObject = ({ item, index }) => {
        var program = [] as any;
        if (styling.content.list?.type == 'Channels') {
            if (contentContext.epgTv != undefined) {
                var epgChannel = contentContext.epgTv?.find((e) => e.i == item._id);
                if (epgChannel != undefined) {
                    var currentTime = moment().unix();
                    program = epgChannel.epg.find((e) => e.s <= currentTime && e.e >= currentTime);
                }
            }
        }
        return (
            <View style={{ flex: 1 }} focusContext={focusContext}>
                <BackgroundImage fullWidth={true} styling={styling} program={program} item={data[index]} type={styling.content.list?.type} deviceContext={deviceContext} appContext={appContext} contentContext={contentContext}></BackgroundImage>

                <View style={{ position: 'absolute', zIndex: 5, height: getCorrectHeight(deviceContext, 30), top: checkIfHeaderHasLogoWide() ? deviceContext.isApple ? 60 : 25 : 0 }}>
                    <ButtonRowTop
                        isSlider={true}
                        fullWidth={true}
                        deviceContext={deviceContext}
                        appContext={appContext}
                        userContext={userContext}
                        playerContext={playerContext}
                        styling={styling}
                        onPressChromecast={onPressChromecast}
                        onPressAirplay={onPressAirplay}
                    ></ButtonRowTop>
                </View>

                <View
                    style={{
                        zIndex: 3,
                        flex: 1,
                        width: getRealWidth(deviceContext),

                        alignItems: 'center',
                    }}
                >
                    <View
                        style={{
                            position: 'absolute',
                            width: getRealWidth(deviceContext),
                            height: getRealHeight(deviceContext) * 1.6,
                            zIndex: 2,
                            alignItems: 'center',
                        }}
                    >
                        <Image
                            isLocal={true}
                            deviceContext={deviceContext}
                            source={require('../../../../assets/images/blackish_mobile.png')}
                            resizeMode={'cover'}
                            style={{
                                width: getRealWidth(deviceContext),
                                height: getRealWidth(deviceContext) * 1.6,
                            }}
                        ></Image>
                    </View>

                    <View style={{ zIndex: 4, alignItems: 'center', justifyContent: 'flex-end', height: getRealWidth(deviceContext) * 1.3 }}>
                        <View style={{ zIndex: 10 }}>
                            <LogoRow fullWidth={true} program={program} item={data[index]} type={styling.content.list?.type} deviceContext={deviceContext} appContext={appContext} contentContext={contentContext} styling={styling}></LogoRow>
                            <StatusTimeRow
                                fullWidth={true}
                                program={program}
                                item={data[index]}
                                type={styling.content.list?.type}
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                contentContext={contentContext}
                                styling={styling}
                            ></StatusTimeRow>

                            <DescriptionRow
                                fullWidth={true}
                                program={program}
                                item={data[index]}
                                type={styling.content.list?.type}
                                deviceContext={deviceContext}
                                userContext={userContext}
                                contentContext={contentContext}
                                styling={styling}
                            ></DescriptionRow>

                            <View style={{ zIndex: 5, alignItems: 'center' }}>
                                <ButtonRow
                                    fullWidth={true}
                                    item={data[index]}
                                    program={program}
                                    type={styling.content.list?.type}
                                    deviceContext={deviceContext}
                                    appContext={appContext}
                                    userContext={userContext}
                                    styling={styling}
                                    onPressBack={() => navigation.goBack()}
                                    onPressPlay={(item) => onPressPlay(styling.content.list?.type, navigation, item, contentContext, appContext, userContext, 0, settingsContext)}
                                    onPressInformation={(item) => onPressInformation(styling.content.list?.type, contentContext, appContext, userContext, item, navigation, settingsContext)}
                                    onPressPlaySeason={(seasonIndex, episodeIndex) => onPressPlaySeason(contentContext, data, navigation, seasonIndex, episodeIndex, settingsContext)}
                                    onPressPlayCatchupTV={(startType, item, programIndex) => onPressPlayCatchupTV(navigation, startType, item, contentContext, settingsContext, programIndex)}
                                    onPressPurchase={() => onPressPurchase()}
                                    onPressContinue={(item) => onPressContinue(styling.content.list?.type, navigation, item, settingsContext)}
                                ></ButtonRow>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        );
    };

    return (
        <View style={{ flex: 1, marginBottom: -getCorrectHeight(deviceContext, 15), backgroundColor: '#000' }} focusContext={focusContext}>
            {data != null && data.length != 0 && (
                <View
                    style={{
                        position: 'absolute',
                        width: getRealWidth(deviceContext),
                        height: getRealWidth(deviceContext) * 1.5,
                        zIndex: -1,
                        alignItems: 'center',
                        top: getCorrectWidth(deviceContext, 15),
                    }}
                >
                    <Image
                        isLocal={true}
                        deviceContext={deviceContext}
                        source={require('../../../../assets/images/blackish_mobile.png')}
                        resizeMode={'cover'}
                        style={{
                            opacity: 0.5,
                            width: getRealWidth(deviceContext),
                            height: getRealWidth(deviceContext) * 1.5,
                        }}
                    ></Image>
                </View>
            )}
            {data != null && data.length != 0 && (
                <Swiper
                    loop={true}
                    showsPagination={true}
                    scrollEnabled={true}
                    autoplayTimeout={9}
                    index={0}
                    animated={true}
                    width={getRealWidth(deviceContext)}
                    height={getRealWidth(deviceContext) * 1.6}
                    autoplay={true}
                    pagingEnabled={true}
                    showsButtons={false}
                    paginationStyle={{
                        position: 'absolute',
                        bottom: 50,
                        left: 0,
                        right: 0,
                        flexDirection: 'row',
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'transparent',
                    }}
                    dotStyle={{ marginRight: 10 }}
                    activeDotStyle={{ marginRight: 10 }}
                    dotColor={appContext.application.theme.progresses.base}
                    activeDotColor={appContext.application.theme.progresses.progress}
                >
                    {data.map((item, index) => {
                        return <HeroObject key={index} item={item} index={index}></HeroObject>;
                    })}
                </Swiper>
            )}
            {data != null && data.length != 0 && (
                <View
                    style={{
                        position: 'absolute',
                        width: getRealWidth(deviceContext),
                        height: getRealHeight(deviceContext),
                        zIndex: -1,
                        alignItems: 'center',
                        top: getRealHeight(deviceContext) * 0.6,
                    }}
                >
                    <Image
                        isLocal={true}
                        deviceContext={deviceContext}
                        source={require('../../../../assets/images/blackish_mobile_reverse.png')}
                        resizeMode={'cover'}
                        style={{
                            width: getRealWidth(deviceContext),
                            height: getRealHeight(deviceContext),
                        }}
                    ></Image>
                </View>
            )}

        </View>
    );
};
export default Hero_Single;
