import { useEffect, useRef, useCallback } from 'react';

const useStateWithPromise = (state, setState) => {
    const resolverRef = useRef(null);

    useEffect(() => {
        if (resolverRef.current) {
            resolverRef.current(state);
            resolverRef.current = null;
        }
        /**
         * Dado que uma atualização de estado pode ser disparada exatamente com o mesmo estado novamente,
         * não é suficiente especificar o estado como a única dependência deste useEffect.
         * É por isso que o resolverRef.current também é uma dependência, porque vai garantir que o handler setNewState
         * seja chamado na renderização anterior.
         */
    }, [resolverRef.current, state]);

    const setNewState = useCallback(
        (newState) => {
            setState(newState);
            return new Promise((resolve) => {
                resolverRef.current = resolve;
            });
        },
        [setState]
    );

    return [setNewState];
};

export default useStateWithPromise;
