import * as React from 'react';
import { View } from '@scriptx-com/xtv-toolkit';
import { getFontSize } from '../../../application/styling/fontSizes';
import lang from '../../../application/languages/languages';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import ScreenWrapper from '../../wrapper';
import { AppContext } from '../../../application/context/appContext';
import { getCorrectHeight } from '../../../application/styling/correctSizes';
import { CommonActions, useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import { sendPageReport } from '../../../reporting/apis/page';
import { actionType, pageType } from '../../../reporting/models/apps';
import { getGlobals } from '../../../globals';
import Text from '../../../application/builder/components/text';
import { getFocusStyling, getPressableStyling, getPressableTextStyling } from '../../../application/builder/components/helpers/helper';
import Pressable from '../../../application/builder/components/pressable/pressable';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import { getUserIp } from '../../../application/data/account';
import { ContentContext } from '../../../application/context/contentContext';
import { PlayerContext } from '../../../application/context/playerContext';
import { sendActionReport } from '../../../reporting/apis/action';
import utils from '../../../application/general/utils';
import { ActivityIndicator } from 'react-native';

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const playerContext = React.useContext(PlayerContext);
    const contentContext = React.useContext(ContentContext);
    const [code, setCode] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);

    let QRCode = global.QRCode;

    React.useEffect(() => {
        getCode();
    }, [navigation, route]);

    const getCode = async () => {
        await fetch(
            getGlobals().AppApiUrl +
                '/auth/code?client=' +
                encodeURIComponent(appContext.application.client) +
                '&deployment=' +
                encodeURIComponent(appContext.application.deployment) +
                '&service=' +
                encodeURIComponent(appContext.application.branding.service),
            {
                method: 'GET',
            }
        )
            .then((response) => response.json())
            .then((response) => {
                setCode(response.code);
                pingCode(response.code);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const pingCode = async (code) => {
        await fetch(
            getGlobals().AppApiUrl +
                '/auth/ping?code=' +
                code +
                '&client=' +
                encodeURIComponent(appContext.application.client) +
                '&deployment=' +
                encodeURIComponent(appContext.application.deployment) +
                '&service=' +
                encodeURIComponent(appContext.application.branding.service),
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => response.json())
            .then((response) => {
                if (response.userid != undefined) {
                    setIsLoading(true);
                    userContext.setUserId(response.userid);
                    userContext.setPass(response.pass);
                    if (response.appid != undefined && response.appid != '') {
                        userContext.setAppId(response.serviceid);
                        checkAppId(response.appid, response.userid, response.pass);
                    } else {
                        loginUser(response.userid, response.pass);
                    }
                } else {
                    setTimeout(() => {
                        pingCode(code);
                    }, 3000);
                }
            });
    };

    const checkAppId = async (appId, userid, pass) => {
        sendActionReport(appContext, deviceContext, userContext, actionType.SELECT_SERVICE);
        var test = appContext.application.settings.nested_apps.find((s) => s.appid == appId);
        if (test != undefined || appId == appContext.application.branding.appid) {
            userContext.setOrginalPackageId(getGlobals().AppPackageID);
            fetchData(test.packageid, appId, userid, pass);
        }
    };
    const fetchData = async (packageId, appId, userid, pass) => {
        const path = 'https://cloudtv.akamaized.net/donotremove/tvms/domains/' + packageId + '/app.json?' + moment().unix();
        await fetch(path)
            .then((response) => response.json())
            .then(async (responseJson) => {
                global.Application = responseJson;
                var pathCart =
                    'https://cloudtv.akamaized.net/' +
                    responseJson.client +
                    '/' +
                    responseJson.deployment +
                    '/' +
                    responseJson.branding.service +
                    '/carts/' +
                    responseJson.settings.webshop_id +
                    '_cart.json';
                await fetch(pathCart)
                    .then((responseCart) => responseCart.json())
                    .then((responseCartJson) => {
                        global.Cart = responseCartJson;
                        utils.storeJson('ServiceID', appId);
                        userContext.setAppId(appId);
                        appContext.setApplication(global.Application);
                        loginUser(userid, pass);
                    })
                    .catch(() => {
                        utils.storeJson('ServiceID', appId);
                        userContext.setAppId(appId);
                        appContext.setApplication(global.Application);
                        loginUser(userid, pass);
                    });
            });
    };

    const loginUser = async (userid, pass) => {
        sendActionReport(appContext, deviceContext, userContext, actionType.LOGIN);
        var result = await getUserIp(userid, pass, appContext, userContext, deviceContext, userContext, playerContext, false);
        if (result != 'success') {
        } else {
            if (deviceContext.isWebTV) {
                var webTvUrl = window.location.href;
                if (webTvUrl.indexOf('connect=true') > -1) {
                    navigation && navigation.navigate('LoginCode');
                } else {
                    startContentLoader();
                }
            } else {
                startContentLoader();
            }
        }
    };

    const startContentLoader = () => {
        navigation.dispatch(
            CommonActions.reset({
                index: 1,
                routes: [{ name: 'Loader' }],
            })
        );
    };

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.CONNECT, duration);
            };
        }, [])
    );

    const goBack = () => {
        navigation.dispatch(
            CommonActions.reset({
                index: 1,
                routes: [{ name: 'Login' }],
            })
        );
    };

    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ focusKey: 'screen' }}>
            <View style={{ flex: 1 }}>
                <View
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.25)',
                    }}
                >
                    <Text
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                            fontWeight: appContext.application.authentication?.connect?.header.title_bold ? 'bold' : 'normal',
                            color: appContext.application.authentication?.connect?.header.title_color,
                        }}
                    >
                        {lang.getTranslation(userContext, 'signin_via_qr')}
                    </Text>
                </View>
                {!isLoading && (
                    <View style={{ flex: 4, margin: 10 }}>
                        <Text
                            style={{
                                alignSelf: 'center',
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                fontWeight: 'bold',
                                color: appContext.application.authentication?.connect?.header.title_color,
                                margin: getCorrectHeight(deviceContext, 10),
                            }}
                        >
                            {lang.getTranslation(userContext, 'two_ways')}
                        </Text>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, margin: 30 }}>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                        marginBottom: getCorrectHeight(deviceContext, 20),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'on_web')}
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'on_web_1_part1')}
                                    {appContext.application.settings.webtv}?connect=true
                                    {lang.getTranslation(userContext, 'on_web_1_part2')}
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'on_web_2')}
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'on_web_3')}
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {code}
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'when_connected')}
                                </Text>
                            </View>

                            <View style={{}}>
                                <View style={{ flex: 1, alignItems: 'center' }}>
                                    <View
                                        style={{
                                            flex: 1,
                                            width: 1,
                                            borderLeftColor: appContext.application.authentication?.connect?.header.title_color,
                                            borderLeftWidth: 1,
                                        }}
                                    ></View>
                                </View>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                        fontWeight: 'bold',
                                        color: appContext.application.authentication?.connect?.header.title_color,
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'or')}
                                </Text>
                                <View style={{ flex: 1, alignItems: 'center' }}>
                                    <View
                                        style={{
                                            flex: 1,
                                            width: 1,
                                            borderLeftColor: appContext.application.authentication?.connect?.header.title_color,
                                            borderLeftWidth: 1,
                                        }}
                                    ></View>
                                </View>
                            </View>

                            <View style={{ flex: 1, margin: 30 }}>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),

                                        marginBottom: getCorrectHeight(deviceContext, 20),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'scan_qr')}
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'scan_qr_1')}
                                </Text>
                                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                    <View
                                        style={{
                                            marginBottom: getCorrectHeight(deviceContext, 10),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: 'white',
                                            padding: getCorrectHeight(deviceContext, 10),
                                        }}
                                    >
                                        {deviceContext.isWebTV == false && <QRCode value={appContext.application.settings.webtv + '?connect=true'} />}
                                    </View>
                                </View>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'scan_qr_2')}
                                </Text>
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', justifyContent: 'center', marginBottom:30 }}>
                            <Pressable
                                style={{
                                    marginTop: 10,
                                    width:
                                        getRealWidth(deviceContext) *
                                        (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                    height: getCorrectHeight(deviceContext, 30),
                                    ...getPressableStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                onPress={() => goBack()}
                            >
                                <View>
                                    <Text
                                        style={{
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            ...getPressableTextStyling(
                                                appContext.application.authentication.connect.components?.button?._id,
                                                appContext
                                            ),
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'back')}
                                    </Text>
                                </View>
                            </Pressable>
                        </View>
                    </View>
                )}
                {isLoading && (
                    <View
                        style={{
                            flex: 4,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'center',
                            paddingBottom: deviceContext.isPhone ? 100 : 10,
                        }}
                    >
                        <ActivityIndicator size={'large'} color={'#fff'}></ActivityIndicator>
                    </View>
                )}
            </View>
        </ScreenWrapper>
    );
};
export default Screen_;
