import { isPlatformAndroid, isPlatformAndroidtv, isPlatformFiretv, isPlatformTizen, isPlatformWeb, isPlatformWebos } from '@rnv/renative';
import * as React from 'react';
import { StyleProp, StyleSheet, TextInput as RNTextInput, TextProps, TextStyle } from 'react-native';

const isAndroidBased = isPlatformAndroidtv || isPlatformFiretv || isPlatformAndroid;
const isWebBased = isPlatformWeb || isPlatformTizen || isPlatformWebos;

const oneTypeFonts = ['Abel-Regular', 'Inter-Light'];

const regularAndBoldFonts = ['Helvetica', 'Lato', 'Montserrat', 'OpenSans', 'Oswald', 'Roboto'];

export const supportedFonts = [...regularAndBoldFonts.map((f) => `${f}-Regular`), ...regularAndBoldFonts.map((f) => `${f}-Bold`), oneTypeFonts];

export const returnCorrectAndroidFontStyles = (styles: TextStyle) => {
    let fontFamily = styles.fontFamily;
    let fontWeight = styles.fontWeight;

    if (styles.fontWeight === 'bold' || (!isNaN(styles.fontWeight as any) && styles.fontWeight >= '600')) {
        fontWeight = 'normal';
        if (supportedFonts.includes(`${styles.fontFamily}-Bold`)) {
            fontFamily = `${styles.fontFamily}-Bold`;
        }
    } else if (supportedFonts.includes(styles.fontFamily)) {
        return { fontFamily, fontWeight };
    } else {
        if (supportedFonts.includes(`${styles.fontFamily}-Regular`)) {
            fontFamily = `${styles.fontFamily}-Regular`;
        }
    }

    return { fontFamily, fontWeight };
};

export function adjustFontStyles(style: StyleProp<TextStyle>) {
    if (isAndroidBased || isWebBased) {
        const styles = Array.isArray(style) ? StyleSheet.flatten(style) : style;
        return returnCorrectAndroidFontStyles(styles as TextStyle);
    }
}

const TextInput = React.forwardRef((props: TextProps, ref: React.LegacyRef<RNTextInput>) => {
    const { style, ...rest } = props;
    return <RNTextInput {...rest} style={[style, adjustFontStyles(style)]} ref={ref} />;
});

export default TextInput;
