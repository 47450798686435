import { CustomerSubscription, ProductPlansPlan } from '.';

export enum CustomerStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export enum CustomerSubscriptionStatus {
    PENDING = 'pending',
    ACTIVE = 'active',
    EXPIRED = 'expired',
    CANCELLED = 'cancelled',
}

export enum CustomerType {
    BUSINESS = 'business',
    PERSONAL = 'personal',
}

export enum CustomerProductStatus {
    TRIAL = 'trial',
    REGULAR = 'regular',
}

export class Customer {
    username: string;

    status: CustomerStatus;
    zipcode: string;
    state: string;
    city: string;
    country: string;
    is_deleted: boolean;
    productStatus: CustomerProductStatus;
    subscriptionStatus: CustomerSubscriptionStatus;
    shopping_cartName: string;
    shopping_cartId: string;
    credit_amount: number;
    wallet_amount: number;
    billing_country: string;
    billing_currency: string;
    billing_taxrate: number;
    originalCreatedAt: number; // take over from production db object creation date
    renewedDate: number;
    renewalDate: number;
    payment_provider: string;
    payment_auto_recurring: boolean;

    plan: ProductPlansPlan;
    baseSubscription: CustomerSubscription;
    extraSubscriptions: CustomerSubscription[];
}
