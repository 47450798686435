import { Animated, FocusContext, View } from '@scriptx-com/xtv-toolkit';
import * as React from 'react';
import { Easing } from 'react-native';
import { isPlatformTvos, isPlatformAndroidtv, isPlatformFiretv } from '@rnv/renative';
import { PlayerContext } from '../../context/playerContext';

const AnimatedView = Animated.createAnimatedComponent(View);

export interface Props {
    children: React.ReactChild | React.ReactChild[];
    focusContext?: FocusContext;
    paused: boolean;
    showControls: boolean;
}

export const PlayerFadeInOut = ({ children, showControls, focusContext }: Props) => {
    const opacity = React.useRef(new Animated.Value(0)).current;
    const contextPlayer = React.useContext(PlayerContext);

    function startAnimation(val) {
        Animated.timing(opacity, {
            toValue: val,
            duration: 800,
            easing: Easing.ease,
            useNativeDriver: true,
        }).start();
    }

    React.useEffect(() => {
        if (showControls) {
            startAnimation(1);
        // TODO: it works but why, I don't know yet
        } else if (isPlatformAndroidtv || isPlatformTvos || isPlatformFiretv) {
            startAnimation(0);
        }
    }, [showControls]);

    React.useEffect(() => {
        if (contextPlayer.paused) {
            global.preventControlsFromHiding = true;
        }
    }, [contextPlayer.paused]);

    return (
        <AnimatedView
            focusContext={focusContext}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                opacity: opacity,
                zIndex: 1,
            }}
        >
            {children}
        </AnimatedView>
    );
};
