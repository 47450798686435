import * as React from 'react';
import { DeviceContext } from '../../../context/deviceContext';
import Top_Portrait from './top.portrait';
import Top_Landscape from './top.landscape';

export const Details_Top = ({ styling, navigation, focusContext, contentScreensAvailable }) => {
    const deviceContext = React.useContext(DeviceContext);

    return (
        <>
            {(deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && <Top_Portrait styling={styling} navigation={navigation} focusContext={focusContext} contentScreensAvailable={contentScreensAvailable}></Top_Portrait>}
            {!deviceContext.isPhone && !deviceContext.isKaiOs && !deviceContext.isPwaVertical && <Top_Landscape styling={styling} navigation={navigation} focusContext={focusContext} contentScreensAvailable={contentScreensAvailable}></Top_Landscape>}
        </>
    );
};

export default Details_Top;
