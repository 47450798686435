import { MutableRefObject } from 'react';
import { View } from 'react-native';
import _ from 'lodash';

export const getUsedHeight = (appContext: any, screenName: any, gridMargin: any, extra: any, insets = 0, tabBarHeight = 0) => {
    var usedHeight = 0;
    var elements = appContext.application.screens.find((s) => s.type == screenName);

    if (elements != undefined) {
        elements.content.forEach((element) => {
            if (element.filterbars != undefined) {
                usedHeight = usedHeight + (extra + gridMargin + element.filterbars.general.margin_top + element.filterbars.general.border_width * 2);
            }
            if (element.menus != undefined) {
                usedHeight = usedHeight + (extra + gridMargin + element.menus.general.margin_top + element.menus.general.border_width * 2);
            }
        });
        elements.header.forEach((element) => {
            if (element.messages != undefined) {
                usedHeight = usedHeight + (extra + gridMargin + element.messages.general.margin_top + element.messages.general.border_width * 2) + 30;
            }
        });
    }
    return usedHeight + insets + tabBarHeight;
};

export const getGridData = async (userContext, screenName, contentContext, appContext) => {
    if (screenName == 'Recordings' && userContext.profile.recordings != undefined) {
        return userContext.profile.recordings;
    }
    if (screenName == 'Channels' && contentContext.channels.categories != undefined) {
        var channels =
            contentContext.channels.categories[
                contentContext.channelData.localCategoryIndex != 0
                    ? contentContext.channelData.localCategoryIndex
                    : contentContext.channelData.channelCategoryIndex
            ].channels;
        if (channels != undefined) {
            return channels.sort((a, b) => a.number - b.number);
        }
    }
    if (screenName == 'Cams' && contentContext.cams.categories != undefined) {
        var cams =
            contentContext.cams.categories[
                contentContext.camData.localCategoryIndex != 0 ? contentContext.camData.localCategoryIndex : contentContext.camData.camCategoryIndex
            ].cams;
        if (cams != undefined) {
            return cams.sort((a, b) => a.number - b.number);
        }
    }
    if (screenName == 'Series' && contentContext.series.categories != undefined) {
        return contentContext.series.categories[
            contentContext.seriesData.localCategoryIndex != 0
                ? contentContext.seriesData.localCategoryIndex
                : contentContext.seriesData.seriesCategoryIndex
        ].series;
    }
    if (screenName == 'Seasons' && contentContext.seriesData.seasons != undefined) {
        return contentContext.seriesData.season.episodes;
    }
    if (screenName == 'Courses' && contentContext.courses.categories != undefined) {
        return contentContext.courses.categories[
            contentContext.courseData.localCategoryIndex != 0
                ? contentContext.courseData.localCategoryIndex
                : contentContext.courseData.courseCategoryIndex
        ].courses;
    }
    if (screenName == 'Course' && contentContext.courseData.course.lessons != undefined) {
        return contentContext.courseData.course.lessons;
    }
    if (screenName == 'Radio' && contentContext.radios.categories != undefined) {
        var radio =
            contentContext.radios.categories[
                contentContext.radioData.localCategoryIndex != 0
                    ? contentContext.radioData.localCategoryIndex
                    : contentContext.radioData.radioCategoryIndex
            ].radios;
        if (radio != undefined) {
            return radio.sort((a, b) => a.number - b.number);
        }
    }
    if (screenName == 'Podcasts' && contentContext.podcasts.categories != undefined) {
        return contentContext.podcasts.categories[
            contentContext.podcastData.localCategoryIndex != 0
                ? contentContext.podcastData.localCategoryIndex
                : contentContext.podcastData.podcastCategoryIndex
        ].podcasts;
    }
    if (screenName == 'Music' && contentContext.albums.categories != undefined) {
        return contentContext.albums.categories[
            contentContext.albumData.localCategoryIndex != 0
                ? contentContext.albumData.localCategoryIndex
                : contentContext.albumData.albumCategoryIndex
        ].albums;
    }
    if (screenName == 'Movies' && contentContext.movies.categories != undefined) {
        return contentContext.movies.categories[
            contentContext.movieData.localCategoryIndex != 0
                ? contentContext.movieData.localCategoryIndex
                : contentContext.movieData.movieCategoryIndex
        ].movies;
    }
    if (screenName == 'Games' && contentContext.games.categories != undefined) {
        return contentContext.games.categories[
            contentContext.gameData.localCategoryIndex != 0
                ? contentContext.gameData.localCategoryIndex
                : contentContext.gameData.gameCategoryIndex
        ].games;
    }
    if (screenName == 'Shorts' && contentContext.shorts.categories != undefined) {
        var cat =
            contentContext.shorts.categories[
                contentContext.shortData.localCategoryIndex != 0
                    ? contentContext.shortData.localCategoryIndex
                    : contentContext.shortData.shortCategoryIndex
            ];
        var index =
            contentContext.shortData.localCategoryIndex != 0
                ? contentContext.shortData.localCategoryIndex
                : contentContext.shortData.shortCategoryIndex;
        if (cat.external == true && cat.mappings.mappings != undefined && cat.mappings.iteration_path != '') {
            var shorts = await getExternalCategory(cat, index, appContext, contentContext);
            return shorts;
        } else {
            return cat.shorts;
        }
    }
    return [];
};

export const calculateGridHeight = async (wrapperRef: MutableRefObject<View>, insets = 0, tabBarHeight = 0): Promise<number> => {
    return new Promise((resolve) => {
        if (wrapperRef.current) {
            wrapperRef.current.measure((_x, _y, _width, _height, _pageX, pageY) => {
                resolve((pageY ? pageY : 0) + insets + tabBarHeight);
            });
        } else {
            resolve(insets + tabBarHeight);
        }
    });
};
export const getExternalCategory = async (cat, categoryIndex, appContext, contentContext) => {
    var shorts = await getExternalData(cat, appContext);
    var shorts_ = contentContext.shorts;
    shorts_.categories[categoryIndex].shorts = shorts;
    contentContext.setShorts(shorts_);
    return shorts;
};
export const getExternalData = async (cat, appContext) => {
    try {
        let response = await fetch(cat.mappings.api);
        if (response.status == 200) {
            let data = await response.json();
            var items = data[cat.mappings.base.slice(0, -1)];
            var jsonMappings = JSON.parse(cat.mappings.mappings);
            const iterationPath = cat.mappings.iteration_path.slice(0, -1);
            const objects = [];
            iterateLevel(items, iterationPath, [], (keys) => {
                objects.push(
                    mapValuesToShort(items, jsonMappings, appContext, (mapping) => {
                        if (mapping == null) {
                            return undefined;
                        } else {
                            return replaceIterationKeys(mapping, keys);
                        }
                    })
                );
            });
            return objects;
        } else {
            return [];
        }
    } catch (e) {
        return [];
    }
};
export const getExternalSearchData = async (cat, appContext, search) => {
    try {
        const response = await fetch(cat.mappings.api, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: {
                    keyword: search,
                },
                page: 0,
                limit: 50,
                sort: {
                    createdDate: -1,
                },
            }),
        });
        if (response.status == 200) {
            let data = await response.json();
            var items = data[cat.mappings.base.slice(0, -1)];
            var jsonMappings = JSON.parse(cat.mappings.mappings);
            const iterationPath = cat.mappings.iteration_path.slice(0, -1);
            const objects = [];
            iterateLevel(items, iterationPath, [], (keys) => {
                objects.push(
                    mapValuesToShort(items, jsonMappings, appContext, (mapping) => {
                        if (mapping == null) {
                            return undefined;
                        } else {
                            return replaceIterationKeys(mapping, keys);
                        }
                    })
                );
            });
            return objects;
        } else {
            return [];
        }
    } catch (e) {
        return [];
    }
};
export const iterateLevel = (item: any, path: string | undefined, previousKeys: any[], callback: (previousKeys: any[]) => void) => {
    if (path == undefined) {
        // We're done iterating, map the values
        callback(previousKeys);
        return;
    }
    const pointIndex = path.indexOf('.');
    const [pathKey, leftovers] = pointIndex == -1 ? [path, undefined] : [path.substring(0, pointIndex), path.substring(pointIndex + 1)];

    if (pathKey == '[0]') {
        const currentKeys = [...previousKeys, null];
        for (const key of Object.keys(item)) {
            currentKeys[currentKeys.length - 1] = key;
            iterateLevel(item[key], leftovers, currentKeys, callback);
        }
    } else {
        // Just skip it, it's a fixed key
        iterateLevel(item[pathKey], leftovers, [...previousKeys, null], callback);
    }
};

export const replaceIterationKeys = (path: string, keys: any[]) => {
    let replacedPath = '';
    for (const key of keys) {
        const pointIndex = path.indexOf('.');

        const [pathKey, leftovers] = pointIndex == -1 ? [path, undefined] : [path.substring(0, pointIndex), path.substring(pointIndex + 1)];

        if (key === null) {
            // We should skip this path
            replacedPath += `.${pathKey}`;
        } else {
            // Put in the iteration key instead
            replacedPath += `.[${key}]`;
        }

        path = leftovers;

        if (leftovers == null) {
            // The path either ends early (acts as a context) or is broken
            break;
        }
    }

    if (replacedPath.length > 0) {
        // Remove the initial '.'
        replacedPath = replacedPath.substring(1);
    }

    if (path != null) {
        // We ran out of iteration keys early, append the remaining path as is
        replacedPath += `.${path}`;
    }

    return replacedPath;
};

export const mapValuesToShort = (item: any, jsonMappings: any, appContext: any, mapper: any) => {
    var languages = appContext.application.settings.languages;
    var translations = [] as any;
    languages.forEach((lang) => {
        translations.push({
            name: lang,
            description: _.get(item, mapper(jsonMappings.Description)) ?? '',
        });
    });
    var tags = _.get(item, mapper(jsonMappings.Tags));

    return {
        name: _.get(item, mapper(jsonMappings.Name)),
        placement: {
            ut_start: _.get(item, mapper(jsonMappings.placement_start_overide)),
            ut_end: _.get(item, mapper(jsonMappings.placement_end_overide)),
            no_play_on_trial: _.get(item, mapper(jsonMappings.placement_supress_on_trial_override)),
        },
        studio: _.get(item, mapper(jsonMappings.Studio)),
        parental: {
            enabled: _.get(item, mapper(jsonMappings.parental_control_overide)),
        },
        security: {
            akamai_token: _.get(item, mapper(jsonMappings.akamai_token_overide)) ?? false,
            flussonic_token: _.get(item, mapper(jsonMappings.flussonic_token_overide)) ?? false,
            legacy_token: _.get(item, mapper(jsonMappings.legacy_token_overide)) ?? false,
            drm: {
                drm_enabled: _.get(item, mapper(jsonMappings.drm_enabled_overide)) ?? false,
                drm_type: _.get(item, mapper(jsonMappings.drm_type_overide)) ?? '',
            },
        },
        translations: translations,
        payperview: {
            rule: 'string',
            enable: 'boolean',
            currencies: [
                {
                    iso3: 'string',
                    credits_rented: 'number',
                    credits_purchased: 'number',
                    key: 'string',
                },
            ],
        },
        tags: [
            {
                name: _.get(item, mapper(jsonMappings.Tags)),
            },
        ],
        ads: {
            vast_url: _.get(item, mapper(jsonMappings.vast_url_override)),
        },
        streams: {
            shorts: {
                url: _.get(item, mapper(jsonMappings.Stream)),
            },
        },
        images: {
            background: _.get(item, mapper(jsonMappings.Background)),
            widescreen: _.get(item, mapper(jsonMappings.Widescreen)),
        },
    };
};
