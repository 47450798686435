import * as React from 'react';

interface ReportContext {}
export const ReportContext = React.createContext<ReportContext>(null);

export function ReportProvider({ children }: { children: any }) {
    return <ReportContext.Provider value={{}}>{children}</ReportContext.Provider>;
}

// export function useUser() {
//     const context = React.useContext(ReportContext);
//     return context;
// }
