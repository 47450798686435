import { View } from '@scriptx-com/xtv-toolkit';
import * as React from 'react';
import { AppContext } from '../../../context/appContext';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { Images } from '../../../models/content/shared/images';
import { Hero_Slider as styling } from '../../../models/elements/heros/slider';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import Image from '../../components/image/image';
import { getChannelsFromSubscription, getCoursesFromSubscription, getGamesFromSubscription, getMoviesFromSubscription, getSeriesFromSubscription } from '../rails/helpers/helper';
import moment from 'moment';
import { BackgroundImage, ButtonRow, DescriptionRow, LogoRow, MetaRow, Pager, ProgressRow, StatusTimeRow } from './helpers/helpers_landscape';
import { onPressContinue, onPressFavorite, onPressInformation, onPressPlay, onPressPlayCatchupTV, onPressPlaySeason, onPressPurchase, onPressReplay, onPressTrailer, onPressWatchlist } from '../details/helpers/functions';
import { getCorrectWidth } from '../../../styling/correctSizes';
import { getCorrectLeftMargin } from '../../../styling/appLeftMargin';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import Swiper from '../../components/swiper/swiper';
import { SettingsContext } from '../../../context/settingsContext';
import { isFactorTv } from '@rnv/renative';
import { checkIfChannelHasPrograms } from './helpers/helper';

export interface Data {
    images: Images;
    name: string;
    tags: string;
    translations: any;
    _id: string;
}

export interface Props {
    styling: styling;
    navigation: any;
    focusContext: any;
}

export const Hero_Slider = ({ styling, navigation, focusContext }: Props) => {
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const settingsContext = React.useContext(SettingsContext);

    const screenName = getCurrentScreenName();

    const [data, setData] = React.useState([] as any);
    const [pageIndex, setPageIndex] = React.useState(0);
    const initialFocusSet = React.useRef(false);

    const [updateScreenFavorite, setUpdateScreenFavorite] = React.useState(0);
    const [updateScreenWatchlist, setUpdateScreenWatchlist] = React.useState(0);

    const getData = async () => {
        var path = '';
        if (styling.content.type == 'Content List') {
            if (styling.content.list?.type == 'Movies') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/movies/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Series') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/series/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Courses') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/courses/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Channels') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/channels/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Games') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/games/lists/' + styling.content.list.list + '_list.json';
            }
            try {
                let response = await fetch(path);
                let data = (await response.json()) as any;
                if (data != undefined && data.length > 0) {
                    if (styling.content.list?.type == 'Channels') {
                        var dataOut = getChannelsFromSubscription(contentContext, data[0].items);
                        var dataOutNew = [] as any;
                        if (dataOut.length > 0) {
                            for (var channel of dataOut) {
                                var test = checkIfChannelHasPrograms(channel, contentContext);
                                if (test) {
                                    dataOutNew.push(channel);
                                }
                            }
                            setData(dataOutNew);
                        }
                    } else if (styling.content.list?.type == 'Movies') {
                        var dataOut = getMoviesFromSubscription(contentContext, data[0].items);
                        if (dataOut.length > 0) {
                            let newData = dataOut;
                            if (dataOut.length > 5) {
                                newData = dataOut.slice(0, 5);
                            }
                            setData(newData);
                        }
                    } else if (styling.content.list?.type == 'Courses') {
                        var dataOut = getCoursesFromSubscription(contentContext, data[0].items);
                        if (dataOut.length > 0) {
                            let newData = dataOut;
                            if (dataOut.length > 5) {
                                newData = dataOut.slice(0, 5);
                            }
                            setData(newData);
                        }
                    } else if (styling.content.list?.type == 'Series') {
                        var dataOut = getSeriesFromSubscription(contentContext, data[0].items);
                        if (dataOut.length > 0) {
                            let newData = dataOut;
                            if (dataOut.length > 5) {
                                newData = dataOut.slice(0, 5);
                            }
                            setData(newData);
                        }
                    } else if (styling.content.list?.type == 'Games') {
                        var dataOut = getGamesFromSubscription(contentContext, data[0].items);
                        if (dataOut.length > 0) {
                            let newData = dataOut;
                            if (dataOut.length > 5) {
                                newData = dataOut.slice(0, 5);
                            }
                        }
                    }
                }
            } catch (error) {
                setData(null);
            }
        }
    };

    React.useEffect(() => {
        navigation.addListener('focus', () => {
            getData();
        });
    }, [navigation]);

    const checkIfHeaderHasLogoWide = () => {
        var test = appContext.application?.screens[0]?.header[0];
        if (test.logowide) {
            return true;
        } else {
            return false;
        }
    };

    const HeroObject = ({ item, index }) => {
        var program = [] as any;
        if (styling.content.list?.type == 'Channels') {
            if (contentContext.epgTv != undefined) {
                var epgChannel = contentContext.epgTv?.find((e) => e.i == item._id);
                if (epgChannel != undefined) {
                    var currentTime = moment().unix();
                    program = epgChannel.epg.find((e) => e.s <= currentTime && e.e >= currentTime);
                }
            }
        }

        if (index > 0) {
            initialFocusSet.current = true;
        }

        return (
            <View key={index} style={{ flex: 1 }} focusContext={focusContext}>
                <View
                    style={{
                        position: 'absolute',
                        width: getRealWidth(deviceContext) + getCorrectWidth(deviceContext, global.menuWidth),
                        height: getRealHeight(deviceContext),
                        zIndex: 2,
                    }}
                >
                    <Image
                        isLocal={true}
                        deviceContext={deviceContext}
                        source={require('../../../../assets/images/gradient-home-2.png')}
                        resizeMode={'cover'}
                        style={{  height: getRealHeight(deviceContext) + 8, width: getRealWidth(deviceContext) + getCorrectWidth(deviceContext, global.menuWidth) }}
                    ></Image>
                </View>
                <View
                    style={{
                        position: 'absolute',
                        width: getRealWidth(deviceContext) + getCorrectWidth(deviceContext, global.menuWidth),
                        height: getRealHeight(deviceContext),
                        zIndex: 2,
                    }}
                >
                    <Image
                        isLocal={true}
                        deviceContext={deviceContext}
                        source={require('../../../../assets/images/gradient-home-2.png')}
                        resizeMode={'cover'}
                        style={{  height: getRealHeight(deviceContext) + 8, width: getRealWidth(deviceContext) + getCorrectWidth(deviceContext, global.menuWidth) }}
                    ></Image>
                </View>

                <BackgroundImage program={program} item={data[index]} type={styling.content.list?.type} deviceContext={deviceContext} appContext={appContext} contentContext={contentContext}></BackgroundImage>
                <View
                    style={{
                        zIndex: 3,
                        flex: 1,
                        width: getRealWidth(deviceContext),
                        paddingTop: getRealHeight(deviceContext) * 0.25 + (checkIfHeaderHasLogoWide() ? 20 : 0),
                        marginLeft: getCorrectWidth(deviceContext, global.menuWidth) + getCorrectLeftMargin(deviceContext, appContext, screenName),
                        paddingRight: getCorrectLeftMargin(deviceContext, appContext, screenName),
                    }}
                >
                    <View>
                        <LogoRow program={program} item={data[index]} type={styling.content.list?.type} deviceContext={deviceContext} appContext={appContext} contentContext={contentContext} styling={styling}></LogoRow>
                        <StatusTimeRow
                            program={program}
                            item={data[index]}
                            type={styling.content.list?.type}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            contentContext={contentContext}
                            styling={styling}
                        ></StatusTimeRow>
                        <MetaRow program={program} item={data[index]} type={styling.content.list?.type} deviceContext={deviceContext} appContext={appContext} userContext={userContext} contentContext={contentContext} styling={styling}></MetaRow>
                        <DescriptionRow program={program} item={data[index]} type={styling.content.list?.type} deviceContext={deviceContext} userContext={userContext} contentContext={contentContext} styling={styling}></DescriptionRow>
                        <ProgressRow
                            program={program}
                            item={data[index]}
                            type={styling.content.list?.type}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            contentContext={contentContext}
                            styling={styling}
                        ></ProgressRow>
                    </View>

                    <View>
                        <ButtonRow
                            focusable={index === pageIndex}
                            hasPreferredFocus={index === 0 && !initialFocusSet.current}
                            focusContext={focusContext}
                            item={data[index]}
                            program={program}
                            type={styling.content.list?.type}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPressBack={() => navigation.goBack()}
                            onPressPlay={(item) => onPressPlay(styling.content.list?.type, navigation, item, contentContext, appContext, userContext, 0, settingsContext)}
                            onPressInformation={(item) => onPressInformation(styling.content.list?.type, contentContext, appContext, userContext, item, navigation, settingsContext)}
                            onPressPlaySeason={(seasonIndex, episodeIndex) => onPressPlaySeason(contentContext, data, navigation, seasonIndex, episodeIndex, settingsContext)}
                            onPressPlayCatchupTV={(startType, item, programIndex) => onPressPlayCatchupTV(navigation, startType, item, contentContext, settingsContext, programIndex)}
                            onPressTrailer={(item) => onPressTrailer(styling.content.list?.type, navigation, item, settingsContext)}
                            onPressPurchase={() => onPressPurchase()}
                            onPressFavorite={(isFavorite, item) => onPressFavorite(isFavorite, contentContext, userContext, styling.content.list?.type, appContext, deviceContext, setUpdateScreenFavorite, item)}
                            onPressWatchlist={(isWatchlist, item, seasonIndex, episodeIndex) =>
                                onPressWatchlist(isWatchlist, userContext, styling.content.list?.type, appContext, contentContext, setUpdateScreenWatchlist, item, seasonIndex, episodeIndex)
                            }
                            onPressContinue={(item) => onPressContinue(styling.content.list?.type, navigation, item, settingsContext)}
                        ></ButtonRow>
                    </View>
                </View>
            </View>
        );
    };

    return (
        <View style={{ flex: 1 }} focusContext={focusContext}>
            {data != null && data.length != 0 && (
                <View
                    style={{
                        width: getRealWidth(deviceContext) + (deviceContext.isWebTV ? 20 : 0),
                        height: getRealHeight(deviceContext),
                        flexDirection: 'row',
                        flexGrow: 1,
                        backgroundColor: '#000',
                    }}
                >
                    {data != null && data.length != 0 && (
                        <Swiper
                            loop={true}
                            showsPagination={true}
                            scrollEnabled={true}
                            autoplayTimeout={9}
                            index={0}
                            animated={true}
                            width={getRealWidth(deviceContext)}
                            height={getRealHeight(deviceContext)}
                            autoplay={true}
                            pagingEnabled={true}
                            showsButtons={false}
                            paginationStyle={{
                                position: 'absolute',
                                bottom: getRealHeight(deviceContext) * 0.25,
                                left: 0,
                                right: 0,
                                flexDirection: 'row',
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'transparent',
                            }}
                            dotStyle={{ marginRight: 10 }}
                            activeDotStyle={{ marginRight: 10 }}
                            dotColor={appContext.application.theme.progresses.base}
                            activeDotColor={appContext.application.theme.progresses.progress}
                            startingPoint={getCorrectWidth(deviceContext, global.menuWidth) + getCorrectLeftMargin(deviceContext, appContext, screenName)}
                            onIndexChanged={(index) => {
                                if (isFactorTv) {
                                    setPageIndex(index);
                                }
                            }}
                        >
                            {data.map((item, index) => {
                                return <HeroObject key={index} item={item} index={index}></HeroObject>;
                            })}
                        </Swiper>
                    )}
                </View>
            )}
        </View>
    );
};
export default Hero_Slider;
