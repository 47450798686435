import { getCorrectSizeUpDown } from './correctSizeUpDown';

export function getFontSize(deviceContext, type: any) {
    switch (deviceContext.deviceType as any) {
        case 'KaiOS':
            return getFontSizesKaiOS(type) * getCorrectSizeUpDown(deviceContext);
        case 'FireTV':
            return getFontSizesFireTVorAndroidTV(type) * getCorrectSizeUpDown(deviceContext);
        case 'AndroidTV':
            return getFontSizesFireTVorAndroidTV(type) * getCorrectSizeUpDown(deviceContext);
        case 'AppleTV':
            return getFontSizesAppleTV(type) * getCorrectSizeUpDown(deviceContext);
        case 'SmartTV_Tizen':
            return getFontSizesTizen(type) * getCorrectSizeUpDown(deviceContext);
        case 'SmartTV_LG':
            return getFontSizesWebOS(type) * getCorrectSizeUpDown(deviceContext);
        case 'PWA':
            return getFontSizesPwa(type) * getCorrectSizeUpDown(deviceContext);
        case 'WebTV':
            return getFontSizesWebTV(type) * getCorrectSizeUpDown(deviceContext);
        case 'iOS':
            return getFontSizesiOS(type) * getCorrectSizeUpDown(deviceContext);
        case 'iPad':
            return getFontSizesiPad(type) * getCorrectSizeUpDown(deviceContext);
        case 'Android_Mobile':
            return getFontSizesAndroidMobile(type) * getCorrectSizeUpDown(deviceContext);
        case 'Android_Tablet':
            return getFontSizesAndroidTablet(type) * getCorrectSizeUpDown(deviceContext);
        default:
            break;
    }
}
//ok
export function getFontSizesKaiOS(type: any) {
    switch (type) {
        case 'XX-Small':
            return 18;
        case 'X-Small':
            return 22;
        case 'Small':
            return 24;
        case 'Normal':
            return 26;
        case 'Large':
            return 38;
        case 'ExtraLarge':
            return 40;
        case 'ExtraExtraLarge':
            return 42;
        case 'ExtraExtraExtraLarge':
            return 54;
        case 'Mega':
            return 79;
        default:
            return 18;
    }
}
//ok
export function getFontSizesFireTVorAndroidTV(type: any) {
    switch (type) {
        case 'XX-Small':
            return 8;
        case 'X-Small':
            return 10;
        case 'Small':
            return 12;
        case 'Normal':
            return 16;
        case 'Large':
            return 22;
        case 'ExtraLarge':
            return 30;
        case 'ExtraExtraLarge':
            return 40;
        case 'ExtraExtraExtraLarge':
            return 50;
        case 'Mega':
            return 93;
        default:
            return 18;
    }
}
export function getFontSizesAppleTV(type: any) {
    switch (type) {
        case 'XX-Small':
            return 12;
        case 'X-Small':
            return 14;
        case 'Small':
            return 16;
        case 'Normal':
            return 18;
        case 'Large':
            return 20;
        case 'ExtraLarge':
            return 22;
        case 'ExtraExtraLarge':
            return 24;
        case 'Mega':
            return 36;
        default:
            return 18;
    }
}
//ok
export function getFontSizesTizen(type: any) {
    switch (type) {
        case 'XX-Small':
            return 6;
        case 'X-Small':
            return 8;
        case 'Small':
            return 10;
        case 'Normal':
            return 14;
        case 'Large':
            return 16;
        case 'ExtraLarge':
            return 28;
        case 'ExtraExtraLarge':
            return 45;
        case 'ExtraExtraExtraLarge':
            return 55;
        case 'Mega':
            return 75;
        default:
            return 18;
    }
}
//ok
export function getFontSizesWebOS(type: any) {
    switch (type) {
        case 'XX-Small':
            return 8;
        case 'X-Small':
            return 10;
        case 'Small':
            return 14;
        case 'Normal':
            return 16;
        case 'Large':
            return 18;
        case 'ExtraLarge':
            return 22;
        case 'ExtraExtraLarge':
            return 49;
        case 'ExtraExtraExtraLarge':
            return 59;
        case 'Mega':
            return 79;
        default:
            return 18;
    }
}
//ok
export function getFontSizesiOS(type: any) {
    switch (type) {
        case 'XX-Small':
            return 6;
        case 'X-Small':
            return 8;
        case 'Small':
            return 10;
        case 'Normal':
            return 12;
        case 'Large':
            return 16;
        case 'ExtraLarge':
            return 20;
        case 'ExtraExtraLarge':
            return 26;
        case 'ExtraExtraExtraLarge':
            return 34;
        case 'Mega':
            return 48;
        default:
            return 12;
    }
}
//ok
export function getFontSizesiPad(type: any) {
    switch (type) {
        case 'XX-Small':
            return 6;
        case 'X-Small':
            return 8;
        case 'Small':
            return 10;
        case 'Normal':
            return 16;
        case 'Large':
            return 18;
        case 'ExtraLarge':
            return 32;
        case 'ExtraExtraLarge':
            return 38;
        case 'ExtraExtraExtraLarge':
            return 46;
        case 'Mega':
            return 50;
        default:
            return 16;
    }
}
export function getFontSizesAndroidTablet(type: any) {
    switch (type) {
        case 'XX-Small':
            return 6;
        case 'X-Small':
            return 8;
        case 'Small':
            return 12;
        case 'Normal':
            return 14;
        case 'Large':
            return 18;
        case 'ExtraLarge':
            return 22;
        case 'ExtraExtraLarge':
            return 32;
        case 'ExtraExtraExtraLarge':
            return 45;
        case 'Mega':
            return 55;
        default:
            return 16;
    }
}
export function getFontSizesAndroidMobile(type: any) {
    switch (type) {
        case 'XX-Small':
            return 6;
        case 'X-Small':
            return 8;
        case 'Small':
            return 10;
        case 'Normal':
            return 12;
        case 'Large':
            return 16;
        case 'ExtraLarge':
            return 20;
        case 'ExtraExtraLarge':
            return 26;
        case 'ExtraExtraExtraLarge':
            return 34;
        case 'Mega':
            return 48;
        default:
            return 12;
    }
}
//ok
export function getFontSizesWebTV(type: any) {
    switch (type) {
        case 'XX-Small':
            return 6;
        case 'X-Small':
            return 8;
        case 'Small':
            return 10;
        case 'Normal':
            return 12;
        case 'Large':
            return 18;
        case 'ExtraLarge':
            return 24;
        case 'ExtraExtraLarge':
            return 32;
        case 'ExtraExtraExtraLarge':
            return 45;
        case 'Mega':
            return 75;
        default:
            return 18;
    }
}
//ok
export function getFontSizesPwa(type: any) {
    switch (type) {
        case 'XX-Small':
            return 2;
        case 'X-Small':
            return 4;
        case 'Small':
            return 8;
        case 'Normal':
            return 10;
        case 'Large':
            return 16;
        case 'ExtraLarge':
            return 20;
        case 'ExtraExtraLarge':
            return 28;
        case 'ExtraExtraExtraLarge':
            return 32;
        case 'Mega':
            return 75;
        default:
            return 18;
    }
}
