import * as React from 'react';
import { useState, Dispatch } from 'react';

interface SettingsContext {
    usedHeight: number;
    tabBarHeight: number;
    showMainMenu: boolean;
    setUsedHeight: Dispatch<any>;
    setShowMainMenu: Dispatch<any>;
    setTabBarHeight: Dispatch<any>;
}
export const SettingsContext = React.createContext<SettingsContext>(null);

export function SettingsProvider({ children }: { children: any }) {
    const [usedHeight, setUsedHeight] = useState(0);
    const [tabBarHeight, setTabBarHeight] = useState(0);
    const [showMainMenu, setShowMainMenu] = useState(false);

    return (
        <SettingsContext.Provider
            value={{
                usedHeight,
                tabBarHeight,
                showMainMenu,
                setUsedHeight,
                setShowMainMenu,
                setTabBarHeight,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
}

// export function useUser() {
//     const context = React.useContext(SettingsContext);
//     return context;
// }
