export const getAuthenticationText = (translations, userContext) => {
    if (translations != undefined) {
        if (translations.find((trans) => trans.name == userContext.selectedLanguage) != undefined) {
            return translations.find((trans) => trans.name == userContext.selectedLanguage)?.description;
        } else {
            return translations[0]?.description;
        }
    } else {
        return '';
    }
};
