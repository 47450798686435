import * as React from 'react';
import { View, Image as Image_ } from '@scriptx-com/xtv-toolkit';
import { getFontSize } from '../../../styling/fontSizes';
import Pressable from '../../components/pressable/pressable';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { DeviceContext } from '../../../context/deviceContext';
import { AppContext } from '../../../context/appContext';
import Image from '../../components/image/image';
import Text from '../../components/text';
import { getFocusStyling, getPressableStyling } from '../../components/helpers/helper';
import { colorShade } from '../../../styling/colorShade';
import { ContentContext } from '../../../context/contentContext';


export interface Program {
    n: string;
    s: any;
    e: any;
}
export interface Data {
    name: string;
    number: string;
    images: {
        widescreen?: string;
        square?: string;
        tile?: string;
        background?: string;
        poster?: string;
        logo?: string;
    };
    _id: string;

    progress?: number;
    catchuptv?: boolean;

    program?: Program;
}

export interface Props {
    focusContext?: any;
    focusRepeatContext?: any;
    noMargin?: boolean;
    height: number;
    width: number;
    onPress: any;
    styling: any;
    data: Data;
    type: string;
    index: number;
    rails?: boolean;
    onLongPress: any;
    heroIsPlayer: boolean;
}

const NUMERIC_IMAGES = {
    1: require('../../../../assets/images/numbers/1.png'),
    2: require('../../../../assets/images/numbers/2.png'),
    3: require('../../../../assets/images/numbers/3.png'),
    4: require('../../../../assets/images/numbers/4.png'),
    5: require('../../../../assets/images/numbers/5.png'),
    6: require('../../../../assets/images/numbers/6.png'),
    7: require('../../../../assets/images/numbers/7.png'),
    8: require('../../../../assets/images/numbers/8.png'),
    9: require('../../../../assets/images/numbers/9.png'),
    10: require('../../../../assets/images/numbers/10.png'),
};

export const Top10 = ({ focusContext, focusRepeatContext, noMargin, height, width, onPress, styling, type, data, index, onLongPress, rails, heroIsPlayer }: Props) => {
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    const contentContext = React.useContext(ContentContext);
    const timeout = React.useRef<NodeJS.Timeout>();

    const onFocus = (data) => {
        timeout.current = setTimeout(() => {
            if (styling.content?.link_to_hero != undefined && styling.content?.link_to_hero && heroIsPlayer) {
                contentContext.setHero({
                    type: type,
                    data: [data],
                });
            }
        }, 1000);
    };

    const onBlur = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    };

    const focusWidth = () => {
        return getPressableStyling(styling.components?.button._id, appContext).borderWidth * 2;
    };
    if (data == undefined && index > 9) {
        return null;
    } else {
        return (
            <View>
                <Pressable
                    style={[
                        {
                            marginBottom: getCorrectWidth(deviceContext, deviceContext.isPhone || deviceContext.isPwaVertical ? 5 : 4),
                            borderRadius: getPressableStyling(styling.components?.button._id, appContext).borderRadius,
                        },
                    ]}
                    focusContext={focusContext}
                    focusRepeatContext={focusRepeatContext}
                    onFocus={() => onFocus(data)}
                    onBlur={() => onBlur()}
                    animatorOptions={getFocusStyling('Buttons', appContext)}
                    onPress={() => onPress(data?._id)}
                    onLongPress={() => onLongPress(data?._id)}
                >
                    <View>
                        <View
                            style={{
                                margin: getCorrectHeight(deviceContext, 3),
                                backgroundColor: getPressableStyling(styling.components?.button._id, appContext).backgroundColor,
                                borderRadius: getPressableStyling(styling.components?.button._id, appContext).borderRadius,
                                padding: 10,
                            }}
                        >
                            <View>
                                {(type == 'Channels' || type == 'Cams' || type == 'Radio' || type == 'Albums' || type == 'Podcasts') && (
                                    <Image
                                        deviceContext={deviceContext}
                                        source={{ uri: appContext.cloudUrl + data?.images?.square }}
                                        resizeMethod={'scale'}
                                        resizeMode={'cover'}
                                        style={{
                                            borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius) ,
                                            height: height - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 2),
                                            width: width - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 2),
                                        }}
                                    />
                                )}
                                {(type == 'Series' || type == 'Courses') && (
                                    <Image
                                    isCache={true}
                                        deviceContext={deviceContext}
                                        source={{ uri: appContext.cloudUrl + data?.images?.square }}
                                        resizeMethod={'scale'}
                                        resizeMode={'cover'}
                                        style={{
                                            borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius) ,
                                            height: height - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 2),
                                            width: (width - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 2)) * 1.7,
                                        }}
                                    />
                                )}
                                {type == 'Movies' && (
                                    <Image
                                        deviceContext={deviceContext}
                                        source={{ uri: appContext.cloudUrl + data?.images?.square }}
                                        resizeMethod={'scale'}
                                        resizeMode={'cover'}
                                        style={{
                                            borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius),
                                            height: height - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 2),
                                            width: width - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 2) * 1.5,
                                        }}
                                    />
                                )}
                            </View>
                        </View>
                        <View
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                zIndex: 0,
                                flex: 1,
                                padding: 7,
                            }}
                        >
                            <View>
                                {(type == 'Channels' || type == 'Cams' || type == 'Radio' || type == 'Albums' || type == 'Podcasts') && (
                                    <Image_
                                        source={require('../../../../assets/images/squa.png')}
                                        resizeMethod={'scale'}
                                        resizeMode={'cover'}
                                        style={{
                                            borderRadius: getPressableStyling(styling.components?.button._id, appContext).borderRadius,
                                            height: height,
                                            width: width,
                                        }}
                                    />
                                )}
                                {(type == 'Series' || type == 'Courses') && (
                                    <Image_
                                        source={require('../../../../assets/images/wide.png')}
                                        resizeMethod={'scale'}
                                        resizeMode={'cover'}
                                        style={{
                                            borderRadius: getPressableStyling(styling.components?.button._id, appContext).borderRadius,
                                            height: height,
                                            width: width * 1.7,
                                        }}
                                    />
                                )}
                                {type == 'Movies' && (
                                    <Image_
                                        source={require('../../../../assets/images/rect.png')}
                                        resizeMethod={'scale'}
                                        resizeMode={'cover'}
                                        style={{
                                            borderRadius: getPressableStyling(styling.components?.button._id, appContext).borderRadius,
                                            height: height,
                                            width: width * 1.5,
                                        }}
                                    />
                                )}
                            </View>

                            <View
                                style={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    justifyContent: 'center',
                                    bottom: 8,
                                    right:8
                                }}
                            >
                                {index < 9 && (
                                    <Image_
                                        source={NUMERIC_IMAGES[index + 1]}
                                        resizeMethod={'scale'}
                                        resizeMode={'contain'}
                                        style={{
                                            height: getCorrectWidth(deviceContext, 74) * 0.6,
                                            width: getCorrectWidth(deviceContext, 52) * 0.6,
                                        }}
                                    />
                                )}
                                   {index == 9 && (
                                    <Image_
                                        source={NUMERIC_IMAGES[index + 1]}
                                        resizeMethod={'scale'}
                                        resizeMode={'contain'}
                                        style={{
                                            height: getCorrectWidth(deviceContext, 76) * 0.6,
                                            width: getCorrectWidth(deviceContext, 108) * 0.6,
                                        }}
                                    />
                                )}
                            </View>
                        </View>
                    </View>
                </Pressable>
            </View>
        );
    }
};
export default Top10;
