import UTILS from '../../general/utils';

export const loadStorageVariables = async () => {
    const responses = await Promise.all([
        UTILS.retrieveJson('Selected_Language'),
        UTILS.retrieveJson('ServiceID'),
        UTILS.retrieveJson('UserID'),
        UTILS.retrieveJson('Pass'),
        UTILS.retrieveJson('AutoLogin'),
        UTILS.retrieveJson('OnboardingFinished'),
        UTILS.retrieveJson('Selected_Language_Code'),
    ]);
    return responses;
};
