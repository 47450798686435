import * as React from 'react';
import Webview from '../../../application/builder/pages/webview';
import ScreenWrapper from '../../wrapper';

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    return (
        <ScreenWrapper style={{ flex: 1 }}>
            <Webview navigation={navigation} route={route} type={'Games'}></Webview>
        </ScreenWrapper>
    );
};
export default Screen_;
