import { App } from '../shared/app';
import { Customer } from '../shared/customer';
import { Device } from '../shared/device';
import { Location } from '../shared/location';
import { Network } from '../shared/network';
import { Product } from '../shared/product';
import { Content } from '../shared/content';

export enum errorType {
    PLAYER_ERROR = 'Player Error',
    APP_ERROR = 'App Error',
    CONTENT_ERROR = 'Content Error',
}

export enum DeviceType {}

export class Error {
    errorType: string;
    error: string; //the actual error message
    timestamp: number; //timestamp of the error
    content: Content;
    app: App;
    location: Location;
    device: Device;
    network: Network;
    customer: Customer;
    product: Product;
}
