import moment from "moment";

export const checkIfChannelHasPrograms = (channel, contentContext) => {
    if (contentContext.epgTv != undefined) {
        var epgChannel = contentContext.epgTv?.find((e) => e.i == channel._id);
        if (epgChannel != undefined) {
            var currentTime = moment().unix();
            var program = epgChannel.epg.find((e) => e.s <= currentTime && e.e >= currentTime);
            if (program != undefined) {
                return true;
            }
        }
    }
    return false;
};
