import * as React from 'react';
import { useState, Dispatch } from 'react';

interface KeyboardContext {
    keyboardVisible: boolean;
    setKeyboardVisible: Dispatch<any>;
    onChangeText: Dispatch<any>;
    placeholder: string;
    isSecure: boolean;
    focusOptions: any;
    value: string;
    openKeyboard: ({ placeholder, focusOptions, isSecure }: { placeholder?: string; focusOptions?: object; isSecure?: boolean }) => void;
}
export const KeyboardContext = React.createContext<KeyboardContext>(null);

export function KeyboardProvider({ children }: { children: any }) {
    const [keyboardVisible, setKeyboardVisible] = useState(false);
    const [value, setOnChangeText] = useState('');
    const [placeholder, setPlaceholder] = useState('');
    const [isSecure, setIsSecure] = useState(false);
    const [focusOptions, setFocusOptions] = useState({});

    const openKeyboard = ({ placeholder = '', isSecure = false, focusOptions = {} }) => {
        setPlaceholder(placeholder);
        setIsSecure(isSecure);
        setFocusOptions(focusOptions);
        setKeyboardVisible(true);
    };

    return (
        <KeyboardContext.Provider
            value={{
                keyboardVisible,
                setKeyboardVisible,
                placeholder,
                onChangeText: setOnChangeText,
                focusOptions,
                openKeyboard,
                isSecure,
                value,
            }}
        >
            {children}
        </KeyboardContext.Provider>
    );
}
