import { App } from '../shared/app';
import { Customer } from '../shared/customer';
import { Device } from '../shared/device';
import { Location } from '../shared/location';
import { Network } from '../shared/network';
import { Product } from '../shared/product';

export enum pageType {
    LANGUAGE = 'Language',
    SERVICES = 'Services',
    LOGIN = 'Login',
    LOADER = 'Loader',
    DISCLAIMER = 'Disclaimer',
    FORGOT = 'Forgot',
    REGISTER = 'Register',
    CONNECT = 'Connect',
    PROFILE = 'Profile',
    ADD_PROFILE = 'Add profile',
    EDIT_PROFILE = 'Edit profile',
    HOME = 'Home',
    APPINFO = 'App Info',
    MESSAGES = 'Messages',
    RENEWAL = 'Renewal',
    SUBINFO = 'Subscription Info',
    UPDATES = 'App Update',
    SIGNUP_DETAILS = 'Signup Details',
    SIGNUP_FORM = 'Signup Form',
    SIGNUP_PRODUCTS = 'Signup Products',
    CHANNELS = 'Channels',
    RADIO = 'Radio',
    CAMS = 'Cams',
    TVGUIDE = 'Tv guide',
    PROGRAM = 'Program',
    RECORDINGS = 'Recordings',
    CATCHUPTV = 'CatchupTV',
    MOVIES = 'Movies',
    SERIES = 'Series',
    MUSIC = 'Music',
    PODCASTS = 'Podcasts',
    EDUCATION = 'Education',
    ABOUT = 'About',
    SETTINGS = 'Settings',
    DEVICES = 'Devices',
    SUPPORT = 'Select Support',
    CHANNEL_PLAYER = 'Channel Player',
    MOVIE_PLAYER = 'Movie Player',
    SERIES_PLAYER = 'Series Player',
    MUSIC_PLAYER = 'Music Player',
    PODCAST_PLAYER = 'Podcast Player',
    RADIO_PLAYER = 'Radio Player',
    RECORDING_PLAYER = 'Recording Player',
    CATCHUPTV_PLAYER = 'CatchupTV Player',
    CAMS_PLAYER = 'Cams Player',
    MOVIE_DETAILS = 'Authenticate',
    SERIES_DETAILS = 'Series Details',
    COURSE_DETAILS = 'Course Details',
    COURSE_PLAYER = 'Course Player',
    COURSES = 'Courses',
    MUSIC_DETAILS = 'Music Details',
    PODCAST_DETAILS = 'Podcast Details',
    SEARCH = 'Search',

    CODE = 'Code',
    GETSTARTED = 'Get Started',
    ONBOARDING = 'Onboarding',
    PWA = 'PWA',
    GAMES_WEBVIEW = 'Game Webview',
    SHORT_PLAYER = 'Short Player',
    DOWNLOADS = 'Downloads',
    PURCHASES = 'Purchases',
    RENTALS = 'Rentals',
    WATCHLIST = 'Watchlist',
    FAVORITES = 'Favorites',
    SHORTS = 'Shorts',
}

export class Page {
    pageType: string;
    timestamp: number; //timestamp start visit page
    duration: number; //number of seconds on this page
    app: App;
    location: Location;
    device: Device;
    network: Network;
    customer: Customer;
    product: Product;
}
