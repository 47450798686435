import * as React from 'react';
import { View, Image as Image_, PressableFocusOptions } from '@scriptx-com/xtv-toolkit';
import { getFontSize } from '../../../styling/fontSizes';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faLock, faPlay } from '@fortawesome/pro-light-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import Pressable from '../../components/pressable/pressable';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { favoriteStatus, watchingStatus } from '../../../data/account';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { UserContext } from '../../../context/userContext';
import { ContentContext } from '../../../context/contentContext';
import moment from 'moment';
import { InteractiveTV } from '../../../models/content/shared/interactivetv';
import { checkParentalLockShouldApply, getLiveImage, getTimedImage } from '../../elements/players/helpers/functions';
import { DeviceContext } from '../../../context/deviceContext';
import lang from '../../../languages/languages';
import { AppContext } from '../../../context/appContext';
import { Translations } from '../../../models/content/shared/translations';
import Image from '../../components/image/image';
import Text from '../../components/text';
import { getFocusStyling, getPressableStyling } from '../../components/helpers/helper';
import { getLiveTag, getReplayTag } from '../players/helpers/helpers';
import { RemoteImageHeightWidth } from '../../components/remoteimage/remoteimage';

export interface Program {
    n: string;
    s: any;
    e: any;
}

export interface Data {
    name: string;
    _id: string;
    number?: string;
    duration?: number;
    images: {
        widescreen?: string;
        square?: string;
        tile?: string;
        background?: string;
        poster?: string;
        logo?: string;
    };
    catchuptv?: boolean;
    interactivetv?: InteractiveTV;
    progress?: number;
    program?: Program;
    channel?: any;
    translations?: Translations;
}

export interface Props {
    fontDensity?: number;
    focusContext?: any;
    focusOptions?: PressableFocusOptions;
    focusRepeatContext?: any;
    noMargin?: boolean;
    height: number;
    width: number;
    onPress: any;
    styling: any;
    data: Data;
    type: string;
    rails?: boolean;
    onLongPress: any;
    heroIsPlayer: boolean;
}

export const Widescreen = ({ fontDensity, focusContext, focusRepeatContext, noMargin, height, width, onPress, styling, type, data, onLongPress, focusOptions, heroIsPlayer }: Props) => {
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const contentContext = React.useContext(ContentContext);
    const deviceContext = React.useContext(DeviceContext);
    const screenName = getCurrentScreenName();
    const timeout = React.useRef<NodeJS.Timeout>();

    const onFocus = (data) => {
        timeout.current = setTimeout(() => {
            if (styling.content?.link_to_hero != undefined && styling.content?.link_to_hero && heroIsPlayer) {
                contentContext.setHero({
                    type: type,
                    data: [data],
                });
            }
        }, 1000);
    };

    const onBlur = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    };

    if (fontDensity == undefined) {
        fontDensity = 1;
    }
    var favorite = favoriteStatus(type, data?._id, userContext);
    var progress = -1;
    var parentalIsApplied = false;

    if (type == 'Seasons') { 
        var watching = watchingStatus(type, contentContext.seriesData.series._id, userContext);
        if (watching != undefined && watching.continue != undefined) {
            var episode = watching.continue.episodes?.find((e) => e?._id == data?._id);
            if (episode != undefined) {
                progress = (episode.position / episode.duration) * 100;
            } else {
                progress = 0;
            }
        } else {
            progress = 0;
        }
    }
    if (type == 'Lessons') {
        var watching = watchingStatus('Courses', contentContext.courseData.course._id, userContext);
        if (watching != undefined && watching.position != undefined) {
            var lesson = watching.lessons.find((e) => e._id == data?._id);
            if (lesson != undefined) {
                progress = (lesson.position / lesson.duration) * 100;
            } else {
                progress = 0;
            }
        } else {
            progress = 0;
        }
    }
    if (type == 'Channels') {
        if (contentContext.epgTv[0] != undefined) {
            var epgAll = contentContext.epgTv?.find((e) => e.i == data?._id);

            if (epgAll != undefined) {
                var currentTime = moment().unix();
                var currentItem = epgAll.epg.find((e) => e.s <= currentTime && e.e >= currentTime);

                if (currentItem != undefined) {
                    var totalProgram = currentItem.e - currentItem.s;
                    var percentageProgram = ((currentTime - currentItem.s) / totalProgram) * 100;
                    data.program = currentItem;
                    progress = percentageProgram;
                    data.catchuptv = data?.interactivetv.pausetv_enabled;

                    parentalIsApplied = checkParentalLockShouldApply(userContext, contentContext, currentItem, data);
                } else {
                    progress = 0;
                }
            } else {
                progress = 0;
            }
        }
    }
    if (screenName == 'Cams') {
        var epgAll1 = contentContext.epgCam.find((e) => e.i == data?._id);
        if (epgAll1 != undefined) {
            var currentTime = moment().unix();
            var currentItem = epgAll.epg.find((e) => e.s <= currentTime && e.e > currentTime);
            if (currentItem != undefined) {
                var totalProgram = currentItem.e - currentItem.s;
                var percentageProgram = ((currentTime - currentItem.s) / totalProgram) * 100;
                data.program = currentItem;
                progress = percentageProgram;
            } else {
                progress = 0;
            }
        } else {
            progress = 0;
        }
    }

    if (screenName == 'Channel_Player') {
        if (contentContext.epgTv[0] != undefined) {
            var epgAll = contentContext.epgTv?.find((e) => e.i == data?._id);
            if (epgAll != undefined) {
                var currentTime = moment().unix();
                var currentItem = epgAll.epg.find((e) => e.s <= currentTime && e.e > currentTime);
                if (currentItem != undefined) {
                    var totalProgram = currentItem.e - currentItem.s;
                    var percentageProgram = ((currentTime - currentItem.s) / totalProgram) * 100;
                    data.program = currentItem;
                    progress = percentageProgram;
                    data.catchuptv = data?.interactivetv.pausetv_enabled;

                    parentalIsApplied = checkParentalLockShouldApply(userContext, contentContext, currentItem, data);
                }
            } else {
                progress = 0;
            }
        }
    }
    if (screenName == 'Channels') {
        if (contentContext.epgTv[0] != undefined) {
            var epgAll = contentContext.epgTv?.find((e) => e.i == data?._id);
            if (epgAll != undefined) {
                var currentTime = moment().unix();
                var currentItem = epgAll.epg.find((e) => e.s <= currentTime && e.e > currentTime);
                if (currentItem != undefined) {
                    var totalProgram = currentItem.e - currentItem.s;
                    var percentageProgram = ((currentTime - currentItem.s) / totalProgram) * 100;
                    data.program = currentItem;
                    progress = percentageProgram;
                    data.catchuptv = data?.interactivetv.pausetv_enabled;

                    parentalIsApplied = checkParentalLockShouldApply(userContext, contentContext, currentItem, data);
                }
            } else {
                progress = 0;
            }
        }
    }
    if (screenName == 'Radio') {
        if (contentContext.epgRadio[0] != undefined) {
            var epgAll = contentContext.epgRadio.find((e) => e.i == data?._id);
            if (epgAll != undefined) {
                var currentTime = moment().unix();
                var currentItem = epgAll.epg.find((e) => e.s <= currentTime && e.e > currentTime);
                if (currentItem != undefined) {
                    var totalProgram = currentItem.e - currentItem.s;
                    var percentageProgram = ((currentTime - currentItem.s) / totalProgram) * 100;
                    data.program = currentItem;
                    progress = percentageProgram;
                    data.catchuptv = data?.interactivetv.pausetv_enabled;
                }
            } else {
                progress = 0;
            }
        }
    }
    var recordingDone = false;
    var recordingPlanned = false;
    var recordingProgress = false;
    if (screenName == 'Recordings') {
        var currentTime = moment().unix();
        if (currentTime > data?.program?.e) {
            recordingDone = true;
        } else if (currentTime > data?.program?.s && currentTime < data?.program?.e) {
            recordingProgress = true;
        } else {
            recordingPlanned = true;
        }
    }

    const getCorrectImage = (data: any) => {
        if (screenName == 'Channels' || screenName == 'Cams' || type == 'Channels' || type == 'Cams') {
            if (screenName != 'Cams' && type != 'Cams' && data?.program != undefined && data?.program?.m != '' && data?.program.m.indexOf('https://') > -1) {
                return data?.program.m;
            } else {
                if (screenName == 'Channels' || type == 'Channels') {
                    return getLiveImage(data?.streams.channels.urls.standard_url);
                }
                if (screenName == 'Cams' || type == 'Cams') {
                    return getLiveImage(data?.streams.cams.urls.standard_url);
                }
            }
        } else if (screenName == 'Recordings') {
            if (data?.program?.m != '') {
                return data?.program.m;
            } else {
                return getTimedImage(data?.channel.streams.channels.urls.standard_url, data?.program?.s);
            }
        } else {
            if (data?.images?.widescreen == undefined) {
                return '';
            }
            if (data?.images?.widescreen?.indexOf('https://') > -1 || data?.images?.widescreen?.indexOf('http://') > -1) {
                return data?.images?.widescreen;
            } else {
                return appContext.cloudUrl + data?.images?.widescreen;
            }
        }
    };
    const getRibbonText = () => {
        var test = styling.ribbon.title.find((t) => t.language == userContext.selectedLanguage);
        if (test != undefined) {
            return test.title;
        } else {
            if (styling.content.title != undefined) {
                return styling.content.title[0].title;
            } else {
                return '';
            }
        }
    };
    const getExtraStyle = () => {
        if (styling.ribbon.align == 'Right') {
            return { right: -2 };
        } else {
            return { left: -2 };
        }
    };
    const getCorrectTranslation = (translations) => {
        return translations.find((trans) => trans.name == userContext.selectedLanguage)?.description;
    };
    const focusWidth = () => {
        return getPressableStyling(styling.components?.button._id, appContext).borderWidth * 2;
    };
    if (data == undefined) {
        return null;
    } else {
        return (
            <Pressable
                style={[
                    {
                        ...getPressableStyling(styling.components?.button._id, appContext),
                        marginBottom: getCorrectWidth(deviceContext, deviceContext.isPhone || deviceContext.isPwaVertical ? 5 : 4),
                        marginHorizontal: getCorrectWidth(deviceContext, 2.5),
                        width: width - getCorrectWidth(deviceContext, 5),
                    },
                ]}
                focusContext={focusContext}
                focusRepeatContext={focusRepeatContext}
                focusOptions={focusOptions}
                onFocus={() => onFocus(data)}
                onBlur={() => onBlur()}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                onPress={() => (screenName == 'Recordings' ? onPress(data) : onPress(data?._id))}
                onLongPress={() => onLongPress(data?._id)}
            >
                <View>
                    {styling.ribbon != undefined && styling.ribbon.show_ribbon && (
                        <View style={[getExtraStyle(), { position: 'absolute', zIndex: 9999 }]}>
                            <View
                                style={{
                                    padding: getCorrectWidth(deviceContext, 3),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: styling.ribbon.background_color,

                                    borderRadius: getCorrectHeight(deviceContext, styling.ribbon.border_radius),
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        color: styling.ribbon.title_color,
                                        fontWeight: styling.ribbon.title_bold ? 'bold' : 'normal',
                                    }}
                                >
                                    {getRibbonText()}
                                </Text>
                            </View>
                        </View>
                    )}
                    <View
                        style={{
                            margin: getCorrectHeight(deviceContext, appContext.application.theme.images.margin),
                            borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius),

                            backgroundColor: appContext.application.theme.images?.background,
                            alignItems: 'center',
                            height:
                                height -
                                getCorrectWidth(deviceContext, getPressableStyling(styling.components?.button._id, appContext).borderWidth * 2) -
                                getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2) -
                                getCorrectWidth(deviceContext, 5) -
                                focusWidth(),
                        }}
                    >
                        {!parentalIsApplied && (
                            <Image_
                                deviceContext={deviceContext}
                                source={{ uri: getCorrectImage(data) }}
                                resizeMethod={'resize'}
                                resizeMode={'cover'}
                                style={{
                                    borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius),

                                    height:
                                        height -
                                        getCorrectWidth(deviceContext, getPressableStyling(styling.components?.button._id, appContext).borderWidth * 2) -
                                        getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2) -
                                        getCorrectWidth(deviceContext, 5) -
                                        focusWidth(),
                                    width:
                                        width -
                                        getCorrectWidth(deviceContext, getPressableStyling(styling.components?.button._id, appContext).borderWidth * 2) -
                                        getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2) -
                                        getCorrectWidth(deviceContext, 5) -
                                        focusWidth(),
                                }}
                            />
                        )}

                        {screenName == 'Recordings' && (
                            <View style={{ position: 'absolute', top: 4, right: 0 }}>
                                {recordingDone && (
                                    <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                                        <Text
                                            style={{
                                                backgroundColor: 'crimson',
                                                color: appContext.application.theme.tags.color,
                                                borderRadius: appContext.application.theme.tags.radius / 2,
                                                fontSize: getFontSize(deviceContext, 'Small'),
                                                padding: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                                includeFontPadding: false,
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'recorded').toUpperCase()}
                                        </Text>
                                    </View>
                                )}
                                {recordingProgress && (
                                    <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                                        <Text
                                            style={{
                                                backgroundColor: 'crimson',
                                                color: appContext.application.theme.tags.color,
                                                borderRadius: appContext.application.theme.tags.radius / 2,
                                                fontSize: getFontSize(deviceContext, 'Small'),
                                                padding: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                                includeFontPadding: false,
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'recording').toUpperCase()}
                                        </Text>
                                    </View>
                                )}
                                {recordingPlanned && (
                                    <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                                        <Text
                                            style={{
                                                backgroundColor: 'crimson',
                                                color: appContext.application.theme.tags.color,
                                                borderRadius: appContext.application.theme.tags.radius / 2,
                                                fontSize: getFontSize(deviceContext, 'Small'),
                                                padding: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                                includeFontPadding: false,
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'planned').toUpperCase()}
                                        </Text>
                                    </View>
                                )}
                            </View>
                        )}
                        {(type == 'Channels' || screenName == 'Channels' || type == 'Cams' || screenName == 'Cams') && styling.texts.placement_text == 'Below' && (
                            <View
                                style={{
                                    position: 'absolute',
                                    top: 5,
                                    left: 5,
                                    width: '100%',
                                    flexDirection: 'row',
                                }}
                            >
                                <Image
                                    deviceContext={deviceContext}
                                    source={{ uri: appContext.cloudUrl + data?.images?.square }}
                                    resizeMethod={'resize'}
                                    resizeMode={'cover'}
                                    style={{
                                        borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius),
                                        height: getCorrectWidth(deviceContext, 30),
                                        width: getCorrectWidth(deviceContext, 30),
                                    }}
                                />
                            </View>
                        )}
                        {screenName == 'Recordings' && (
                            <View
                                style={{
                                    position: 'absolute',
                                    top: 5,
                                    left: 5,
                                    width: '100%',
                                    flexDirection: 'row',
                                }}
                            >
                                <Image
                                    isCache={true}
                                    deviceContext={deviceContext}
                                    source={{ uri: appContext.cloudUrl + data?.channel?.images?.square }}
                                    resizeMethod={'resize'}
                                    resizeMode={'cover'}
                                    style={{
                                        borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius),
                                        height: getCorrectWidth(deviceContext, 30),
                                        width: getCorrectWidth(deviceContext, 30),
                                    }}
                                />
                            </View>
                        )}
                        {(type == 'Channels' || screenName == 'Channels' || type == 'Cams' || screenName == 'Cams') && appContext.application.theme.icons.show_live && styling.texts.placement_text == 'Above' && (
                            <View
                                style={{
                                    position: 'absolute',
                                    top: getCorrectWidth(deviceContext, appContext.application.theme.images.margin),
                                    right: getCorrectWidth(deviceContext, appContext.application.theme.images.margin),
                                    flexDirection: 'row',
                                }}
                            >
                                <>{getLiveTag(lang.getTranslation(userContext, 'live').toUpperCase(), appContext, deviceContext)}</>
                                {data?.catchuptv && <>{getReplayTag(lang.getTranslation(userContext, 'replay').toUpperCase(), appContext, deviceContext)}</>}
                            </View>
                        )}
                        {(type == 'Channels' || screenName == 'Channels' || type == 'Cams' || screenName == 'Cams') && appContext.application.theme.icons.show_live && styling.texts.placement_text != 'Above' && (
                            <View
                                style={{
                                    position: 'absolute',
                                    bottom: getCorrectWidth(deviceContext, appContext.application.theme.images.margin) + 5,
                                    right: getCorrectWidth(deviceContext, appContext.application.theme.images.margin),
                                    flexDirection: 'row',
                                }}
                            >
                                <>{getLiveTag(lang.getTranslation(userContext, 'live').toUpperCase(), appContext, deviceContext)}</>
                                {data?.catchuptv && <>{getReplayTag(lang.getTranslation(userContext, 'replay').toUpperCase(), appContext, deviceContext)}</>}
                            </View>
                        )}

                        {(type == 'Channels' || screenName == 'Channels' || type == 'Cams' || screenName == 'Cams') && appContext.application.theme.icons.show_favorite && favorite && (
                            <View
                                style={{
                                    position: 'absolute',
                                    top: 5,
                                    right: 5,
                                    width: '100%',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <View
                                    style={{
                                        backgroundColor: '#333',
                                        borderRadius: 100,
                                        padding: getCorrectHeight(deviceContext, 3),
                                        marginLeft: getCorrectWidth(deviceContext, 3),
                                    }}
                                >
                                    <FontAwesomeIcon icon={faHeart} color={'#fff'} size={getFontSize(deviceContext, 'Normal')} />
                                </View>
                            </View>
                        )}
                        {type != 'Channels' && screenName != 'Channels' && type != 'Cams' && screenName != 'Cams' && appContext.application.theme.icons.show_favorite && favorite && (
                            <View
                                style={{
                                    position: 'absolute',
                                    top: 5,
                                    right: 5,
                                    width: '100%',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <View
                                    style={{
                                        backgroundColor: '#333',
                                        borderRadius: 100,
                                        padding: getCorrectHeight(deviceContext, 3),
                                    }}
                                >
                                    <FontAwesomeIcon icon={faHeart} color={'#fff'} size={getFontSize(deviceContext, 'Normal')} />
                                </View>
                            </View>
                        )}
                        {parentalIsApplied && (
                            <View
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: height - appContext.application.theme.images.margin * 2 - getPressableStyling(styling.components?.button._id, appContext).borderWidth * 2,
                                }}
                            >
                                <FontAwesomeIcon icon={faLock} color={'#999'} size={getFontSize(deviceContext, 'ExtraLarge')}></FontAwesomeIcon>
                            </View>
                        )}
                        {appContext.application.theme.icons.show_play && styling.content.styling.content.play_directly && (
                            <View
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    bottom: 0,
                                    right: 0,
                                    left: 0,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <View
                                    style={{
                                        borderRadius: 100,
                                        height: width / 7,
                                        width: width / 7,
                                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FontAwesomeIcon
                                        size={width / 15}
                                        style={{
                                            color: appContext.application.theme.icons.background,
                                        }}
                                        icon={faPlay}
                                    />
                                </View>
                            </View>
                        )}
                        {styling.texts.placement_text == 'Above' && screenName != 'Seasons' && screenName != 'Course' && (
                            <View
                                style={{
                                    position: 'absolute',
                                    bottom: appContext.application.theme.images.margin / 2,
                                    left: appContext.application.theme.images.margin,
                                    right: appContext.application.theme.images.margin * 2,
                                }}
                            >
                                {data?.number != undefined && (
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            marginHorizontal: getCorrectHeight(deviceContext, appContext.application.theme.images.margin + 10),
                                            // width: width - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 3),
                                            color: styling.texts.title_color,
                                            fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            marginLeft: getCorrectHeight(deviceContext, 5),
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {data?.number}. {data?.name}
                                    </Text>
                                )}

                                {data?.number == undefined && type != 'Series' && (
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            marginHorizontal: getCorrectHeight(deviceContext, appContext.application.theme.images.margin + 10),
                                            //width: width - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 3) - 20,
                                            color: styling.texts.title_color,
                                            fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            marginBottom: getCorrectHeight(deviceContext, 5),
                                            marginLeft: getCorrectHeight(deviceContext, 5),
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {data?.name}
                                    </Text>
                                )}
                                {data?.number == undefined && type == 'Series' && data?.images.logo == '' && (
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            marginHorizontal: getCorrectHeight(deviceContext, appContext.application.theme.images.margin + 10),
                                            //width: width - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 3) - 20,
                                            color: styling.texts.title_color,
                                            fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Large'),
                                            marginBottom: getCorrectHeight(deviceContext, 5),
                                            marginLeft: getCorrectHeight(deviceContext, 5),
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {data?.name}
                                    </Text>
                                )}
                                {data?.number == undefined && type == 'Series' && data?.images.logo != '' && (
                                    <View style={{ marginLeft: getCorrectWidth(deviceContext, 5), paddingBottom: getCorrectWidth(deviceContext, 7) }}>
                                        <RemoteImageHeightWidth uri={appContext.cloudUrl + data?.images.logo} desiredWidth={width * 0.45} desiredHeight={height * 0.3} deviceContext={deviceContext} />
                                    </View>
                                )}
                                {progress > -1 && (
                                    <View
                                        style={{
                                            marginRight: getCorrectWidth(deviceContext, appContext.application.theme.images.margin),
                                            marginVertical: getCorrectHeight(deviceContext, 2),
                                            backgroundColor: progress == 0 ? 'transparent' : appContext.application.theme.progresses.base,
                                            height: getCorrectHeight(deviceContext, 2),
                                            shadowColor: 'rgba(0, 0, 0, 0.3)',
                                            shadowOffset: { width: -1, height: 1 },
                                            shadowRadius: 2,
                                        }}
                                    >
                                        <View
                                            style={{
                                                backgroundColor: appContext.application.theme.progresses.progress,
                                                height: getCorrectHeight(deviceContext, 2),
                                                shadowColor: 'rgba(0, 0, 0, 0.3)',
                                                shadowOffset: { width: -1, height: 1 },
                                                shadowRadius: 2,

                                                width: progress + '%',
                                            }}
                                        ></View>
                                    </View>
                                )}
                                {data?.program != undefined && (
                                    <>
                                        {!parentalIsApplied && (
                                            <Text
                                                numberOfLines={1}
                                                style={{
                                                    marginHorizontal: getCorrectHeight(deviceContext, appContext.application.theme.images.margin + 10),
                                                    //  width: width - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 3) - 20,
                                                    color: styling.texts.subtext_color,
                                                    fontWeight: styling.texts.subtext_bold == true ? 'bold' : 'normal',
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    marginBottom: getCorrectHeight(deviceContext, 5),
                                                    marginLeft: getCorrectHeight(deviceContext, 5),
                                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                    textShadowOffset: { width: -1, height: 1 },
                                                    textShadowRadius: 2,
                                                }}
                                            >
                                                {data?.program.n}
                                            </Text>
                                        )}
                                        {parentalIsApplied && (
                                            <Text
                                                numberOfLines={1}
                                                style={{
                                                    marginHorizontal: getCorrectHeight(deviceContext, appContext.application.theme.images.margin + 10),
                                                    //  width: width - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 3),
                                                    color: styling.texts.subtext_color,
                                                    fontWeight: styling.texts.subtext_bold == true ? 'bold' : 'normal',
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    marginBottom: getCorrectHeight(deviceContext, 5),
                                                    marginLeft: getCorrectHeight(deviceContext, 5),
                                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                    textShadowOffset: { width: -1, height: 1 },
                                                    textShadowRadius: 2,
                                                }}
                                            >
                                                ***********
                                            </Text>
                                        )}
                                        {screenName == 'Recordings' && (
                                            <Text
                                                numberOfLines={1}
                                                style={{
                                                    marginHorizontal: getCorrectHeight(deviceContext, appContext.application.theme.images.margin + 10),
                                                    //  width: width - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 3) - 20,
                                                    color: styling.texts.subtext_color,
                                                    fontWeight: styling.texts.subtext_bold == true ? 'bold' : 'normal',
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Small'),
                                                    marginTop: getCorrectHeight(deviceContext, -5),
                                                    marginBottom: getCorrectHeight(deviceContext, 5),
                                                    marginLeft: getCorrectHeight(deviceContext, 5),
                                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                    textShadowOffset: { width: -1, height: 1 },
                                                    textShadowRadius: 2,
                                                }}
                                            >
                                                {moment.unix(data?.program?.s).format('ddd ' + deviceContext.clockSetting)} - {moment.unix(data?.program?.e).format('ddd ' + deviceContext.clockSetting)}
                                            </Text>
                                        )}
                                    </>
                                )}
                            </View>
                        )}
                    </View>

                    {(styling.texts.placement_text == 'Below' || screenName == 'Seasons' || screenName == 'Course') && (
                        <View
                            style={{
                                marginTop: getCorrectHeight(deviceContext,  type == 'Shorts' || type == 'Games' ? 0 :5),
                                marginHorizontal: appContext.application.theme.images.margin > 0 ? getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2) : getCorrectHeight(deviceContext, 10),
                                marginBottom: getCorrectHeight(deviceContext, 5),
                            }}
                        >
                            {data?.number != undefined && (
                                <View style={{ flexDirection: 'row' }}>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            color: styling.texts.title_color,
                                            fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Large'),
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {data?.number}. {data?.name}
                                    </Text>
                                </View>
                            )}
                            {data?.number == undefined && type == 'Shorts' && (
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                        height: getFontSize(deviceContext, type == 'Shorts' ? 'Normal' : 'Large') * 2,
                                    }}
                                >
                                    {data?.name}
                                </Text>
                            )}
                            {data?.number == undefined && type != 'Shorts' && (
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.title_color,
                                        fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, type == 'Shorts' || type == 'Games' ? 'Normal' : 'Large'),
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {data?.name}
                                </Text>
                            )}

                            {progress > -1 && (
                                <View
                                    style={{
                                        marginVertical: getCorrectHeight(deviceContext, 2),
                                        marginBottom: getCorrectHeight(deviceContext, 5),
                                        marginRight: getCorrectWidth(deviceContext, appContext.application.theme.images.margin),
                                        backgroundColor: progress == 0 ? 'transparent' : appContext.application.theme.progresses.base,
                                        height: getCorrectHeight(deviceContext, 2),
                                        shadowColor: 'rgba(0, 0, 0, 0.3)',
                                        shadowOffset: { width: -1, height: 1 },
                                        shadowRadius: 2,
                                    }}
                                >
                                    <View
                                        style={{
                                            backgroundColor: appContext.application.theme.progresses.progress,
                                            height: getCorrectHeight(deviceContext, 2),
                                            shadowColor: 'rgba(0, 0, 0, 0.3)',
                                            shadowOffset: { width: -1, height: 1 },
                                            shadowRadius: 2,

                                            width: progress + '%',
                                        }}
                                    ></View>
                                </View>
                            )}

                            {data?.translations != undefined && (
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.texts.title_color,

                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {getCorrectTranslation(data?.translations)}
                                </Text>
                            )}

                            {data?.duration != undefined && (
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.title_color,
                                        marginTop: getCorrectHeight(deviceContext, 5),
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {data?.duration > 0 && <>{moment().startOf('day').add('seconds', data?.duration).format('m') + 'm'}</>}
                                </Text>
                            )}
                            {/* {data?.translations == undefined && data?.program == undefined && (
                                <>
                                    <Text
                                        numberOfLines={2}
                                        style={{
                                            color: styling.texts.title_color,
                                            fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {' '}
                                    </Text>
                                    <Text
                                        numberOfLines={2}
                                        style={{
                                            color: styling.texts.title_color,
                                            fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {' '}
                                    </Text>
                                </>
                            )} */}
                            {data?.program != undefined && (
                                <>
                                    {!parentalIsApplied && (
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                color: styling.texts.subtext_color,
                                                fontWeight: styling.texts.subtext_bold == true ? 'bold' : 'normal',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                textShadowOffset: { width: -1, height: 1 },
                                                textShadowRadius: 2,
                                            }}
                                        >
                                            {data?.program.n}
                                        </Text>
                                    )}
                                    {parentalIsApplied && (
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                color: styling.texts.subtext_color,

                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                textShadowOffset: { width: -1, height: 1 },
                                                textShadowRadius: 2,
                                            }}
                                        >
                                            ***********
                                        </Text>
                                    )}

                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            color: styling.texts.subsubtext_color,

                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Small'),
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {moment.unix(data?.program?.s).format('ddd ' + deviceContext.clockSetting)} - {moment.unix(data?.program?.e).format('ddd ' + deviceContext.clockSetting)}
                                    </Text>
                                </>
                            )}
                        </View>
                    )}
                </View>
            </Pressable>
        );
    }
};
export default Widescreen;
