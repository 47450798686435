import * as React from 'react';
import { Easing, ViewStyle } from 'react-native';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import { Animated, View, FocusContext } from '@scriptx-com/xtv-toolkit';

const AnimatedView = Animated.createAnimatedComponent(View);

export interface Props {
    children: React.ReactChild | React.ReactChild[];
    focusContext?: FocusContext;
    style?: ViewStyle;
}

export const ScreenFadeIn = ({ children, focusContext, style }: Props) => {
    const opacity = React.useRef(new Animated.Value(0)).current;
    // const contextPlayer = React.useContext(PlayerContext);

    function startAnimation() {
        Animated.timing(opacity, {
            toValue: 1,
            duration: 500,
            easing: Easing.ease,
            useNativeDriver: false,
        }).start(() => {});
    }

    useEffectOnce(() => {
        startAnimation();
    });

    return (
        <AnimatedView
            focusContext={focusContext}
            onLoad={startAnimation}
            style={{
                opacity: opacity,
                zIndex: 1,
                ...style,
            }}
        >
            {children}
        </AnimatedView>
    );
};
