import * as React from 'react';
import { View, ActivityIndicator, withParentContextMapper, Screen } from '@scriptx-com/xtv-toolkit';
import { Details_Top as styling } from '../../../../models/elements/details/top/top';
import { getRealHeight, getRealWidth } from '../../../../styling/realDeviceSizes';
import { getCorrectHeight, getCorrectWidth } from '../../../../styling/correctSizes';
import { TextFade } from '../../../animations/textFade';
import { ContentContext } from '../../../../context/contentContext';
import { UserContext } from '../../../../context/userContext';
import Modal from '../../../components/modal/modal';
import { DeviceContext } from '../../../../context/deviceContext';
import { AppContext } from '../../../../context/appContext';
import Parental from '../../../components/parental/parental';
import Image from '../../../components/image/image';
import { getModalStyling } from '../../../components/helpers/helper';
import { ActorsRowText, BackgroundImage, ButtonRow, MetaRow, StatusTimeRow, DescriptionRow, ProgressRow, LogoRow, ActorsRowImages } from '../../details/helpers/helpers_landscape';
import { onPressFavorite, onPressRecording, onPressRecordingDelete, onPressRemind, onPressWatchlist, recordProgram, remindProgram } from '../../details/helpers/functions';
import { SettingsContext } from '../../../../context/settingsContext';
import moment from 'moment';
import FocusHelper from '../../../../focushelper/FocusHelper';

const TextFadeWithContext = withParentContextMapper(TextFade);

export interface Props {
    styling: styling;
    navigation: any;
    focusContext?: any;
    contentScreensAvailable: boolean;
}
import { setFocus } from '@scriptx-com/xtv-toolkit';
import { useContext, useEffect } from 'react';

export interface ModalProps {
    styling: any;
    program?: any;
    channel?: any;
    focusContext: any;
    navigation: any;
    closeModal: any;
    onPressPlay: any;
    onPressPurchase: any;
    onPressPlayRecording: any;
    onPressPlayCatchupTV: any;
    programs: any;
    programIndex: any;
}
const ProgramModal = ({ program, channel, focusContext, styling, navigation, closeModal, onPressPlay, onPressPurchase, onPressPlayRecording, onPressPlayCatchupTV, programs, programIndex }: ModalProps) => {
    const deviceContext = useContext(DeviceContext);
    const userContext = useContext(UserContext);
    const appContext = useContext(AppContext);
    const [data, setData] = React.useState([] as any);

    const [showRecording, setShowRecording] = React.useState(false);
    const [showRecordingSuccess, setShowRecordingSuccess] = React.useState(false);
    const [showRecordingError, setShowRecordingError] = React.useState(false);
    const [showRecordingWaiting, setShowRecordingWaiting] = React.useState(false);
    const [showParentalRecording, setShowParentalRecording] = React.useState(false);
    const [showRecordingRemoved, setShowRecordingRemoved] = React.useState(false);

    const [showReminder, setShowReminder] = React.useState(false);
    const [showReminderSuccess, setShowReminderSuccess] = React.useState(false);
    const [showReminderRemoved, setShowReminderRemoved] = React.useState(false);
    const [showParentalReminder, setShowParentalReminder] = React.useState(false);

    const contentContext = React.useContext(ContentContext);
    const settingsContext = React.useContext(SettingsContext);

    const [updateScreenFavorite, setUpdateScreenFavorite] = React.useState(0);
    const [updateScreenWatchlist, setUpdateScreenWatchlist] = React.useState(0);

    const getData = async () => {
        var path = '';
        const date = moment().add(contentContext.epgOffSetDay, 'day').format('DD') + '-' + Number(moment().add(0, 'day').month() + 1) + '-' + moment().add(0, 'day').year();
        var path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/channels/epg/details/' + channel._id + '_' + date + '_details.json';

        try {
            let response = await fetch(path);
            let data = await response.json();
            if (data != undefined) {
                var program_ = data.find((e) => e.i == program.i);
                data = {
                    program: program_,
                };
                setData(data);
            }
        } catch (error) {
            setData(null);
        }
    };

    React.useEffect(() => {
        getData();
    }, [navigation]);

    React.useEffect(() => {
        return () => {
            FocusHelper.cfEpisodeIndex = undefined;
            FocusHelper.cfSeasonIndex = undefined;
        };
    }, []);

    const onSubmitRecording = async () => {
        setShowRecording(false);
        setShowRecordingWaiting(true);
        var result = (await recordProgram(userContext, appContext, data.program, channel)) as any;
        if (result?.success == false) {
            setShowRecordingWaiting(false);
            setShowRecordingError(true);
        } else {
            setShowRecordingWaiting(false);
            setShowRecordingSuccess(true);
        }
    };
    const onSubmitReminder = () => {
        setShowReminder(false);
        remindProgram(userContext, appContext, data.program, channel);
        setShowReminderSuccess(true);
    };

    useEffect(() => {
        setFocus('modal-group');
        return () => {
            setFocus('delete-profile-btn');
        };
    }, []);

    const onPressPlayLocal = () => {
        contentContext.setChannelData({
            ...contentContext.channelData,
            channelList: contentContext.channelData.channelListList,
            channelIndex: contentContext.channelData.channelListList.findIndex((c) => c._id == channel._Id),
            channelCategoryIndex: contentContext.channelData.channelListCategoryIndex,
            programIndex: programIndex,
            programListIndex: programIndex,
        });
        onPressPlay(channel, program, programs);
    };
    const onPressPlayCatchupTVLocal = (startType) => {
        contentContext.setChannelData({
            ...contentContext.channelData,
            channelList: contentContext.channelData.channelListList,
            channelIndex: contentContext.channelData.channelListList.findIndex((c) => c._id == channel._Id),
            channelCategoryIndex: contentContext.channelData.channelListCategoryIndex,
            programIndex: programIndex,
            programListIndex: programIndex,
        });
        onPressPlayCatchupTV(channel, program, programs, startType)
    };
    const onPressPlayRecordingLocal = () => {
        contentContext.setChannelData({
            ...contentContext.channelData,
            channelList: contentContext.channelData.channelListList,
            channelIndex: contentContext.channelData.channelListList.findIndex((c) => c._id == channel._Id),
            channelCategoryIndex: contentContext.channelData.channelListCategoryIndex,
            programIndex: programIndex,
            program:program,
            programListIndex: programIndex,
        });
        onPressPlayRecording(channel, programs)
    }

    const onPressPurchaseLocal = () => {
   
    };

    return (
        //focusContext={focusContext}
        <Screen focusOptions={{ focusKey: 'modal-group', group: 'modal-group', screenOrder: 1000 }} style={[{ flex: 1, overflow: 'visible' }]}>
            <View style={{ flexGrow: 1, minHeight: getRealHeight(deviceContext), overflow: 'hidden' }} focusContext={focusContext}>
                {showParentalRecording && (
                    <Parental
                        focusContext={focusContext}
                        styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                        setParentalApproved={() => {
                            setShowRecording(true);
                            setShowParentalRecording(false);
                        }}
                        setShowParental={setShowParentalRecording}
                        type={'access_content'}
                    ></Parental>
                )}
                {showParentalReminder && (
                    <Parental
                        focusContext={focusContext}
                        styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                        setParentalApproved={() => {
                            setShowReminder(true);
                            setShowParentalReminder(false);
                        }}
                        setShowParental={setShowParentalRecording}
                        type={'access_content'}
                    ></Parental>
                )}
                {showRecordingSuccess && (
                    <Modal
                        program={data.program}
                        focusContext={focusContext}
                        styling={getModalStyling(appContext.application.theme.modals.recording, appContext)}
                        type={'recording_success'}
                        submitChoice={null}
                        setShowModal={() => setShowRecordingSuccess(false)}
                    ></Modal>
                )}
                {showRecordingError && (
                    <Modal
                        program={data.program}
                        focusContext={focusContext}
                        styling={getModalStyling(appContext.application.theme.modals.recording, appContext)}
                        type={'recording_error'}
                        submitChoice={null}
                        setShowModal={() => setShowRecordingError(false)}
                    ></Modal>
                )}
                {showRecordingWaiting && <Modal program={data.program} focusContext={focusContext} styling={getModalStyling(appContext.application.theme.modals.recording, appContext)} type={'recording_waiting'} submitChoice={null}></Modal>}
                {showRecording && (
                    <Modal
                        program={data.program}
                        focusContext={focusContext}
                        styling={getModalStyling(appContext.application.theme.modals.recording, appContext)}
                        type={'recording'}
                        submitChoice={onSubmitRecording}
                        setShowModal={() => setShowRecording(false)}
                    ></Modal>
                )}
                {showReminder && (
                    <Modal
                        program={data.program}
                        focusContext={focusContext}
                        styling={getModalStyling(appContext.application.theme.modals.reminder, appContext)}
                        type={'reminder'}
                        setShowModal={setShowReminder}
                        submitChoice={onSubmitReminder}
                    ></Modal>
                )}
                {showReminderSuccess && (
                    <Modal
                        program={data.program}
                        focusContext={focusContext}
                        styling={getModalStyling(appContext.application.theme.modals.reminder, appContext)}
                        type={'reminder_success'}
                        setShowModal={setShowReminderSuccess}
                        submitChoice={() => setShowReminderSuccess(false)}
                    ></Modal>
                )}
                {showReminderRemoved && (
                    <Modal
                        program={data.program}
                        focusContext={focusContext}
                        styling={getModalStyling(appContext.application.theme.modals.reminder, appContext)}
                        type={'reminder_removed'}
                        setShowModal={setShowReminderRemoved}
                        submitChoice={() => setShowReminderRemoved(false)}
                    ></Modal>
                )}
                {showRecordingRemoved && (
                    <Modal
                        program={data.program}
                        focusContext={focusContext}
                        styling={getModalStyling(appContext.application.theme.modals.reminder, appContext)}
                        type={'recording_removed'}
                        setShowModal={setShowRecordingRemoved}
                        submitChoice={() => setShowRecordingRemoved(false)}
                    ></Modal>
                )}

                <View focusContext={focusContext} style={{ flexGrow: 1 }}>
                    {data != null && data?.length == 0 && (
                        <View
                            style={{
                                flexDirection: 'row',
                                width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth),
                                height: getRealHeight(deviceContext) - getCorrectHeight(deviceContext, deviceContext.isPhone || deviceContext.isPwaVertical ? 0 : 100),
                            }}
                        >
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                <ActivityIndicator size={'large'} color={'white'}></ActivityIndicator>
                            </View>
                        </View>
                    )}
                    {data != null && data.length != 0 && (
                        <View style={{ flexGrow: 1 }}>
                            <View
                                style={{
                                    position: 'absolute',
                                    width: getRealWidth(deviceContext),
                                    height: '100%',
                                    zIndex: 1,
                                }}
                            >
                                <Image isLocal={true} deviceContext={deviceContext} source={require('../../../../../assets/images/vod_gradient.png')} resizeMode={'cover'} style={{ height: '100%', width: getRealWidth(deviceContext) }}></Image>
                            </View>
                            <View style={{ zIndex: 0 }}>
                                <BackgroundImage channel={channel} program={program} item={data} type={'Program'} deviceContext={deviceContext} appContext={appContext} contentContext={contentContext}></BackgroundImage>
                            </View>
                            <View
                                style={{
                                    zIndex: 3,
                                    flex: 1,
                                    width: getRealWidth(deviceContext),
                                    paddingTop: 75,
                                    paddingLeft: 75,
                                    marginBottom: getCorrectHeight(deviceContext, deviceContext.isAndroidTV || deviceContext.isFireTV ? 0 : 40),
                                }}
                            >
                                <TextFadeWithContext>
                                    <LogoRow channel={channel} item={data} type={'Program'} deviceContext={deviceContext} appContext={appContext} contentContext={contentContext} styling={styling}></LogoRow>
                                    <StatusTimeRow item={data} type={'Program'} deviceContext={deviceContext} appContext={appContext} userContext={userContext} contentContext={contentContext} styling={styling}></StatusTimeRow>
                                    <MetaRow item={data} type={'Program'} deviceContext={deviceContext} appContext={appContext} userContext={userContext} contentContext={contentContext} styling={styling}></MetaRow>
                                    <DescriptionRow item={data} type={'Program'} deviceContext={deviceContext} userContext={userContext} contentContext={contentContext} styling={styling}></DescriptionRow>
                                    <ActorsRowText type={'Program'} item={data} styling={styling} appContext={appContext} deviceContext={deviceContext} userContext={userContext}></ActorsRowText>
                                    <ProgressRow item={data} type={'Program'} deviceContext={deviceContext} appContext={appContext} userContext={userContext} contentContext={contentContext} styling={styling}></ProgressRow>
                                </TextFadeWithContext>
                                <View>
                                    <ButtonRow
                                        focusContext={focusContext}
                                        item={data}
                                        type={'Program'}
                                        deviceContext={deviceContext}
                                        appContext={appContext}
                                        userContext={userContext}
                                        contentContext={contentContext}
                                        styling={styling}
                                        onPressRecordingDelete={() => onPressRecordingDelete(userContext, appContext, data.program, navigation)}
                                        onPressBack={() => closeModal()}
                                        onPressPlay={() => onPressPlayLocal()}
                                        onPressPlayRecording={(item) => onPressPlayRecordingLocal}
                                        onPressPlaySeason={() => null}
                                        onPressPlayLesson={() => null}
                                        onPressPlayCatchupTV={(startType) => onPressPlayCatchupTVLocal(startType)}
                                        onPressTrailer={() => null}
                                        onPressReplay={() => null}
                                        onPressPurchase={() => onPressPurchaseLocal()}
                                        onPressFavorite={(isFavorite, item) => onPressFavorite(isFavorite, contentContext, userContext, 'Program', appContext, deviceContext, setUpdateScreenFavorite, item)}
                                        onPressWatchlist={(isWatchlist, item, seasonIndex, episodeIndex) => onPressWatchlist(isWatchlist, userContext, 'Program', appContext, contentContext, setUpdateScreenWatchlist, item, seasonIndex, episodeIndex)}
                                        onPressContinue={() => null}
                                        onPressRecording={(isRecorded) => onPressRecording(isRecorded, userContext, appContext, data.program, setShowRecording, setShowRecordingRemoved)}
                                        onPressRemind={(isReminded) => onPressRemind(isReminded, data.program, appContext, userContext, setShowReminder, setShowReminderRemoved)}
                                    ></ButtonRow>
                                </View>
                                <View>
                                    <ActorsRowImages focusContext={focusContext} type={'Program'} item={data} styling={styling} appContext={appContext} deviceContext={deviceContext} userContext={userContext}></ActorsRowImages>
                                </View>
                            </View>
                        </View>
                    )}
                </View>
            </View>
        </Screen>
    );
};

export default ProgramModal;
