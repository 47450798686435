import { Pressable, PressableFocusOptions } from '@scriptx-com/xtv-toolkit';
import * as React from 'react';

export interface PressableProps {
    animatorOptions: any;
    children: React.ReactChild;
    hasPreferredFocus?: boolean;
    focusOptions?: PressableFocusOptions;
    focusRepeatContext?: any;
    focusContext?: any;
    onPress?: any;
    props?: any;
    onMouseOver?: any;
    onMouseOut?: any;
    style?: any;
    ref?;
}
const Pressable_ = ({
    animatorOptions,
    hasPreferredFocus,
    focusOptions = {},
    focusRepeatContext,
    focusContext,
    ref,
    children,
    onPress,
    onMouseOver,
    onMouseOut,
    style,
    ...props
}: PressableProps) => {
    if (animatorOptions == null) {
        animatorOptions = {
            blur: {},
            focus: { backgroundColor: '#ffffff' },
            type: 'background',
        };
    }
    return (
        <Pressable
            {...props}
            style={style}
            focusRepeatContext={focusRepeatContext}
            focusContext={focusContext}
            onPress={onPress}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            focusOptions={{
                hasPreferredFocus: hasPreferredFocus != undefined ? hasPreferredFocus : false,
                animator: animatorOptions,
                ...focusOptions,
            }}
        >
            {children}
        </Pressable>
    );
};

export default Pressable_;
