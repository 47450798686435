import { ActivityIndicator, View } from '@scriptx-com/xtv-toolkit';
import { DarkTheme, DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack';
import { isPlatformAndroidtv, isPlatformFiretv, isPlatformTvos } from '@rnv/renative';
import * as React from 'react';
import { createNativeStackNavigator } from '../../libs/@react-navigation';
import { AppContext } from '../context/appContext';
import { DeviceContext } from '../context/deviceContext';
import Bottom from '../navigators/bottom';
import Drawer from '../navigators/drawer';
import Left from '../navigators/left';
import Top from '../navigators/top';

const Base = isPlatformTvos || isPlatformAndroidtv || isPlatformFiretv ? createNativeStackNavigator() : createStackNavigator();

export const NavigationBase = () => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);

    var navigatorType = 'Bottom Menu';
    if (deviceContext.isWebTV && !deviceContext.isPwaVertical) {
        navigatorType = appContext.application.navigators?.browser.type;
    } else if (deviceContext.isTablet) {
        navigatorType = appContext.application.navigators?.tablet.type;
    } else if (deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) {
        navigatorType = appContext.application.navigators?.mobile.type;
    } else if (deviceContext.isTelevision) {
        navigatorType = appContext.application.navigators?.television.type;
    }

    const navTheme = {
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: 'transparent',
        },
        flex:1
      };
      
    return (
        <View style={{ flex: 1 }}>
       
            <NavigationContainer theme={navTheme}>

                <View style={{ flex: 1 }}>
                
                    {navigatorType == 'Bottom Menu' && (
                        <Base.Navigator
                            screenOptions={{
                                gestureDirection: 'horizontal',
                                gestureEnabled: true,
                                //gestureResponseDistance: { horizontal: 300 }, // optional
                                cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                                headerShown: false,
                                cardStyle: {  backgroundColor: 'transparent' },
                            }}
                        >
                          
                            <Base.Screen
                                options={{
                                    headerShown: false,
                                    cardStyle: { backgroundColor: 'transparent' },
                                }}
                                name="Menu_Bottom"
                                component={Bottom}
                            />
                        </Base.Navigator>
                    )}
                    {navigatorType == 'Top Menu' && (
                        <Base.Navigator
                            screenOptions={{
                                gestureDirection: 'horizontal',
                                gestureEnabled: true,
                                //gestureResponseDistance: { horizontal: 300 }, // optional
                                cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                                headerShown: false,
                                cardStyle: { backgroundColor: 'transparent' },
                            }}
                        >
                            <Base.Screen
                                options={{
                                    headerShown: false,
                                    cardStyle: { backgroundColor: 'transparent' },
                                }}
                                name="Menu_Top"
                                component={Top}
                            />
                        </Base.Navigator>
                    )}
                    {navigatorType == 'Drawer' && (
                        <Base.Navigator screenOptions={{ headerShown: false }}>
                            <Base.Screen
                                options={{
                                    gestureDirection: 'horizontal',
                                    gestureEnabled: true,
                                    //gestureResponseDistance: { horizontal: 300 }, // optional
                                    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                                    headerShown: false,
                                    // cardStyle: { backgroundColor: 'transparent' }
                                }}
                                name="Drawer"
                                component={Drawer}
                            />
                        </Base.Navigator>
                    )}
                    {navigatorType == 'Left Menu' && (
                        <Base.Navigator screenOptions={{ headerShown: false, cardStyle: { backgroundColor: 'transparent' } }}>
                            <Base.Screen
                                options={{
                                    gestureDirection: 'horizontal',
                                    gestureEnabled: true,
                                    //gestureResponseDistance: { horizontal: 300 }, // optional
                                    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                                    headerShown: false,
                                    cardStyle: { backgroundColor: 'transparent' },
                                }}
                                name="Left Menu"
                                component={Left}
                            />
                        </Base.Navigator>
                    )}
                </View>
            </NavigationContainer>
        </View>
    );
};
export default NavigationBase;
