import * as React from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import { ScreenContainer, Screen } from 'react-native-screens'; //eslint-disable-line
import { isPlatformTizen, isPlatformWebos } from '@rnv/renative';
import { getCorrectWidth } from '../../styling/correctSizes';
import { UserContext } from '../../context/userContext';
import { DeviceContext } from '../../context/deviceContext';
import { SettingsContext } from '../../context/settingsContext';
import { AppContext } from '../../context/appContext';

const { width, height } = Dimensions.get('window');

export default function SideNavigation({ state, navigation, descriptors, drawerContent, width, ...rest }) {
    const renderContent = () => (
        <ScreenContainer style={{ flex: 1 }}>
            {state.routes.map((route, i) => {
                const isFocused = state.index === i;
                const style = isPlatformTizen || isPlatformWebos ? { opacity: isFocused ? 1 : 0, width, height } : { opacity: isFocused ? 1 : 0 };

                const active = isPlatformTizen || isPlatformWebos ? 1 : isFocused ? 1 : 0;

                return (
                    <Screen key={route.key} style={[StyleSheet.absoluteFill, style]} active={active} stackPresentation="modal">
                        {descriptors[route.key].render()}
                    </Screen>
                );
            })}
        </ScreenContainer>
    );

    const renderDrawerView = () =>
        drawerContent({
            state,
            navigation,
            descriptors,
            ...rest,
        });
    //TODO: test if pointer event dont give problem
    const settingsContext = React.useContext(SettingsContext);
    const contextUser = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);

    var navigatorType = 'Bottom Menu';
    if (deviceContext.isWebTV && !deviceContext.isPwaVertical) {
        navigatorType = appContext.application.navigators?.browser.type;
    } else if (deviceContext.isTablet) {
        navigatorType = appContext.application.navigators?.tablet.type;
    } else if (deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) {
        navigatorType = appContext.application.navigators?.mobile.type;
    } else if (deviceContext.isTelevision) {
        navigatorType = appContext.application.navigators?.television.type;
    }
    return (
        <View style={{ flex: 1 }}>
            {contextUser.profiled && (
                <View
                    style={{
                        width: settingsContext.showMainMenu ? getCorrectWidth(deviceContext, navigatorType == 'Drawer' ? 50 : 100) : 0,
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 2,
                        opacity: 1,
                        position: 'absolute',
                        ...((isPlatformTizen || isPlatformWebos) && {
                            height,
                        }),
                    }}
                >
                    {renderDrawerView()}
                </View>
            )}
            <View style={{ flex: 1 }}>{renderContent()}</View>
        </View>
    );
}
