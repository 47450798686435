import moment from 'moment';

export const getTimeline = () => {
    var timeline = [] as any;
    for (let index = 0; index < 24; index++) {
        timeline.push({
            i: '1',
            n: '',
            d: '',
            s: moment().startOf('day').add('hour', index).unix(),
            e: moment().startOf('day').add('hour', index).unix() + 3600,
            m: '',
            b: false,
            p: '',
            t: '',
            mt: null,
        });
    }
    return timeline;
};
