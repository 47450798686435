import * as React from 'react';
import { Animated, Easing, Image } from 'react-native';
import { DeviceContext } from '../../context/deviceContext';

export interface Props {
    style: any;
    source: any;
    blurRadius?: any;
}

export const ImageFadeZoom = ({ style, source, blurRadius }: Props) => {
    const opacity = new Animated.Value(0);
    const animatedValue = new Animated.Value(0);
    const deviceContext = React.useContext(DeviceContext);

    const onLoad = () => {
        Animated.timing(opacity, {
            toValue: 1,
            duration: 2000,
            easing: Easing.ease,
            useNativeDriver: true,
        }).start(() => {
            Animated.timing(animatedValue, {
                toValue: 1,
                duration: 9000,
                easing: Easing.ease,
                useNativeDriver: true,
            }).start();
        });
    };
    if (!deviceContext.isTizen && !deviceContext.isWebos && !deviceContext.isKaiOs) {
        return (
            <Animated.Image
                onLoad={onLoad}
                source={source}
                blurRadius={blurRadius == undefined ? 0 : blurRadius}
                style={[
                    {
                        opacity: opacity,
                        transform: [
                            {
                                scaleX: animatedValue.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [1, 1.12],
                                }),
                            },
                            {
                                scaleY: animatedValue.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [1, 1.12],
                                }),
                            },
                        ],
                    },
                    style,
                ]}
            />
        );
    } else {
        return <Image source={source} style={style} />;
    }
};
