import * as React from 'react';
import { Animated, Easing, Image } from 'react-native';
import { DeviceContext } from '../../context/deviceContext';

export interface Props {
    next: any;
    style: any;
    source: any;
    blurRadius?: any;
}

export const ImageFadeZoomNext = ({ style, source, next, blurRadius }: Props) => {
    const animatedOpacity = new Animated.Value(0);
    const animatedScale = new Animated.Value(0);
    const deviceContext = React.useContext(DeviceContext);

    const onLoad = () => {
        Animated.sequence([
            Animated.timing(animatedOpacity, {
                toValue: 1,
                duration: 2000,
                easing: Easing.ease,
                useNativeDriver: true,
            }),
            Animated.timing(animatedScale, {
                toValue: 1,
                duration: 8000,
                easing: Easing.ease,
                useNativeDriver: true,
            }),
        ]).start(next);
    };

    if (!deviceContext.isTizen && !deviceContext.isWebos && !deviceContext.isKaiOs) {
        return (
            <Animated.Image
                onLoad={onLoad}
                source={source}
                blurRadius={blurRadius != undefined ? blurRadius : 0}
                style={[
                    {
                        opacity: animatedOpacity,
                        transform: [
                            {
                                scaleX: animatedScale.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [1, 1.11],
                                }),
                            },
                            {
                                scaleY: animatedScale.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [1, 1.11],
                                }),
                            },
                        ],
                    },
                    style,
                ]}
            />
        );
    } else {
        return <Image source={source} style={style} />;
    }
};
