import { getRealHeight, getRealWidth } from './realDeviceSizes';

export const getCorrectSizeUpDown = (deviceContext) => {
    var baseWidth = 1280;
    var baseHeight = 720;

    var currentWidth = getRealWidth(deviceContext);
    var currentHeight = getRealHeight(deviceContext);

    if (baseHeight == currentHeight && baseWidth == currentWidth) {
        return 1;
    } else if (currentHeight > currentWidth) {
        //portrait
        return currentHeight / baseHeight;
    } else if (currentWidth > currentHeight) {
        //landscape
        return currentWidth / baseWidth;
    }
};
