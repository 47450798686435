import { getGlobals } from '../../globals';

class Languages {
    getTranslation(contextUser, text) {
        if (text == null || text == undefined) {
            return '';
        }
        var language = global.Translations.find((t) => t.language == contextUser.selectedLanguage);
        if (language != undefined) {
            text = text.toLowerCase().toString().replace(' ', '_');
            if (language.translations[text] != undefined) {
                return language.translations[text];
            } else {
                return text + '!';
            }
        } else {
            var language = global.Translations.find((t) => t.language == getGlobals().DefaultLanguage);
            if (language != undefined) {
                text = text.toLowerCase().toString().replace(' ', '_');
                if (language.translations[text] != undefined) {
                    return language.translations[text];
                } else {
                    return text + '!';
                }
            } else {
                return 'Missing language: ' + contextUser.selectedLanguage;
            }
        }
    }
    setLanguage(language) {
        switch (language) {
            case 'বাংলা':
                userContext.selectedLanguage = 'Bengali';
                break;
            case 'Ελληνικά':
                userContext.selectedLanguage = 'Greek';
                break;
            case 'አማርኛ':
                userContext.selectedLanguage = 'Amharic';
                break;
            case 'English':
                userContext.selectedLanguage = 'English';
                break;
            case 'Español':
                userContext.selectedLanguage = 'Spanish';
                break;
            case 'Deutsch':
                userContext.selectedLanguage = 'German';
                break;
            case 'Монгол':
                userContext.selectedLanguage = 'Mongolian';
                break;
            case 'Русский':
                userContext.selectedLanguage = 'Russian';
                break;
            case 'Polski':
                userContext.selectedLanguage = 'Polish';
                break;
            case 'Français':
                userContext.selectedLanguage = 'French';
                break;
            case 'Português':
                userContext.selectedLanguage = 'Portuguese';
                break;
            case 'हिन्दुस्तानी':
                userContext.selectedLanguage = 'Urdu';
                break;
            case 'العربية':
                userContext.selectedLanguage = 'Arabic';
                break;
            case 'फिजी बात':
                userContext.selectedLanguage = 'Hindi';
                break;
            case 'Nederlands':
                userContext.selectedLanguage = 'Dutch';
                break;
            case 'မြန်မာစာ or မြန်မာစကား':
                userContext.selectedLanguage = 'Burmese';
                break;
            case 'کوردی':
                userContext.selectedLanguage = 'Kurdish';
                break;
            case 'Papiamentu':
                userContext.selectedLanguage = 'Papiamentu';
                break;
            case 'ไทย':
                userContext.selectedLanguage = 'Thai';
                break;
        }
    }
    getLanguage(language) {
        var language = global.Translations.find((t) => t.language == language);

        if (language != undefined) {
            return language.translations['_language'];
        }
    }
    getLanguageCode(language) {
        switch (language) {
            case 'Bengali':
                return 'bd';
                break;
            case 'Greek':
                return 'gr';
                break;
            case 'Amharic':
                return 'et';
                break;
            case 'English':
                return 'en';
                break;
            case 'Spanish':
                return 'es';
                break;
            case 'German':
                return 'de';
                break;
            case 'Russian':
                return 'ru';
                break;
            case 'Poland':
                return 'po';
                break;
            case 'Mongolian':
                return 'mo';
                break;
            case 'French':
                return 'fr';
                break;
            case 'Portuguese':
                return 'pr';
                break;
            case 'Thai':
                return 'th';
                break;
            case 'Urdu':
                return 'ur';
                break;
            case 'Arabic':
                return 'ar';
                break;
            case 'Hindi':
                return 'hi';
                break;
            case 'Nederlands':
                return 'nl';
                break;
            case 'Burmese':
                return 'bm';
                break;
            case 'Kurdish':
                return 'kr';
                break;
            case 'Papiamentu':
                return 'pm';
                break;
            default:
                return 'en';
                break;
        }
    }
}
const languages = new Languages();
export default languages;
