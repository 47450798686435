import * as React from 'react';
import { useWindowDimensions, View } from 'react-native';
import { initialWindowMetrics, useSafeAreaInsets } from '../../libs/react-native-safe-area-context';
import { SettingsContext } from '../context/settingsContext';
import { isPlatformAndroid, isPlatformIos } from '@rnv/renative';
import { calculateGridHeight } from '../builder/elements/grids/helpers/functions';
import { DeviceContext } from '../context/deviceContext';
import { Dimensions } from 'react-native';

export function useGridHeightCalculator() {
    const deviceContext = React.useContext(DeviceContext);
    const bottomInset = React.useRef<number>(initialWindowMetrics?.insets?.bottom ? initialWindowMetrics?.insets?.bottom : 0).current;
    const targetRef = React.useRef<View>();
    const { tabBarHeight } = React.useContext(SettingsContext);
    const { height: windowHeight } = useWindowDimensions();
    const [gridHeight, setGridHeight] = React.useState(windowHeight);

    const calculate = async () => {
        if (isPlatformAndroid) {
            setGridHeight(Dimensions.get('window').height);
            return;
        }
        var extra = 0;
        if (isPlatformAndroid && (deviceContext.isPhone || deviceContext.isTablet)) {
            extra = await calculateGridHeight(targetRef, tabBarHeight);
        } else if (isPlatformIos && (deviceContext.isPhone || deviceContext.isTablet)) {
            extra = await calculateGridHeight(targetRef, tabBarHeight + bottomInset);
        } else {
            extra = await calculateGridHeight(targetRef, bottomInset);
        }

        setGridHeight(windowHeight - extra);
    };

    const onLayout = () => {
        setTimeout(() => {
            calculate();
        }, 1);
    };

    React.useEffect(() => {
        calculate();
    }, []);

    return { gridHeight, targetRef, onLayout, paddingBottom: isPlatformAndroid ? 150 : 0 };
}
