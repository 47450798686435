import { ActivityIndicator, View } from '@scriptx-com/xtv-toolkit';
import moment from 'moment';
import * as React from 'react';
import Pressable from '../../../../application/builder/components/pressable/pressable';
import lang from '../../../../application/languages/languages';
import { AppContext } from '../../../context/appContext';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { watchingStatus } from '../../../data/account';
import { Hero_Single as styling } from '../../../models/elements/heros/single';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import { checkParentalLockShouldApply, getLiveImage } from '../../elements/players/helpers/functions';
import { getChannelsFromSubscription, getDetailsChannel, getDetailsCourse, getDetailsMovie, getDetailsSeries, getMoviesFromSubscription, playChannel, playMovie } from '../rails/helpers/helper';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPlay, faSquareInfo } from '@fortawesome/pro-light-svg-icons';
import Image from '../../components/image/image';
import Text from '../../components/text';
import { getPressableStyling, getFocusStyling, getPressableTextStyling } from '../../components/helpers/helper';
import { getLiveTag, getProgramTime }from '../players/helpers/helpers';
import { ImageFadeZoomNext } from '../../animations/imageFadeZoomNext';
import TheoPlayer from '../../components/players/theoPlayer';
import { PlayerContext } from '../../../context/playerContext';
import { getDrm, getToken } from '../players/helpers/security';
import { getStreamParams } from '../players/helpers/stream';
import { RemoteImageHeightWidth } from '../../components/remoteimage/remoteimage';
import { SettingsContext } from '../../../context/settingsContext';

export interface Props {
    styling: styling;
    navigation: any;
    focusContext?: any;
}

export const Hero_Player = ({ styling, navigation, focusContext }: Props) => {
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const playerContext = React.useContext(PlayerContext);
    const settingsContext = React.useContext(SettingsContext);
    
    const [hide, setHide] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [data, setData] = React.useState([] as any);
    const [index, setIndex] = React.useState(0);
    const [progress, setProgress] = React.useState(0);
    const [program, setProgram] = React.useState([] as any);
    const [showPlayer, setShowPlayer] = React.useState(false);

    var isParentalEnabled = false;
    var marginLeft = global.menuWidth == 0 ? 40 : 0;

    if (appContext.application.screens[0].content[0] != undefined) {
        var element = appContext.application.screens[0].content[0];
        if (element.rectangle_rails != undefined) {
            marginLeft += element.rectangle_rails.placement.margin_left;
        } else if (element.genre_rails != undefined) {
            marginLeft += element.genre_rails.placement.margin_left;
        } else if (element.square_rails != undefined) {
            marginLeft += element.square_rails.placement.margin_left;
        } else if (element.top10_rails != undefined) {
            marginLeft += element.top10_rails.placement.margin_left;
        } else if (element.tile_rails != undefined) {
            marginLeft += element.tile_rails.placement.margin_left;
        } else if (element.player_rails != undefined) {
            marginLeft += element.widescreen_rails.placement.margin_left;
        } else if (element.widescreen_rails != undefined) {
            marginLeft += element.widescreen_rails.placement.margin_left;
        } else if (element.round_rails != undefined) {
            marginLeft += element.round_rails.placement.margin_left;
        } else if (element.morphing_rails != undefined) {
            marginLeft += element.morphing_rails.placement.margin_left;
        }
    }
    const getData = async (heroContent?: any) => {
        var path = '';
        if (heroContent.length == 0) {
            if (styling.content.list?.type == 'Movies') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/movies/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Series') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/series/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Courses') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/courses/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Channels') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/channels/lists/' + styling.content.list.list + '_list.json';
            }

            try {
                let response = await fetch(path);
                let data = await response.json();

                if (data != undefined && data.length > 0) {
                    if (styling.content.list?.type == 'Channels') {
                        var dataOut = getChannelsFromSubscription(contentContext, data[0].items);
                        var random = Math.floor(Math.random() * dataOut.length);
                        var channel = dataOut[random];
                        if (contentContext.epgTv[0] != undefined) {
                            var epgChannel = contentContext.epgTv?.find((e) => e.i == channel._id);
                            if (epgChannel != undefined) {
                                var currentTime = moment().unix();
                                var program = epgChannel.epg.find((e) => e.s <= currentTime && e.e >= currentTime);
                                if (program != undefined) {
                                    setProgram(program);
                                    var totalProgram = program?.e - program?.s;
                                    var progress = ((currentTime - program?.s) / totalProgram) * 100;
                                    isParentalEnabled = checkParentalLockShouldApply(userContext, contentContext, program, channel);
                                    setProgress(progress);
                                    setIndex(random);
                                    setData(dataOut);
                                    setShow(true);
                                }
                            }
                        }
                    } else {
                        var random = Math.floor(Math.random() * data[0].items.length);
                        if (styling.content.list?.type == 'Movies') {
                            try {
                                var dataOut = getMoviesFromSubscription(contentContext, data[0].items);
                                var watching = watchingStatus( 'Movies', data[random]._id,userContext);
                                if (watching != undefined && watching.position != undefined) {
                                    setProgress(Math.round((watching.position / watching.duration) * 100));
                                }
                                setIndex(random);
                                setData(dataOut);
                                setShow(true);
                            } catch (e) {
                                setProgress(0);
                            }
                        }
                    }
                }
            } catch (error) {
                setHide(true);
            }
        } else {
            if (heroContent.type == 'Channels') {
                var channel = heroContent.data[index];
                if (contentContext.epgTv[0] != undefined) {
                    var epgChannel = contentContext.epgTv?.find((e) => e.i == channel._id);
                    if (epgChannel != undefined) {
                        var currentTime = moment().unix();
                        var program = epgChannel.epg.find((e) => e.s <= currentTime && e.e >= currentTime);
                        if (program != undefined) {
                            setProgram(program);
                            var totalProgram = program?.e - program?.s;
                            var progress = ((currentTime - program?.s) / totalProgram) * 100;
                            isParentalEnabled = checkParentalLockShouldApply(userContext, contentContext, program, channel);
                            setProgress(progress);
                            setData(heroContent.data);
                            setShow(true);
                        }
                    }
                }
            } else {
                if (heroContent.type == 'Movies') {
                    try {
                        var watching = watchingStatus('Movies', heroContent.data._id,userContext);
                        if (watching != undefined && watching.position != undefined) {
                            setProgress(Math.round((watching.position / watching.duration) * 100));
                        }
                    } catch (e) {
                        setProgress(0);
                    }
                }
                setData(heroContent.data);
                setShow(true);
            }
        }
    };

    React.useEffect(() => {
        if (contentContext.hero == undefined) {
            getData();
        }
    }, [navigation]);

    React.useEffect(() => {
        if (contentContext.hero != undefined) {
            setShowPlayer(false);
            setIndex(0);
            setShowPlayer(false);
            getData(contentContext.hero);
        }
    }, [contentContext.hero]);

    const playItem = (item: any) => {
        if (styling.content.list?.type == 'Movies') {
            playMovie(contentContext, item, navigation,settingsContext);
        }
        if (styling.content.list?.type == 'Channels') {
            playChannel(navigation, appContext, userContext, item, navigation,settingsContext);
        }
    };

    const detailItem = (item: any) => {
        if (styling.content.list?.type == 'Channels') {
            getDetailsChannel(contentContext, item, navigation);
        }
        if (styling.content.list?.type == 'Movies') {
            getDetailsMovie(contentContext, item, navigation);
        }
        if (styling.content.list?.type == 'Series') {
            getDetailsSeries(navigation, item, navigation);
        }
        if (styling.content.list?.type == 'Courses') {
            getDetailsCourse(navigation, item, navigation);
        }
    };

    const generateUniqueIdx = (prevIdx) => {
        let rand = Math.floor(Math.random() * data.length);
        while (rand === prevIdx) {
            rand = Math.floor(Math.random() * data.length);
        }
        return rand;
    };

    const nextSlide = () => {
        if (contentContext.hero.length == 0) {
            const rand = generateUniqueIdx(index);

            var progress = 0;
            if (styling.content.list?.type == 'Movies') {
                try {
                    var watching = watchingStatus( 'Movies', data[rand]._id,userContext);
                    if (watching != undefined && watching.position != undefined) {
                        setProgress(Math.round((watching.position / watching.duration) * 100));
                    } else {
                        setProgress(0);
                    }
                } catch (e) {
                    setProgress(0);
                }
            }
            if (styling.content.list?.type == 'Channels') {
                var channel = data[rand];
                if (contentContext.epgTv[0] != undefined) {
                    var epgChannel = contentContext.epgTv?.find((e) => e.i == channel._id);
                    if (epgChannel != undefined) {
                        var currentTime = moment().unix();
                        var program = epgChannel.epg.find((e) => e.s <= currentTime && e.e >= currentTime);
                        if (program != undefined) {
                            var totalProgram = program?.e - program?.s;
                            var progress = ((currentTime - program?.s) / totalProgram) * 100;
                            isParentalEnabled = checkParentalLockShouldApply(userContext, contentContext, program, channel);
                            setProgress(progress);
                            setProgram(program);
                        }
                    }
                }
            }
            setIndex(rand);
        } else {
            setShowPlayer(true);
            startPlayer();
        }
    };

    const startPlayer = async () => {
        var stream = '' as any;
        var progress = 0 as any;
        var type = contentContext.hero.type;
        switch (type) {
            case 'Channels':
                if (!data[index]?.parental?.enabled) {
                    if (deviceContext.isSmartTV || deviceContext.isTizen || deviceContext.isWebos || deviceContext.isWebTV || deviceContext.isPwa) {
                        stream = await getToken(data[index], playerContext.tk, data[index]?.streams.channels?.urls.tizen_webos, deviceContext, userContext);
                    } else if (deviceContext.isIos || deviceContext.isAppleTV) {
                        stream = await getToken(data[index], playerContext.tk, data[index]?.streams.channels?.urls.ios_tvos, deviceContext, userContext);
                    } else {
                        stream = await getToken(data[index], playerContext.tk, data[index]?.streams.channels?.urls.standard_url, deviceContext, userContext);
                    }
                    await getDrm(data[index], data[index]?._id, 'channels', appContext.application, deviceContext, playerContext);
                    stream = getStreamParams(stream, data[index]?.name, data[index]?._id, 'Channels', deviceContext, userContext, appContext, data[index]?.streams.params);
                    playerContext.setStream({ url: stream });
                    playerContext.setPlayer({
                        ...playerContext.player,
                        paused: false,
                        isLive: true,
                        type: 'Channels',
                        error: '',
                    });
                    playerContext.setPaused(false);
                }
                break;

            case 'Movies':
                if (!data[index]?.parental?.enabled) {
                    stream = data[index]?.streams?.movies?.url ?? '';
                    var watching = watchingStatus( 'Movies', data[index]?._id,userContext);
                    var drm = await getDrm(data[index], data[index]?._id, 'movies', appContext.application, deviceContext, playerContext);
                    if (drm != null) {
                        playerContext.setDrm(drm);
                    }
                    stream = getStreamParams(stream, data[index]?.name, data[index]?._id, 'Movies', deviceContext, userContext, appContext, data[index]?.streams.params);
                    playerContext.setStream({
                        url: stream,
                        progress: watching != undefined && watching.progress != 0 ? watching.position : 0, //TODO:test this
                    });
                    playerContext.setPlayer({
                        ...playerContext.player,
                        paused: false,
                        isLive: false,
                        type: 'Movies',
                        error: '',
                    });
                    playerContext.setPaused(false);
                }
                break;
            case 'Series':
                if (!data[index]?.parental?.enabled) {
                    stream = contentContext.seriesData.episode?.streams?.series?.url;
                    var test = userContext.profile.watchings.series.find((w) => w._id == data[index]?._id);
                    if (test != undefined) {
                        var episode = test.find((e) => e._id == contentContext.seriesData.episode._id);
                        if (episode != undefined) {
                            progress = episode.position;
                        }
                    }
                    var drm = await getDrm(data[index], data[index]?._id, 'cams', appContext.application, deviceContext, playerContext);
                    if (drm != null) {
                        playerContext.setDrm(drm);
                    }
                    stream = getStreamParams(stream, contentContext.seriesData.episode.name, contentContext.seriesData.episode._id, 'Series', deviceContext, userContext, appContext, contentContext.seriesData.episode.streams.params);
                    playerContext.setStream({
                        url: stream,
                        progress: progress != undefined && progress != 0 ? progress.continue.position : 0,
                    });
                    playerContext.setPlayer({
                        ...playerContext.player,
                        paused: false,
                        isLive: false,
                        type: 'Series',
                        error: '',
                    });
                    playerContext.setPaused(false);
                }
                break;
            case 'Courses':
                if (!data[index]?.parental?.enabled) {
                    stream = contentContext.courseData.lesson?.streams?.courses?.url;
                    var test = userContext.profile.watchings.courses.find((w) => w._id == data[index]?._id);
                    if (test != undefined) {
                        var lesson = test.find((e) => e._id == contentContext.courseData.lesson._id);
                        if (lesson != undefined) {
                            progress = lesson.position;
                        }
                    }
                    var drm = await getDrm(data[index], data[index]?._id, 'courses', appContext.application, deviceContext, playerContext);
                    if (drm != null) {
                        playerContext.setDrm(drm);
                    }
                    stream = getStreamParams(stream, data[index]?.name, data[index]?._id, 'Courses', deviceContext, userContext, appContext, data[index]?.streams.param);
                    playerContext.setStream({
                        url: stream,
                        progress: progress != undefined && progress != 0 ? progress.continue.position : 0,
                    });
                    playerContext.setPlayer({
                        ...playerContext.player,
                        paused: false,
                        isLive: false,
                        type: 'Courses',
                        error: '',
                    });
                    playerContext.setPaused(false);
                }
                break;
        }
    };

    const getCorrectImage = () => {
        if (program?.m != '') {
            return program.m;
        } else {
            return getLiveImage(data[index]?.streams.channels.urls.standard_url);
        }
    };
    const checkIfLogoExists = () => {
        if (styling.content.list?.type == 'Movies' || styling.content.list?.type == 'Series') {
            if (data[index]?.images?.logo != undefined && data[index]?.images?.logo != '') {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };
    return (
        <View style={{ flex: 1 }} focusContext={focusContext}>
            <View style={{ flex: 1, backgroundColor: '#000' }}>
                {!show && (
                    <View>
                        <View
                            style={{
                                flexDirection: 'row',
                                backgroundColor: '#000',
                                width: getRealWidth(deviceContext),
                                height: getRealHeight(deviceContext) - getCorrectHeight(deviceContext, deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 0 : 100),
                            }}
                        >
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                <ActivityIndicator size={'large'} color={'white'}></ActivityIndicator>
                            </View>
                        </View>
                    </View>
                )}
                {show && (
                    <View
                        style={{
                            position: 'absolute',
                            width: getRealWidth(deviceContext),
                            height: getRealWidth(deviceContext),
                            zIndex: 1,
                        }}
                    >
                        <Image
                            isLocal={true}
                            deviceContext={deviceContext}
                            source={require('../../../../assets/images/gradrient_vertical_hero_player.png')}
                            resizeMethod={'scale'}
                            resizeMode={'stretch'}
                            style={{ height: getCorrectHeight(deviceContext, 300), width: getRealWidth(deviceContext) }}
                        ></Image>
                    </View>
                )}
                {show && (
                    <View
                        style={{
                            position: 'absolute',
                            width: getRealWidth(deviceContext),
                            height: getRealWidth(deviceContext),
                            zIndex: 1,
                        }}
                    >
                        <Image
                            isLocal={true}
                            deviceContext={deviceContext}
                            source={require('../../../../assets/images/gradrient_vertical_hero_player.png')}
                            resizeMethod={'scale'}
                            resizeMode={'stretch'}
                            style={{ height: getCorrectHeight(deviceContext, 300), width: getRealWidth(deviceContext) }}
                        ></Image>
                    </View>
                )}

                {show && (
                    <View
                        style={{
                            flex: 1,
                            backgroundColor: '#000',
                        }}
                    >
                        {showPlayer && (
                            <View style={[{ flex: 1, marginLeft: getRealWidth(deviceContext) / 2 }]}>
                                <TheoPlayer
                                    adsEnabled={false}
                                    castEnabled={false}
                                    style={{
                                        objectFit: 'cover',
                                        height: getRealHeight(deviceContext) / 2,
                                        width: getRealWidth(deviceContext) / 2,
                                    }}
                                />
                            </View>
                        )}
                        <View
                            style={{
                                width: getRealWidth(deviceContext),
                                display: 'flex',
                                justifyContent: 'center',
                                overflow: 'hidden',
                            }}
                        >
                            {styling.content.list?.type == 'Channels' && !showPlayer && (
                                <ImageFadeZoomNext
                                    deviceContext={deviceContext}
                                    resizeMethod={'scale'}
                                    resizeMode={'contain'}
                                    // key={index}
                                    style={{
                                        marginLeft: getRealWidth(deviceContext) / 2.5,
                                        width: getRealWidth(deviceContext) / 1.5,
                                        height: getRealHeight(deviceContext) / 1.5,
                                    }}
                                    next={() => nextSlide()}
                                    source={{ uri: getCorrectImage() }}
                                />
                            )}
                            {styling.content.list?.type != 'Channels' && !showPlayer && (
                                <ImageFadeZoomNext
                                    deviceContext={deviceContext}
                                    resizeMethod={'scale'}
                                    resizeMode={'contain'}
                                    // key={index}
                                    style={{
                                        width: getRealWidth(deviceContext) / 1.5,
                                        height: getRealHeight(deviceContext) / 1.5,
                                    }}
                                    next={() => nextSlide()}
                                    source={{ uri: appContext.cloudUrl + data[index]?.images?.background }}
                                />
                            )}
                        </View>
                    </View>
                )}

                {show && (
                    <View
                        style={{
                            zIndex: 2,
                            position: 'absolute',
                            top: getCorrectHeight(deviceContext, styling.content.list?.type == 'Channels' ? 50 : 40),
                            height: 50,
                            left: getCorrectWidth(deviceContext, marginLeft) + getCorrectWidth(deviceContext, global.menuWidth),
                            width: getRealWidth(deviceContext) - (getCorrectWidth(deviceContext, deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 20 : 30) + getCorrectWidth(deviceContext, global.menuWidth / 2)),
                        }}
                    >
                        <View
                            // key={index}
                            change={index}
                            style={{ width: getRealWidth(deviceContext) * 0.8 }}
                        >
                            {styling.content.list?.type != 'Channels' && !checkIfLogoExists() && (
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        fontWeight: styling.texts.title_bold ? 'bold' : null,
                                        color: styling.texts.title_color,
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {data[index]?.name}
                                </Text>
                            )}
                            {checkIfLogoExists() && <RemoteImageHeightWidth uri={appContext.cloudUrl + data[index]?.images.logo} desiredWidth={getRealWidth(deviceContext) * 0.25} desiredHeight={100} deviceContext={deviceContext} />}
                            {styling.content.list?.type == 'Channels' && (
                                <>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Large'),
                                            fontWeight: styling.texts.title_bold ? 'bold' : null,
                                            color: styling.texts.title_color,
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {data[index]?.number}. {data[index]?.name}
                                    </Text>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                            fontWeight: styling.texts.title_bold ? 'bold' : null,
                                            color: styling.texts.title_color,
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {program.n}
                                    </Text>

                                    {progress > 0 && (
                                        <View
                                            style={{
                                                bottom: getCorrectHeight(deviceContext, 5),
                                                marginTop: getCorrectHeight(deviceContext, 10),
                                            }}
                                        >
                                            <View
                                                style={{
                                                    marginVertical: getCorrectHeight(deviceContext, 2),
                                                    height: getCorrectHeight(deviceContext, 2),

                                                    width: getRealWidth(deviceContext) / 2.5,
                                                    backgroundColor: appContext.application.theme.progresses.base,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        backgroundColor: appContext.application.theme.progresses.progress,
                                                        height: getCorrectHeight(deviceContext, 2),

                                                        width: progress + '%',
                                                    }}
                                                ></View>
                                            </View>
                                        </View>
                                    )}
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        {getLiveTag(lang.getTranslation(userContext, 'live').toUpperCase(), appContext, deviceContext)}
                                        {getProgramTime(moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting) + '-' + moment.unix(program?.e).format(deviceContext.clockSetting), appContext, deviceContext)}
                                    </View>
                                    {!isParentalEnabled && (
                                        <Text
                                            numberOfLines={2}
                                            style={{
                                                marginTop: getCorrectHeight(deviceContext, 5),
                                                width: getRealWidth(deviceContext) * 0.4,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.texts.title_bold ? 'bold' : null,
                                                color: styling.texts.title_color,
                                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                textShadowOffset: { width: -1, height: 1 },
                                                textShadowRadius: 2,
                                            }}
                                        >
                                            {program.d}
                                        </Text>
                                    )}
                                </>
                            )}

                            {progress > 0 && styling.content.list?.type == 'Movies' && (
                                <View
                                    style={{
                                        width: getCorrectWidth(deviceContext, 140),
                                    }}
                                >
                                    <View
                                        style={{
                                            marginVertical: getCorrectHeight(deviceContext, 2),
                                            height: getCorrectHeight(deviceContext, 2),

                                            width: getRealWidth(deviceContext) / 2.5,
                                            backgroundColor: appContext.application.theme.progresses.base,
                                        }}
                                    >
                                        <View
                                            style={{
                                                backgroundColor: appContext.application.theme.progresses.progress,
                                                height: getCorrectHeight(deviceContext, 2),

                                                width: progress + '%',
                                            }}
                                        ></View>
                                    </View>
                                </View>
                            )}

                            {/* {getTags(data[index]?.tags, styling, appContext, deviceContext, userContext)} */}

                            {(styling.content.list?.type == 'Movies' || styling.content.list?.type == 'Courses') && data[0].translations != undefined && (
                                <View style={{ width: getRealWidth(deviceContext) * 0.4 }}>
                                    {data[0].translations.find((trans) => trans.name == userContext.selectedLanguage) != undefined && (
                                        <Text
                                            numberOfLines={2}
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.texts.subtext_bold ? 'bold' : null,
                                                color: styling.texts.subtext_color,
                                                marginTop: getCorrectHeight(deviceContext, 5),
                                                marginBottom: getCorrectHeight(deviceContext, 10),
                                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                textShadowOffset: { width: -1, height: 1 },
                                                textShadowRadius: 2,
                                            }}
                                        >
                                            {data[0].translations.find((trans) => trans.name == userContext.selectedLanguage)?.description}
                                        </Text>
                                    )}
                                    {data[0].translations.find((trans) => trans.name == userContext.selectedLanguage) == undefined && data[0].translations.length > 0 && (
                                        <Text
                                            numberOfLines={3}
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.texts.subtext_bold ? 'bold' : null,
                                                color: styling.texts.subtext_color,
                                                marginTop: getCorrectHeight(deviceContext, 5),
                                                marginBottom: getCorrectHeight(deviceContext, 10),
                                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                textShadowOffset: { width: -1, height: 1 },
                                                textShadowRadius: 2,
                                            }}
                                        >
                                            {data[0].translations[0]?.description}
                                        </Text>
                                    )}
                                </View>
                            )}
                            <View
                                style={{
                                    flexDirection: 'row',
                                    marginTop: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 5 : 0,
                                }}
                            >
                                {styling.content.list?.type == 'Movies' && (
                                    <Pressable
                                        style={{
                                            marginVertical: getCorrectHeight(deviceContext, 5),
                                            marginRight: getCorrectHeight(deviceContext, 5),
                                            width: getCorrectWidth(deviceContext, 100),
                                            ...getPressableStyling(styling.components?.button?._id, appContext),
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => playItem(data[index])}
                                    >
                                        <View
                                            style={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                height: getCorrectHeight(deviceContext, 30),
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faPlay} color={'#fff'} size={getFontSize(deviceContext, 'Normal') * 1.5} style={{ marginRight: 3 }}></FontAwesomeIcon>
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'play_movie')}
                                            </Text>
                                        </View>
                                    </Pressable>
                                )}
                                {styling.content.list?.type != 'Channels' && (
                                    <Pressable
                                        style={{
                                            marginVertical: getCorrectHeight(deviceContext, 5),
                                            marginRight: getCorrectHeight(deviceContext, 5),
                                            width: getCorrectWidth(deviceContext, 100),
                                            ...getPressableStyling(styling.components?.button?._id, appContext),
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => detailItem(data[index])}
                                    >
                                        <View
                                            style={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                height: getCorrectHeight(deviceContext, 30),
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faSquareInfo} color={'#fff'} size={getFontSize(deviceContext, 'Normal') * 1.5} style={{ marginRight: 3 }}></FontAwesomeIcon>

                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'information')}
                                            </Text>
                                        </View>
                                    </Pressable>
                                )}
                            </View>
                            {styling.content.list?.type == 'Channels' && (
                                <View style={{ flexDirection: 'row' }}>
                                    <Pressable
                                        style={{
                                            marginVertical: getCorrectHeight(deviceContext, 5),
                                            marginRight: getCorrectHeight(deviceContext, 5),
                                            width: getCorrectWidth(deviceContext, 100),
                                            ...getPressableStyling(styling.components?.button?._id, appContext),
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => detailItem(data[index])}
                                    >
                                        <View
                                            style={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                height: getCorrectHeight(deviceContext, 30),
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faPlay} color={'#fff'} size={getFontSize(deviceContext, 'Normal') * 1.5} style={{ marginRight: 10 }}></FontAwesomeIcon>

                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'watch_now')}
                                            </Text>
                                        </View>
                                    </Pressable>
                                    <Pressable
                                        style={{
                                            marginVertical: getCorrectHeight(deviceContext, 5),
                                            marginRight: getCorrectHeight(deviceContext, 5),
                                            width: getCorrectWidth(deviceContext, 100),
                                            ...getPressableStyling(styling.components?.button?._id, appContext),
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => playItem(data[index])}
                                    >
                                        <View
                                            style={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                height: getCorrectHeight(deviceContext, 30),
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faSquareInfo} color={'#fff'} size={getFontSize(deviceContext, 'Normal') * 1.5} style={{ marginRight: 10 }}></FontAwesomeIcon>

                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'information')}
                                            </Text>
                                        </View>
                                    </Pressable>
                                </View>
                            )}
                        </View>
                    </View>
                )}
            </View>
        </View>
    );
};
export default Hero_Player;
