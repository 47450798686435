import { Tags } from './tags';

export enum contentType {
    CHANNEL = 'Channel',
    MOVIE = 'Movie',
    RADIO = 'Radio',
    CAM = 'Cam',
    EPISODE = 'Episode',
    POD = 'Pod',
    SONG = 'Song',
    LESSON = 'Lesson',
    CATCHUPTV = 'CachtupTV',
    RECORDINGS = 'Recordings',
}

export enum mainType {
    CHANNEL = 'Channels',
    MOVIE = 'Movies',
    RADIO = 'Radio',
    CAM = 'Cams',
    SERIES = 'Series',
    COURSE = 'Course',
    PODCAST = 'Podcast',
    MUSIC = 'Music',
    CATCHUPTV = 'CatchupTV',
    RECORDINGS = 'Recordings',
    SHORTS = 'Shorts',
}

export class Content {
    contentType: contentType;
    mainType: mainType;
    id: string;
    image: string;
    name: string;
    provider: string;
    subname: string;
    tags?: Tags[];
}
