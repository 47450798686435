import * as React from 'react';
import { Hero } from '../../models/application/hero';
import Details_AudioStandard from '../elements/details/audiostandard';
import { Details_Top } from '../elements/details/top';
import { Hero_Player } from '../elements/heros/player';
import { Hero_Single } from '../elements/heros/single';
import { Hero_Slider } from '../elements/heros/slider';

const Screen = ({ elements, focusContext, navigation, contentScreensAvailable }): React.ReactElement => {
    const getElement = (element: Hero, index: any) => {
        if (element.singles != undefined) {
            return <Hero_Single navigation={navigation} key={index} styling={element.singles} focusContext={focusContext}></Hero_Single>;
        }
        if (element.sliders != undefined) {
            return <Hero_Slider navigation={navigation} key={index} styling={element.sliders} focusContext={focusContext}></Hero_Slider>;
        }
        if (element.players != undefined) {
            return <Hero_Player navigation={navigation} key={index} styling={element.players} focusContext={focusContext}></Hero_Player>;
        }

        if (element.details_top != undefined) {
            return <Details_Top key={index} navigation={navigation} styling={element.details_top} focusContext={focusContext} contentScreensAvailable={contentScreensAvailable}></Details_Top>;
        }

        if (element.details_audiostandard != undefined) {
            return <Details_AudioStandard key={index} navigation={navigation} styling={element.details_audiostandard} focusContext={focusContext} contentScreensAvailable={contentScreensAvailable}></Details_AudioStandard>;
        }
    };

    return elements.map((element: any, index: any) => {
        return getElement(element, index);
    });
};
export default Screen;
