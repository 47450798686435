import * as React from 'react';
import { Animated, Easing, Image } from 'react-native';

export interface Props {
    style: any;
    source: any;
}

export const ImageFadeBlurZoom = ({ style, source }: Props) => {
    const opacity = new Animated.Value(0);
    const animatedValue = new Animated.Value(0);

    const onLoad = () => {
        Animated.timing(opacity, {
            toValue: 1,
            duration: 2000,
            easing: Easing.ease,
            useNativeDriver: true,
        }).start(() => {
            Animated.timing(animatedValue, {
                toValue: 1,
                duration: 9000,
                easing: Easing.ease,
                useNativeDriver: true,
            }).start();
        });
    };

    return (
        <Animated.Image
            blurRadius={90}
            onLoad={onLoad}
            source={source}
            style={[
                {
                    opacity: opacity,
                    transform: [
                        {
                            scaleX: animatedValue.interpolate({
                                inputRange: [0, 1],
                                outputRange: [1, 1.12],
                            }),
                        },
                        {
                            scaleY: animatedValue.interpolate({
                                inputRange: [0, 1],
                                outputRange: [1, 1.12],
                            }),
                        },
                    ],
                },
                style,
            ]}
        />
    );
};
