export const checkIfKnownMenuItem = (menu) => {
    switch (menu) {
        case 'Channels':
            return true;
            break;
        case 'Channels':
            return true;
            break;
        case 'Radio':
            return true;
            break;
        case 'Movies':
            return true;
            break;
        case 'Series':
            return true;
            break;
        case 'Courses':
            return true;
            break;
        case 'Music':
            return true;
            break;
        case 'Podcasts':
            return true;
            break;
        case 'Recordings':
            return true;
            break;
        case 'TV Guide':
            return true;
            break;
        case 'Downloads':
            return true;
            break;
        case 'Favorites':
            return true;
            break;
        case 'Home':
            return true;
            break;
        case 'Cams':
            return true;
            break;
        case 'Search':
            return true;
            break;
        case 'Games':
            return true;
            break;
        case 'Watchlist':
            return true;
            break;
        case 'Rentals':
            return true;
            break;
        case 'Purchases':
            return true;
            break;
            case 'Education':
                return true;
                break;
        default:
            return false;
            break;
    }
};
export const checkIfMenuHasWebview = (appContext, menu) => {
    var screen = appContext.application.screens.find((s) => s.type == menu);
    var hasWebview = false;
    screen?.content?.forEach((element) => {
        if (element.webview) {
            hasWebview = true;
        }
    });
    return hasWebview;
};
