import { faUser } from '@fortawesome/pro-light-svg-icons';
import { useFocusEffect } from '@react-navigation/native';
import { ActivityIndicator, View } from '@scriptx-com/xtv-toolkit';
import moment from 'moment';
import * as React from 'react';
import { getFocusStyling, getInputStyling, getKeyboardStyling, getModalStyling, getPressableStyling, getPressableTextStyling } from '../../../application/builder/components/helpers/helper';
import Input from '../../../application/builder/components/input/input';
import Keyboard from '../../../application/builder/components/keyboard/keyboard';
import Modal from '../../../application/builder/components/modal/modal';
import Pressable from '../../../application/builder/components/pressable/pressable';
import Text from '../../../application/builder/components/text';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { KeyboardContext } from '../../../application/context/keyboardContext';
import { UserContext } from '../../../application/context/userContext';
import lang from '../../../application/languages/languages';
import { getCorrectHeight } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import { getGlobals } from '../../../globals';
import { sendActionReport } from '../../../reporting/apis/action';
import { sendPageReport } from '../../../reporting/apis/page';
import { actionType, pageType } from '../../../reporting/models/apps';
import ScreenWrapper from '../../wrapper';

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const [showForgot, setShowForgot] = React.useState(false);
    const { keyboardVisible, openKeyboard, value, setKeyboardVisible } = React.useContext(KeyboardContext);
    const [show, setShow] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [username, setUsername] = React.useState(userContext.userId);
    const [type, setType] = React.useState('');
    const [error, setError] = React.useState('');

    React.useEffect(() => {
        sendActionReport(appContext, deviceContext, userContext, actionType.FORGOT_LOGIN);
        setShow(true);
    }, [navigation, route]);

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.FORGOT, duration);
            };
        }, [])
    );

    const sendForgotPasswordMessage = () => {
        setIsLoading(true);
        fetch(getGlobals().AppApiUrl + '/forgot?instance=' + appContext.application.client + '&cms=' + appContext.application.deployment + '&crm=' + appContext.application.branding.service, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: username,
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.error == '') {
                    setShowForgot(true);
                } else {
                    setError(lang.getTranslation(userContext, response.error));
                }
                setIsLoading(false);
            })
            .catch((e) => {
                setError(e);
                setIsLoading(false);
            });
    };

    const goBack = () => {
        navigation && navigation.navigate('Login');
    };
    const closeError = () => {
        setError('');
    };
    const closeSend = () => {
        setShowForgot(false);
    };

    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ focusKey: 'screen' }}>
            {keyboardVisible && type == 'userid' && (
                <Keyboard
                    onCloseKeyboard={() => setKeyboardVisible(false)}
                    focusContext={focusContext}
                    width={getRealWidth(deviceContext) * 0.8}
                    isSecure={false}
                    placeholder={''}
                    styling={getKeyboardStyling(appContext.application.authentication.login.components?.keyboard._id, appContext)}
                    onChangText={setUsername}
                    value={username}
                    focusOptions={{ nextFocusUp: 'submit-button', nextFocusDown: 'submit-button' }}
                ></Keyboard>
            )}
            {showForgot && <Modal focusContext={focusContext} styling={getModalStyling(appContext.application.theme.modals.subscription, appContext)} type={'forgot'} timerTime={3} submitChoice={closeSend} setShowModal={closeSend}></Modal>}
            {error != '' && <Modal error={error} focusContext={focusContext} styling={getModalStyling(appContext.application.theme.modals.exit, appContext)} type={'error'} setShowModal={closeError} submitChoice={closeError}></Modal>}
            {show && (
                <View style={{ flex: 1 }}>
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, appContext.application.authentication?.login?.header.title_size),
                                fontWeight: appContext.application.authentication?.login?.header.title_bold ? 'bold' : 'normal',
                                color: appContext.application.authentication?.login?.header.title_color,
                            }}
                        >
                            {lang.getTranslation(userContext, 'login_password')}
                        </Text>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, appContext.application.authentication?.login?.header.sub_size),
                                fontWeight: appContext.application.authentication?.login?.header.sub_bold ? 'bold' : 'normal',
                                color: appContext.application.authentication?.login?.header.sub_color,
                                marginHorizontal: 30,
                                textAlign: 'center',
                            }}
                        >
                            {lang.getTranslation(userContext, 'login_password_why')}
                        </Text>
                    </View>
                    {!isLoading && (
                        <View style={{ flex: 4, flexDirection: 'row' }}>
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                <Input
                                    value={username}
                                    onChangText={(value) => setUsername(value)}
                                    icon={faUser}
                                    width={getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3)}
                                    isSecure={false}
                                    placeholder={lang.getTranslation(userContext, 'userid')}
                                    animation={getFocusStyling('Inputs', appContext)}
                                    styling={getInputStyling(appContext.application.authentication.login.components?.input?._id, appContext)}
                                    focusOptions={{ focusKey: 'userid' }}
                                    onFocus={() => {
                                        if (keyboardVisible) {
                                            setKeyboardVisible(false);
                                        }
                                    }}
                                    showKeyboard={() => {
                                        setKeyboardVisible(true);
                                        setType('userid');
                                    }}
                                ></Input>
                                <View style={{ marginTop: 10, ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext) }}>
                                    <Pressable
                                        style={{
                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                            height: getCorrectHeight(deviceContext, 30),
                                            ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => sendForgotPasswordMessage()}
                                    >
                                        <View>
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'submit')}
                                            </Text>
                                        </View>
                                    </Pressable>
                                </View>
                                <View style={{ marginTop: 10, ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext) }}>
                                    <Pressable
                                        style={{
                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                            height: getCorrectHeight(deviceContext, 30),
                                            ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => goBack()}
                                    >
                                        <View>
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'back')}
                                            </Text>
                                        </View>
                                    </Pressable>
                                </View>
                            </View>
                        </View>
                    )}
                    {isLoading && (
                        <View style={{ flex: 4, flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                            <ActivityIndicator size={'large'} color={'#fff'}></ActivityIndicator>
                        </View>
                    )}
                </View>
            )}
        </ScreenWrapper>
    );
};
export default Screen_;
