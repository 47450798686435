import * as React from 'react';

import { DetailsBar } from '../elements/misc/detailsbar';
import { DvrProgress } from '../elements/misc/dvrprogress';
import { FilterBar } from '../elements/misc/filterbar';
import { SearchBar } from '../elements/misc/searchbar';
import { LogoTime } from '../elements/misc/logotime';
import { Navigation_Menu } from '../elements/navigation/menu';
import { Promotions_Ads } from '../elements/promotions/ad';
import Promotions_CastoolaAds from '../elements/promotions/castoolaads';
import { Promotions_Messages } from '../elements/promotions/promo';
import { Promotions_Ota } from '../elements/promotions/ota';
import { Header } from '../../models/application/header';
import { UserContext } from '../../context/userContext';
import LogoWide from '../elements/misc/logowide';

const Screen = ({ elements, focusContext, scrollA, navigation }): React.ReactElement => {
    const userContext = React.useContext(UserContext);
    const getElement = (element: Header, index: any) => {
        if (element.ads != undefined && userContext.userId.indexOf('_review') < 0) {
            return <Promotions_Ads key={index} styling={element.ads} focusContext={focusContext}></Promotions_Ads>;
        }
        if (element.ads != undefined && userContext.userId.indexOf('_review') < 0) {
            return <Promotions_CastoolaAds key={index} styling={element.ads} focusContext={focusContext}></Promotions_CastoolaAds>;
        }
        if (element.messages != undefined && userContext.userId.indexOf('_review') < 0) {
            return <Promotions_Messages key={index} styling={element.messages} focusContext={focusContext}></Promotions_Messages>;
        }
        if (element.otas != undefined && userContext.userId.indexOf('_review') < 0) {
            return <Promotions_Ota key={index} styling={element.otas} focusContext={focusContext}></Promotions_Ota>;
        }
        if (element.detailsbars != undefined) {
            return <DetailsBar key={index} styling={element.detailsbars} focusContext={focusContext}></DetailsBar>;
        }
        if (element.filterbars != undefined) {
            return <FilterBar key={index} styling={element.filterbars} focusContext={focusContext}></FilterBar>;
        }
        if (element.searchbars != undefined && userContext.userId.indexOf('_review') < 0) {
            return <SearchBar key={index} styling={element.searchbars} focusContext={focusContext} navigation={navigation}></SearchBar>;
        }
        if (element.dvrprogress != undefined) {
            return <DvrProgress key={index} styling={element.dvrprogress} focusContext={focusContext}></DvrProgress>;
        }
        if (element.logotime != undefined) {
            return <LogoTime key={index} styling={element.logotime} scrollA={scrollA}></LogoTime>;
        }
        if (element.logowide != undefined) {
            return <LogoWide key={index} styling={element.logowide} scrollA={scrollA}></LogoWide>;
        }
        if (element.menus != undefined) {
            return <Navigation_Menu key={index} styling={element.menus} focusContext={focusContext} scrollA={scrollA}></Navigation_Menu>;
        }
    };

    return (
        <>
            {elements.map((element: any, index: any) => {
                return getElement(element, index);
            })}
        </>
    );
};
export default Screen;
