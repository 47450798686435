export const getCorrectLeftMargin = (deviceContext, appContext, screenName) => {
    var width = appContext.application.theme.home_details_left_margin;
    if (
        screenName == 'Movies' ||
        screenName == 'Series' ||
        screenName == 'Channels' ||
        screenName == 'Radio' ||
        screenName == 'Shorts' ||
        screenName == 'Podcasts' ||
        screenName == 'Courses' ||
        screenName == 'Favorites' ||
        screenName == 'Watchlist' ||
        screenName == 'Downloads' ||
        screenName == 'Games' ||
        screenName == 'TV Guide'
    ) {
        var app = appContext.application as any;
        var screen = app.screens.find((s) => s.type == screenName);
        var contents = screen.content;
        var element = [] as any;
        for (let content of Object.values(contents)) {
            if (content['long_grids'] != undefined) {
                element = content['long_grids'];
            }
            if (content['widescreen_grids'] != undefined) {
                element = content['widescreen_grids'];
            }
            if (content['square_grids'] != undefined) {
                element = content['square_grids'];
            }
            if (content['morphing_grids'] != undefined) {
                element = content['morphing_grids'];
            }
            if (content['rectangle_grids'] != undefined) {
                element = content['rectangle_grids'];
            }
            if (content['tile_grids'] != undefined) {
                element = content['tile_grids'];
            }
            width = element.length != 0 ? element.placement.margin_left : 20;

        }
    }
    switch (deviceContext.deviceType as any) {
        case 'KaiOS':
            return width;
        case 'FireTV':
            return width / 2;
        case 'AndroidTV':
            return width / 2;
        case 'AppleTV':
            return width;
        case 'SmartTV_Tizen':
            return width;
        case 'SmartTV_LG':
            return width;
        case 'PWA':
            return 5;
        case 'WebTV':
            return width;
        case 'iOS':
            return 5;
        case 'iPad':
            return width;
        case 'Android_Mobile':
            return 5;
        case 'Android_Tablet':
            return width / 2;
        case 'SetTopBox':
            return width;
        default:
            break;
    }
};
