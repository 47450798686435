import { FocusContext, View, Image } from '@scriptx-com/xtv-toolkit';
import * as React from 'react';
import { DeviceContext } from '../../../context/deviceContext';
import { useEffectOnce } from '../../../hooks/useEffectOnce';
import { UserContext } from '../../../context/userContext';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import Text from '../../components/text';
import { getFontSize } from '../../../styling/fontSizes';
import lang from '../../../../application/languages/languages';

export interface Data {
    image: string;
}

export interface Props {
    styling: any;
    focusContext?: FocusContext;
    fromLoader?: boolean;
}

export const Promotions_CastoolaFullscreen = ({ styling, focusContext, fromLoader }: Props) => {
    const [data, setData] = React.useState([] as any);
    const [show, setShow] = React.useState(false);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const screenName = getCurrentScreenName();

    useEffectOnce(() => {
        async function myAsynFunction() {
            try {
                var random = Math.floor(1000 + Math.random() * 9000);
                var deviceType = '5';
                var deviceOs = '0';
                if (deviceContext.deviceType == 'WebTV') {
                    deviceType = '1';
                    deviceOs = '1';
                } else if (deviceContext.deviceType == 'Android_Mobile') {
                    deviceType = '2';
                    deviceOs = '5';
                } else if (deviceContext.deviceType == 'iOS') {
                    deviceType = '2';
                    deviceOs = '6';
                } else if (deviceContext.deviceType == 'KaiOS') {
                    deviceType = '2';
                    deviceOs = 'Other';
                } else if (deviceContext.deviceType == 'Android_Tablet') {
                    deviceType = '3';
                    deviceOs = '5';
                } else if (deviceContext.deviceType == 'iPad') {
                    deviceType = '3';
                    deviceOs = '6';
                } else if (deviceContext.deviceType == 'SetTopBox') {
                    deviceType = '4';
                    deviceOs = '5';
                } else if (deviceContext.deviceType == 'SmartTV_Tizen') {
                    deviceOs = '8';
                } else if (deviceContext.deviceType == 'SmartTV_LG') {
                    deviceOs = '9';
                } else if (deviceContext.deviceType == 'AndroidTV') {
                    deviceOs = '7';
                } else if (deviceContext.deviceType == 'FireTV') {
                    deviceOs = '13';
                } else if (deviceContext.deviceType == 'SmartTV_Vidaa') {
                    deviceOs = '11';
                }

                let path =
                    'https://mwaretv.client-api.castoola.tv/' +
                    styling.publisher_id +
                    '/v2.5/ads/display?placement_name=fullscreen&subscriber_id=' +
                    encodeURI(userContext.userId) +
                    '&device_type=' +
                    deviceType +
                    '&device_id=' +
                    encodeURI(deviceContext.uniqueId) +
                    '&consent=' +
                    (userContext.profile.consent != undefined ? userContext.profile.consent : true) +
                    '&profile_id=' +
                    encodeURI(userContext.profile.id) +
                    '&operating_system=' +
                    deviceOs +
                    '&context=' +
                    screenName +
                    '&bust=' +
                    random;

                let response = await fetch(path);
                if (response.status == 200) {
                    let data = await response.json();
                    if (data != undefined) {
                        var imageData = '';
                        if (deviceContext.isPhone) {
                            var test = data.creatives.find((c) => c.orientation == 'PORTRAIT');
                            if (test != undefined) {
                                imageData = test.creative_url;
                            }
                        } else {
                            var test = data.creatives.find((c) => c.orientation == 'LANDSCAPE');
                            if (test != undefined) {
                                imageData = test.creative_url;
                            }
                        }
                        setData(imageData);
                        sentImpressionTracker(data.impression_trackers);
                        sentConversionTracker(data.conversion_trackers);
                        setShow(true);
                        setShow(false);
                        setShow(true);
                    }
                }
            } catch (e) {}
        }

        myAsynFunction();
    });

    const sentConversionTracker = async (trackers) => {
        trackers.forEach(async (tracker) => {
            try {
                let response = await fetch(tracker);
                if (response.status == 200) {
                }
            } catch (e) {}
        });
    };

    const sentImpressionTracker = async (trackers) => {
        trackers.forEach(async (tracker) => {
            try {
                let response = await fetch(tracker);
                if (response.status == 200) {
                }
            } catch (e) {}
        });
    };

    return (
        <View focusContext={focusContext}>
            {show && (
                <View style={{ width: getRealWidth(deviceContext), height: getRealHeight(deviceContext) }}>
                    <View style={{ position: 'absolute', zIndex: 1, margin: 10, padding: 10, backgroundColor: '#000000' }}>
                        {userContext.profile.consent && <Text style={{ color: '#ffffff', fontFamily: deviceContext.fontType, fontSize: getFontSize(deviceContext, 'Small') }}>{lang.getTranslation(userContext, 'sponsered_personalized_ad')}</Text>}
                        {!userContext.profile.consent && <Text style={{ color: '#ffffff', fontFamily: deviceContext.fontType, fontSize: getFontSize(deviceContext, 'Small') }}>{lang.getTranslation(userContext, 'sponsered_ad')}</Text>}
                    </View>
                    <Image
                        source={{ uri: data }}
                        style={{
                            width: getRealWidth(deviceContext),
                            height: getRealHeight(deviceContext),
                        }}
                    />
                </View>
            )}
        </View>
    );
};

export default Promotions_CastoolaFullscreen;
