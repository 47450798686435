import * as React from 'react';
import Page from '../../../application/builder/pages/page';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import ScreenWrapper from '../../wrapper';
import { getFocusOptions } from '../../wrapper/helpers/getFocusOptions';

const Screen_ = ({ navigation, route }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const screen = appContext.application.screens.find((s) => s.type == 'TV Guide');
    const data = [];
    const focusOptions = getFocusOptions(deviceContext, appContext, 'content-screen');
    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ ...focusOptions, group: 'content-screen' }} stealFocus={false}>
            <Page navigation={navigation} route={route} screen={screen} data={data} type={'TV Guide'}></Page>
        </ScreenWrapper>
    );
};
export default Screen_;
