import { ActivityIndicator, setFocus, View, ViewGroup } from '@scriptx-com/xtv-toolkit';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import lang from '../../../languages/languages';
import { Modal as Styling } from '../../../models/components/modal/modal';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import Text from '../text';
import { AppContext } from '../../../context/appContext';
import { getFocusStyling, getPressableStyling, getPressableTextStyling } from '../helpers/helper';
import { ScreenFadeIn } from '../../animations/screenFadeIn';
import Pressable from '../../../../application/builder/components/pressable/pressable';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export interface ModalProps {
    styling: Styling;
    type: string;
    setShowModal?: any;
    submitChoice: any;
    focusContext: any;
    submitChoice2?: any;
    timerTime?: number;
    message?: string;
    favorite?: boolean;
    watchlist?: boolean;
    data?: any;
    contentType?: string;
    program?: any;
    error?: string;
}
const Modal = ({ styling, type, setShowModal, submitChoice, timerTime, message, submitChoice2, favorite, watchlist, focusContext, data, contentType, error, program }: ModalProps) => {
    const deviceContext = useContext(DeviceContext);
    const userContext = useContext(UserContext);
    const appContext = useContext(AppContext);

    const [timerInternal, setTimerInteral] = useState(timerTime != undefined ? timerTime : 99999);
    const ViewOrBottomSheet = deviceContext.isPhone ? global.BottomSheet : ScreenFadeIn;
    const ViewOrBottomSheetModalProvider = deviceContext.isPhone ? global.BottomSheetModalProvider : View;
    const ViewOrBottomSheetView = deviceContext.isPhone ? global.BottomSheetView : View;
    const Pressable_ = deviceContext.isPhone ? global.BottomTouchableWithoutFeedback : Pressable;

    const getTitle = () => {
        switch (type) {
            case 'reset_profiles':
                return 'reset_profiles';
                break;
            case 'recording':
                return 'record_program';
                break;
            case 'recording_waiting':
                return 'record_program';
                break;
            case 'recording_error':
                return 'record_program';
                break;
            case 'recording_success':
                return 'record_program';
                break;
            case 'reminder':
                return 'remind_program';
                break;
            case 'exit':
                return 'exit_app_title';
                break;
            case 'sleeptimer':
                return 'sleeptimer';
                break;
            case 'delete_profile':
                return 'delete_profile_title';
                break;
            case 'quickmenu':
                return 'quickmenu';
                break;
            case 'error':
                return 'login_error';
                break;
            case 'movie_ended':
                return 'movie_ended';
                break;
            case 'forgot':
                return 'forgot_password';
                break;
            case 'change':
                return 'change_password';
                break;
            default:
                break;
        }
    };

    const getSubText = () => {
        switch (type) {
            case 'reset_profiles':
                return 'reset_profiles_info';
                break;
            case 'exit':
                return 'exit_app_sub';
                break;
            case 'sleeptimer':
                return 'sleeptimer_info';
                break;
            case 'delete_profile':
                return 'delete_profile_info';
                break;
            case 'movie_ended':
                return 'movie_ended_info';
                break;
            case 'forgot':
                return 'forget_email_send';
                break;
            case 'change':
                return 'change_email_send';
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        let timer;
        if (timerTime != 99999) {
            timer = setInterval(() => {
                var newTimer = timerInternal - 1;
                if (newTimer > 0) {
                    setTimerInteral(newTimer);
                } else {
                    clearInterval(timer);
                    submitChoice();
                }
            }, 3000);
        }
        return () => clearInterval(timer);
    }, [timerInternal]);

    useEffect(() => {
        setFocus('modal-group');
        return () => {
            setFocus('delete-profile-btn');
        };
    }, []);

    let modalStyles = {};

    if (
        [
            'quickmenu',
            'delete_profile',
            'error',
            'movie_ended',
            'exit',
            'sleeptimer',
            'recording',
            'recording_waiting',
            'recording_success',
            'reminder',
            'reminder_success',
            'reminder_removed',
            'recording_removed',
            'recording_error',
            'reset_profiles',
            'forgot',
            'change',
        ].includes(type)
    ) {
        modalStyles = !deviceContext.isPhone
            ? {
                  position: 'absolute',
                  zIndex: 99999,
                  top: 0,
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  // width:getRealWidth(deviceContext),
                  //height: deviceContext.isWebTV ? '100%' : getRealHeight(deviceContext),
              }
            : {
                  position: 'absolute',
                  zIndex: 99999,
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,

                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: getRealHeight(deviceContext),
              };
    }

    // hooks
    const bottomSheetHeightPercentage = deviceContext.isAndroid
        ? type == 'error' ||
          type == 'exit' ||
          type == 'recording' ||
          type == 'recording_success' ||
          type == 'recording_waiting' ||
          type == 'recording_success' ||
          type == 'recording_error' ||
          type == 'reminder' ||
          type == 'reminder_success' ||
          type == 'reminder_removed' ||
          type == 'recording_removed'
            ? 320
            : type == 'quickmenu' || type == 'forgot' || type == 'change' || 'reset_profiles'
            ? 260
            : 300
        : type == 'error' ||
          type == 'exit' ||
          type == 'recording' ||
          type == 'recording_success' ||
          type == 'recording_waiting' ||
          type == 'recording_success' ||
          type == 'recording_error' ||
          type == 'reminder' ||
          type == 'reminder_success' ||
          type == 'reminder_removed' ||
          type == 'recording_removed'
        ? 340
        : type == 'quickmenu'
        ? 350
        : 300;

    const bottomSheetRef = useRef<typeof ViewOrBottomSheet>(null);
    const snapPoints = useMemo(() => [0, bottomSheetHeightPercentage], []);
    const handleSheetAnimate = useCallback((fromIndex: number, toIndex: number) => {
        if (toIndex == 0) {
            handleCollapsePress();
        }
    }, []);
    const handleCollapsePress = useCallback(() => {
        setShowModal(false);
    }, []);

    return (
        <ViewGroup focusOptions={{ focusKey: 'modal-group', group: 'modal-group' }} focusContext={focusContext} style={[{ flex: 1, overflow: 'visible' }, modalStyles]}>
            <ViewOrBottomSheetModalProvider>
                <ViewOrBottomSheet ref={bottomSheetRef} index={1} snapPoints={snapPoints} animateOnMount={true} enableContentPanningGesture={true} enableHandlePanningGesture={true} onAnimate={handleSheetAnimate}>
                    <ViewOrBottomSheetView
                        style={[
                            {
                                flex: 1,
                                flexDirection: 'row',
                                width: getRealWidth(deviceContext),
                                height: getRealHeight(deviceContext),
                                alignItems: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                            },
                            modalStyles,
                        ]}
                    >
                        <View
                            style={{
                                width: deviceContext.isKaiOs || deviceContext.isPhone ? getRealWidth(deviceContext) : getCorrectHeight(deviceContext, type == 'quickmenu' ? 300 : 400),
                                minHeight: deviceContext.isKaiOs
                                    ? getRealHeight(deviceContext) / 2
                                    : deviceContext.isPhone
                                    ? getRealHeight(deviceContext)
                                    : getCorrectWidth(
                                          deviceContext,

                                          type == 'recording_success' ||
                                              type == 'recording_waiting' ||
                                              type == 'recording_success' ||
                                              type == 'recording_error' ||
                                              type == 'reminder_success' ||
                                              type == 'reminder_removed' ||
                                              type == 'recording_removed' ||
                                              type == 'quickmenu'
                                              ? 240
                                              : type == 'error' || 'reset_profiles'
                                              ? 170
                                              : type == 'delete_profile' || type == 'reminder' || type == 'recording'
                                              ? 175
                                              : type == 'sleeptimer' || type == 'change' || type == 'forgot' || type == 'exit' 
                                              ? 125
                                              : 200
                                      ),
                                borderColor: styling.border_color,
                                borderWidth: deviceContext.isPhone ? 0 : styling.border_width,
                                borderRadius: deviceContext.isPhone ? 0 : getCorrectWidth(deviceContext, 3),
                                flexDirection: 'row',
                                alignSelf: 'center',
                                backgroundColor: styling.background_color,
                            }}
                        >
                            {(type == 'sleeptimer' || type == 'exit' || type == 'forgot' || type == 'change') && (
                                <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                                    <View style={{ flex: deviceContext.isPhone ? 0 : 1 }}>
                                        <Text
                                            style={{
                                                color: styling.title_color,
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                                lineHeight: getFontSize(deviceContext, 'ExtraLarge'),
                                                marginBottom: type != 'exit' && type != 'sleeptimer' ? getCorrectHeight(deviceContext, 10) : 5,
                                            }}
                                        >
                                            {lang.getTranslation(userContext, getTitle())}
                                        </Text>
                                        {type != 'exit' && (
                                            <Text
                                                style={{
                                                    color: styling.subtext_color,
                                                    fontWeight: styling.subtext_bold ? 'bold' : 'normal',
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    marginBottom: getCorrectHeight(deviceContext, 5),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, getSubText())}
                                            </Text>
                                        )}
                                        {type == 'exit' && (
                                            <View style={{ flexDirection: 'row', backgroundColor: '#d6f0ff', alignItems: 'center', padding: getCorrectHeight(deviceContext, 10), marginVertical: 5, borderRadius: 5 }}>
                                                <FontAwesomeIcon icon={faInfoCircle} color={'#46b1fe'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                                <Text
                                                    style={{
                                                        marginLeft: 10,
                                                        color: '#46b1fe',
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'press_back_one_more')}
                                                </Text>
                                            </View>
                                        )}
                                        {type == 'sleeptimer' && (
                                            <View style={{ flexDirection: 'row', backgroundColor: '#d6f0ff', alignItems: 'center', padding: getCorrectHeight(deviceContext, 10), marginVertical: 5, borderRadius: 5 }}>
                                                <FontAwesomeIcon icon={faInfoCircle} color={'#46b1fe'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                                <Text
                                                    style={{
                                                        marginLeft: 10,
                                                        color: '#46b1fe',
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'sleeptimer_infos')} {timerInternal} {lang.getTranslation(userContext, 'seconds')}
                                                </Text>
                                            </View>
                                        )}
                                    </View>
                                </View>
                            )}
                            {type == 'quickmenu' && (
                                <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                                    <View style={{ flex: deviceContext.isPhone ? 0 : 1, alignSelf: 'flex-start' }}>
                                        <Text
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                            }}
                                        >
                                            {lang.getTranslation(userContext, getTitle())}
                                        </Text>
                                    </View>
                                    <View style={{ flexDirection: 'column', justifyContent: 'flex-end', marginTop: getCorrectHeight(deviceContext, 10) }}>
                                        {appContext.application.settings.allow_favorites && (
                                            <Pressable_
                                                style={{
                                                    margin: getCorrectWidth(deviceContext, 2),
                                                    paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                                    height: getCorrectHeight(deviceContext, 30),
                                                    ...getPressableStyling(styling.components?.button?._id, appContext),
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                                // hasTVPreferredFocus={true}
                                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                                onPress={() => submitChoice2('favorite')}
                                            >
                                                <View>
                                                    <Text
                                                        style={{
                                                            fontFamily: deviceContext.fontType,
                                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                        }}
                                                    >
                                                        {lang.getTranslation(userContext, watchlist ? 'remove_from_watchlist' : 'add_to_watchlist')}
                                                    </Text>
                                                </View>
                                            </Pressable_>
                                        )}
                                        {appContext.application.settings.allow_watchlist && (
                                            <Pressable_
                                                style={{
                                                    margin: getCorrectWidth(deviceContext, 2),
                                                    paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                                    height: getCorrectHeight(deviceContext, 30),
                                                    ...getPressableStyling(styling.components?.button?._id, appContext),
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                                onPress={() => submitChoice('watchlist')}
                                            >
                                                <View>
                                                    <Text
                                                        style={{
                                                            padding: 10,
                                                            fontFamily: deviceContext.fontType,
                                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                        }}
                                                    >
                                                        {lang.getTranslation(userContext, favorite ? 'remove_from_favorite' : 'add_to_favorite')}
                                                    </Text>
                                                </View>
                                            </Pressable_>
                                        )}
                                        <Pressable_
                                            style={{
                                                margin: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                                height: getCorrectHeight(deviceContext, 30),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            // hasTVPreferredFocus={true}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => setShowModal(false)}
                                        >
                                            <View>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'cancel')}
                                                </Text>
                                            </View>
                                        </Pressable_>
                                    </View>
                                </View>
                            )}
                            {type == 'error' && (
                                <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                                    <View style={{ flex: deviceContext.isPhone ? 0 : 1 }}>
                                        <Text
                                            style={{
                                                color: '#fff',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                                fontWeight: 'bold',
                                                marginBottom: getCorrectHeight(deviceContext, 10),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, getTitle())}
                                        </Text>

                                        {/* <Text
                                            style={{
                                                color: '#fff',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: getCorrectHeight(deviceContext, 10),
                                            }}
                                        >
                                            {error}
                                        </Text> */}
                                        <View style={{ flexDirection: 'row', backgroundColor: '#ffe1e3', alignItems: 'center', padding: getCorrectHeight(deviceContext, 10), marginVertical: 5, borderRadius: 5 }}>
                                            <FontAwesomeIcon icon={faExclamationCircle} color={'#f84d51'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                            <Text
                                                style={{
                                                    marginLeft: 10,
                                                    color: '#f84d51',
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                }}
                                            >
                                                {error}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <Pressable_
                                            style={{
                                                margin: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                                height: getCorrectHeight(deviceContext, 30),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            // hasTVPreferredFocus={true}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => setShowModal(false)}
                                        >
                                            <View>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'close')}
                                                </Text>
                                            </View>
                                        </Pressable_>
                                    </View>
                                </View>
                            )}
                            {type == 'movie_ended' && (
                                <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                                    <View style={{ flex: deviceContext.isPhone ? 0 : 1, alignSelf: 'flex-start' }}>
                                        <Text
                                            style={{
                                                color: '#fff',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                                fontWeight: 'bold',
                                                marginBottom: getCorrectHeight(deviceContext, 10),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, getTitle())}
                                        </Text>
                                        <Text
                                            style={{
                                                color: '#fff',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Large'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: getCorrectHeight(deviceContext, 10),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, getSubText())}
                                        </Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <Pressable_
                                            style={{
                                                margin: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                                height: getCorrectHeight(deviceContext, 30),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            // hasTVPreferredFocus={true}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => submitChoice(false)}
                                        >
                                            <View>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'back')}
                                                </Text>
                                            </View>
                                        </Pressable_>
                                        <Pressable_
                                            style={{
                                                margin: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                                height: getCorrectHeight(deviceContext, 30),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            // hasTVPreferredFocus={true}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => submitChoice2(false)}
                                        >
                                            <View>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'restart')}
                                                </Text>
                                            </View>
                                        </Pressable_>
                                    </View>
                                </View>
                            )}
                            {type == 'delete_profile' && (
                                <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                                    <View style={{ flex: deviceContext.isPhone ? 0 : 1, alignSelf: 'flex-start' }}>
                                        <Text
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: getCorrectHeight(deviceContext, 10),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, getTitle())}
                                        </Text>
                                        <Text
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: getCorrectHeight(deviceContext, 10),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, getSubText())}
                                        </Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <Pressable_
                                            style={{
                                                margin: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                                height: getCorrectHeight(deviceContext, 30),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            // hasTVPreferredFocus={true}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => setShowModal(false)}
                                        >
                                            <View>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'cancel')}
                                                </Text>
                                            </View>
                                        </Pressable_>
                                        <Pressable_
                                            style={{
                                                margin: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                                height: getCorrectHeight(deviceContext, 30),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => submitChoice()}
                                        >
                                            <View>
                                                <Text
                                                    style={{
                                                        padding: 10,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'submit')}
                                                </Text>
                                            </View>
                                        </Pressable_>
                                    </View>
                                </View>
                            )}
                            {type == 'reminder' && (
                                <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                                    <View style={{ flex: deviceContext.isPhone ? 0 : 1, alignSelf: 'flex-start' }}>
                                        <Text
                                            numberOfLines={2}
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                                lineHeight: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: 5,
                                            }}
                                        >
                                            {program.n}
                                        </Text>
                                        <Text
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                lineHeight: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: getCorrectHeight(deviceContext, 10),
                                            }}
                                        >
                                            {moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format(deviceContext.clockSetting)}
                                        </Text>
                                        <Text
                                            numberOfLines={3}
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: getCorrectHeight(deviceContext, 5),
                                            }}
                                        >
                                            {program.d}
                                        </Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <Pressable_
                                            style={{
                                                margin: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                                height: getCorrectHeight(deviceContext, 30),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            // hasTVPreferredFocus={true}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => setShowModal(false)}
                                        >
                                            <View>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'cancel')}
                                                </Text>
                                            </View>
                                        </Pressable_>
                                        <Pressable_
                                            style={{
                                                margin: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                                height: getCorrectHeight(deviceContext, 30),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => submitChoice()}
                                        >
                                            <View>
                                                <Text
                                                    style={{
                                                        padding: 10,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'set_reminder')}
                                                </Text>
                                            </View>
                                        </Pressable_>
                                    </View>
                                </View>
                            )}
                            {type == 'reminder_success' && (
                                <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                                    <View style={{ flex: deviceContext.isPhone ? 0 : 1, alignSelf: 'flex-start' }}>
                                        <Text
                                            numberOfLines={2}
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                                lineHeight: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: 5,
                                            }}
                                        >
                                            {program.n}
                                        </Text>
                                        <Text
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                lineHeight: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: getCorrectHeight(deviceContext, 10),
                                            }}
                                        >
                                            {moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format(deviceContext.clockSetting)}
                                        </Text>
                                        <Text
                                            numberOfLines={3}
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: getCorrectHeight(deviceContext, 5),
                                            }}
                                        >
                                            {program.d}
                                        </Text>
                                        <View style={{ flexDirection: 'row', backgroundColor: '#c3f2d6', alignItems: 'center', padding: getCorrectHeight(deviceContext, 10), marginVertical: 5, borderRadius: 5 }}>
                                            <FontAwesomeIcon icon={faCheckCircle} color={'#28ac5c'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                            <Text
                                                style={{
                                                    marginLeft: 10,
                                                    color: '#28ac5c',
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'reminder_success')}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <Pressable_
                                            style={{
                                                margin: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                                height: getCorrectHeight(deviceContext, 30),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            // hasTVPreferredFocus={true}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => submitChoice(false)}
                                        >
                                            <View>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'close')}
                                                </Text>
                                            </View>
                                        </Pressable_>
                                    </View>
                                </View>
                            )}
                            {type == 'recording_removed' && (
                                <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                                    <View style={{ flex: deviceContext.isPhone ? 0 : 1, alignSelf: 'flex-start' }}>
                                        <Text
                                            numberOfLines={2}
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                                lineHeight: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: 5,
                                            }}
                                        >
                                            {program.n}
                                        </Text>
                                        <Text
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                lineHeight: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: getCorrectHeight(deviceContext, 10),
                                            }}
                                        >
                                            {moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format(deviceContext.clockSetting)}
                                        </Text>
                                        <Text
                                            numberOfLines={3}
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: getCorrectHeight(deviceContext, 5),
                                            }}
                                        >
                                            {program.d}
                                        </Text>
                                        <View style={{ flexDirection: 'row', backgroundColor: '#c3f2d6', alignItems: 'center', padding: getCorrectHeight(deviceContext, 10), marginVertical: 5, borderRadius: 5 }}>
                                            <FontAwesomeIcon icon={faInfoCircle} color={'#28ac5c'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                            <Text
                                                style={{
                                                    marginLeft: 10,
                                                    color: '#28ac5c',
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'recording_removed')}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <Pressable_
                                            style={{
                                                margin: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                                height: getCorrectHeight(deviceContext, 30),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            // hasTVPreferredFocus={true}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => submitChoice(false)}
                                        >
                                            <View>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'close')}
                                                </Text>
                                            </View>
                                        </Pressable_>
                                    </View>
                                </View>
                            )}
                            {type == 'reminder_removed' && (
                                <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                                    <View style={{ flex: deviceContext.isPhone ? 0 : 1, alignSelf: 'flex-start' }}>
                                        <Text
                                            numberOfLines={2}
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                                lineHeight: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: 5,
                                            }}
                                        >
                                            {program.n}
                                        </Text>
                                        <Text
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                lineHeight: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: getCorrectHeight(deviceContext, 10),
                                            }}
                                        >
                                            {moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format(deviceContext.clockSetting)}
                                        </Text>
                                        <Text
                                            numberOfLines={3}
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: getCorrectHeight(deviceContext, 5),
                                            }}
                                        >
                                            {program.d}
                                        </Text>
                                        <View style={{ flexDirection: 'row', backgroundColor: '#c3f2d6', alignItems: 'center', padding: getCorrectHeight(deviceContext, 10), marginVertical: 5, borderRadius: 5 }}>
                                            <FontAwesomeIcon icon={faInfoCircle} color={'#28ac5c'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                            <Text
                                                style={{
                                                    marginLeft: 10,
                                                    color: '#28ac5c',
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'reminder_removed')}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <Pressable_
                                            style={{
                                                margin: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                                height: getCorrectHeight(deviceContext, 30),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            // hasTVPreferredFocus={true}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => submitChoice(false)}
                                        >
                                            <View>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'close')}
                                                </Text>
                                            </View>
                                        </Pressable_>
                                    </View>
                                </View>
                            )}
                            {type == 'recording' && (
                                <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                                    <View style={{ flex: deviceContext.isPhone ? 0 : 1, alignSelf: 'flex-start' }}>
                                        <Text
                                            numberOfLines={2}
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                                lineHeight: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: 5,
                                            }}
                                        >
                                            {program.n}
                                        </Text>
                                        <Text
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                lineHeight: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: getCorrectHeight(deviceContext, 10),
                                            }}
                                        >
                                            {moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format(deviceContext.clockSetting)}
                                        </Text>
                                        <Text
                                            numberOfLines={3}
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: getCorrectHeight(deviceContext, 5),
                                            }}
                                        >
                                            {program.d}
                                        </Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <Pressable_
                                            style={{
                                                margin: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                                height: getCorrectHeight(deviceContext, 30),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            // hasTVPreferredFocus={true}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => setShowModal(false)}
                                        >
                                            <View>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'cancel')}
                                                </Text>
                                            </View>
                                        </Pressable_>
                                        <Pressable_
                                            style={{
                                                margin: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                                height: getCorrectHeight(deviceContext, 30),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => submitChoice()}
                                        >
                                            <View>
                                                <Text
                                                    style={{
                                                        padding: 10,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'plan_recording')}
                                                </Text>
                                            </View>
                                        </Pressable_>
                                    </View>
                                </View>
                            )}
                            {type == 'recording_waiting' && (
                                <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                                    <View style={{ flex: deviceContext.isPhone ? 0 : 1, alignSelf: 'flex-start' }}>
                                        <Text
                                            numberOfLines={2}
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                                lineHeight: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: 5,
                                            }}
                                        >
                                            {program.n}
                                        </Text>
                                        <Text
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                lineHeight: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: getCorrectHeight(deviceContext, 10),
                                            }}
                                        >
                                            {moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format(deviceContext.clockSetting)}
                                        </Text>
                                        <Text
                                            numberOfLines={3}
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: getCorrectHeight(deviceContext, 5),
                                            }}
                                        >
                                            {program.d}
                                        </Text>

                                        <View style={{ flexDirection: 'row', backgroundColor: '#d6f0ff', alignItems: 'center', padding: getCorrectHeight(deviceContext, 10), marginVertical: 5, borderRadius: 5 }}>
                                            <ActivityIndicator color={'#46b1fe'} size={'small'}></ActivityIndicator>
                                            <Text
                                                style={{
                                                    marginLeft: 10,
                                                    color: '#46b1fe',
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'recording_waiting')}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            )}
                            {type == 'recording_success' && (
                                <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                                    <View style={{ flex: deviceContext.isPhone ? 0 : 1, alignSelf: 'flex-start' }}>
                                        <Text
                                            numberOfLines={2}
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                                lineHeight: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: 5,
                                            }}
                                        >
                                            {program.n}
                                        </Text>
                                        <Text
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                lineHeight: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: getCorrectHeight(deviceContext, 10),
                                            }}
                                        >
                                            {moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format(deviceContext.clockSetting)}
                                        </Text>
                                        <Text
                                            numberOfLines={3}
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: getCorrectHeight(deviceContext, 5),
                                            }}
                                        >
                                            {program.d}
                                        </Text>
                                        <View style={{ flexDirection: 'row', backgroundColor: '#c3f2d6', alignItems: 'center', padding: getCorrectHeight(deviceContext, 10), marginVertical: 5, borderRadius: 5 }}>
                                            <FontAwesomeIcon icon={faInfoCircle} color={'#28ac5c'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                            <Text
                                                style={{
                                                    marginLeft: 10,
                                                    color: '#28ac5c',
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'recording_success')}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <Pressable_
                                            style={{
                                                margin: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                                height: getCorrectHeight(deviceContext, 30),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            // hasTVPreferredFocus={true}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => setShowModal(false)}
                                        >
                                            <View>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'close')}
                                                </Text>
                                            </View>
                                        </Pressable_>
                                    </View>
                                </View>
                            )}
                            {type == 'reset_profiles' && (
                                <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                                    <View style={{ flex: deviceContext.isPhone ? 0 : 1 }}>
                                        <Text
                                            style={{
                                                color: styling.title_color,
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                                lineHeight: getFontSize(deviceContext, 'ExtraLarge'),
                                                marginBottom: getCorrectHeight(deviceContext, 10),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, getTitle())}
                                        </Text>

                                        <View style={{ flexDirection: 'row', backgroundColor: '#d6f0ff', alignItems: 'center', padding: getCorrectHeight(deviceContext, 10), marginVertical: 5, borderRadius: 5 }}>
                                            <FontAwesomeIcon icon={faInfoCircle} color={'#46b1fe'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                            <Text
                                                style={{
                                                    marginLeft: 10,
                                                    color: '#46b1fe',
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                }}
                                            >
                                                {lang.getTranslation(userContext, getSubText())}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <Pressable_
                                            style={{
                                                margin: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                                height: getCorrectHeight(deviceContext, 30),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            // hasTVPreferredFocus={true}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => setShowModal(false)}
                                        >
                                            <View>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'cancel')}
                                                </Text>
                                            </View>
                                        </Pressable_>
                                        <Pressable_
                                            style={{
                                                margin: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                                height: getCorrectHeight(deviceContext, 30),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => submitChoice()}
                                        >
                                            <View>
                                                <Text
                                                    style={{
                                                        padding: 10,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'submit')}
                                                </Text>
                                            </View>
                                        </Pressable_>
                                    </View>
                                </View>
                            )}
                            {type == 'reminder' && (
                                <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                                    <View style={{ flex: deviceContext.isPhone ? 0 : 1, alignSelf: 'flex-start' }}>
                                        <Text
                                            numberOfLines={2}
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                                lineHeight: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: 5,
                                            }}
                                        >
                                            {program.n}
                                        </Text>
                                        <Text
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                lineHeight: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: getCorrectHeight(deviceContext, 10),
                                            }}
                                        >
                                            {moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format(deviceContext.clockSetting)}
                                        </Text>
                                        <Text
                                            numberOfLines={3}
                                            style={{
                                                color: styling.title_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                marginBottom: getCorrectHeight(deviceContext, 5),
                                            }}
                                        >
                                            {program.d}
                                        </Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <Pressable_
                                            style={{
                                                margin: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                                height: getCorrectHeight(deviceContext, 30),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            // hasTVPreferredFocus={true}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => setShowModal(false)}
                                        >
                                            <View>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'cancel')}
                                                </Text>
                                            </View>
                                        </Pressable_>
                                        <Pressable_
                                            style={{
                                                margin: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                                height: getCorrectHeight(deviceContext, 30),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => submitChoice()}
                                        >
                                            <View>
                                                <Text
                                                    style={{
                                                        padding: 10,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'set_reminder')}
                                                </Text>
                                            </View>
                                        </Pressable_>
                                    </View>
                                </View>
                            )}
                        </View>
                    </ViewOrBottomSheetView>
                </ViewOrBottomSheet>
            </ViewOrBottomSheetModalProvider>
        </ViewGroup>
    );
};

export default Modal;
