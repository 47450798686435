import * as React from 'react';
import { DeviceContext } from '../../../context/deviceContext';
import AudioStandard_Portrait from './audiostandard.portrait';
import AudioStandard_Landscape from './audiostandard.landscape';

export const Details_AudioStandard = ({ styling, navigation, focusContext, contentScreensAvailable }) => {
    const deviceContext = React.useContext(DeviceContext);

    return (
        <>
            {(deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && (
                <AudioStandard_Portrait styling={styling} navigation={navigation} focusContext={focusContext}></AudioStandard_Portrait>
            )}
            {!deviceContext.isPhone && !deviceContext.isKaiOs && !deviceContext.isPwaVertical && (
                <AudioStandard_Landscape styling={styling} navigation={navigation} focusContext={focusContext}></AudioStandard_Landscape>
            )}
        </>
    );
};

export default Details_AudioStandard;
