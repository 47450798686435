export const getCorrectColumns = (styling, deviceContext) => {
    if (deviceContext.isWebTV && !deviceContext.isPwaVertical) {
        return styling.layout.columns_browser;
    } else if (deviceContext.isTablet) {
        return styling.layout.columns_tablet;
    } else if (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs) {
        return styling.layout?.columns_mobile ?? 1;
    } else if (deviceContext.isTelevision) {
        return styling.layout.columns_television;
    }
};
