import * as React from 'react';
import { Image, View, Pressable } from '@scriptx-com/xtv-toolkit';
import { getFontSize } from '../../../styling/fontSizes';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { useEffectOnce } from '../../../hooks/useEffectOnce';
import { DeviceContext } from '../../../context/deviceContext';
import { AppContext } from '../../../context/appContext';
import { UserContext } from '../../../context/userContext';
import moment from 'moment';
import Text from '../../components/text';
import { getFocusStyling, getMessageStyling } from '../../components/helpers/helper';
import { getMessages } from '../../../data/account';
import { getCorrectLeftMargin } from '../../../styling/appLeftMargin';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { colorShade } from '../../../styling/colorShade';

export interface Data {
    image: string;
    title: string;
    sub: string;
}

export interface Props {
    focusContext?: any;
    location?: any;
}

export const Subscriber_Messages = ({ focusContext, location }: Props) => {
    const [show, setShow] = React.useState(false);
    const [data, setData] = React.useState([] as any);
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const screenName = getCurrentScreenName();

    const getMessage = async () => {
        var messages = await getMessages(appContext.application, userContext);
        if (messages.length > 0) {
            if (location != undefined) {
                var l = messages.length - 1;
                if (l > -1) {
                    if (messages[l].type == 'renewal_reminders') {
                        setData({
                            subject: messages[0].subject,
                            date: moment.unix(messages[0].timestamp).format('ll'),
                            message: messages[0].content,
                        });
                        setShow(true);
                    }
                }
            } else {
                var message = messages.find((m) => m.timestamp > moment().startOf('d').unix() && m.timestamp < moment().endOf('d').unix());
                if (message != undefined) {
                    setData({
                        subject: message.subject,
                        date: moment.unix(message.timestamp).format('ll'),
                        message: message.content,
                    });
                    setShow(true);
                }
            }
        }
    };

    useEffectOnce(() => {
        getMessage();
    });


    return (
        <View focusContext={focusContext}>
            {show && (
                <View
                    style={{
                        ...getMessageStyling(appContext),
                        marginLeft: getCorrectLeftMargin(deviceContext, appContext, screenName),
                        marginRight: getCorrectLeftMargin(deviceContext, appContext, screenName),
                        marginTop: getCorrectHeight(deviceContext, appContext.application.theme.rails_top_margin),
                        marginBottom: getCorrectHeight(deviceContext, appContext.application.theme.rails_bottom_margin),
                        borderColor: colorShade(getMessageStyling(appContext).backgroundColor, 30),
                        borderWidth:2
                    }}
                >
                    <Pressable
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        hasPreferredFocus={false}
                        style={{
                            ...getMessageStyling(appContext),
                            flexDirection: 'row',
                        }}
                    >
                        <>
                            <View
                                style={{
                                    flex: 1,
                                    margin: getCorrectHeight(deviceContext, 8),
                                    padding: 20,
                                }}
                            >
                                <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: getCorrectHeight(deviceContext, 2) }}>
                                    <View style={{ flex: 1 }}>
                                        <Text
                                            style={{
                                                color: getMessageStyling(appContext).color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Large'),
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {data.subject}
                                        </Text>
                                    </View>
                                    <View style={{ flex: 1, justifyContent: 'center' }}>
                                        <Text
                                            style={{
                                                color: getMessageStyling(appContext).color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            {moment(data.date).format('ll')}
                                        </Text>
                                    </View>
                                </View>
                                <Text
                                    style={{
                                        color: getMessageStyling(appContext).color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                    }}
                                >
                                    {data.message}
                                </Text>
                            </View>
                        </>
                    </Pressable>
                </View>
            )}
        </View>
    );
};

export default Subscriber_Messages;
