import moment from 'moment';
import Akamai from 'akamai-auth-token';
import CryptoJS from '../../../../general/cryptojs';
import * as sha1 from '../../../../general/sha1';

export const getDrm = async (contentContext: any, content_id: any, type: any, application: any, deviceContext, playerContext) => {
    if (contentContext.security.drm.drm_enabled == false) {
        return null;
    } else {
        if (contentContext.security.drm.drm_type == 'irdeto') {
            return getDrmWidevineFairplayIrdeto(deviceContext, playerContext);
        } else if (contentContext.security.drm.drm_type == 'buydrm') {
            return await getDrmWidevineFairplayBuyDRM(application, playerContext);
        } else {
            return [];
        }
    }
};

export const getToken = async (content: any, tk: any, url: any, deviceContext, userContext) => {
    return await fetch('https://cloudtv.akamaized.net/ip.php?_=' + moment().unix()).then(async (response) => {
        let data = await response.json();
        if (data != undefined) {
            deviceContext.setIpAddress(data.ip);
            return getTokenPerContentType(content, tk, url, deviceContext, userContext, data.ip);
        } else {
            return getTokenPerContentType(content, tk, url, deviceContext, userContext, deviceContext.ipAddress);
        }
    });
};
export const getTokenPerContentType = (content: any, tk: any, url: any, deviceContext, userContext, ipAddress) => {
    if (content.security.akamai_token) {
        return getAkamaiToken(url, tk, deviceContext, userContext, ipAddress);
    } else if (content.security.flussonic_token) {
        return getFlussonicToken(url, tk, ipAddress);
    } else if (content.security.legacy_token) {
        return getAkamaiTokenLegacy(url, tk, deviceContext, userContext, ipAddress);
    } else {
        return url;
    }
};

export const getAkamaiToken = (url, tk, deviceContext, userContext, ipAddress) => {
    if (url == '' || url == undefined) {
        return url;
    }
    if (tk.ak == undefined || tk.ak == '') {
        return url;
    }
    //var splitUrl = url.split('?');
    // var urlToken = splitUrl[0].split('/');
    // urlToken.pop();
    // urlToken.shift();
    // urlToken.shift();
    // urlToken.shift();
    // urlToken = urlToken.join('/');
    var config = {
        data: ipAddress + '-' + deviceContext.formFactor,
        algorithm: 'SHA256',
        acl: '/*',
        key: tk.ak,
        window: 30000,
        encoding: false,
        time: new Date().getTime(),
    };
    var akamai = new Akamai(config);
    var token = akamai.generateToken();
    token = token + '&uid=' + encodeURIComponent(userContext.userId); //added UID request BTL
    url += url.indexOf('?') > -1 ? '&hdnts=' + token : '?hdnts=' + token;
    return url;
};
export const getAkamaiTokenLegacy = (url, tk, deviceContext, userContext, ipAddress) => {
    if (url == '' || url == undefined) {
        return url;
    }
    if (tk.akl == undefined || tk.akl == '') {
        return url;
    }
    var url_ = url.split('?')[0].split('#')[0];
    var comp = [];
    var RandomNumber = Math.floor(Math.random() * 1000) + 1;
    comp.push('random=' + RandomNumber);
    comp.push('path=' + url_);
    comp.push('checksum=' + encodeURIComponent(deviceContext.userAgent));
    comp.push('ip=' + ipAddress);
    comp.push('date=' + new Date().getTime());
    comp.push('userid=' + userContext.userId);
    var token = encodeURIComponent(
        'JP' +
            CryptoJS.AES.encrypt(comp.join('~'), CryptoJS.enc.Hex.parse(tk.akl), {
                iv: CryptoJS.enc.Hex.parse('fedcba9876543210'),
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            }).toString()
    );
    url += url.indexOf('?') > -1 ? '&token=' + token : '?token=' + token;
    return url;
};
export const getFlussonicToken = (url, tk, ipAddress) => {
    if (url == '' || url == undefined) {
        return url;
    }
    if (tk.fl == undefined || tk.fl == '') {
        return url;
    }
    var salt = randomString(16, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    var splitUrl = url.split('?');
    var streamName = splitUrl[0].split('/');
    streamName.pop();
    streamName.shift();
    streamName.shift();
    streamName.shift();
    streamName = streamName.join('/');
    var comp = [];

    comp.push(streamName);
    comp.push(ipAddress);
    comp.push(moment().utc().unix());
    comp.push(moment().utc().unix() + 9600);
    comp.push(tk.fl);
    comp.push(salt);

    var hash = comp.join('');
    var token = sha1(hash) + '-' + salt + '-' + (moment().utc().unix() + 9600) + '-' + (moment().utc().unix() - 180);
    url += url.indexOf('?') > -1 ? '&token=' + token : '?token=' + token;
    return url;
};

export const getDrmWidevineFairplayBuyDRM = async (application, playerContext) => {
    var drmKeyUrl = 'https://cloudtv.akamaized.net/' + application.client + '/' + application.deployment + '/drm/keys/buydrm.txt';
    return await getTokenFromServer(drmKeyUrl).then((data) => {
        playerContext.setDrm({
            ...playerContext.drm,
            supplier: 'buydrm',
            headers: {
                customData: data.trim(),
            },
        });
    });
};
export const getDrmWidevineFairplayIrdeto = (deviceContext, playerContext) => {
    var drmServerUrl = '';
    if (!deviceContext.isIos && !deviceContext.isAppleTV) {
        drmServerUrl = playerContext.drm.keyServerUrl + '/licenseServer/widevine/v1/' + playerContext.drm.accountId + '/license?contentId=channel1';
    } else {
        drmServerUrl = playerContext.drm.keyServerUrl + 'licenseServer/streaming/v1/' + playerContext.drm.accountId + '/getckc';
    }
    let myDrm = {
        drmType: 'Widevine',
        drmKey: '',
        drmCertificateUrl: playerContext.drm.keyServerUrl + '/licenseServer/streaming/v1/' + playerContext.drm.accountId + '/getcertificate?applicationId=' + deviceContext.uniqueId,
        drmLicenseServerUrl: drmServerUrl,
    };
    return myDrm;
};
export const getTokenFromIrdetoServer = (path) => {
    var myHeaders = new Headers();
    myHeaders.set('Content-Type', 'application/octet-stream');
    try {
        return fetch(path, {
            method: 'POST',
            headers: myHeaders,
        })
            .then((res) => {
                let result = res.blob();
                return result;
            })
            .catch((error) => {});
    } catch (err) {}
};

export const getTokenFromServer = (path) => {
    return fetch(path)
        .then((res) => {
            let result = res.text();
            return result;
        })
        .catch((error) => {
            return '';
        });
};
export const randomString = (length, chars) => {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
};
