import * as React from 'react';
import { DeviceContext } from '../../../context/deviceContext';
import Single_Portrait from './single.portrait';
import Single_Landscape from './single.landscape';

export const Hero_Single = ({ styling, navigation, focusContext }) => {
    const deviceContext = React.useContext(DeviceContext);

    return (
        <>
            {(deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && (
                <Single_Portrait styling={styling} navigation={navigation} focusContext={focusContext}></Single_Portrait>
            )}
            {!deviceContext.isPhone && !deviceContext.isKaiOs && !deviceContext.isPwaVertical && (
                <Single_Landscape styling={styling} navigation={navigation} focusContext={focusContext}></Single_Landscape>
            )}
        </>
    );
};

export default Hero_Single;
