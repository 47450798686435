import * as React from 'react';
import Player from '../../../application/builder/pages/videoplayer';
import ScreenWrapper from '../../wrapper';

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    return (
        <ScreenWrapper style={{ flex: 1 }}>
            <Player navigation={navigation} route={route} type={'Recordings'}></Player>
        </ScreenWrapper>
    );
};
export default Screen_;
