import { faAndroid } from '@fortawesome/free-brands-svg-icons';
import {
    faSearch,
    faMoneyBill,
    faTv,
    faThList,
    faDotCircle,
    faFilm,
    faVideo,
    faMusic,
    faLock,
    faPlayCircle,
    faDownload,
    faSchool,
    faPodcast,
    faCameraCctv,
    faRadio,
    faUser,
    faLaptopMobile,
    faHeadset,
    faInfoCircle,
    faCog,
    faCreditCard,
    faBoxesPacking,
    faLegal,
    faBackspace,
    faFileLines,
    faCamcorder,
    faBox,
    faGridRound2Plus,
    faTelevision,
    faHomeBlank,
    faBook,
    faHeart,
    faListCheck,
    faGamepadModern,
    faBooks,
} from '@fortawesome/pro-light-svg-icons';

export const getMenuIcon = (menu, contextUser) => {
    switch (menu) {
        case 'Disclaimer':
            return faLegal;
            break;
        case 'Switch Profile':
            return faUser;
            break;
        case 'Manual':
            return faBook;
            break;
        case 'Renewal':
            return faCreditCard;
            break;
        case 'Registration':
            return faUser;
            break;
        case 'Settings':
            return faCog;
            break;
        case 'App':
            return faInfoCircle;
            break;
        case 'Pwa':
            return faGridRound2Plus;
            break;
        case 'Back':
            return faBackspace;
            break;
        case 'Subscription':
            return faBoxesPacking;
            break;
        case 'Devices':
            return faLaptopMobile;
            break;
        case 'About':
            return faFileLines;
            break;
        case 'Updates':
            return faDownload;
            break;
        case 'Support':
            return faHeadset;
            break;
        case 'Search':
            return faSearch;
            break;
        case 'Radio':
            return faRadio;
            break;
        case 'Cams':
            return faCameraCctv;
            break;
        case 'Home':
            return faHomeBlank;
            break;
        case 'Channels':
            return faTelevision;
            break;
        case 'Television':
            return faTv;
            break;
        case 'TV Guide':
            return faThList;
            break;
        case 'Recordings':
            return faDotCircle;
            break;
        case 'Movies':
            return faFilm;
            break;
        case 'Shorts':
            return faCamcorder;
            break;
        case 'Series':
            return faVideo;
            break;
        case 'Music':
            return faMusic;
            break;
        case 'Apps':
            return faAndroid;
            break;
        case 'Account':
            return faUser;
            break;
        case 'Logout':
            return faLock;
            break;
        case 'My List':
            return faPlayCircle;
            break;
        case 'Downloads':
            return faDownload;
            break;
        case 'Courses':
            return faSchool;
            break;
        case 'Podcasts':
            return faPodcast;
            break;
        case 'Security':
            return faCameraCctv;
            break;
        case 'Favorites':
            return faHeart;
            break;
        case 'Watchlist':
            return faListCheck;
            break;
        case 'Rentals':
            return faMoneyBill;
            break;
        case 'Purchases':
            return faMoneyBill;
            break;
        case 'Downloads':
            return faDownload;
            break;
        case 'Games':
            return faGamepadModern;
            break;
        case 'Education':
            return faBooks;
            break;
        default:
            return faBox;
            break;
    }
};
