import { Pressable, ScrollView, View, Image } from '@scriptx-com/xtv-toolkit';
import { useFocusEffect } from '@react-navigation/native';
import { isPlatformAndroid, isPlatformIos } from '@rnv/renative';
import moment from 'moment';
import * as React from 'react';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import lang from '../../../application/languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import { useSafeAreaInsets } from '../../../libs/react-native-safe-area-context';
import { sendPageReport } from '../../../reporting/apis/page';
import { pageType } from '../../../reporting/models/apps';
import ScreenWrapper from '../../wrapper';
import { getFocusOptions } from '../../wrapper/helpers/getFocusOptions';
import QRCode from '../../../libs/react-native-qrcode-svg';
import Text from '../../../application/builder/components/text';

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const insets = useSafeAreaInsets();
    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        setShow(true);
    }, [navigation, route]);

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.APPINFO, duration);
            };
        }, [])
    );

    const focusOptions = getFocusOptions(deviceContext, appContext, 'settings-screen');

    return (
        <ScreenWrapper
            style={{ flex: 1 }}
            focusOptions={{
                ...focusOptions,
                group: 'settings-screen',
            }}
        >
            {show && (
                <View style={{ flex: 1, marginLeft: getCorrectWidth(deviceContext, global.menuWidth) }}>
                    <View
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: appContext.application.theme.settings.background_color1,
                            borderRadius: appContext.application.theme.settings.border_radius,
                        }}
                    >
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                fontWeight: appContext.application.authentication?.services?.header.title_bold ? 'bold' : 'normal',
                                color: '#ffffff',
                            }}
                        >
                            {lang.getTranslation(userContext, 'install_apps')}
                        </Text>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, appContext.application.authentication?.services?.header.sub_size),
                                fontWeight: appContext.application.authentication?.services?.header.sub_bold ? 'bold' : 'normal',
                                color: '#ffffff',
                            }}
                        >
                            {lang.getTranslation(userContext, 'install_apps_info').toUpperCase()}
                        </Text>
                    </View>
                    <View
                        style={{ flex: 4, alignItems: 'center', paddingBottom: getCorrectHeight(deviceContext, deviceContext.isTelevision ? 0 : 30) }}
                    >
                        <ScrollView
                            contentContainerStyle={{
                                paddingBottom: insets.bottom + (isPlatformAndroid ? 40 : isPlatformIos ? 120 : 40),
                            }}
                        >
                            <Pressable
                                focusOptions={{
                                    animator: {
                                        type: 'scale',
                                        focus: {
                                            scale: 1.01,
                                        },
                                    },
                                }}
                                style={{
                                    padding: getCorrectWidth(deviceContext, 50),
                                    flexDirection: 'row',
                                    width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth + 20),
                                    backgroundColor: appContext.application.theme.settings.background_color2,
                                    borderRadius: appContext.application.theme.settings.border_radius,

                                    margin: getCorrectWidth(deviceContext, 10),
                                }}
                            >
                                <View style={{ flex: 1 }}>
                                    <View style={{ flex: 1, flexDirection: 'row', marginBottom: 50 }}>
                                        <View style={{ flex: 1, flexDirection: 'row' }}>
                                            <Image
                                                resizeMethod={'scale'}
                                                resizeMode={'contain'}
                                                source={{ uri: require('../../../assets/images/google.png') }}
                                                style={{
                                                    width: getCorrectHeight(deviceContext, 50),
                                                    height: getCorrectHeight(deviceContext, 50),
                                                }}
                                            ></Image>
                                            <Text
                                                style={{
                                                    color: appContext.application.theme.settings.font_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Large'),
                                                    margin: getCorrectWidth(deviceContext, 10),
                                                }}
                                            >
                                                {global.Cart.app_stores.google_text}
                                            </Text>
                                            <View
                                                style={{
                                                    width: getCorrectWidth(deviceContext, 75),
                                                    height: getCorrectWidth(deviceContext, 75),
                                                    backgroundColor: 'white',
                                                    marginRight: 100,
                                                    borderRadius: 5,
                                                    justifyContent: 'center',
                                                    alignContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <QRCode size={getCorrectWidth(deviceContext, 65)} value={global.Cart.app_stores.google_url} />
                                            </View>
                                        </View>
                                        <View style={{ flex: 1, flexDirection: 'row' }}>
                                            <Image
                                                resizeMethod={'scale'}
                                                resizeMode={'contain'}
                                                source={{ uri: require('../../../assets/images/apple.png') }}
                                                style={{
                                                    width: getCorrectHeight(deviceContext, 50),
                                                    height: getCorrectHeight(deviceContext, 50),
                                                }}
                                            ></Image>
                                            <Text
                                                style={{
                                                    color: appContext.application.theme.settings.font_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Large'),
                                                    margin: getCorrectWidth(deviceContext, 10),
                                                }}
                                            >
                                                {global.Cart.app_stores.apple_text}
                                            </Text>
                                            <View
                                                style={{
                                                    width: getCorrectWidth(deviceContext, 75),
                                                    height: getCorrectWidth(deviceContext, 75),
                                                    backgroundColor: 'white',
                                                    borderRadius: 5,
                                                    justifyContent: 'center',
                                                    alignContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <QRCode size={getCorrectWidth(deviceContext, 65)} value={global.Cart.app_stores.apple_urk} />
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ flex: 1, flexDirection: 'row', marginBottom: 50 }}>
                                        <View style={{ flex: 1, flexDirection: 'row' }}>
                                            <Image
                                                resizeMethod={'scale'}
                                                resizeMode={'contain'}
                                                source={{ uri: require('../../../assets/images/amazon.png') }}
                                                style={{
                                                    width: getCorrectHeight(deviceContext, 50),
                                                    height: getCorrectHeight(deviceContext, 50),
                                                }}
                                            ></Image>
                                            <Text
                                                style={{
                                                    color: appContext.application.theme.settings.font_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Large'),
                                                    margin: getCorrectWidth(deviceContext, 10),
                                                }}
                                            >
                                                {global.Cart.app_stores.amazon_text}
                                            </Text>
                                            <View
                                                style={{
                                                    width: getCorrectWidth(deviceContext, 75),
                                                    height: getCorrectWidth(deviceContext, 75),
                                                    backgroundColor: 'white',
                                                    marginRight: 100,
                                                    borderRadius: 5,
                                                    justifyContent: 'center',
                                                    alignContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <QRCode size={getCorrectWidth(deviceContext, 65)} value={global.Cart.app_stores.amazon_url} />
                                            </View>
                                        </View>
                                        <View style={{ flex: 1, flexDirection: 'row' }}>
                                            <Image
                                                resizeMethod={'scale'}
                                                resizeMode={'contain'}
                                                source={{ uri: require('../../../assets/images/vidaa.png') }}
                                                style={{
                                                    width: getCorrectHeight(deviceContext, 50),
                                                    height: getCorrectHeight(deviceContext, 50),
                                                }}
                                            ></Image>
                                            <Text
                                                style={{
                                                    color: appContext.application.theme.settings.font_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Large'),
                                                    margin: getCorrectWidth(deviceContext, 10),
                                                }}
                                            >
                                                {global.Cart.app_stores.vidaa_text}
                                            </Text>
                                            <View
                                                style={{
                                                    width: getCorrectWidth(deviceContext, 75),
                                                    height: getCorrectWidth(deviceContext, 75),
                                                    backgroundColor: 'white',
                                                    borderRadius: 5,
                                                    justifyContent: 'center',
                                                    alignContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <QRCode size={getCorrectWidth(deviceContext, 65)} value={global.Cart.app_stores.vidaa_url} />
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ flex: 1, flexDirection: 'row', marginBottom: 50 }}>
                                        <View style={{ flex: 1, flexDirection: 'row' }}>
                                            <Image
                                                resizeMethod={'scale'}
                                                resizeMode={'contain'}
                                                source={{ uri: require('../../../assets/images/samsung.png') }}
                                                style={{
                                                    width: getCorrectHeight(deviceContext, 50),
                                                    height: getCorrectHeight(deviceContext, 50),
                                                }}
                                            ></Image>
                                            <Text
                                                style={{
                                                    color: appContext.application.theme.settings.font_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Large'),
                                                    margin: getCorrectWidth(deviceContext, 10),
                                                }}
                                            >
                                                {global.Cart.app_stores.samsung_text}
                                            </Text>
                                            <View
                                                style={{
                                                    width: getCorrectWidth(deviceContext, 75),
                                                    height: getCorrectWidth(deviceContext, 75),
                                                    backgroundColor: 'white',
                                                    marginRight: 100,
                                                    borderRadius: 5,
                                                    justifyContent: 'center',
                                                    alignContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <QRCode size={getCorrectWidth(deviceContext, 65)} value={global.Cart.app_stores.samsung_url} />
                                            </View>
                                        </View>
                                        <View style={{ flex: 1, flexDirection: 'row' }}>
                                            <Image
                                                resizeMethod={'scale'}
                                                resizeMode={'contain'}
                                                source={{ uri: require('../../../assets/images/lg.png') }}
                                                style={{
                                                    width: getCorrectHeight(deviceContext, 50),
                                                    height: getCorrectHeight(deviceContext, 50),
                                                }}
                                            ></Image>
                                            <Text
                                                style={{
                                                    color: appContext.application.theme.settings.font_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Large'),
                                                    margin: getCorrectWidth(deviceContext, 10),
                                                }}
                                            >
                                                {global.Cart.app_stores.lg_text}
                                            </Text>
                                            <View
                                                style={{
                                                    width: getCorrectWidth(deviceContext, 75),
                                                    height: getCorrectWidth(deviceContext, 75),
                                                    backgroundColor: 'white',
                                                    borderRadius: 5,
                                                    justifyContent: 'center',
                                                    alignContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <QRCode size={getCorrectWidth(deviceContext, 65)} value={global.Cart.app_stores.lg_url} />
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ flex: 1, flexDirection: 'row', marginBottom: 50 }}>
                                        <View style={{ flex: 1, flexDirection: 'row' }}>
                                            <Image
                                                resizeMethod={'scale'}
                                                resizeMode={'contain'}
                                                source={{ uri: require('../../../assets/images/roku.png') }}
                                                style={{
                                                    width: getCorrectHeight(deviceContext, 50),
                                                    height: getCorrectHeight(deviceContext, 50),
                                                }}
                                            ></Image>
                                            <Text
                                                style={{
                                                    color: appContext.application.theme.settings.font_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Large'),
                                                    margin: getCorrectWidth(deviceContext, 10),
                                                }}
                                            >
                                                {global.Cart.app_stores.roku_text}
                                            </Text>
                                            <View
                                                style={{
                                                    width: getCorrectWidth(deviceContext, 75),
                                                    height: getCorrectWidth(deviceContext, 75),
                                                    backgroundColor: 'white',
                                                    marginRight: 100,
                                                    borderRadius: 5,
                                                    justifyContent: 'center',
                                                    alignContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <QRCode size={getCorrectWidth(deviceContext, 65)} value={global.Cart.app_stores.roku_url} />
                                            </View>
                                        </View>
                                        <View style={{ flex: 1, flexDirection: 'row' }}>
                                            <Image
                                                resizeMethod={'scale'}
                                                resizeMode={'contain'}
                                                source={{ uri: require('../../../assets/images/apk.png') }}
                                                style={{
                                                    width: getCorrectHeight(deviceContext, 50),
                                                    height: getCorrectHeight(deviceContext, 50),
                                                }}
                                            ></Image>
                                            <Text
                                                style={{
                                                    color: appContext.application.theme.settings.font_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Large'),
                                                    margin: getCorrectWidth(deviceContext, 10),
                                                }}
                                            >
                                                {global.Cart.app_stores.apk_text}
                                            </Text>
                                            <View
                                                style={{
                                                    width: getCorrectWidth(deviceContext, 75),
                                                    height: getCorrectWidth(deviceContext, 75),
                                                    backgroundColor: 'white',
                                                    borderRadius: 5,
                                                    justifyContent: 'center',
                                                    alignContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <QRCode size={getCorrectWidth(deviceContext, 65)} value={global.Cart.app_stores.apk_url} />
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </Pressable>
                        </ScrollView>
                    </View>
                </View>
            )}
        </ScreenWrapper>
    );
};
export default Screen_;
