import * as React from 'react';
import { Image, View, Pressable } from '@scriptx-com/xtv-toolkit';
import { getFontSize } from '../../../styling/fontSizes';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { useEffectOnce } from '../../../hooks/useEffectOnce';
import { DeviceContext } from '../../../context/deviceContext';
import { AppContext } from '../../../context/appContext';
import { UserContext } from '../../../context/userContext';
import moment from 'moment';
import Text from '../../components/text';
import { getCorrectName } from '../players/helpers/functions';
import { getFocusStyling, getMessageStyling, getPressableStyling } from '../../components/helpers/helper';
import { getCorrectLeftMargin } from '../../../styling/appLeftMargin';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
export interface Data {
    image: string;
    title: string;
    sub: string;
}

export interface Props {
    styling: any;
    focusContext?: any;
}

export const Promotions_Messages = ({ styling, focusContext }: Props) => {
    const [show, setShow] = React.useState(false);
    const [data, setData] = React.useState([] as any);
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const screenName = getCurrentScreenName();

    const getMessage = async () => {
        if (styling.translations != undefined) {
            var desc = getCorrectName(styling.translations, userContext);
            setData({
                image: styling.translations.image,
                title: styling.translations.name,
                sub: styling.translations.date,
                description: desc,
            });
            setShow(true);
        }
    };

    useEffectOnce(() => {
        getMessage();
    });

    return (
        <View focusContext={focusContext}>
            {show && (
                <View
                    style={{
                        backgroundColor: getMessageStyling(appContext).backgroundColor,
                        marginLeft: getCorrectLeftMargin(deviceContext, appContext, screenName),
                        marginRight: getCorrectLeftMargin(deviceContext, appContext, screenName),
                        marginTop: getCorrectHeight(deviceContext, appContext.application.theme.rails_top_margin),
                        marginBottom: getCorrectHeight(deviceContext, appContext.application.theme.rails_bottom_margin),
                        borderRadius:getMessageStyling(appContext).borderRadius
                    }}
                >
                    <Pressable
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        hasTVPreferredFocus={false}
                        style={{

                            ...getMessageStyling(appContext),
                            flexDirection: 'row',
                        }}
                    >
                        <>
                            {data.image != '' && !deviceContext.isPhone && !deviceContext.isPwaVertical && !deviceContext.isKaiOs && (
                                <View
                                    style={{
                                        margin: appContext.application.theme.images.margin,
                                    }}
                                >
                                    <Image
                                        resizeMode={'contain'}
                                        source={{ uri: appContext.cloudUrl + data.image }}
                                        style={{
                                            height: getCorrectHeight(deviceContext, 90),
                                            width: getCorrectWidth(deviceContext, 160),
                                            borderRadius:getMessageStyling(appContext).borderRadius
                                        }}
                                    ></Image>
                                </View>
                            )}
                            <View
                                style={{
                                    flex: 1,
                                    margin: getCorrectHeight(deviceContext, 8),
                                }}
                            >
                                <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: getCorrectHeight(deviceContext, 2.5) }}>
                                    <View style={{ flex: 1 }}>
                                        <Text
                                            style={{
                                                color: getMessageStyling(appContext).color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                                fontWeight: styling.texts.title_bold ? 'bold' : null,
                                            }}
                                        >
                                            {data.title}
                                        </Text>
                                    </View>
                                    <View style={{ flex: 1, alignItems: 'flex-end' }}>
                                        <Text
                                            style={{
                                                color: getMessageStyling(appContext).color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            {moment(data.sub).format('ll')}
                                        </Text>
                                    </View>
                                </View>
                                <Text
                                    style={{
                                        color: getMessageStyling(appContext).color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                    }}
                                >
                                    {data.description}
                                </Text>
                            </View>
                        </>
                    </Pressable>
                </View>
            )}
        </View>
    );
};

export default Promotions_Messages;
