import { FocusContext, View, Image } from '@scriptx-com/xtv-toolkit';
import { faChromecast } from '@fortawesome/free-brands-svg-icons';
import {
    faCog,
    faHistory,
    faAirplay,
    faChevronDown,
    faArrowLeft,
    faHeart,
    faPause,
    faPlay,
    faStepBackward,
    faWindowRestore,
    faStepForward,
    faRectangleHistory,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faLaptopBinary,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as React from 'react';
import { AppContext } from '../../../context/appContext';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { PlayerContext } from '../../../context/playerContext';
import { UserContext } from '../../../context/userContext';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import lang from '../../../languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import Pressable from '../../components/pressable/pressable';
import { getImage, getLogo, getSubSubText, getSubSubTextNext, getSubText, getSubTextNext, getTitle, getTitleNext } from '../../elements/players/helpers/functions';
import { ProgressBars } from './helpers/progress';
import Text from '../../components/text';
import { getFocusStyling, getPressableStyling, getPressableTextStyling } from '../../components/helpers/helper';
import { getLiveTag, getReplayTag } from './helpers/helpers';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { RemoteImageHeightWidth } from '../../components/remoteimage/remoteimage';
import { getRealWidth } from '../../../styling/realDeviceSizes';
import moment from 'moment';

export interface Props {
    navigation: any;
    focusContext?: FocusContext;
    styling: any;
    onPrevious: any;
    onNext: any;
    showChannels: any;
    showEpisodes: any;
    showLessons: any;
    showShorts: any;
    showSettings: any;
    openChromecast: any;
    openAirplay: any;
    manageFavorite: any;
    onPlayPause: any;
    startPiP: any;
    showSupport: any;
    onScrubberDrag: any;
    onRestart: any;
    onForward: any;
    onRewind: any;
    isFavorite: boolean;
    isRestart: boolean;
    onBack: any;
    onClose: any;
    onPreviousCatchupTV: any;
    paused: boolean;
    onTest: any;
}

export const Player_Standard = ({
    styling,
    onPrevious,
    onNext,
    onRewind,
    onForward,
    showChannels,
    showEpisodes,
    showShorts,
    showLessons,
    showSettings,
    openChromecast,
    openAirplay,
    manageFavorite,
    onPlayPause,
    startPiP,
    onScrubberDrag,
    onRestart,
    isRestart,
    isFavorite,
    onBack,
    onClose,
    focusContext,
    paused,
    onTest,
}: Props) => {
    const appContext = React.useContext(AppContext);
    const playerContext = React.useContext(PlayerContext);
    const contentContext = React.useContext(ContentContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const screenName = getCurrentScreenName();

    const [showNextView, setShowNextView] = React.useState(false);
    const [showPreviousView, setShowPreviousView] = React.useState(false);

    const showPrevious = () => {
        setShowPreviousView(true);
    };
    const hidePrevious = () => {
        setShowPreviousView(false);
    };
    const showNext = () => {
        setShowNextView(true);
    };
    const hideNext = () => {
        setShowNextView(false);
    };

    const getDescription = (item) => {
        var test = item.translations?.find((trans) => trans.name == userContext.selectedLanguage);
        if (test != undefined) {
            return test?.description;
        } else {
            if (item?.translations != undefined) {
                return item?.translations[0]?.description;
            } else {
                return '';
            }
        }
    };

    const getPrevious = () => {
        switch (screenName) {
            case 'Channel_Player':
                var index = contentContext.channelData.channelIndex == 0 ? contentContext.channelData.channelList.length - 1 : contentContext.channelData.channelIndex - 1;
                var channel = contentContext.channelData.channelList[index];
                return (
                    <View style={{ flex: 1, marginTop: getCorrectHeight(deviceContext, 40) }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'previous_channel')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.2,
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: getCorrectHeight(deviceContext, 10),
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'contain'}
                                source={{ uri: appContext.cloudUrl + channel.images?.square }}
                                style={{
                                    width: getCorrectHeight(deviceContext, 30),
                                    height: getCorrectHeight(deviceContext, 30),
                                }}
                            ></Image>
                            <View style={{ marginLeft: 20, width: getRealWidth(deviceContext) * 0.12 }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {channel.number}
                                </Text>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {channel.name}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            case 'CatchupTV_Player':
                var index = contentContext.channelData.programIndex == 0 ? contentContext.channelData.programList.length - 1 : contentContext.channelData.programIndex - 1;
                var program = contentContext.channelData.programList[index];
                var channel = contentContext.channelData.channel;
                return (
                    <View style={{ flex: 1, marginTop: getCorrectHeight(deviceContext, 40) }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'previous_programs')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.22,
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: getCorrectHeight(deviceContext, 10),
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'contain'}
                                source={{ uri: appContext.cloudUrl + channel.images?.square }}
                                style={{
                                    width: getCorrectHeight(deviceContext, 30),
                                    height: getCorrectHeight(deviceContext, 30),
                                }}
                            ></Image>
                            <View style={{ marginLeft: 20, width: getRealWidth(deviceContext) * 0.15 }}>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {program.n}
                                </Text>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {moment.unix(program?.s).format('ddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format('ddd ' + deviceContext.clockSetting)}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            case 'Cam_Player':
                var index = contentContext.camData.camIndex == 0 ? contentContext.camData.camList.length - 1 : contentContext.camData.camIndex - 1;
                var cam = contentContext.camData.camList[index];
                return (
                    <View style={{ flex: 1, marginTop: getCorrectHeight(deviceContext, 40) }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'previous_cam')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.2,
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: getCorrectHeight(deviceContext, 10),
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'contain'}
                                source={{ uri: appContext.cloudUrl + cam.images?.square }}
                                style={{
                                    width: getCorrectHeight(deviceContext, 30),
                                    height: getCorrectHeight(deviceContext, 30),
                                }}
                            ></Image>
                            <View style={{ marginLeft: 20, width: getRealWidth(deviceContext) * 0.12 }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {cam.number}
                                </Text>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {cam.name}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            case 'Series_Player':
                var index = contentContext.seriesData.episodeIndex - 1;
                if (index < 0) {
                    return null;
                }
                var episode = contentContext.seriesData.episodeList[index];
                return (
                    <View style={{ flex: 1 }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'previous_episode')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.4,
                                flexDirection: 'row',
                                padding: 10,
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'cover'}
                                source={{ uri: appContext.cloudUrl + episode.images?.widescreen }}
                                style={{
                                    width: getCorrectWidth(deviceContext, 130),
                                    height: getCorrectWidth(deviceContext, 72),
                                }}
                            ></Image>
                            <View style={{ flex: 1, marginHorizontal: 20, justifyContent: 'flex-start' }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                        marginBottom: 5,
                                    }}
                                >
                                    {episode.number}. {episode.name}
                                </Text>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getDescription(episode)}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            case 'Shorts_Player':
                var index = contentContext.shortData.shortIndex - 1;
                if (index < 0) {
                    return null;
                }
                var short = contentContext.shortData.shortList[index];
                return (
                    <View style={{ flex: 1 }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'previous_short')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.4,
                                flexDirection: 'row',
                                padding: 10,
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'cover'}
                                source={{ uri: appContext.cloudUrl + short.images?.widescreen }}
                                style={{
                                    width: getCorrectWidth(deviceContext, 130),
                                    height: getCorrectWidth(deviceContext, 72),
                                }}
                            ></Image>
                            <View style={{ flex: 1, marginHorizontal: 20, justifyContent: 'flex-start' }}>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                        marginBottom: 5,
                                    }}
                                >
                                    {short.name}
                                </Text>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getDescription(short)}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            case 'Course_Player':
                var index = contentContext.courseData.lessonIndex - 1;
                if (index < 0) {
                    return null;
                }
                var lesson = contentContext.courseData.lessonList[index];
                return (
                    <View style={{ flex: 1 }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'previous_lesson')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.4,
                                flexDirection: 'row',
                                padding: 10,
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'cover'}
                                source={{ uri: appContext.cloudUrl + lesson.images?.widescreen }}
                                style={{
                                    width: getCorrectWidth(deviceContext, 130),
                                    height: getCorrectWidth(deviceContext, 72),
                                }}
                            ></Image>
                            <View style={{ flex: 1, marginHorizontal: 20, justifyContent: 'flex-start' }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                        marginBottom: 5,
                                    }}
                                >
                                    {lesson.name}
                                </Text>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getDescription(lesson)}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            default:
                return '';
                break;
        }
    };
    const getNext = () => {
        switch (screenName) {
            case 'Channel_Player':
                var index = contentContext.channelData.channelIndex == contentContext.channelData.channelList.length - 1 ? 0 : contentContext.channelData.channelIndex + 1;
                var channel = contentContext.channelData.channelList[index];
                return (
                    <View style={{ flex: 1, marginTop: getCorrectHeight(deviceContext, 40) }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'next_channel')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.2,
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: getCorrectHeight(deviceContext, 10),
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'contain'}
                                source={{ uri: appContext.cloudUrl + channel.images?.square }}
                                style={{
                                    width: getCorrectHeight(deviceContext, 30),
                                    height: getCorrectHeight(deviceContext, 30),
                                }}
                            ></Image>
                            <View style={{ marginLeft: 20, width: getRealWidth(deviceContext) * 0.12 }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {channel.number}
                                </Text>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {channel.name}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            case 'CatchupTV_Player':
                var index = contentContext.channelData.programIndex == contentContext.channelData.programList.length - 1 ? 0 : contentContext.channelData.programIndex + 1;
                var program = contentContext.channelData.programList[index];
                var channel = contentContext.channelData.channel;
                return (
                    <View style={{ flex: 1, marginTop: getCorrectHeight(deviceContext, 40) }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'next_programs')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.2,
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: getCorrectHeight(deviceContext, 10),
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'contain'}
                                source={{ uri: appContext.cloudUrl + channel.images?.square }}
                                style={{
                                    width: getCorrectHeight(deviceContext, 30),
                                    height: getCorrectHeight(deviceContext, 30),
                                }}
                            ></Image>
                            <View style={{ marginLeft: 20, width: getRealWidth(deviceContext) * 0.15 }}>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {program.n}
                                </Text>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {moment.unix(program?.s).format('ddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format('ddd ' + deviceContext.clockSetting)}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            case 'Cam_Player':
                var index = contentContext.camData.camIndex == contentContext.camData.camList.length - 1 ? 0 : contentContext.camData.camIndex + 1;
                var cam = contentContext.camData.camList[index];
                return (
                    <View style={{ flex: 1, marginTop: getCorrectHeight(deviceContext, 40) }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'next_cam')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.2,
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: getCorrectHeight(deviceContext, 10),
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'contain'}
                                source={{ uri: appContext.cloudUrl + cam.images?.square }}
                                style={{
                                    width: getCorrectHeight(deviceContext, 30),
                                    height: getCorrectHeight(deviceContext, 30),
                                }}
                            ></Image>
                            <View style={{ marginLeft: 20, width: getRealWidth(deviceContext) * 0.12 }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {cam.number}
                                </Text>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {cam.name}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            case 'Series_Player':
                var index = contentContext.seriesData.episodeIndex + 1 <= contentContext.seriesData.episodeList.length - 1 ? contentContext.seriesData.episodeIndex + 1 : -1;
                if (index == -1) {
                    return null;
                }
                var episode = contentContext.seriesData.episodeList[index];
                if(episode == undefined){
                    return null
                }
                return (
                    <View style={{ flex: 1 }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'next_episode')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.4,
                                flexDirection: 'row',
                                padding: 10,
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'cover'}
                                source={{ uri: appContext.cloudUrl + episode.images?.widescreen }}
                                style={{
                                    width: getCorrectWidth(deviceContext, 130),
                                    height: getCorrectWidth(deviceContext, 72),
                                }}
                            ></Image>
                            <View style={{ flex: 1, marginHorizontal: 20, justifyContent: 'flex-start' }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                        marginBottom: 5,
                                    }}
                                >
                                    {episode.number}. {episode.name}
                                </Text>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getDescription(episode)}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            case 'Shorts_Player':
                var index = contentContext.shortData.shortIndex + 1 <= contentContext.shortData.shortList.length - 1 ? contentContext.shortData.shortIndex + 1 : -1;
                if (index == -1) {
                    return null;
                }
                var short = contentContext.shortData.shortList[index];
                if(short == undefined){
                    return null
                }
                return (
                    <View style={{ flex: 1 }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'next_shorts')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.4,
                                flexDirection: 'row',
                                padding: 10,
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'cover'}
                                source={{ uri: appContext.cloudUrl + short.images?.widescreen }}
                                style={{
                                    width: getCorrectWidth(deviceContext, 130),
                                    height: getCorrectWidth(deviceContext, 72),
                                }}
                            ></Image>
                            <View style={{ flex: 1, marginHorizontal: 20, justifyContent: 'flex-start' }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                        marginBottom: 5,
                                    }}
                                >
                                    {short.name}
                                </Text>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getDescription(short)}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            case 'Course_Player':
                var index = contentContext.courseData.lessonIndex + 1 <= contentContext.courseData.lessonList.length - 1 ? contentContext.courseData.lessonIndex + 1 : -1;
                if (index == -1) {
                    return null;
                }
                var lesson = contentContext.courseData.lessonList[index];
                if(lesson == undefined){
                    return null
                }
                return (
                    <View style={{ flex: 1 }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'next_lessons')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.4,
                                flexDirection: 'row',
                                padding: 10,
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'cover'}
                                source={{ uri: appContext.cloudUrl + lesson.images?.widescreen }}
                                style={{
                                    width: getCorrectWidth(deviceContext, 130),
                                    height: getCorrectWidth(deviceContext, 72),
                                }}
                            ></Image>
                            <View style={{ flex: 1, marginHorizontal: 20, justifyContent: 'flex-start' }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                        marginBottom: 5,
                                    }}
                                >
                                    {lesson.name}
                                </Text>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getDescription(lesson)}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            default:
                return '';
                break;
        }
    };
    const onNextLocal = () => {
        onNext(screenName);
    };
    const onPreviousLocal = () => {
        onPrevious(screenName);
    };

    return (
        <View style={{ flex: 1, padding: getCorrectWidth(deviceContext, 20) }} focusContext={focusContext}>
            <View style={{ flex: 2, flexDirection: 'row' }}>
                <View style={{ flex: 1, flexDirection: 'row' }}>
                    <View style={{ flexDirection: 'row', height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 35 : 50) }}>
                        {(screenName == 'Channel_Player' || screenName == 'Cam_Player' || screenName == 'CatchupTV_Player' || screenName == 'Recording_Player') && (
                            <View>{getImage(contentContext, screenName, styling, deviceContext, appContext)}</View>
                        )}
                        {getLogo(contentContext, screenName) == '' && (
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        marginLeft: getCorrectWidth(deviceContext, 10),
                                        color: styling.texts.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'ExtraLarge' : 'ExtraExtraLarge'),
                                        fontWeight: 'normal',
                                        lineHeight: getFontSize(deviceContext, deviceContext.isPhone ? 'ExtraLarge' : 'ExtraExtraLarge'),
                                    }}
                                >
                                    {getTitle(contentContext, screenName)}
                                </Text>
                            </View>
                        )}
                    </View>
                    {getLogo(contentContext, screenName) != '' && (
                        <View style={{ flexDirection: 'row', alignItems: 'center', height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 35 : 50) }}>
                            <RemoteImageHeightWidth uri={appContext.cloudUrl + getLogo(contentContext, screenName)} desiredWidth={getRealWidth(deviceContext) * 0.15} desiredHeight={50} deviceContext={deviceContext} />
                        </View>
                    )}
                </View>
                <View style={{ flex: 1, alignItems: 'flex-end' }}>
                    {screenName == 'CatchupTV_Player' && (
                        <View style={{ position: 'absolute', top: 0, right: 0, backgroundColor: 'royalblue', borderRadius: appContext.application.theme.tags.radius / 2 }}>
                            <Text
                                numberOfLines={1}
                                style={{
                                    color: '#fff',
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                    fontWeight: 'normal',
                                    lineHeight: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                    margin: deviceContext.isPhone ? 5 : 5,
                                }}
                            >
                                {lang.getTranslation(userContext, 'replay').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {screenName == 'Channel_Player' && (
                        <View style={{ position: 'absolute', top: 0, right: 0, backgroundColor: 'crimson', borderRadius: appContext.application.theme.tags.radius / 2 }}>
                            <Text
                                numberOfLines={1}
                                style={{
                                    color: '#fff',
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                    fontWeight: 'normal',
                                    lineHeight: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                    margin: deviceContext.isPhone ? 5 : 5,
                                }}
                            >
                                {lang.getTranslation(userContext, 'live').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {screenName == 'Recording_Player' && (
                        <View style={{ position: 'absolute', top: 0, right: 0, backgroundColor: 'crimson', borderRadius: appContext.application.theme.tags.radius / 2 }}>
                            <Text
                                numberOfLines={1}
                                style={{
                                    color: '#fff',
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                    fontWeight: 'normal',
                                    lineHeight: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                    margin: deviceContext.isPhone ? 5 : 5,
                                }}
                            >
                                {lang.getTranslation(userContext, 'recording').toUpperCase()}
                            </Text>
                        </View>
                    )}

                    {showPreviousView ? getPrevious() : null}
                    {showNextView ? getNext() : null}
                </View>
            </View>
            <View style={{ flex: 2 }}></View>
            <View style={{ flex: 1.5, flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 10), marginHorizontal: 5 }}>
                {screenName == 'Channel_Player' && (
                    <View style={{ flex: 1.5, justifyContent: 'flex-end' }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                marginTop: getCorrectHeight(deviceContext, 3),
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'current_program')}
                        </Text>
                        <View
                            style={{
                                padding: getCorrectHeight(deviceContext, 10),
                                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                marginTop: getCorrectHeight(deviceContext, 3),
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <View style={{ flexDirection: 'row', marginTop: getCorrectHeight(deviceContext, 3) }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        lineHeight: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getSubSubText(contentContext, screenName)}
                                </Text>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: getCorrectHeight(deviceContext, 3) }}>
                                {contentContext.channelData.channel.interactivetv.catchuptv_enabled == true && (
                                    <View style={{ justifyContent: 'center' }}>{getLiveTag(lang.getTranslation(userContext, 'live').toUpperCase(), appContext, deviceContext)}</View>
                                )}
                                {contentContext.channelData.channel.interactivetv.catchuptv_enabled == true && (
                                    <View style={{ justifyContent: 'center', marginRight: 5 }}>{getReplayTag(lang.getTranslation(userContext, 'replay').toUpperCase(), appContext, deviceContext)}</View>
                                )}
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        lineHeight: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getSubText(contentContext, screenName, deviceContext)}
                                </Text>
                            </View>
                        </View>
                    </View>
                )}
                {screenName == 'CatchupTV_Player' && (
                    <View style={{ flex: 1.5, justifyContent: 'flex-end' }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                marginTop: getCorrectHeight(deviceContext, 3),
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'current_program')}
                        </Text>
                        <View
                            style={{
                                padding: getCorrectHeight(deviceContext, 10),
                                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                marginTop: getCorrectHeight(deviceContext, 3),
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <View style={{ flexDirection: 'row', marginTop: getCorrectHeight(deviceContext, 3) }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        lineHeight: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getSubSubText(contentContext, screenName)}
                                </Text>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: getCorrectHeight(deviceContext, 3) }}>
                                {contentContext.channelData.channel.interactivetv.catchuptv_enabled == true && (
                                    <View style={{ justifyContent: 'center' }}>{getLiveTag(lang.getTranslation(userContext, 'live').toUpperCase(), appContext, deviceContext)}</View>
                                )}
                                {contentContext.channelData.channel.interactivetv.catchuptv_enabled == true && (
                                    <View style={{ justifyContent: 'center', marginRight: 5 }}>{getReplayTag(lang.getTranslation(userContext, 'replay').toUpperCase(), appContext, deviceContext)}</View>
                                )}
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        lineHeight: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getSubText(contentContext, screenName, deviceContext)}
                                </Text>
                            </View>
                        </View>
                    </View>
                )}
                {screenName == 'Cam_Player' && (
                    <View style={{ flex: 1.5, justifyContent: 'flex-end' }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                marginTop: getCorrectHeight(deviceContext, 3),
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'current_program')}
                        </Text>
                        <View
                            style={{
                                padding: getCorrectHeight(deviceContext, 10),
                                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                marginTop: getCorrectHeight(deviceContext, 3),
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <View style={{ flexDirection: 'row', marginTop: getCorrectHeight(deviceContext, 3) }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        lineHeight: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getSubSubText(contentContext, screenName)}
                                </Text>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: getCorrectHeight(deviceContext, 3) }}>
                                {contentContext.camData.cam.interactivetv.catchuptv_enabled == true && (
                                    <View style={{ justifyContent: 'center' }}>{getLiveTag(lang.getTranslation(userContext, 'live').toUpperCase(), appContext, deviceContext)}</View>
                                )}
                                {contentContext.camData.cam.interactivetv.catchuptv_enabled == true && (
                                    <View style={{ justifyContent: 'center', marginRight: 5 }}>{getReplayTag(lang.getTranslation(userContext, 'replay').toUpperCase(), appContext, deviceContext)}</View>
                                )}
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        lineHeight: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getSubText(contentContext, screenName, deviceContext)}
                                </Text>
                            </View>
                        </View>
                    </View>
                )}
                {screenName == 'Series_Player' && (
                    <View style={{ flex: 1.5, justifyContent: 'flex-end' }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                marginTop: getCorrectHeight(deviceContext, 3),
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'current_episode')}
                        </Text>
                        <View
                            style={{
                                padding: getCorrectHeight(deviceContext, 10),
                                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                marginTop: getCorrectHeight(deviceContext, 3),
                                borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius ?? 0,
                            }}
                        >
                            <View style={{ marginTop: getCorrectHeight(deviceContext, 3) }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        lineHeight: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getSubText(contentContext, screenName, deviceContext)}
                                </Text>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        lineHeight: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getDescription(contentContext.seriesData.episode)}
                                </Text>
                            </View>
                        </View>
                    </View>
                )}
                {screenName == 'Course_Player' && (
                    <View style={{ flex: 1.5, justifyContent: 'flex-end' }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                marginTop: getCorrectHeight(deviceContext, 3),
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'current_lesson')}
                        </Text>
                        <View
                            style={{
                                padding: getCorrectHeight(deviceContext, 10),
                                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                marginTop: getCorrectHeight(deviceContext, 3),
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <View style={{ marginTop: getCorrectHeight(deviceContext, 3) }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        lineHeight: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getSubText(contentContext, screenName, deviceContext)}
                                </Text>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        lineHeight: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getDescription(contentContext.courseData.lesson)}
                                </Text>
                            </View>
                        </View>
                    </View>
                )}
                {screenName == 'Short_Player' && (
                    <View style={{ flex: 1.5, justifyContent: 'flex-end' }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                marginTop: getCorrectHeight(deviceContext, 3),
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'current_short')}
                        </Text>
                        <View
                            style={{
                                padding: getCorrectHeight(deviceContext, 10),
                                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                marginTop: getCorrectHeight(deviceContext, 3),
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <View style={{ marginTop: getCorrectHeight(deviceContext, 3) }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        lineHeight: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getSubText(contentContext, screenName, deviceContext)}
                                </Text>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        lineHeight: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getDescription(contentContext.shortData.short)}
                                </Text>
                            </View>
                        </View>
                    </View>
                )}
                {screenName == 'Channel_Player' && (
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'flex-end', marginLeft: 10 }}>
                        <View style={{ flex: 1 }}>
                            <Text
                                numberOfLines={1}
                                style={{
                                    marginTop: getCorrectHeight(deviceContext, 3),
                                    opacity: 0.6,
                                    color: styling.texts.subtext_color,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    lineHeight: getFontSize(deviceContext, 'Small'),
                                    fontWeight: 'bold',
                                }}
                            >
                                {lang.getTranslation(userContext, 'next_programs')}
                            </Text>
                            <View
                                style={{
                                    padding: getCorrectHeight(deviceContext, 10),
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    marginTop: getCorrectHeight(deviceContext, 3),
                                    borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                }}
                            >
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        lineHeight: getFontSize(deviceContext, 'Normal'),
                                    }}
                                >
                                    {getSubSubTextNext(contentContext, screenName, userContext)}
                                </Text>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        marginTop: getCorrectHeight(deviceContext, 3),
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        lineHeight: getFontSize(deviceContext, 'Small'),
                                    }}
                                >
                                    {getSubTextNext(contentContext, screenName, deviceContext)}
                                </Text>
                            </View>
                        </View>
                    </View>
                )}
                {screenName == 'CatchupTV_Player' && (
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'flex-end', marginLeft: 10 }}>
                        <View style={{ flex: 1 }}>
                            <Text
                                numberOfLines={1}
                                style={{
                                    opacity: 0.6,
                                    color: styling.texts.subtext_color,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    lineHeight: getFontSize(deviceContext, 'Small'),
                                    fontWeight: 'bold',
                                }}
                            >
                                {lang.getTranslation(userContext, 'next_programs')}
                            </Text>
                            <View
                                style={{
                                    padding: getCorrectHeight(deviceContext, 5),
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    marginTop: getCorrectHeight(deviceContext, 3),
                                    borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                }}
                            >
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        lineHeight: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getSubSubTextNext(contentContext, screenName, userContext)}
                                </Text>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        marginTop: getCorrectHeight(deviceContext, 3),
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        lineHeight: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getSubTextNext(contentContext, screenName, deviceContext)}
                                </Text>
                            </View>
                        </View>
                    </View>
                )}
                {screenName == 'Cam_Player' &&  (
                    <View style={{ flex: 1, flexDirection: 'row', marginLeft: 10, alignItems: 'flex-end' }}>
                        <View style={{ flex: 1 }}>
                            <Text
                                numberOfLines={1}
                                style={{
                                    opacity: 0.6,
                                    color: styling.texts.subtext_color,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    lineHeight: getFontSize(deviceContext, 'Small'),
                                    fontWeight: 'bold',
                                }}
                            >
                                {lang.getTranslation(userContext, 'next_programs')}
                            </Text>
                            <View
                                style={{
                                    padding: getCorrectHeight(deviceContext, 5),
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    marginTop: getCorrectHeight(deviceContext, 3),
                                    borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                }}
                            >
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        lineHeight: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getSubSubTextNext(contentContext, screenName, userContext)}
                                </Text>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        marginTop: getCorrectHeight(deviceContext, 3),
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        lineHeight: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getSubTextNext(contentContext, screenName, deviceContext)}
                                </Text>
                            </View>
                        </View>
                    </View>
                )}
                {screenName == 'Series_Player' && getTitleNext(contentContext, screenName, userContext) != '' && (
                    <View style={{ flex: 1, flexDirection: 'row', marginLeft: 10, alignItems: 'flex-end' }}>
                        <View style={{ flex: 1 }}>
                            <Text
                                numberOfLines={1}
                                style={{
                                    opacity: 0.6,
                                    color: styling.texts.subtext_color,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    lineHeight: getFontSize(deviceContext, 'Small'),
                                    fontWeight: 'bold',
                                }}
                            >
                                {lang.getTranslation(userContext, 'next_episode')}
                            </Text>
                            <View
                                style={{
                                    flex: 1,
                                    padding: getCorrectHeight(deviceContext, 5),
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    marginTop: getCorrectHeight(deviceContext, 3),
                                    borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                }}
                            >
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        lineHeight: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getTitleNext(contentContext, screenName, userContext)}
                                </Text>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        marginTop: getCorrectHeight(deviceContext, 3),
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        lineHeight: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getSubSubTextNext(contentContext, screenName, userContext)}
                                </Text>
                            </View>
                        </View>
                    </View>
                )}
                {screenName == 'Course_Player' && getTitleNext(contentContext, screenName, userContext) != '' && (
                    <View style={{ flex: 1, flexDirection: 'row', marginLeft: 10, alignItems: 'flex-end' }}>
                        <View style={{ flex: 1 }}>
                            <Text
                                numberOfLines={1}
                                style={{
                                    opacity: 0.6,
                                    color: styling.texts.subtext_color,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    lineHeight: getFontSize(deviceContext, 'Small'),
                                    fontWeight: 'bold',
                                }}
                            >
                                {lang.getTranslation(userContext, 'next_lesson')}
                            </Text>
                            <View
                                style={{
                                    padding: getCorrectHeight(deviceContext, 5),
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    marginTop: getCorrectHeight(deviceContext, 3),
                                    borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                }}
                            >
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        lineHeight: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getTitleNext(contentContext, screenName, userContext)}
                                </Text>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        marginTop: getCorrectHeight(deviceContext, 3),
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        lineHeight: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getSubSubTextNext(contentContext, screenName, userContext)}
                                </Text>
                            </View>
                        </View>
                    </View>
                )}
                {screenName == 'Short_Player' && getTitleNext(contentContext, screenName, userContext) != '' && (
                    <View style={{ flex: 1, flexDirection: 'row', marginLeft: 10, alignItems: 'flex-end' }}>
                        <View style={{ flex: 1 }}>
                            <Text
                                numberOfLines={1}
                                style={{
                                    opacity: 0.6,
                                    color: styling.texts.subtext_color,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    lineHeight: getFontSize(deviceContext, 'Small'),
                                    fontWeight: 'bold',
                                }}
                            >
                                {lang.getTranslation(userContext, 'next_short')}
                            </Text>
                            <View
                                style={{
                                    padding: getCorrectHeight(deviceContext, 5),
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    marginTop: getCorrectHeight(deviceContext, 3),
                                    borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                }}
                            >
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        lineHeight: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getTitleNext(contentContext, screenName, userContext)}
                                </Text>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        marginTop: getCorrectHeight(deviceContext, 3),
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        lineHeight: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getSubSubTextNext(contentContext, screenName, userContext)}
                                </Text>
                            </View>
                        </View>
                    </View>
                )}
            </View>
            <View style={{ alignItems: 'center', justifyContent: 'center', marginBottom: getCorrectHeight(deviceContext, deviceContext.isPhone ? 3 : 7) }}>
                <ProgressBars
                    onClose={() => {
                        onClose();
                    }}
                    playerContext={playerContext}
                    contentContext={contentContext}
                    onScrubberDrag={onScrubberDrag}
                    deviceContext={deviceContext}
                    userContext={userContext}
                    appContext={appContext}
                    focusContext={focusContext}
                />
            </View>
            <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1, flexDirection: 'row' }}>
                    <ButtonIcon focusContext={focusContext} icon={faArrowLeft} text={'back'} deviceContext={deviceContext} styling={styling} appContext={appContext} theFunction={onBack} userContext={userContext} casting={undefined} />
                    {/* {getButtonIcon(faLaptopBinary, 'test', deviceContext, styling, appContext, onTest, userContext, null, null)} */}

                    <ButtonIcon icon={faChevronDown} text={'hide'} deviceContext={deviceContext} styling={styling} appContext={appContext} theFunction={onClose} userContext={userContext} casting={undefined} />

                    {screenName != 'Movie_Player' && screenName != 'Recording_Player' ? (
                        <ButtonIcon
                            focusContext={focusContext}
                            icon={faStepBackward}
                            text={'previous'}
                            deviceContext={deviceContext}
                            styling={styling}
                            appContext={appContext}
                            theFunction={onPreviousLocal}
                            userContext={userContext}
                            showFunction={showPrevious}
                            hideFunction={hidePrevious}
                            casting={undefined}
                        />
                    ) : null}

                    {screenName != 'Movie_Player' && screenName != 'Recording_Player' ? (
                        <ButtonIcon
                            focusContext={focusContext}
                            icon={faStepForward}
                            text={'next'}
                            deviceContext={deviceContext}
                            styling={styling}
                            appContext={appContext}
                            theFunction={onNextLocal}
                            userContext={userContext}
                            showFunction={showNext}
                            hideFunction={hideNext}
                            casting={undefined}
                        />
                    ) : null}
                    {screenName != 'Channel_Player' && screenName != 'Cam_Player' && !deviceContext.isPhone ? (
                        <ButtonIcon focusContext={focusContext} icon={faChevronDoubleLeft} text={'rewind'} deviceContext={deviceContext} styling={styling} appContext={appContext} theFunction={onRewind} userContext={userContext} casting={undefined} />
                    ) : null}

                    {screenName == 'Channel_Player' && contentContext.channelData.channel.interactivetv.catchuptv_enabled && !deviceContext.isPhone ? (
                        <ButtonIcon focusContext={focusContext} icon={faChevronDoubleLeft} text={'rewind'} deviceContext={deviceContext} styling={styling} appContext={appContext} theFunction={onRewind} userContext={userContext} casting={undefined} />
                    ) : null}

                    {screenName != 'Channel_Player' && screenName != 'Cam_Player' ? (
                        <ButtonIcon
                            focusContext={focusContext}
                            icon={paused ? faPlay : faPause}
                            text={'play'}
                            deviceContext={deviceContext}
                            styling={styling}
                            appContext={appContext}
                            theFunction={onPlayPause}
                            userContext={userContext}
                            casting={undefined}
                        />
                    ) : null}
                    {screenName == 'Channel_Player' && contentContext.channelData.channel.interactivetv.pausetv_enabled ? (
                        <ButtonIcon
                            focusContext={focusContext}
                            icon={paused ? faPlay : faPause}
                            text={'play'}
                            deviceContext={deviceContext}
                            styling={styling}
                            appContext={appContext}
                            theFunction={onPlayPause}
                            userContext={userContext}
                            casting={undefined}
                        />
                    ) : null}

                    {screenName != 'Channel_Player' && screenName != 'Cam_Player' && !deviceContext.isPhone ? (
                        <ButtonIcon
                            focusContext={focusContext}
                            icon={faChevronDoubleRight}
                            text={'forward'}
                            deviceContext={deviceContext}
                            styling={styling}
                            appContext={appContext}
                            theFunction={onForward}
                            userContext={userContext}
                            casting={undefined}
                        />
                    ) : null}
                    {screenName == 'Channel_Player' && contentContext.channelData.channel.interactivetv.catchuptv_enabled && !deviceContext.isPhone ? (
                        <ButtonIcon
                            focusContext={focusContext}
                            icon={faChevronDoubleRight}
                            text={'forward'}
                            deviceContext={deviceContext}
                            styling={styling}
                            appContext={appContext}
                            theFunction={onForward}
                            userContext={userContext}
                            casting={undefined}
                        />
                    ) : null}

                    {isRestart ? (
                        <ButtonIcon focusContext={focusContext} icon={faHistory} text={'restart'} deviceContext={deviceContext} styling={styling} appContext={appContext} theFunction={onRestart} userContext={userContext} casting={undefined} />
                    ) : null}

                    {
                        <ButtonIcon
                            focusContext={focusContext}
                            icon={isFavorite ? faHeartSolid : faHeart}
                            text={'favorite'}
                            deviceContext={deviceContext}
                            styling={styling}
                            appContext={appContext}
                            theFunction={manageFavorite}
                            userContext={userContext}
                            casting={undefined}
                        />
                    }
                </View>

                <View style={{ flex: 1, justifyContent: 'flex-end', flexDirection: 'row' }}>
                    {(screenName == 'Channel_Player' || screenName == 'CatchupTV_Player' || screenName == 'Recording_Player') && (
                        <Pressable
                            style={{
                                height: getCorrectWidth(deviceContext, 40),
                                padding: getCorrectHeight(deviceContext, deviceContext.isPhone ? 1 : 3),
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                            hasPreferredFocus={false}
                            focusContext={focusContext}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            focusOptions={{
                                focusKey:'list',
                                nextFocusUp: 'scrubber',
                                nextFocusLeft: ['favorite', 'forward', 'play', 'rewind', 'next'],
                            }}
                            onPress={() => showChannels()}
                        >
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(255, 255,255, 0.1)',
                                    paddingHorizontal: getCorrectWidth(deviceContext, deviceContext.isPhone ? 10 : 20),
                                    borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                }}
                            >
                                <FontAwesomeIcon icon={faRectangleHistory} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        marginLeft: 10,
                                        color: styling.texts.title_color,
                                        fontWeight: 'normal',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'channel_list')}
                                </Text>
                            </View>
                        </Pressable>
                    )}
                    {screenName == 'Series_Player' && (
                        <Pressable
                            style={{
                                height: getCorrectWidth(deviceContext, 40),
                                padding: getCorrectHeight(deviceContext, deviceContext.isPhone ? 1 : 3),
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            focusOptions={{
                                focusKey:'list',
                                nextFocusUp: 'scrubber',
                                nextFocusLeft: ['favorite', 'forward', 'play', 'rewind', 'next'],
                            }}
                            focusContext={focusContext}
                            hasPreferredFocus={false}
                            onPress={() => showEpisodes()}
                        >
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(255, 255,255, 0.1)',
                                    paddingHorizontal: getCorrectWidth(deviceContext, deviceContext.isPhone ? 10 : 20),
                                    borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                }}
                            >
                                <FontAwesomeIcon icon={faRectangleHistory} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        marginLeft: 10,
                                        color: styling.texts.title_color,
                                        fontWeight: 'normal',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'episodes')}
                                </Text>
                            </View>
                        </Pressable>
                    )}
                    {screenName == 'Course_Player' && (
                        <Pressable
                            style={{
                                height: getCorrectWidth(deviceContext, 40),
                                padding: getCorrectHeight(deviceContext, deviceContext.isPhone ? 1 : 3),
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                            focusContext={focusContext}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            focusOptions={{
                                focusKey:'list',
                                nextFocusUp: 'scrubber',
                                nextFocusLeft: ['favorite', 'forward', 'play', 'rewind', 'next'],
                            }}
                            hasPreferredFocus={false}
                            onPress={() => showLessons()}
                        >
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(255, 255,255, 0.1)',
                                    paddingHorizontal: getCorrectWidth(deviceContext, deviceContext.isPhone ? 10 : 20),
                                    borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                }}
                            >
                                <FontAwesomeIcon icon={faRectangleHistory} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        marginLeft: 10,
                                        color: styling.texts.title_color,
                                        fontWeight: 'normal',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'lessons')}
                                </Text>
                            </View>
                        </Pressable>
                    )}
                    {screenName == 'Short_Player' && (
                        <Pressable
                            style={{
                                height: getCorrectWidth(deviceContext, 40),
                                padding: getCorrectHeight(deviceContext, deviceContext.isPhone ? 1 : 3),
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            focusOptions={{
                                focusKey:'list',
                                nextFocusUp: 'scrubber',
                                nextFocusLeft: ['favorite', 'forward', 'play', 'rewind', 'next'],
                            }}
                            focusContext={focusContext}
                            hasPreferredFocus={false}
                            onPress={() => showShorts()}
                        >
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(255, 255,255, 0.1)',
                                    paddingHorizontal: getCorrectWidth(deviceContext, deviceContext.isPhone ? 10 : 20),
                                    borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                }}
                            >
                                <FontAwesomeIcon icon={faRectangleHistory} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        marginLeft: 10,
                                        color: styling.texts.title_color,
                                        fontWeight: 'normal',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'shorts')}
                                </Text>
                            </View>
                        </Pressable>
                    )}
                    <Pressable
                        style={{
                            height: getCorrectWidth(deviceContext, 40),
                            padding: getCorrectHeight(deviceContext, deviceContext.isPhone ? 1 : 3),
                            borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                        }}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        focusOptions={{
                            focusKey:'list',
                            nextFocusUp: 'scrubber',
                            nextFocusLeft: ['list'],
                        }}
                        focusContext={focusContext}
                        hasPreferredFocus={false}
                        onPress={() => showSettings()}
                    >
                        <View
                            style={{
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'rgba(255, 255,255, 0.1)',
                                paddingHorizontal: getCorrectWidth(deviceContext, deviceContext.isPhone ? 10 : 20),
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <FontAwesomeIcon icon={faCog} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                            <Text
                                numberOfLines={1}
                                style={{
                                    marginLeft: 10,
                                    color: styling.texts.title_color,
                                    fontWeight: 'normal',
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {lang.getTranslation(userContext, 'settings')}
                            </Text>
                        </View>
                    </Pressable>
                    {/* {deviceContext.isPhone || deviceContext.isTablet || deviceContext.isWebTV ? (
                        <ButtonIcon focusContext={focusContext} icon={faWindowRestore} text={'pip'} deviceContext={deviceContext} styling={styling} appContext={appContext} theFunction={startPiP} userContext={userContext} />
                    ) : null} */}
                    {deviceContext.isPhone || deviceContext.isTablet || deviceContext.isWebTV ? (
                        <ButtonIcon
                            focusContext={focusContext}
                            icon={deviceContext.isIos ? faAirplay : faChromecast}
                            text={'casting'}
                            deviceContext={deviceContext}
                            styling={styling}
                            appContext={appContext}
                            theFunction={deviceContext.isIos ? openAirplay : openChromecast}
                            userContext={userContext}
                            casting={playerContext.casting}
                        />
                    ) : null}
                </View>
            </View>
        </View>
    );
};

export default Player_Standard;

export const ButtonIcon = ({ icon, text, deviceContext, styling, appContext, theFunction, userContext, showFunction, hideFunction, focusContext, casting }: any) => {
    const [showText, setShowText] = React.useState(false);
    return (
        <View focusContext={focusContext} style={{ width: getCorrectWidth(deviceContext, 40) }}>
            <Pressable
                focusContext={focusContext}
                style={{
                    width: getCorrectWidth(deviceContext, 40),
                    height: getCorrectWidth(deviceContext, 40),
                    padding: getCorrectHeight(deviceContext, deviceContext.isPhone ? 1 : 3),
                    borderRadius: 100,
                }}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                focusOptions={{
                    nextFocusUp: 'scrubber',
                    focusKey: text,
                }}
                hasPreferredFocus={text == 'back' ? true : false}
                onPress={() => {
                    theFunction();
                }}
                onFocus={() => {
                    setShowText(true);
                    if (showFunction != null) {
                        showFunction();
                    }
                }}
                onMouseOver={() => {
                    setShowText(true);
                    if (showFunction != null) {
                        showFunction();
                    }
                }}
                onBlur={() => {
                    setShowText(false);
                    if (hideFunction != null) {
                        hideFunction();
                    }
                }}
                onMouseOut={() => {
                    setShowText(false);
                    if (hideFunction != null) {
                        hideFunction();
                    }
                }}
            >
                <View
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        borderRadius: 100,
                        flexDirection: 'row',
                    }}
                >
                    {text == 'forward' && <Image source={require('../../../../assets/images/ff.png')} resizeMode={'cover'} style={{ height: getCorrectWidth(deviceContext, 11), width: getCorrectWidth(deviceContext, 11) }}></Image>}
                    {text == 'rewind' && <Image source={require('../../../../assets/images/rew.png')} resizeMode={'cover'} style={{ height: getCorrectWidth(deviceContext, 11), width: getCorrectWidth(deviceContext, 11) }}></Image>}
                    {text != 'rewind' && text != 'forward' && <FontAwesomeIcon icon={icon} color={casting != undefined && (casting.type == 'Chromecast' || casting.type == 'Airplay') ? 'green' : getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>}
                </View>
            </Pressable>
            {showText && (
                <View style={{ alignItems: 'center' }}>
                    <Text
                        numberOfLines={1}
                        style={{
                            textAlign: 'center',
                            color: styling.texts.subtext_color,
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Small'),
                            fontWeight: 'normal',
                        }}
                    >
                        {lang.getTranslation(userContext, text)}
                    </Text>
                </View>
            )}
        </View>
    );
};
