import * as React from 'react';
import { View,  CreateListRenderItemInfo, FlashList, FocusContext } from '@scriptx-com/xtv-toolkit';
import { useEffectOnce } from '../../../hooks/useEffectOnce';
import Menu from '../shared/menu';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getRealWidth } from '../../../styling/realDeviceSizes';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { AppContext } from '../../../context/appContext';
import { FlashList as FlashList_ } from '@scriptx-com/flash-list';
import { isFactorMobile, isFactorTv, isPlatformAndroid, isPlatformIos } from '@rnv/renative';
import { getFocusStyling } from '../../components/helpers/helper';
import { getCorrectLeftMargin } from '../../../styling/appLeftMargin';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { GetScrollElement, GetTopPart } from './helpers/views';


export interface Data {
    items: [
        {
            square_image: string;
            name: string;
            _id: string;
        }
    ];
}

export interface Props {
    styling: any;
    focusContext?: FocusContext;
    navigation: any;
}

export const Menu_Rails = React.memo(({ styling, focusContext, navigation }: Props) => {
    const [data, setData] = React.useState([] as any);
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const rails = React.useRef<FlashList_<number> | null>(null);
    const [viewIndex, setViewIndex] = React.useState(0);
    const [viewableItemsCount, setViewableItemsCount] = React.useState(0);
    const screenName = getCurrentScreenName();

    let height = isPlatformAndroid || isPlatformIos ? React.useRef(getCorrectHeight(deviceContext, styling.placement.height)).current : getCorrectHeight(deviceContext, styling.placement.height);
    var width = (height / 9) * 16;

    const getData = async () => {

        if (styling.content.menu != undefined && styling.content.menu.length > 0) {
            setData(styling.content.menu);
        }
    };

    useEffectOnce(() => {
        getData();
    })

    const detailsItem = (item: any, index: any) => {
        navigation.navigate(item);
        contentContext.setActiveMenu(item);
    };

    const renderItem = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        if (userContext.userId.indexOf('_review') > -1 && item.name == 'Search') {
            return null;
        } else {
            return <Menu styling={styling} data={item} height={height} width={width} focusRepeatContext={focusRepeatContext} onPress={() => detailsItem(item, index)}></Menu>;
        }
    };
    const getRailsWidth = () => {
        if (deviceContext.isPhone || deviceContext.isPwaVertical) {
            return getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 5);
        } else {
            return getRealWidth(deviceContext) - getCorrectWidth(deviceContext, +styling.placement.padding + styling.placement.margin_left + styling.placement.margin_right + global.menuWidth);
        }
    };
    const getRailsHeight = () => {
        if (styling.placement.height < height * 2) {
            return null;
        } else {
            return getCorrectHeight(deviceContext, styling.placement.height + styling.placement.padding * 2);
        }
    };

    const scrollRight = () => {
        const next = viewIndex + 1;
        if (next === data.length) {
            rails.current?.scrollToEnd({ animated: true });
        } else {
            rails.current?.scrollToIndex({ animated: true, index: viewIndex + 1 });
        }
    };

    const scrollLeft = () => {
        const next = Math.max(0, viewIndex - viewableItemsCount);
        rails.current?.scrollToIndex({ animated: true, index: next });
    };

    const onViewableItemsChanged = ({ viewableItems, changed }) => {
        if (isFactorTv || isFactorMobile) return;
        if (viewableItems.length !== viewableItemsCount) {
            setViewableItemsCount(viewableItems.length);
        }
        if (Array.isArray(changed)) {
            let visibleItemIndex;
            changed.forEach((item) => {
                if (item.isViewable) {
                    visibleItemIndex = item.index;
                }
            });
            if (visibleItemIndex !== undefined) {
                setViewIndex(visibleItemIndex);
            }
        }
    };
    const getTitle = (titles) => {
        if (titles == undefined || titles.length == 0) {
            return '';
        }
        var test = styling.content.list?.title.find((t) => t.language == userContext.selectedLanguage);
        if (test != undefined) {
            return test.title;
        } else {
            if (styling.content.list?.title != undefined) {
                return styling.content.list?.title[0].title;
            } else {
                return '';
            }
        }
    };

    const extraTopBottomMargin = () => {
        if(styling.placement.background_color != '#00000000' && styling.placement.background_color != 'transparent'){
            return  getCorrectHeight(deviceContext, styling.placement.padding)
        }else{
            return 0
        }
    }

    return (
        <View focusContext={focusContext} >
    {data != null && data.length > 0 && (
                <View
                    style={{
                        flex: 1,
                        marginLeft:  getCorrectLeftMargin(deviceContext, appContext, screenName),
                        marginRight: 0,
                        marginTop: appContext.application.theme.rails_top_margin + extraTopBottomMargin(),
                        marginBottom:appContext.application.theme.rails_bottom_margin + extraTopBottomMargin(),
                    }}
                >
                    <View style={{ flex: 1 }}>
                        <View
                            style={{
                                backgroundColor: styling.placement.background_color,
                                height: data.length > 0 ? getRailsHeight() : data.length == 0 ? getRailsHeight() : 0,
                                borderRadius: getCorrectWidth(deviceContext, styling.placement.border_radius),
                                paddingTop: getCorrectHeight(deviceContext, styling.placement.padding),
                                paddingBottom: getCorrectHeight(deviceContext, styling.placement.padding),
                                paddingLeft:
                                styling.placement.background_color != '#00000000' && styling.placement.background_color != 'transparent'
                                    ? deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs
                                        ? getCorrectWidth(deviceContext, 5)
                                        : getCorrectWidth(deviceContext, styling.placement.padding)
                                    : 0,
                                paddingRight:deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs
                                ? getCorrectWidth(deviceContext, 0)
                                : deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0 : getCorrectWidth(deviceContext, styling.placement.padding),
                            }}
                        >
                      <GetTopPart deviceContext={deviceContext} appContext={appContext} userContext={userContext} styling={styling} data={data} focusContext={focusContext} openOverviewPage={undefined} ></GetTopPart>
                            {data.length > 0 && (
                                <View style={{ flex: 1, justifyContent: 'center', width: getRailsWidth() }} focusContext={focusContext}>
                                    <FlashList
                                        type="row"
                                        ref={rails}
                                        data={data}
                                        onViewableItemsChanged={onViewableItemsChanged}
                                        estimatedItemSize={height}
                                        drawDistance={height * 2}
                                        numColumns={1}
                                        horizontal={true}
                                        showsHorizontalScrollIndicator={false}
                                        renderItem={renderItem}
                                        initialScrollIndex={0}
                                        style={{ flex: 1 }}
                                        focusOptions={{
                                            autoLayoutScaleAnimation: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? true : false,
                                            autoLayoutSize: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? 10 : 0,
                                        }}
                                    />
                                                {deviceContext.isWebTV && !deviceContext.isPwa && data.length > 0 && data.length > viewableItemsCount && (
                                        <GetScrollElement height={height} scrollLeft={scrollLeft} scrollRight={scrollRight} deviceContext={deviceContext} appContext={appContext}></GetScrollElement>
                                    )}
                                </View>
                            )}
                        </View>
                    </View>
                </View>
            )}
        </View>
    );
});
export default Menu_Rails;
