import * as React from 'react';
import { View, ActivityIndicator, Screen, FocusContext } from '@scriptx-com/xtv-toolkit';
import { FilterBar as styling } from '../../../models/elements/misc/filterbar/filterbar';
import { useEffectOnce } from '../../../hooks/useEffectOnce';
import { getRealWidth } from '../../../styling/realDeviceSizes';
import { faMicrophone, faSearch } from '@fortawesome/pro-light-svg-icons';
import Input from '../../components/input/input';
import lang from '../../../languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import Pressable from '../../components/pressable/pressable';
import { getFontSize } from '../../../styling/fontSizes';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { faTrash }  from '@fortawesome/pro-light-svg-icons';
import { AppContext } from '../../../context/appContext';
import { KeyboardContext } from '../../../context/keyboardContext';
import Text from '../../components/text';
import { getFocusStyling, getPressableStyling, getPressableTextStyling, getInputStyling } from '../../components/helpers/helper';

export interface Data {
    items: [
        {
            square_image: string;
            name: string;
            _id: string;
        },
    ];
}

export interface Props {
    styling: styling;
    focusContext?: FocusContext;
}

export const FilterBar = ({ styling, focusContext }: Props) => {
    const { keyboardVisible, openKeyboard, value, setKeyboardVisible } = React.useContext(KeyboardContext);
    const contentContext = React.useContext(ContentContext);
    const deviceContext = React.useContext(DeviceContext);
    const contextUser = React.useContext(UserContext);
    const appContext = React.useContext(AppContext);

    const [showKeyboard, setShowKeyboard] = React.useState(false);

    React.useEffect(() => {
        contentContext.setSearching(true);
        contentContext.setSearch(value);
    }, [value]);

    const setFilterLocal = (value: any) => {
        // contentContext.setSearching(true)
        contentContext.setSearch(value);
    };

    const clearFilter = () => {
        contentContext.setSearch('');
    };

    var screenWidth =
        getRealWidth(deviceContext) -
        getCorrectWidth(deviceContext, +styling.general.margin_left + styling.general.margin_right) -
        getCorrectWidth(deviceContext, global.menuWidth);

    return (
        <View focusContext={focusContext}>
            <View
                style={{
                    height: getCorrectHeight(deviceContext, 39),
                    backgroundColor: styling.general.background_color,
                    marginLeft: getCorrectWidth(deviceContext, styling.general.margin_left ?? 0),
                    marginRight: getCorrectWidth(deviceContext, styling.general.margin_right ?? 0),
                    marginTop: getCorrectHeight(deviceContext, styling.general.margin_top ?? 0),
                    marginBottom: getCorrectHeight(deviceContext, styling.general.margin_bottom ?? 0),
                    width: screenWidth,
                }}
            >
                <View style={{ flexDirection: 'row' }} focusContext={focusContext}>
                    {/* <View>
                        <Pressable focusContext={focusContext} animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => clearFilter()}>
                            <View
                                style={{
                                    height: getCorrectHeight(deviceContext, 30),
                                    width: getCorrectWidth(deviceContext, 40),
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    marginRight: getCorrectWidth(deviceContext, 4),
                                    ...getPressableStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                <FontAwesomeIcon icon={faMicrophone} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Normal') * 1.5}></FontAwesomeIcon>
                            </View>
                        </Pressable>
                    </View> */}
                    <Input
                        value={contentContext.search}
                        onChangText={(value) => setFilterLocal(value)}
                        icon={faSearch}
                        width={screenWidth - getCorrectWidth(deviceContext, deviceContext.isPhone || deviceContext.isPwaVertical ? 60 : 100)}
                        isSecure={false}
                        placeholder={lang.getTranslation(contextUser, 'filter_content')}
                        animation={getFocusStyling('Inputs', appContext)}
                        styling={getInputStyling(styling.components?.input?._id, appContext)}
                        showKeyboard={() => {
                            openKeyboard({
                                focusOptions: { nextFocusUp: 'filter-bar', nextFocusDown: 'filter-bar' },
                            });
                        }}
                        focusOptions={{ focusKey: 'filter-bar' }}
                        onFocus={() => {
                            if (keyboardVisible) {
                                setKeyboardVisible(false);
                            }
                        }}
                    />
                    <View>
                        <Pressable
                            style={{
                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                height: getCorrectHeight(deviceContext, 31),
                                margin: getCorrectWidth(deviceContext, 4),
                            }}
                            focusContext={focusContext}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => clearFilter()}
                        >
                            <View
                                style={{
                                    width: getCorrectWidth(deviceContext, deviceContext.isPhone || deviceContext.isPwaVertical ? 40 : 84),
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    alignContent: 'center',
                                    alignSelf: 'center',
                                    flex: 1,
                                }}
                            >
                                {!deviceContext.isPhone && !deviceContext.isPwaVertical && (
                                    <Text
                                        style={{
                                            paddingHorizontal: getCorrectWidth(deviceContext, 3),
                                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                        }}
                                    >
                                        {lang.getTranslation(contextUser, 'clear_filter')}
                                    </Text>
                                )}
                                {(deviceContext.isPhone || deviceContext.isPwaVertical) && (
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        color={getPressableTextStyling(styling.components?.button?._id, appContext).color}
                                        size={getFontSize(deviceContext, 'Normal') * 1.2}
                                    ></FontAwesomeIcon>
                                )}
                            </View>
                        </Pressable>
                    </View>
                </View>
            </View>
        </View>
    );
};
export default FilterBar;
