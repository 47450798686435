import { Animated, View } from '@scriptx-com/xtv-toolkit';
import moment from 'moment';
import React, { Component } from 'react';
import { PanGestureHandler, State } from 'react-native-gesture-handler';
import { colorShade } from '../../../styling/colorShade';
import { getFontSize } from '../../../styling/fontSizes';
import Pressable from '../pressable/pressable';
import Text from '../text';

const DefaultColors = {
    valueColor: '#fff',
    trackBackgroundColor: '#DDD',
    trackColor: '#666',
    scrubbedColor: 'red',
    bufferedTrackColor: '#999',
};

const ScrubbingRates = {
    half: {
        threshold: 40,
        rate: 0.5,
    },
    quarter: {
        threshold: 80,
        rate: 0.25,
    },
    fine: {
        threshold: 120,
        rate: 0.1,
    },
};

const PLACEHOLDER_DISPLAY_VALUE = '';
const TrackSliderSize = 20;
const SCALE_UP_DURAITON = 150;

var formatValue = (value) => {
    const hours = Math.floor(value / 3600);
    const rawMinutes = value / 60 - 60 * hours;
    const minutes = Math.floor(rawMinutes);
    const seconds = Math.floor((rawMinutes - minutes) * 60);
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    const formattedMinutes = minutes < 10 && hours ? `0${minutes}` : minutes;

    if (hours) {
        return `${hours}:${formattedMinutes}:${formattedSeconds}`;
    }
    return `${formattedMinutes}:${formattedSeconds}`;
};

export default class Srubber extends Component {
    constructor(props) {
        super(props);
        this.scaleFactor = new Animated.Value(0);

        this.state = {
            scrubbing: false,
            scrubRate: 1,
            scrubbingValue: 0,
            startingNumberValue: props.value,
        };

        this._translateX = new Animated.Value(0);
        this._translateY = new Animated.Value(0);

        this._lastOffset = { x: 0, y: 0 };
        this._onGestureEvent = Animated.event(
            [
                {
                    nativeEvent: {
                        translationY: this._translateX,
                        translationX: this._translateY,
                    },
                },
            ],
            { useNativeDriver: false }
        );

        this.initiateAnimator();
    }

    static propTypes = {};

    componentWillUnmount() {
        this._translateX.removeAllListeners();
    }

    scaleUp = () => {
        Animated.timing(this.scaleFactor, {
            toValue: 1,
            duration: SCALE_UP_DURAITON,
            useNativeDriver: true,
        }).start();
    };

    scaleDown = () => {
        Animated.timing(this.scaleFactor, {
            toValue: 0,
            duration: SCALE_UP_DURAITON,
            useNativeDriver: true,
        }).start();
    };

    _onHandlerStateChange = (event) => {
        if (event.nativeEvent.state === State.BEGAN) {
            const { totalDuration, value } = this.props;
            const currentPercent = totalDuration !== 0 ? Math.min(totalDuration, value) / totalDuration : 0;
            const initialX = currentPercent * this.props.scrubberWidth;
            const boundedX = Math.min(Math.max(initialX, 0), this.props.scrubberWidth - TrackSliderSize);

            this.panResonderMoved = false;

            this._lastOffset.x = boundedX;

            this._translateX.setOffset(boundedX);
            this._translateX.setValue(0);
            global.preventControlsFromHiding = true;
            global.isScrubbing = true;
            this.setState({ scrubbing: true }, this.scaleUp);
        } else if (event.nativeEvent.state === State.ACTIVE) {
            this.panResonderMoved = true;
        } else if (event.nativeEvent.state === State.END) {
            const { scrubberWidth } = this.props;
            const { totalDuration } = this.props;

            this._lastOffset.x = this._lastOffset.x + this._translateX._value;

            const boundedX = Math.min(Math.max(this._lastOffset.x, 0), scrubberWidth);

            const percentScrubbed = boundedX / scrubberWidth;
            const scrubbingValue = percentScrubbed * totalDuration;

            if (this.panResonderMoved) {
                this.onSlidingComplete(scrubbingValue);
            }
            global.isScrubbing = false;
            this.setState({ scrubbing: false, scrubRate: 1 }, this.scaleDown);
        }
    };

    formattedStartingNumber = () => {
        let { scrubbing, startingNumberValue } = this.state;
        let { value, totalDuration, s } = this.props;

        if (!totalDuration) {
            return PLACEHOLDER_DISPLAY_VALUE;
        }
        if (s != undefined && this.props.live == true) {
            var value_ = Math.round(value + s);
            value = moment.unix(value_).format(this.props.deviceContext.clockSetting);
            var startingNumberValue_ = Math.round(startingNumberValue + s);
            startingNumberValue_ = moment.unix(startingNumberValue_).format(this.props.deviceContext.clockSetting);
            return scrubbing ? startingNumberValue_ : value != 'Invalid date' ? value : '';
        } else {
            return scrubbing ? formatValue(startingNumberValue) : formatValue(value);
        }
    };

    formattedEndingNumber = () => {
        const { value, totalDuration } = this.props;
        const { scrubbing, endingNumberValue } = this.state;
        const cappedValue = Math.min(totalDuration, value);
        const remainingValue = totalDuration - cappedValue;

        if (!totalDuration) {
            return PLACEHOLDER_DISPLAY_VALUE;
        }
        const scrubbingValue = typeof endingNumberValue === 'number' ? endingNumberValue : remainingValue;

        return `${scrubbing ? formatValue(scrubbingValue) : formatValue(totalDuration)}`;
    };

    onSlidingComplete = (scrubbingValue) => {
        this.props.onSlidingComplete(scrubbingValue);
    };

    handleScrubRateChange = (value) => {
        const { scrubRate } = this.state;
        if (Math.abs(value.y) > ScrubbingRates.fine.threshold) {
            if (scrubRate !== ScrubbingRates.fine.rate) {
                this.setState({ scrubRate: ScrubbingRates.fine.rate });
            }
            return;
        }

        if (Math.abs(value.y) > ScrubbingRates.quarter.threshold) {
            if (scrubRate !== ScrubbingRates.quarter.rate) {
                this.setState({ scrubRate: ScrubbingRates.quarter.rate });
            }
            return;
        }

        if (Math.abs(value.y) > ScrubbingRates.half.threshold) {
            if (scrubRate !== ScrubbingRates.half.rate) {
                this.setState({ scrubRate: ScrubbingRates.half.rate });
            }
            return;
        }

        if (Math.abs(value.y) < ScrubbingRates.half.threshold) {
            if (scrubRate !== 1) {
                this.setState({ scrubRate: 1 });
            }
            return;
        }
    };

    initiateAnimator = () => {
        this._translateX.addListener(({ value }) => {
            const boundedValue = Math.min(Math.max(value, 0), this.props.scrubberWidth);

            this.setState({
                startingNumberValue: (boundedValue / this.props.scrubberWidth) * this.props.totalDuration,
                endingNumberValue: (1 - boundedValue / this.props.scrubberWidth) * this.props.totalDuration,
            });
            return;
        });
    };

    onTap = ({ nativeEvent }) => {
        if (nativeEvent.state === State.END && this.props.tapNavigation) {
            const { scrubberWidth } = this.props;
            const { totalDuration } = this.props;
            this.onSlidingComplete((nativeEvent.x / scrubberWidth) * totalDuration);
        }
    };

    render() {
        const {
            value = 0,
            // bufferedValue = 0,
            totalDuration = 1,
            trackBackgroundColor = this.props.trackcolor,
            trackColor = this.props.scrubbedColor,
            scrubbedColor = this.props.scrubbedColor,
            //bufferedTrackColor = DefaultColors.bufferedTrackColor,
            displayedValueStyle = { color: DefaultColors.valueColor },
            displayValues = true,
        } = this.props;

        const { scrubbing } = this.state;
        const { scrubberWidth } = this.props;
        // We don't want any value exceeding the totalDuration
        const cappedValue = Math.min(totalDuration, value);
        // const cappedBufferedValue = Math.min(totalDuration, bufferedValue);
        const progressPercent = totalDuration !== 0 ? cappedValue / totalDuration : 0;
        const displayPercent = progressPercent * scrubberWidth;
        const progressWidth = progressPercent * 100;

        if (progressWidth == NaN) {
            progressWidth = 0;
        }

        const bufferedProgressPercent = totalDuration !== 0 && this.props.s != undefined && this.props.e > moment().unix() && this.props.live == true ? (moment().unix() - this.props.s) / totalDuration : 0;
        var bufferedProgressWidth = bufferedProgressPercent * 100;

        const scrubberColor = scrubbing ? { backgroundColor: scrubbedColor } : { backgroundColor: trackColor };

        const progressTrackStyle = scrubbing ? { backgroundColor: scrubbedColor } : { backgroundColor: trackColor };

        const startingValueStyle = scrubbing ? [displayedValueStyle, { color: scrubbedColor }] : displayedValueStyle;

        const trackBackgroundStyle = { backgroundColor: trackBackgroundColor };
        const bufferedTrackBackgroundStyle = { backgroundColor: colorShade(trackColor, 70) };

        let boundX = progressPercent;

        if (scrubberWidth) {
            boundX = this._translateX.interpolate({
                inputRange: [0, scrubberWidth],
                outputRange: [0, scrubberWidth],
                extrapolate: 'clamp',
            });
        }

        const scaleValue = this.scaleFactor.interpolate({
            inputRange: [0, 1],
            outputRange: [1.0, 2.0],
        });
        const scaleStyle = { scale: scaleValue };

        return (
            <View
                focusContext={this.props.focusContext}
                style={{
                    width: scrubberWidth,
                }}
            >
                <View
                    style={{
                        position: 'relative',
                        height: 5,
                        paddingTop: TrackSliderSize / 2,
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                    }}
                >
                    {/* <TapGestureHandler
            onHandlerStateChange={this.onTap}
            maxDurationMs={2000}
            hitSlop={{ top: 20, bottom: 20, left: 0, right: 0 }}
          >
        
          </TapGestureHandler> */}
                    <View
                        style={[
                            {
                                position: 'absolute',
                                height: 5,
                                width: '100%',
                                borderRadius: 3,
                            },
                            trackBackgroundStyle,
                        ]}
                    />
                    <View
                        key="bufferedTrack"
                        style={[
                            {
                                position: 'absolute',
                                height: 5,
                                width: 0,
                                left: 0,
                                borderTopLeftRadius: 3,
                                borderBottomLeftRadius: 3,
                                zIndex: 1,
                            },
                            { ...bufferedTrackBackgroundStyle },
                            { width: `${bufferedProgressWidth}%` },
                        ]}
                    ></View>
                    <Animated.View
                        key="backgroundTrack"
                        style={[
                            {
                                position: 'absolute',
                                height: 5,
                                width: 0,
                                left: 0,
                                borderTopLeftRadius: 3,
                                borderBottomLeftRadius: 3,
                                zIndex: 2,
                            },
                            { ...progressTrackStyle },
                            !scrubbing ? { width: `${progressWidth}%` } : { width: boundX },
                        ]}
                    />
                    {(this.props.deviceContext.isWebTV || this.props.deviceContext.isPhone || this.props.deviceContext.isTablet) && (
                        <PanGestureHandler onGestureEvent={this._onGestureEvent} onHandlerStateChange={this._onHandlerStateChange} minDist={0} hitSlop={{ top: 20, bottom: 20, left: 50, right: 50 }}>
                            <Animated.View
                                style={[
                                    {
                                        zIndex: 3,
                                        position: 'absolute',
                                        left: 0 - TrackSliderSize / 2,
                                    },
                                    !scrubbing
                                        ? {
                                              left: isNaN(displayPercent - TrackSliderSize / 2) ? 0 : displayPercent - TrackSliderSize / 2,
                                          }
                                        : { transform: [{ translateY: boundX }] },
                                ]}
                                hitSlop={{ top: 20, bottom: 20, left: 50, right: 50 }}
                            >
                                <Animated.View
                                    key="progressTrack"
                                    style={[
                                        {
                                            width: TrackSliderSize,
                                            height: TrackSliderSize,
                                            borderRadius: TrackSliderSize,
                                            borderColor: '#ffffff',
                                        },
                                        { ...scrubberColor },
                                        //{ transform: [scaleStyle] },
                                    ]}
                                />
                            </Animated.View>
                        </PanGestureHandler>
                    )}
                    {!this.props.deviceContext.isWebTV && !this.props.deviceContext.isPhone && !this.props.deviceContext.isTablet && (
                        <View
                            style={[
                                {
                                    zIndex: 3,
                                    position: 'absolute',
                                    left: 0 - TrackSliderSize / 2,
                                },
                            ]}
                            hitSlop={{ top: 20, bottom: 20, left: 50, right: 50 }}
                        >
                            <Pressable
                                focusContext={this.props.focusContext}
                                style={{
                                    width: TrackSliderSize,
                                    height: TrackSliderSize,
                                    borderRadius: TrackSliderSize,
                                    backgroundColor: scrubbedColor,
                                    left: (scrubberWidth / 100) * progressWidth,
                                }}
                                animatorOptions={{
                                    blur: { borderWidth: 0 },
                                    focus: { borderColor: '#ffffff', borderRadius: 100, borderWidth: 4 },
                                    type: 'border',
                                }}
                                onFocus={() => {
                                    global.scrubberIsFocus = true;
                                }}
                                onBlur={() => {
                                    global.scrubberIsFocus = false;
                                }}
                            >
                                <View
                                    key="progressTrack"
                                    style={[
                                        {
                                            width: TrackSliderSize,
                                            height: TrackSliderSize,
                                            borderRadius: TrackSliderSize,
                                        },
                                    ]}
                                />
                            </Pressable>
                        </View>
                    )}
                </View>
                {displayValues ? (
                    <View
                        style={{
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: 4,
                        }}
                    >
                        <Text
                            style={[
                                startingValueStyle,
                                {
                                    fontFamily: this.props.deviceContext.fontType,
                                    fontSize: getFontSize(this.props.deviceContext, this.props.deviceContext.isPhone ? 'Small' : 'Normal'),
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 1,
                                },
                            ]}
                        ></Text>
                        <Text
                            style={[
                                displayedValueStyle,
                                {
                                    fontFamily: this.props.deviceContext.fontType,
                                    fontSize: getFontSize(this.props.deviceContext, this.props.deviceContext.isPhone ? 'Small' : 'Normal'),
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 1,
                                },
                            ]}
                        >
                            {this.formattedStartingNumber()}
                            &nbsp; / &nbsp;
                            {this.props.e != null ? moment.unix(this.props.e).format(this.props.deviceContext.clockSetting) : this.formattedEndingNumber()}
                        </Text>
                    </View>
                ) : null}
            </View>
        );
    }
}
