import * as React from 'react';
import { Animated, Easing, View } from 'react-native';
import { useEffectOnce } from '../../hooks/useEffectOnce';

export interface Props {
    children: React.ReactChild | React.ReactChild[];
}

export const TextFade = ({ children }: Props) => {
    const opacity = React.useRef(new Animated.Value(0)).current;

    function startAnimation() {
        Animated.timing(opacity, {
            toValue: 1,
            duration: 1000,
            easing: Easing.ease,
            useNativeDriver: true,
        }).start(() => {
            Animated.timing(opacity, {
                toValue: 1,
                duration: 9000,
                easing: Easing.ease,
                useNativeDriver: true,
            }).start();
        });
    }

    useEffectOnce(() => {
        startAnimation();
    });

    return (
        <Animated.View
            collapsable={false}
            style={{
                opacity: opacity,
            }}
        >
            {children}
        </Animated.View>
    );
};
