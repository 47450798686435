import React from 'react';
import HTML from 'react-native-render-html';
import Decode from 'unescape';

const HTMLRenderer = ({ html, contentWidth }: { html: string; contentWidth: number }) => {
    return (
        <HTML
            enableCSSInlineProcessing={true}
            source={{
                html: Decode(html),
            }}
            contentWidth={contentWidth}
        />
    );
};

export default HTMLRenderer;
