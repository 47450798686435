import * as React from 'react';
import { useState, Dispatch } from 'react';
import { getGlobals } from '../../globals';
interface DeviceContext {
    formFactor: string;
    setFormFactor: Dispatch<any>;
    model: string;
    setModel: Dispatch<any>;
    type: string;
    setType: Dispatch<any>;
    os: string;
    setOs: Dispatch<any>;
    osVersion: string;
    setOsVersion: Dispatch<any>;
    fontType: string;
    setFontType: Dispatch<any>;
    number: string;
    setNumber: Dispatch<any>;

    isPhone: boolean;
    setIsPhone: Dispatch<any>;
    isTablet: boolean;
    setIsTablet: Dispatch<any>;
    isTelevision: boolean;
    setIsTelevision: Dispatch<any>;
    isWebTV: boolean;
    setIsWebTV: Dispatch<any>;
    isPwa: boolean;
    setIsPwa: Dispatch<any>;
    isPwaVertical: boolean;
    setIsPwaVertical: Dispatch<any>;
    isPwaHorizontal: boolean;
    setIsPwaHorizontal: Dispatch<any>;
    isSmartTV: boolean;
    setIsSmartTV: Dispatch<any>;

    isTizen: boolean;
    setIsTizen: Dispatch<any>;
    isApple: boolean;
    setIsApple: Dispatch<any>;
    isWebos: boolean;
    setIsWebos: Dispatch<any>;
    isAndroidTV: boolean;
    setIsAndroidTV: Dispatch<any>;
    isFireTV: boolean;
    setIsFireTV: Dispatch<any>;
    isAppleTV: boolean;
    setIsAppleTV: Dispatch<any>;
    isSetTopBox: boolean;
    setIsSetTopBox: Dispatch<any>;
    isChrome: boolean;
    setIsChrome: Dispatch<any>;
    isSafari: boolean;
    setIsSafari: Dispatch<any>;
    isAndroid: boolean;
    setIsAndroid: Dispatch<any>;
    isIos: boolean;
    setIsIos: Dispatch<any>;
    isKaiOs: boolean;
    setIsKaiOs: Dispatch<any>;

    ipAddress: string;
    setIpAddress: Dispatch<any>;
    uniqueId: string;
    setUniqueId: Dispatch<any>;
    userAgent: string;
    setUserAgent: Dispatch<any>;
    deviceType: string;
    setDeviceType: Dispatch<any>;
    networkType: string;
    setNetworkType: Dispatch<any>;
    provider: string;
    setProvider: Dispatch<any>;

    isLauncher: boolean;
    setIsLauncher: Dispatch<any>;
    version: string;
    setVersion: Dispatch<any>;
    packageId: string;
    setPackageId: Dispatch<any>;
    clockSetting: string;
    setClockSetting: Dispatch<any>;
    menuWidth: number;
    setMenuWidth: Dispatch<any>;
}
export const DeviceContext = React.createContext<DeviceContext>(null);

export function DeviceProvider({ children }: { children: any }) {
    const [formFactor, setFormFactor] = useState('');
    const [model, setModel] = useState('');
    const [type, setType] = useState('');
    const [os, setOs] = useState('');
    const [osVersion, setOsVersion] = useState('');
    const [fontType, setFontType] = useState('Lato');
    const [number, setNumber] = useState('');

    const [isPhone, setIsPhone] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [isTelevision, setIsTelevision] = useState(false);
    const [isPwaHorizontal, setIsPwaHorizontal] = useState(false);
    const [isPwaVertical, setIsPwaVertical] = useState(false);
    const [isPwa, setIsPwa] = useState(false);
    const [isWebTV, setIsWebTV] = useState(false);

    const [isTizen, setIsTizen] = useState(false);
    const [isApple, setIsApple] = useState(false);
    const [isWebos, setIsWebos] = useState(false);
    const [isAndroidTV, setIsAndroidTV] = useState(false);
    const [isFireTV, setIsFireTV] = useState(false);
    const [isSetTopBox, setIsSetTopBox] = useState(false);
    const [isChrome, setIsChrome] = useState(false);
    const [isSafari, setIsSafari] = useState(false);
    const [isAndroid, setIsAndroid] = useState(false);
    const [isAppleTV, setIsAppleTV] = useState(false);
    const [isIos, setIsIos] = useState(false);
    const [isKaiOs, setIsKaiOs] = useState(false);
    const [isSmartTV, setIsSmartTV] = useState(false);

    const [ipAddress, setIpAddress] = useState('');
    const [networkType, setNetworkType] = useState('uknown');
    const [provider, setProvider] = useState('uknown');
    const [uniqueId, setUniqueId] = useState('');
    const [userAgent, setUserAgent] = useState('');
    const [deviceType, setDeviceType] = useState('');

    const [isLauncher, setIsLauncher] = useState(false);
    const [version, setVersion] = useState(getGlobals().App_Version);
    const [appName, setAppName] = useState(getGlobals().AppName);

    const [packageId, setPackageId] = useState(getGlobals().AppPackageID);
    const [clockSetting, setClockSetting] = useState('hh:mm A');
    const [menuWidth, setMenuWidth] = useState(0);

    return (
        <DeviceContext.Provider
            value={{
                provider,
                setProvider,
                os,
                setOs,
                osVersion,
                setOsVersion,
                formFactor,
                setFormFactor,
                model,
                setModel,
                type,
                setType,
                fontType,
                setFontType,
                number,
                setNumber,

                isPhone,
                setIsPhone,
                isTablet,
                setIsTablet,
                isTelevision,
                setIsTelevision,
                isPwaVertical,
                setIsPwaVertical,
                isPwaHorizontal,
                setIsPwaHorizontal,
                isPwa,
                setIsPwa,
                isWebTV,
                setIsWebTV,

                isTizen,
                setIsTizen,
                isApple,
                setIsApple,
                isWebos,
                setIsWebos,
                isAndroidTV,
                setIsAndroidTV,
                isFireTV,
                setIsFireTV,
                isSetTopBox,
                setIsSetTopBox,
                isChrome,
                setIsChrome,
                isSafari,
                setIsSafari,
                isAndroid,
                setIsAndroid,
                isAppleTV,
                setIsAppleTV,
                isIos,
                setIsIos,
                isKaiOs,
                setIsKaiOs,
                isSmartTV,
                setIsSmartTV,

                ipAddress,
                setIpAddress,
                uniqueId,
                setUniqueId,
                userAgent,
                setUserAgent,
                deviceType,
                setDeviceType,

                isLauncher,
                setIsLauncher,
                appName,
                setAppName,
                version,
                setVersion,
                packageId,
                setPackageId,
                clockSetting,
                setClockSetting,
                menuWidth,
                setMenuWidth,
                networkType,
                setNetworkType,
            }}
        >
            {children}
        </DeviceContext.Provider>
    );
}

// export function useUser() {
//     const context = React.useContext(DeviceContext);
//     return context;
// }
