import { Channel } from '@scriptx-com/tv-guide';
import { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../context/appContext';

export const MINUTES_IN_DAY = 1440;

const timestampToMinutes = (ts: number, startOfTheDayTimestamp: number) => {
    return (ts * 1000 - startOfTheDayTimestamp) / 60000;
};

const getStartOfTheDayTimestamp = (selectedDate: number): number => {
    let d = new Date(selectedDate);
    d.setHours(0, 0, 0, 0);
    return d.getTime();
};

const fillEmptyValues = (channel: Channel, startOfTheDayTimestamp: number) => {
    for (let index = 0; index <= 1440; index += 60) {
        const tsToCompare = +new Date(startOfTheDayTimestamp + index * 60000) / 1000;
        const program = channel.programs.find((p) => p.start <= tsToCompare && p.end > tsToCompare);
        if (!program) {
            const newProgram = {
                title: '',
                image: '',
                start: +new Date(startOfTheDayTimestamp + index * 60000) / 1000,
                end: +new Date(startOfTheDayTimestamp + (index + 60) * 60000) / 1000,
                data: { description: 'Not available' },
            };
            channel.programs.push(newProgram);
        }
    }
};

const useDataNormalizer = (data: any, selectedDate: number) => {
    const [normalizedData, setNormalizedData] = useState<Channel[]>([]);
    const [newDate, setNewDate] = useState(selectedDate);
    const startOfTheDayTimestamp = useMemo(() => getStartOfTheDayTimestamp(selectedDate), [selectedDate]);
    const appContext = useContext(AppContext);

    useEffect(() => {
        const holder: Channel[] = [];
        for (let i = 0; i < data.length; i++) {
            const { n, d, m, ca } = data[i];
            const channel: Channel = {
                title: n ?? ' ',
                no: d,
                icon: appContext.cloudUrl + m,
                programs: [],
                icons: ca != undefined && ca == true ? ['https://cloudtv.akamaized.net/Backup/history.png'] : [],
            };

            for (let j = 0; j < data[i]?.epg?.length; j++) {
                const { n, s, e, m, d } = data[i]?.epg[j];
                const startTimestampToMinutes = timestampToMinutes(s, startOfTheDayTimestamp);
                if (inRange(startTimestampToMinutes)) {
                    channel.programs.push({
                        title: n,
                        start: s,
                        end: e,
                        image: m,
                        // description: d,
                        data: { program_index: j, channel_index: i, description: d },
                    });
                }
            }
            fillEmptyValues(channel, startOfTheDayTimestamp);
            var test = holder.find((h) => h.title == channel.title && h.no == channel.no);
            if (test == undefined) {
                holder.push(channel);
            }
        }
        setNormalizedData(holder);
        setNewDate(selectedDate);
    }, [data, selectedDate]);

    const inRange = (startTimestampToMinutes: number) => startTimestampToMinutes >= 0 && startTimestampToMinutes < MINUTES_IN_DAY;

    return { normalizedData, selectedDate: newDate };
};

export default useDataNormalizer;
