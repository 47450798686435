import * as React from 'react';
import { BackHandler } from 'react-native';
import { useNavigationBuilder, StackRouter, StackActions, createNavigatorFactory } from '@react-navigation/native';
import { screensEnabled } from 'react-native-screens';
import SideNavigation from './SideNavigation';

function SideNavigator({ initialRouteName, children, screenOptions, drawerContent, width, ...rest }) {
    if (!screensEnabled()) {
        throw new Error('Native stack is only available if React Native Screens is enabled.');
    }

    const { state, navigation, descriptors } = useNavigationBuilder(StackRouter, {
        initialRouteName,
        children,
        screenOptions,
    });

    React.useEffect(() => {
        const backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
            return true;
        });

        return () => {
            backHandler.remove();
        };
    }, []);

    const tabPressEventHandler = React.useCallback(() => {
        const isFocused = navigation.isFocused();
        requestAnimationFrame(() => {
            if (state.index > 0 && isFocused) {
                navigation.dispatch({
                    ...StackActions.popToTop(),
                    target: state.key,
                });
            }
        });
    }, [navigation, state.index, state.key]);

    React.useEffect(() => {
        navigation.addListener('tabPress', tabPressEventHandler);
        return () => navigation.removeListener('tabPress', tabPressEventHandler);
    }, [navigation, state.index, tabPressEventHandler]);

    return <SideNavigation width={width} state={state} descriptors={descriptors} navigation={navigation} drawerContent={drawerContent} {...rest} />;
}

export const createTVSideNavigator = createNavigatorFactory(SideNavigator);
